import { coreInstance as axios, api } from 'settings';
import { logger } from 'helpers';
import * as actionTypes from './actionTypes';

const getTimezonesStart = () => {
	return {
		type: actionTypes.GET_TIMEZONES_START,
	};
};

const getTimezonesSuccess = (data) => {
	return {
		type: actionTypes.GET_TIMEZONES_SUCCESS,
		data: data,
	};
};

export const getTimezones = () => {
	return (dispatch) => {
		dispatch(getTimezonesStart());

		axios
			.get(api.timezones.index)
			.then((response) => {
				logger('[Action] Get states');

				dispatch(getTimezonesSuccess(response.data.data));
			})
			.catch(() => {
				logger('[Action] Get states fail');
			});
	};
};
