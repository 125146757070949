import React from 'react';
import listConfig from './Configs/list';

import s from '../style.module.scss';
import i18next from 'i18next';

const HistoryList = (props) => {
	const { checkHandler } = props;

	return (
		<div className={s.pane}>
			<div className={s.paneHead}>
				<h3>{i18next.t('history')}</h3>
			</div>
			<div className={s.paneBody}>
				<ul className={s.linkList}>
					{listConfig.map((el, index) => (
						<li key={index}>
							<span onClick={() => checkHandler(el.name, index)}>
								{i18next.t(el.displayText)}
							</span>
						</li>
					))}
				</ul>
			</div>
		</div>
	);
};

export default HistoryList;
