import React from 'react';
import T from 'prop-types';

const WarningOne = (props) => (
	<svg {...props} className={props.className}>
		<path
			d='M10.77 14.641a1.111 1.111 0 0 0 0-1.554 1.093 1.093 0 0 0-.77-.32 1.09 1.09 0 0 0-1.087 1.097A1.09 1.09 0 0 0 10 14.962c.287 0 .567-.116.77-.32zm.1-3.645V6.912a.874.874 0 0 0-.87-.878c-.48 0-.87.393-.87.878v4.084c0 .484.39.878.87.878s.87-.394.87-.878zm-8.507 5.248h15.274L10 2.657 2.363 16.244zM19.13 18H.87a.868.868 0 0 1-.752-.436.885.885 0 0 1-.005-.875L9.243.445A.869.869 0 0 1 10 0c.313 0 .602.17.756.445l9.13 16.244a.884.884 0 0 1-.004.875.868.868 0 0 1-.752.436z'
			opacity={props.opacity}
		/>
	</svg>
);

WarningOne.defaultProps = {
	fill: '#999',
	opacity: 0.6,
	width: 20,
	height: 18,
	viewBox: '0 0 20 18',
};

WarningOne.propTypes = {
	className: T.string,
};

export default WarningOne;
