import React from 'react';
import T from 'prop-types';

const AlertIcon = (props) => (
	<svg {...props} className={props.className}>
		<path
			d='M9.99526 15.6479C10.2655 15.6479 10.4904 15.5598 10.6699 15.3834C10.8494 15.207 10.9392 14.9837 10.9392 14.7135C10.9392 14.4432 10.85 14.2173 10.6717 14.0358C10.4934 13.8544 10.2691 13.7636 9.99881 13.7636C9.72854 13.7636 9.50366 13.8542 9.32414 14.0354C9.14463 14.2166 9.05487 14.4423 9.05487 14.7126C9.05487 14.9829 9.14404 15.2063 9.32237 15.383C9.50068 15.5596 9.72498 15.6479 9.99526 15.6479ZM9.23207 11.7391H10.9097V5.40894H9.23207V11.7391ZM10.0044 20.5729C8.6221 20.5729 7.32487 20.3112 6.1127 19.7879C4.90054 19.2645 3.84037 18.5488 2.93222 17.6406C2.02407 16.7325 1.30832 15.672 0.784982 14.4591C0.261661 13.2463 0 11.948 0 10.5643C0 9.18063 0.261661 7.8821 0.784982 6.66871C1.30832 5.45531 2.02375 4.39853 2.93128 3.49837C3.83883 2.59823 4.89917 1.88561 6.11231 1.36052C7.32546 0.835424 8.62404 0.572876 10.008 0.572876C11.3921 0.572876 12.691 0.835276 13.9049 1.36007C15.1187 1.88486 16.1756 2.59707 17.0754 3.4967C17.9753 4.39633 18.6877 5.45423 19.2126 6.67039C19.7375 7.88656 20 9.18619 20 10.5693C20 11.9521 19.7374 13.2496 19.2123 14.4617C18.6872 15.6738 17.9746 16.7322 17.0745 17.6368C16.1743 18.5415 15.1165 19.257 13.901 19.7833C12.6856 20.3097 11.3867 20.5729 10.0044 20.5729Z'
			fill='#DF0C0C'
		/>
	</svg>
);

AlertIcon.defaultProps = {
	fill: '#6AB221',
	width: 24,
	height: 24,
	viewBox: '0 0 24 24',
};

AlertIcon.propTypes = {
	className: T.string,
};

export default AlertIcon;
