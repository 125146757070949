import { coreInstance as axios, api } from 'settings';
import { logger } from 'helpers';
import { loadingOn, loadingOff } from 'store/actions';
import * as actionTypes from '../actionTypes';

export const setSearchParam = (params) => {
	return {
		type: actionTypes.SET_SEARCH_PARAM,
		params: params,
	};
};

const getProfilesStart = () => {
	return {
		type: actionTypes.GET_PROFILES_START,
	};
};

const getProfilesSuccess = (data, isMerge) => {
	return {
		type: actionTypes.GET_PROFILES_SUCCESS,
		data: data,
		isMerge: isMerge,
	};
};

export const getProfiles = (options, isMerge) => {
	return (dispatch) => {
		isMerge || dispatch(getProfilesStart());

		axios
			.get(api.concentrator_profiles.index, { params: options })
			.then((response) => {
				logger('[Action] Get profiles');

				dispatch(getProfilesSuccess(response.data.data, isMerge));
			})
			.catch(() => {
				logger('[Action] Get profiles fail');
			});
	};
};

const getProfileByIdStart = () => {
	return {
		type: actionTypes.GET_PROFILES_BY_ID_START,
	};
};

const getProfileByIdSuccess = (data) => {
	return {
		type: actionTypes.GET_PROFILES_BY_ID_SUCCESS,
		data: data,
	};
};

export const getProfileById = (id, options) => {
	return (dispatch) => {
		dispatch(getProfileByIdStart());

		axios
			.get(`${api.concentrator_profiles.index}/${id}`, { params: options })
			.then((response) => {
				logger('[Action] Get profile by ID');

				dispatch(getProfileByIdSuccess(response.data.data));
			})
			.catch(() => {
				logger('[Action] Get profile by ID fail');
			});
	};
};

export const getDataLogs = (link) => {
	return async (dispatch) => {
		dispatch(loadingOn());

		try {
			const response = await axios.get(link.slice(7));
			window.open(response.data.data, '_blank');
			dispatch(loadingOff());
		} catch (e) {
			dispatch(loadingOff());
			console.error(e);
		}
	};
};

const getSearchDataStart = () => {
	return {
		type: actionTypes.GET_SEARCH_DATA_START,
	};
};

const getSearchDataSuccess = (data) => {
	return {
		type: actionTypes.GET_SEARCH_DATA_SUCCESS,
		data: data,
	};
};

export const getSearchData = (args) => {
	const { viewMode, params } = args;
	let apiUri = api.concentrator_profiles.index;

	if (viewMode === 'map') {
		// apiUri = api.concentrator_locations.all;
		params.withNotEmpty = 'location';
	}

	return (dispatch) => {
		dispatch(getSearchDataStart());

		axios
			.get(apiUri, { params })
			.then((response) => {
				logger('[Action] Get profiles');

				dispatch(getSearchDataSuccess(response.data.data));
			})
			.catch(() => {
				logger('[Action] Get profiles fail');
			});
	};
};

export const clearSearchData = () => {
	return {
		type: actionTypes.CLEAR_SEARCH_DATA,
	};
};

export const setFilter = ({ key, item, isMulti, provider }) => {
	return {
		type: actionTypes.SET_FILTER,
		key,
		item,
		isMulti,
		provider,
	};
};

export const removeFilter = (key, id) => {
	return {
		type: actionTypes.REMOVE_FILTER,
		key,
		id,
	};
};
