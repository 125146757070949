import React, { Component } from 'react';
import { Tabs, ConcentratorsMap, Modal } from 'components';
import { connect } from 'react-redux';
import { permissions } from 'helpers';
import * as actions from 'store/actions';
import { makeGetAllEngineeringState } from 'store/selectors';
import RecentError from '../Dashboard/RecentError';
import ColumnLife from '../Dashboard/ColumnLife';
import CurrentlyConnected from '../Dashboard/CurrentlyConnected';
import SoftwareVersion from '../Dashboard/SoftwareVersion';
import BatteryStatus from '../Dashboard/BatteryStatus';
import SystemPower from './SystemPower';
import FlowSettings from './FlowSettings';
import O2Purity from './O2Purity';
import ErrorStatusPopup from './Popup';
import LifeClock from './LifeClock';
import { modelsParams } from './Configs/paramsCreator';
import { getParam } from '../Dashboard/Configs/creator';
import actionsCreator from './Configs/actionsCreator';
import { actionsParams } from './Configs/constants';
import { Info, Search } from 'components/Icons';
import ReactTooltip from 'react-tooltip';
import { routers } from 'settings';
import { Link } from 'react-router-dom';

import s from './style.module.scss';
import ReactGA from 'react-ga';
import ItemModal from '../Dashboard/ItemModal';
import { withTranslation } from 'react-i18next';

class EngineeringDashboard extends Component {
	constructor(props) {
		super(props);

		this.state = {
			filters: {},
			activeTab: 0,
			modal: {
				isOpen: false,
				isCountOpen: false,
			},
		};

		const successHandler = (id) => {
			// TODO: remove hack after refactoring ProviderCodesSelect component;
			this.state.filters.modelIds = [id];
			return props.setDashParam(
				{
					search: { concentrator_model_id: [id] },
					searchFields: { concentrator_model_id: 'in' },
				},
				actionsCreator(actionsParams.DASH_PARAMS),
			);
		};

		props.getModels({ params: modelsParams, successHandler });

		if (permissions.check('providers.main.filter')) {
			props.getProviders();
		} else {
			props.getPinCodes();
		}
		this.inogenProvidersFlag = true;
		this.values = [];
		this.InogenFlag = false;
		this.NonInogenflag = false;
	}

	componentDidUpdate(prevProps) {
		const { params } = this.props;
		const isParamsChange = params !== prevProps.params;

		if (isParamsChange) {
			this.props.getRecentError({
				params,
				actionTypes: actionsCreator(actionsParams.MOST_RECENT_ERROR),
			});
			this.props.getConnected({
				params,
				actionTypes: actionsCreator(actionsParams.CURRENTLY_CONNECTED),
			});
			this.props.getConnectedByTime({
				params,
				days: 30,
				actionTypes: actionsCreator(actionsParams.APP_CONNECTED),
			});
			this.props.getColumnLife({
				params,
				actionTypes: actionsCreator(actionsParams.COLUMN_LIFE),
			});
			this.props.getSoftwareVersions({
				params,
				actionTypes: actionsCreator(actionsParams.SOFTWARE_VERSIONS),
			});
			this.props.getO2Purity({ params });
			this.props.getLifeClock({ params });
			this.props.getSystemPower({ params });
			this.props.getBatteryStatus({ params });
			this.props.getBatteryStatusById({ params });
			this.props.getFlowSettings({ params });
			this.props.getLocations({ params, lastMin: 10 });
			this.props.getLocationsCount({ params, lastMin: 10 });
		}
	}

	componentWillUnmount() {
		this.props.resetDashParam(actionsCreator(actionsParams.RESET_PARAMS));
	}

	addModelHandler = (id) => {
		this.setState(
			{
				filters: {
					...this.state.filters,
					modelIds: [id],
				},
			},
			() => {
				this.props.setDashParam(
					getParam(this.state.filters),
					actionsCreator(actionsParams.DASH_PARAMS),
				);
			},
		);
	};

	onTabClick = (name, index, { id }) => {
		this.setState({
			activeTab: index,
		});
		this.addModelHandler(id);
	};

	filterAddHandler = (item, key, inogenProviders, parent) => {
		this.inogenProvidersFlag = inogenProviders;
		this.setState(
			(ps) => {
				const filters = { ...ps.filters };

				if (item) {
					filters[key]
						? filters[key][item.id]
							? delete filters[key][item.id]
							: (filters[key][item.id] = item)
						: (filters[key] = { [item.id]: item });
				} else {
					filters['pinCodes'] = {};
					filters['providers'] = {};
				}

				if (key === 'pinCodes') {
					const isAllChecked =
						parent && parent.provider_codes.every((el) => filters[key][el.id]);

					if (isAllChecked) {
						filters.providers
							? (filters.providers[parent.id] = parent)
							: (filters.providers = { [parent.id]: parent });

						parent.provider_codes.forEach((el) => {
							filters[key][el.id] && delete filters[key][el.id];
						});
					} else if (filters.providers) {
						filters.providers[item.provider_id] &&
							delete filters.providers[item.provider_id];
					}
				}

				if (key === 'providers' && filters.pinCodes) {
					filters[key][item.id] &&
						filters[key][item.id].provider_codes.forEach((el) => {
							filters.pinCodes[el.id] && delete filters.pinCodes[el.id];
						});
				}
				const filtererdArray =
					filters.providers && Object.values(filters.providers);
				this.values =
					filtererdArray &&
					filtererdArray.length > 0 &&
					filtererdArray.map((obj) => obj['name']);

				return { filters };
			},
			() => {
				const { providers = {}, pinCodes = {}, modelIds } = this.state.filters;
				const { setDashParam } = this.props;
				// const providers = Object.keys(providers).length ? Object.values(providers) : null;
				const pinCodeIds = Object.keys(pinCodes).length
					? Object.keys(pinCodes)
					: null;

				setDashParam(
					getParam({ providers, pinCodeIds, modelIds }),
					actionsCreator(actionsParams.DASH_PARAMS),
				);
			},
		);
	};

	pinHandler = (row) => {
		if (permissions.check('concentrators.details')) {
			const modal = { isOpen: true };

			ReactGA.event({
				category: 'Concentrators',
				action: 'Click',
				label: 'Amount of Clicks on Map Pins',
			});

			ReactGA.event({
				category: 'Concentrators',
				action: 'Click',
				label: 'Table row',
			});

			if (row.itemId && Array.isArray(row.itemId)) {
				if (row.itemId.length > 1) {
					modal.groups = row.itemId;
				} else {
					modal.rowId = row.itemId[0];
				}
			}

			if (row.model && Array.isArray(row.model)) {
				if (row.model.length > 1) {
					modal.groupsModels = row.model;
				} else {
					modal.concentrator_model_id = row.model[0];
				}
			} else {
				modal.rowId = row.id;
				modal.concentrator_model_id = row.concentrator_model_id;
			}

			this.setState({ modal });
		}
	};

	modalClose = () => {
		this.setState({ modal: { isOpen: false, isCountOpen: false } });
	};

	errorClickHandler = (errorData, params) => {
		this.props.getRecentErrorStatus({ errorData, params });
		this.setState({ modal: { isCountOpen: true } });
	};

	render() {
		const {
			loading,
			loadingOn,
			loadingOff,
			currentlyConnected,
			monthlyConnected,
			totalConnected,
			models,
			recentErrorData,
			recentErrorPeriod,
			appConnected,
			columnLifeData,
			softwareVersionsData,
			o2purity,
			lifeClock,
			flowSettings,
			systemPower,
			flowSettingsStatistics,
			locationData,
			countData,
			headingsData,
			currentUserData,
			params,
			t,
			batteryStatus,
			recentErrorStatus,
		} = this.props;

		const {
			activeTab,
			modal: { rowId, isOpen, groups, isCountOpen },
		} = this.state;
		this.InogenFlag = false;
		this.NonInogenflag = false;
		this.InogenProviders = true;
		let isInogenUserFlag = false;
		currentUserData &&
			currentUserData.roles &&
			currentUserData.roles.length > 0 &&
			currentUserData.roles.map((userData) => {
				if (userData.is_inogen) {
					isInogenUserFlag = true;
				}
				return userData;
			});
		this.values &&
			this.values.length > 0 &&
			this.values.map((data) => {
				if (
					data &&
					(data === t('inogen') || data === t('inogen_engineering'))
				) {
					this.InogenFlag = true;
				} else {
					this.NonInogenflag = true;
				}
				return data;
			});
		this.InogenFlag && this.NonInogenflag
			? (this.InogenProviders = false)
			: this.InogenFlag
			  ? (this.InogenProviders = true)
			  : this.NonInogenflag
			    ? (this.InogenProviders = false)
			    : (this.InogenProviders = true);
		let modelData = JSON.parse(JSON.stringify(models));
		if (!this.InogenProviders && !isInogenUserFlag) {
			modelData =
				models &&
				models.length > 0 &&
				models.filter((data) => {
					return data.id && data.id !== 12 && data.id !== 13;
				});
		}
		let modalContent;
		if (isCountOpen) {
			modalContent = (
				<ErrorStatusPopup
					ErrorStatusdata={recentErrorStatus}
					cancelHandler={this.modalClose}
				/>
			);
		}
		return (
			<div className={s.page}>
				<div className={s.content}>
					<h1 className={s.pageTitle}>
						{t('engineering_dashboard')}
						<div className={s.filters}></div>
					</h1>

					<Tabs
						className={s.tabs}
						activeClassName={s.activeTab}
						data={modelData}
						activeItem={activeTab}
						onItemClick={this.onTabClick}
					/>

					<div className={s.board}>
						<div className={s.full}>
							<div className={s.panel}>
								<div className={s.mapHead}>
									<h2 className={s.title}>
										{countData} {t('concentrators')}
										<Info
											fill='#a5bad4'
											data-tip=''
											data-for='Concentrators'
											className={s.infoIconTrigger}
										/>
										<span>{t('last_10_min')}</span>
									</h2>
									<ReactTooltip
										id='Concentrators'
										className={s.tooltip}
										place='bottom'
										type='light'
										effect='solid'
									>
										<Info fill='#a5bad4' className={s.infoIconTooltip} />
										<p>{t('total_number_of_concentrators_sent_10_minutes')}</p>
									</ReactTooltip>
									<Link
										to={routers.concentrators + '?search'}
										className={s.link}
									>
										<Search fill='#30adfa' className={s.icon} />{' '}
										<span>{t('search_concentrator_on_map')}</span>
									</Link>
								</div>
								<ConcentratorsMap
									data={locationData}
									headings={headingsData}
									onClick={this.pinHandler}
								/>
							</div>
						</div>
						<RecentError
							width='full'
							data={recentErrorData}
							period={recentErrorPeriod}
							total={appConnected}
							params={params}
							errorClick={this.errorClickHandler}
							env='engineering'
						/>
						<ColumnLife width='twoThird' data={columnLifeData} />
						<CurrentlyConnected
							currentlyConnected={currentlyConnected}
							total={totalConnected}
							monthlyConnected={monthlyConnected}
						/>
						<SystemPower
							data={systemPower}
							flowSettingsStatistics={flowSettingsStatistics}
							params={params}
							modelData={models[activeTab]}
						/>
						<FlowSettings data={flowSettings} />
						<O2Purity data={o2purity} />
						<SoftwareVersion data={softwareVersionsData} modelsData={models} />
						<LifeClock data={lifeClock} />
						<BatteryStatus data={batteryStatus} />
					</div>
				</div>

				<ItemModal
					itemId={rowId}
					isOpen={isOpen}
					groups={groups}
					modelId={this.state.modal.concentrator_model_id}
					onRowClicked={this.pinHandler}
					closeModal={this.modalClose}
					modelGroups={this.state.modal.groupsModels}
					loadingOn={loadingOn}
					env='engineering'
					loadingOff={loadingOff}
				/>
				<Modal
					className={s.modal}
					show={isCountOpen}
					modalClosed={this.modalClose}
					loading={loading}
				>
					{modalContent}
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = () => {
	const getAllEngineeringState = makeGetAllEngineeringState();

	return (props, state) => getAllEngineeringState(props, state);
};

const mapDispatchToProps = (dispatch) => ({
	setDashParam: (params, action) =>
		dispatch(actions.setDashParam(params, action)),
	getProviders: (args) => dispatch(actions.getProviders(args)),
	getPinCodes: (args) => dispatch(actions.getPinCodes(args)),
	getModels: (args) => dispatch(actions.getModels(args)),
	getRecentError: (args) => dispatch(actions.getRecentError(args)),
	getConnected: (args) => dispatch(actions.getConnected(args)),
	getSoftwareVersions: (args) => dispatch(actions.getSoftwareVersions(args)),
	getColumnLife: (args) => dispatch(actions.getColumnLife(args)),
	getConnectedByTime: (args) => dispatch(actions.getConnectedByTime(args)),
	getO2Purity: (args) => dispatch(actions.getO2Purity(args)),
	getCurrentUser: () => dispatch(actions.getCurrentUser()),
	getSystemPower: (args) => dispatch(actions.getSystemPower(args)),
	getBatteryStatus: (args) => dispatch(actions.getBatteryStatus(args)),
	getBatteryStatusById: (args) => dispatch(actions.getBatteryStatusById(args)),
	getRecentErrorStatus: (args) => dispatch(actions.getRecentErrorStatus(args)),
	getLifeClock: (args) => dispatch(actions.getLifeClock(args)),
	getFlowSettings: (args) => dispatch(actions.getFlowSettings(args)),
	resetDashParam: (args) => dispatch(actions.resetDashParam(args)),
	getLocations: (args) => dispatch(actions.getLocations(args)),
	getLocationsCount: (args) => dispatch(actions.getLocationsCount(args)),
});

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withTranslation()(EngineeringDashboard));
