import ReactGA from 'react-ga';
import { coreInstance as axios, api } from 'settings';
import { logger, permissions } from 'helpers';
import { loadingOn, loadingOff } from 'store/actions';
import objectToFormData from 'object-to-formdata';
import * as actionTypes from './actionTypes';

const getCurrentUserStart = () => {
	return {
		type: actionTypes.GET_CURRENT_USER_START,
	};
};

const getCurrentUserSuccess = (data) => {
	return {
		type: actionTypes.GET_CURRENT_USER_SUCCESS,
		data: data,
	};
};

const getCurrentUserFail = (error) => {
	return {
		type: actionTypes.GET_CURRENT_USER_FAIL,
		error: error,
	};
};

const updateUserProfile = (data) => {
	return {
		type: actionTypes.UPDATE_USER_PROFILE,
		data,
	};
};

export const getCurrentUser = () => {
	return (dispatch) => {
		const options = {
			params: {
				with: ['roles', 'socialUsers'],
				append: 'role_permissions',
			},
		};

		dispatch(getCurrentUserStart());
		dispatch(loadingOn());

		axios
			.get(api.user.index, options)
			.then((response) => {
				logger('[Action] Get current user');

				const { roles } = response.data.data;

				permissions.define = response.data.data;

				ReactGA.event({
					category: 'Authorization',
					action: 'Authorizations',
					label: `${roles[0] && roles[0].label} Log In`,
				});

				dispatch(getCurrentUserSuccess(response.data.data));
				dispatch(loadingOff());
			})
			.catch(() => {
				logger('[Action] Get current user fail');

				dispatch(getCurrentUserFail());
				dispatch(loadingOff());
			});
	};
};

export const saveUserProfile = (args = {}) => {
	const { name, icon, success, error } = args;
	const payload = {
		name,
		icon,
	};

	return (dispatch) => {
		dispatch(loadingOn());
		axios
			.post(api.user.index, objectToFormData(payload))
			.then((response) => {
				dispatch(updateUserProfile(response.data.data));
				success && success();
				dispatch(loadingOff());
			})
			.catch((err) => {
				err.response.data = {
					message: 'Images can be PNG or JPG and less than 10MB',
				};
				error && error(err.response.data);
				dispatch(loadingOff());
			});
	};
};
