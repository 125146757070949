import React from 'react';
import s from '../style.module.scss';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

const ChangeSummary = (props) => {
	let content = <div className={s.noSummary}>{i18next.t('no_summary')}</div>;
	const { t } = props;
	if (
		props.description &&
		Array.isArray(props.description) &&
		props.description.join()
	) {
		content = props.description.map((el, i) => (
			<p key={i}>
				{' '}
				{i + 1}. {el}
			</p>
		));
	}

	return (
		<div className={s.panel}>
			<div className={s.panelHead}>{t('change_summary_detail')}</div>
			<div className={s.panelContent}>{content}</div>
		</div>
	);
};

export default withTranslation()(ChangeSummary);
