import React from 'react';
import T from 'prop-types';

const Off = (props) => (
	<svg {...props} className={props.className}>
		<g fill='none' fillRule='evenodd'>
			<path
				stroke='#e04829'
				strokeLinecap='round'
				strokeWidth='2'
				d='M7.05 7.343a7 7 0 1 0 9.9 0'
			/>
			<rect fill='#e04829' width='2' height='6' x='11' y='4' rx='1' />
		</g>
	</svg>
);

Off.defaultProps = {
	width: 24,
	height: 24,
	viewBox: '0 0 24 24',
};

Off.propTypes = {
	className: T.string,
};

export default Off;
