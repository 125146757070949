import React from 'react';
import { Table, PatientTabs } from 'components';
import { AuxWrap } from 'hoc';
import tabsConfig from './Configs/tabs';
import tableConfig from './Configs/table';
import s from './style.module.scss';

const History = (props) => {
	const { activeTab, data, loading, tabClickHandler } = props;
	const tableClasses = [s.historyTable];

	if (!activeTab) {
		tableClasses.push(s.stateLeft);
	}

	return (
		<AuxWrap>
			<PatientTabs
				className={s.tableTabs}
				activeClassName={s.activeTab}
				data={tabsConfig}
				activeItem={activeTab}
				onItemClick={tabClickHandler}
			/>
			<div className={s.tabs_table_separator}></div>
			<div className={s.table}>
				<Table
					className={tableClasses.join(' ')}
					loading={loading}
					config={tableConfig[activeTab]}
					data={data}
				/>
			</div>
		</AuxWrap>
	);
};

export default History;
