import React, { Component } from 'react';
import s from './style.module.scss';
import { Button, Input } from 'components';
import { checkValidity } from 'helpers';
import i18next from 'i18next';
import * as actions from '../../../store/actions';
import { constants } from '../../../helpers';
import { connect } from 'react-redux';
import { makeGetPatientDataState } from '../../../store/selectors';
import { withTranslation } from 'react-i18next';

class Notification extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: false,
			isSendingNotification: false,
			controls: {
				elementConfigTitle: {
					type: 'text',
					elementConfig: {
						type: 'text',
						placeholder: 'title',
					},
					validation: {
						required: true,
						minLength: constants.minLength,
						maxLength: 50,
					},
					value: '',
					valid: true,
					errorMsg: i18next.t('provider_name_invalid'),
					touched: false,
				},

				elementConfigDescription: {
					type: 'textarea',
					elementConfig: {
						type: 'text',
						placeholder: 'Description',
					},
					validation: {
						required: false,
						minLength: constants.minLength,
						maxLength: 100,
					},
					value: '',
					valid: true,
					errorMsg: i18next.t('add_description'),
					touched: false,
				},

				elementConfigLink: {
					elementType: 'input',
					elementConfig: {
						type: 'text',
						placeholder: 'link_address',
					},
					value: '',
					validation: {
						required: false,
						isurl: true,
					},
					valid: true,
					errorMsg: i18next.t('link_unavailable'),
					touched: false,
				},
			},
		};
	}

	inputChangedHandler = (event, name) => {
		var value = '';
		if (event && event.target) {
			const target = event.target;
			value = target.value;
		}

		this.setState((prevState) => {
			const state = { ...prevState.controls };
			const controls = state[name];
			controls.value = value;
			controls.valid = checkValidity(value, controls.validation);
			return { controls: state };
		});
	};

	inputFocusOutHandler = (event, name) => {
		this.setState((prevState) => {
			const state = { ...prevState.controls };
			const controls = state[name];
			controls.touched = true;
			return { controls: state };
		});
	};
	notification = (event) => {
		event.preventDefault();
		const { serialNo, closeNotification } = this.props;
		const { controls } = this.state;
		this.setState({ isSendingNotification: true });
		let params = {
			title: controls.elementConfigTitle.value,
			body: controls.elementConfigDescription.value,
			concentrator_profiles_filter: `search=serial_number:${serialNo}&searchFields=serial_number:in`,
			push_type_id: constants.PUSH_TYPE_SERVICE,
			url_to_open: controls.elementConfigLink.value,
		};
		this.props.sendNotification(params, closeNotification);
	};
	fieldLengthValidation = (fieldKey) => {
		const { controls } = this.state;
		const length = controls[fieldKey].value.length;
		const maxLength = controls[fieldKey].validation.maxLength;

		return (
			<span>
				{length}/
				<span
					className={length >= maxLength ? s.red : length > 0 ? s.green : ''}
				>
					{maxLength - length}
				</span>
			</span>
		);
	};

	render() {
		const { controls, isLoading } = this.state;
		const isFormValid =
			controls.elementConfigTitle.valid &&
			controls.elementConfigDescription.valid &&
			controls.elementConfigLink.valid &&
			controls.elementConfigTitle.value &&
			!isLoading;

		return (
			<div>
				{/* /* <div className={s.main_form}> */}

				<div className={s.contentContainer}>
					<div className={s.addUserContainer}>
						{/* <form> */}
						<div className={s.notification_title}>
							<label className={s.title_label}>
								{i18next.t('title_placeholder')}{' '}
								<span className={s.mandatory}>{'*'}</span>
							</label>
							<Input
								className={'input'}
								newDesign={true}
								elementType={'input'}
								elementConfig={controls.elementConfigTitle.elementConfig}
								value={controls.elementConfigTitle.value}
								invalid={!controls.elementConfigTitle.valid}
								invalidMsg={controls.elementConfigTitle.errorMsg}
								touched={controls.elementConfigTitle.touched}
								changed={(event) =>
									this.inputChangedHandler(event, 'elementConfigTitle')
								}
								onBlur={(event) =>
									this.inputFocusOutHandler(event, 'elementConfigTitle')
								}
								maxlength={controls.elementConfigTitle.validation.maxLength}
							/>
						</div>
						<div className={s.alignCountValidation}>
							{this.fieldLengthValidation('elementConfigTitle')}
						</div>
						<div className={s.notification_description}>
							<label className={s.title_label}>
								{i18next.t('type_here_placeholder')}
							</label>
							<Input
								className={'textarea'}
								elementType={'textarea'}
								elementConfig={controls.elementConfigDescription.elementConfig}
								value={controls.elementConfigDescription.value}
								invalid={!controls.elementConfigDescription.valid}
								invalidMsg={controls.elementConfigDescription.errorMsg}
								touched={controls.elementConfigDescription.touched}
								changed={(event) =>
									this.inputChangedHandler(event, 'elementConfigDescription')
								}
								onBlur={(event) =>
									this.inputFocusOutHandler(event, 'elementConfigDescription')
								}
								maxlength={
									controls.elementConfigDescription.validation.maxLength
								}
							/>
						</div>
						<div className={s.alignCountValidation}>
							{this.fieldLengthValidation('elementConfigDescription')}
						</div>
						<div className={s.notification_link}>
							<label className={s.title_label}>
								{i18next.t('link_address_placeholder')}
							</label>
							<Input
								className={'input'}
								elementType={'input'}
								elementConfig={controls.elementConfigLink.elementConfig}
								value={controls.elementConfigLink.value}
								invalid={!controls.elementConfigLink.valid}
								invalidMsg={controls.elementConfigLink.errorMsg}
								touched={controls.elementConfigLink.touched}
								changed={(event) =>
									this.inputChangedHandler(event, 'elementConfigLink')
								}
								onBlur={(event) =>
									this.inputFocusOutHandler(event, 'elementConfigLink')
								}
							/>
						</div>

						{/* </form> */}
					</div>
					<div className={s.send_button}>
						<Button
							className={s.detailsButton}
							btnType='type-19'
							disabled={!isFormValid || this.state.isSendingNotification}
							clicked={this.notification}
						>
							{isLoading ? i18next.t('loading') : i18next.t('send')}
						</Button>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = () => {
	const getPatientDataState = makeGetPatientDataState();
	return (props, state) => getPatientDataState(props, state);
};

const mapDispatchToProps = (dispatch) => {
	return {
		sendNotification: (params, closeNotification) =>
			dispatch(actions.sendNotification(params, closeNotification)),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withTranslation()(Notification));
