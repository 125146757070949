import React from 'react';
import T from 'prop-types';

const Unplugged = (props) => (
	<svg {...props} className={props.className}>
		<path
			fill='#999'
			fillRule='nonzero'
			d='M5.057 16.29L2.808 18.54a1.06 1.06 0 1 1-1.498-1.498l2.269-2.269A8 8 0 0 1 14.773 3.578L17.04 1.31a1.06 1.06 0 1 1 1.498 1.498l-2.248 2.249A8 8 0 0 1 5.057 16.29zm1.428-1.427a6 6 0 0 0 8.378-8.378l-8.378 8.378zm-1.472-1.525l8.325-8.325a6 6 0 0 0-8.324 8.324z'
		/>
	</svg>
);

Unplugged.defaultProps = {
	fill: '',
	width: 20,
	height: 20,
	viewBox: '0 0 20 20',
};

Unplugged.propTypes = {
	className: T.string,
};

export default Unplugged;
