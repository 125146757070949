import i18next from 'i18next';

export const POCs = {
	G4: 10,
	G5: 11,
	G4HF: 12,
	G5V2: 13,
};

export const FLOW_SETTINGS_FILTERS = [
	{
		label: i18next.t('flow_settings') + ' 1',
		value: '1',
		isActive: false,
		devices: [POCs.G4, POCs.G5, POCs.G4HF, POCs.G5V2],
	},
	{
		label: i18next.t('flow_settings') + ' 2',
		value: '2',
		isActive: false,
		devices: [POCs.G4, POCs.G5, POCs.G4HF, POCs.G5V2],
	},
	{
		label: i18next.t('flow_settings') + ' 3',
		value: '3',
		isActive: false,
		devices: [POCs.G4, POCs.G5, POCs.G4HF, POCs.G5V2],
	},
	{
		label: i18next.t('flow_settings') + ' 4',
		value: '4',
		isActive: false,
		devices: [POCs.G5, POCs.G4HF, POCs.G5V2],
	},
	{
		label: i18next.t('flow_settings') + ' 5',
		value: '5',
		isActive: false,
		devices: [POCs.G5, POCs.G5V2],
	},
	{
		label: i18next.t('flow_settings') + ' 6',
		value: '6',
		isActive: false,
		devices: [POCs.G5, POCs.G5V2],
	},
];

export const POINTS = {
	x1: 'x1',
	x2: 'x2',
	x11: 'x11',
	x22: 'x22',
};
