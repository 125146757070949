import { coreInstance as axios, api } from 'settings';
import { logger } from 'helpers';
import * as actionTypes from '../actionTypes';

const getPushNotificationsTypesStart = () => {
	return {
		type: actionTypes.GET_PUSH_NOTIFICATIONS_TYPES_START,
	};
};

const getPushNotificationsTypesSuccess = (data) => {
	return {
		type: actionTypes.GET_PUSH_NOTIFICATIONS_TYPES_SUCCESS,
		data: data,
	};
};

export const getPushNotificationsTypes = (options) => {
	return (dispatch) => {
		dispatch(getPushNotificationsTypesStart());

		axios
			.get(api.push_notification_types.index, { params: options })
			.then((response) => {
				logger('[Action] Get push notification types');

				dispatch(getPushNotificationsTypesSuccess(response.data.data));
			})
			.catch(() => {
				logger('[Action] Get push notification types fail');
			});
	};
};
