import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
	data: [],
	loading: false,
};

const getProvidersNotifiableStart = (state) => {
	return updateObject(state, { loading: true });
};

const getProvidersNotifiableSuccess = (state, action) => {
	return updateObject(state, { data: action.data, loading: false });
};

const providersNotifiableData = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_PROVIDERS_NOTIFIABLE_START:
			return getProvidersNotifiableStart(state, action);
		case actionTypes.GET_PROVIDERS_NOTIFIABLE_SUCCESS:
			return getProvidersNotifiableSuccess(state, action);
		default:
			return state;
	}
};

export default providersNotifiableData;
