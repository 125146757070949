import React from 'react';
import { Table } from 'components';
import s from './style.module.scss';
import { coreInstance as axios, api } from 'settings';
import { useEffect, useState } from 'react';
import {
	ResponsiveContainer,
	LineChart,
	Line,
	XAxis,
	YAxis,
	Tooltip,
} from 'recharts';
import ps from '../Dashboard/Connected/style.module.scss';
import cs from 'classnames';
import moment from 'moment';

const TooltipContent = (props) => {
	const { active, payload } = props;

	if (active && payload) {
		const time = payload[0]?.payload?.time;
		return (
			<div className={s.tooltip}>
				<div className={s.value}>{payload ? payload[0].payload.count : ''}</div>
				<div>{moment(time).format('DD-MMM')}</div>
			</div>
		);
	}

	return null;
};

const DbStatistics = (props) => {
	// Do API call to get the latest records
	const [latestRecords, setLatestRecords] = useState([]);
	const [formattedSeriesData, setFormattedSeriesData] = useState([]);
	useEffect(() => {
		axios
			.get(api.db_statistics.index)
			.then((response) => {
				let tableRecords = response?.data?.data?.single_count_records;
				let groupedSingleData = tableRecords.reduce((acc, obj) => {
					let key = obj.category;
					if (!acc[key]) {
						acc[key] = [];
					}
					acc[key].push(obj);
					return acc;
				}, {});
				setLatestRecords(groupedSingleData);


				let seriesRecord = response?.data?.data?.series_records;
				let finalFormattedData = {}
				seriesRecord.forEach((item) => {
					let formattedItem = item?.query_output.map((output) => {
						return {
							connected_hour_at: output?.datetime,
							count: Number(output?.count),
							time: moment(output?.datetime).format('DD-MMM'),
						};
					});
					finalFormattedData[item.query_name] = formattedItem;
				});
				setFormattedSeriesData(finalFormattedData);


			})
			.catch((error) => {
				console.log('error', error);
			});
	}, []);

	const { loading } = props;

	// Create a table configuration object with 2 columns as title and count
	const tableConfig = {
		dataHead: [{ displayText: 'Title' }, { displayText: 'Count' }],
		dataBody: {
			title: {
				path: ['query_name'],
				view: ({ value }) => (value ? value : '-'),
			},
			count: {
				path: ['query_output'],
				view: ({ value }) => (value ? value : '-'),
			},
		},
	};

	return (
		<>
			<div className={s.statsContainer}>
				{/* Graphs data */}
				{Object.keys(formattedSeriesData).map((item) => {
					return (
						<div className={s.lineChartDiv} key={item}>
							<div className={cs(ps.panel, s.panel)}>
								<h2 className={ps.title}> {item} </h2>
								<ResponsiveContainer height={230}>
									<LineChart data={formattedSeriesData[item]} margin={{ top: 10, right: 30 }}>
										<XAxis reversed dataKey='time' />

										<YAxis
											allowDecimals={false}
											type='number'
											dataKey='count'
											width={40}
										/>

										<Line
											type='monotone'
											dataKey='count'
											stroke='#6ab221'
											strokeWidth={2}
											dot={false}
										/>

										<Tooltip content={<TooltipContent />} />
									</LineChart>
								</ResponsiveContainer>
							</div>
						</div>
					);
				})}




				
				{/* Iterate in loop for each category and display the table */}
				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						gap: '10px',
					}}
				>
					{Object.keys(latestRecords).map((category) => {
						return (
							<div key={category} style={{ width: '50%' }}>
								<h3>{category}</h3>
								<Table
									data={latestRecords[category]}
									config={tableConfig}
									loading={loading}
								/>
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
};

export default DbStatistics;
