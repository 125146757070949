import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
	data: null,
	loading: true,
	requestParams: {
		orderBy: 'version',
		sortedBy: 'asc',
	},
};

const getFirmwaresStart = (state) => {
	return updateObject(state, { loading: true });
};

const getFirmwaresSuccess = (state, action) => {
	return updateObject(state, { data: action.data, loading: false });
};

const setRequestParam = (state, action) => {
	const { search } = action.params;
	const params = { ...state.requestParams };

	params.search = { ...params.search, ...search };

	return updateObject(state, { requestParams: params });
};

const clearFirmwares = () => {
	return initialState;
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_ALL_FIRMWARES_START:
			return getFirmwaresStart(state, action);
		case actionTypes.GET_ALL_FIRMWARES_SUCCESS:
			return getFirmwaresSuccess(state, action);
		case actionTypes.SET_ALL_FIRMWARES_REQUEST_PARAM:
			return setRequestParam(state, action);

		case actionTypes.CLEAR_FIRMWARES:
			return clearFirmwares(state, action);

		default:
			return state;
	}
};

export default reducer;
