import React, { Fragment } from 'react';
import moment from 'moment';

import sLatest from '../newStyle.module.scss';
import i18next from 'i18next';

import { permissions } from '../../../helpers';

import { DeleteButton } from 'components/Icons';

const table = {
	dataHead: [
		{ displayText: i18next.t('version') },
		{ displayText: i18next.t('part_number') },
		{ displayText: i18next.t('concentrator_number') },
		{ displayText: i18next.t('modified_on'), textAlign: 'center' },
		{ displayText: i18next.t('created_by') },
		{ displayText: i18next.t('status'), textAlign: 'center' },
		{ displayText: i18next.t('action'), textAlign: 'center' },
	],
	dataBody: {
		version: {
			path: 'version',
			view: ({ value }) => (value ? value : '- -'),
		},
		part_number: {
			path: 'part_num',
			view: ({ value }) => (value ? value : '- -'),
		},
		count: {
			path: 'count',
			view: ({ value }) => (value ? value : '0'),
		},
		release_date: {
			path: 'released_at',
			textAlign: 'center',
			view: ({ value }) => (value ? moment(value).format('MM.DD.YYYY') : '- -'),
		},
		released_by: {
			path: 'created_by',
			view: ({ value }) => (value ? value : '- -'),
		},
		label: {
			path: 'status_id',
			textAlign: 'center',
			view: ({ value }) => {
				if (value === 1) {
					return (
						<span className={sLatest.latest}>{i18next.t('latest_status')}</span>
					);
				} else if (value === 2) {
					return (
						<span className={sLatest.upcoming}>
							{i18next.t('upcoming_status')}
						</span>
					);
				} else if (value === 3) {
					return (
						<span className={sLatest.history}>
							{i18next.t('history_status')}
						</span>
					);
				}
			},
		},

		delete: {
			path: 'count',
			textAlign: 'center',
			view: ({ props, row }) => {
				const countData = {
					countValue: row.status_id,
				};
				const clickDeleteToolTip = (event) => {
					event.stopPropagation();
					props.onDeleteItemClickToolTip(event, countData, row.version);
				};

				const clickHandler = (event) => {
					event.stopPropagation();
					props.onDeleteItemClick('deleteBtn');
				};
				const clickEnableDeleteToolTip = (event) => {
					event.stopPropagation();
					props.onDeleteItemClickToolTip(event, row);
				};
				if (!permissions.check('firmwares.delete')) {
					return <></>;
				}
				if (row.status_id === 5 && row.filepath) {
					return (
						<Fragment>
							<DeleteButton
								fill='#6D6D6D'
								height='20'
								onClick={clickHandler}
								onMouseOver={clickEnableDeleteToolTip}
							/>
						</Fragment>
					);
				} else {
					return (
						<Fragment>
							<DeleteButton
								fill='#B6B6B6'
								height='20'
								onMouseOver={clickDeleteToolTip}
							/>
						</Fragment>
					);
				}
			},
		},
	},
	rowView: (row, classesArr, style) => {
		if (row.status_id === 1) {
			classesArr.push(style['latestRow']);
		}
	},
};

export default table;
