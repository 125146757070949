import { coreInstance as axios, api } from 'settings';
import { logger } from 'helpers';
import * as actionTypes from './actionTypes';

const getCitiesStart = () => {
	return {
		type: actionTypes.GET_CITIES_START,
	};
};

const getCitiesSuccess = (data) => {
	return {
		type: actionTypes.GET_CITIES_SUCCESS,
		data: data,
	};
};

export const getCities = (args = {}) => {
	const { params } = args;

	return (dispatch) => {
		dispatch(getCitiesStart());

		axios
			.get(api.cities.index, { params })
			.then((response) => {
				logger('[Action] Get cities');

				dispatch(getCitiesSuccess(response.data.data));
			})
			.catch(() => {
				logger('[Action] Get cities fail');
			});
	};
};
