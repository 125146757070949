import React from 'react';
import T from 'prop-types';

const Profile = (props) => (
	<svg {...props} className={props.className}>
		<path d='M22 21a1 1 0 0 1-2 0 7.998 7.998 0 0 0-4.32-7.105 1 1 0 1 1 .922-1.775A9.998 9.998 0 0 1 22 21zM8.436 13.827a1.01 1.01 0 0 1-.103.061A7.998 7.998 0 0 0 4 21a1 1 0 0 1-2 0c0-3.623 1.944-6.9 5.01-8.668a6 6 0 1 1 1.426 1.494zM8 20h13a1 1 0 1 1 0 2H8a1 1 0 0 1 0-2zm4-7a4 4 0 1 0 0-8 4 4 0 0 0 0 8z' />
	</svg>
);

Profile.defaultProps = {
	fill: '#999',
	width: 24,
	height: 24,
	viewBox: '0 0 24 24',
};

Profile.propTypes = {
	className: T.string,
};

export default Profile;
