import i18next from 'i18next';
import moment from 'moment';
import { coreInstance as axios, api } from 'settings';
import { logger } from 'helpers';
import { toast } from 'helpers';

HTMLElement.prototype.click = function () {
	var evt = this.ownerDocument.createEvent('MouseEvents');
	evt.initMouseEvent(
		'click',
		true,
		true,
		this.ownerDocument.defaultView,
		1,
		0,
		0,
		0,
		0,
		false,
		false,
		false,
		false,
		0,
		null,
	);
	this.dispatchEvent(evt);
};

export const downloadReport = async (args) => {
	try {
		// Add last 1 year date in connected_at
		args.params.search = {
			...args.params.search,
			connected_at: [
				moment().utc().subtract(1, 'years').format('YYYY-MM-DDTHHmmss'),
			],
		};

		args.params.searchFields = {
			...args.params.searchFields,
			connected_at: '>=',
		};

		const options = {
			params: args.params,
			responseType: 'blob',
		};

		args.start();
		toast.success(i18next.t('report_download_start'));

		axios
			.get(api.concentrator_profiles.export, options)
			.then((response) => {
				logger('[Action] Get binary file');

				const fileName = args.params.export + '_Reports.xlsx';
				const url = window.URL.createObjectURL(new Blob([response.data]));

				try {
					if (navigator.appVersion.toString().indexOf('.NET') > 0)
						//download blob for IE
						window.navigator.msSaveBlob(response.data, fileName);
					else {
						const downloadLink = document.createElement('a');
						downloadLink.href = url;
						downloadLink.setAttribute('download', fileName);
						document.body.appendChild(downloadLink);
						downloadLink.click();
						downloadLink.remove();
					}
					toast.success(i18next.t('report_download_complete'));
					args.success();
				} catch (e) {
					toast.error(i18next.t('oops_something_went_wrong'));
					console.error(e);
				}
			})
			.catch(() => {
				logger('[Action] Get binary file fail');
				toast.error(i18next.t('oops_something_went_wrong'));
				args.error();
			});
	} catch (e) {
		toast.error(i18next.t('oops_something_went_wrong'));
		console.error(e);
	}
};
