import { coreInstance as axios, api } from 'settings';
import { logger } from 'helpers';
import * as actionTypes from './actionTypes';
import i18next from 'i18next';
import { getCurrentUser } from '../actions/user';

const getPortalConsentsStart = () => {
	return {
		type: actionTypes.GET_PORTAL_CONSENTS_START,
	};
};

const getPortalConsentsSuccess = (data) => {
	return {
		type: actionTypes.GET_PORTAL_CONSENTS_SUCCESS,
		data: data,
	};
};

const getUserConsentStart = () => {
	return {
		type: actionTypes.GET_USER_CONSENT_START,
	};
};

const getUserConsentSuccess = (data) => {
	return {
		type: actionTypes.GET_USER_CONSENT_SUCCESS,
		data: data,
	};
};

const addUserConsentStart = () => {
	return {
		type: actionTypes.ADD_USER_CONSENT_START,
	};
};

const addUserConsentSuccess = (data) => {
	return {
		type: actionTypes.ADD_USER_CONSENT_SUCCESS,
		data: data,
	};
};

export const getPortalConsents = (args = {}) => {
	const { params = {} } = args;
	const currentLang = i18next.language;

	params.search = { consent_type: 'terms_of_use_' + currentLang };
	params.searchFields = { consent_type: '=' };

	return (dispatch) => {
		dispatch(getPortalConsentsStart());

		axios
			.get(api.settings.index, { params })
			.then((response) => {
				logger('[Action] get Portal Consents');

				dispatch(getPortalConsentsSuccess(response.data.data));
			})
			.catch(() => {
				logger('[Action] get Portal Consents fail');
			});
	};
};

export const getUserConsent = (args = {}) => {
	const { params = {} } = args;
	// const currentLang = i18next.language;

	params.search = { consent_type: 'terms_of_use' };
	params.searchFields = { consent_type: '=' };

	return (dispatch) => {
		dispatch(getUserConsentStart());

		axios
			.get(api.consents.index, { params })
			.then((response) => {
				logger('[Action] Get consents');

				dispatch(getUserConsentSuccess(response.data.data));
			})
			.catch(() => {
				logger('[Action] Get consents fail');
			});
	};
};

export const addUserConsent = (args) => {
	const { data, success, error } = args;

	return (dispatch) => {
		dispatch(addUserConsentStart());

		axios
			.post(api.accept_consent.index, data)
			.then((response) => {
				logger('[Action] Add consent');
				dispatch(addUserConsentSuccess(response.data.data));
				dispatch(getCurrentUser());
				success && success(response.data);
			})
			.catch((err) => {
				logger('[Action] Add consent fail');
				error && error(err);
			});
	};
};
