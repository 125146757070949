import { coreInstance as axios, api } from 'settings';
import { logger } from 'helpers';
import * as actionTypes from './actionTypes';

const getParametersStart = () => {
	return {
		type: actionTypes.GET_ENGINEERINGPARAMS_START,
	};
};

const getParametersSuccess = (data) => {
	return {
		type: actionTypes.GET_ENGINEERINGPARAMS_SUCCESS,
		data: data,
	};
};

export const getParameters = () => {
	return (dispatch) => {
		dispatch(getParametersStart());
		axios
			.get(api.engineering_params.index)
			.then((response) => {
				logger('fetched Engineering Param');
				dispatch(getParametersSuccess(response.data));
			})
			.catch((error) => {
				logger('Error fetching Param:', error);
			});
	};
};
