import moment from 'moment';
import { coreInstance as axios, api } from 'settings';
import { logger } from 'helpers';
import * as actionTypes from '../../actions/actionTypes';

const getConnectedStart = (action) => {
	return {
		type: actionTypes[action],
	};
};

const getConnectedSuccess = (rows, action) => {
	return {
		type: actionTypes[action],
		rows: rows,
	};
};

export const getConnectedByTime = (args = { params: {}, days: 30 }) => {
	const params = {
		...args.params,
		search: {
			...args.params.search,
			connected_at: [
				moment().utc().subtract(args.days, 'days').format('YYYY-MM-DDTHHmmss'),
			],
		},
		searchFields: {
			...args.params.searchFields,
			connected_at: '>=',
		},
		count: 1,
	};

	const actions = args.actionTypes || {
		start: actionTypes.GET_APP_CONNECTED_START,
		success: actionTypes.GET_APP_CONNECTED_SUCCESS,
	};

	return (dispatch) => {
		dispatch(getConnectedStart(actions.start));

		axios
			.get(api.concentrator_profiles.index, { params })
			.then((response) => {
				logger('[Action] Get connected concentrators');

				dispatch(getConnectedSuccess(response.data.rows, actions.success));
			})
			.catch(() => {
				logger('[Action] Get connected concentrators fail');
			});
	};
};
