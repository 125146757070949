import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
	portal_consent: [],
	data: null,
	loading: false,
};

const getPortalConsentsStart = (state) => {
	return updateObject(state, { loading: true });
};

const getPortalConsentsSuccess = (state, action) => {
	return updateObject(state, { portal_consent: action.data, loading: false });
};

const getUserConsentStart = (state) => {
	return updateObject(state, { loading: true });
};

const getUserConsentSuccess = (state, action) => {
	return updateObject(state, { data: action.data, loading: false });
};

const addUserConsentStart = (state) => {
	return updateObject(state, { loading: true });
};

const addUserConsentSuccess = (state, action) => {
	return updateObject(state, { data: action.data, loading: false });
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_PORTAL_CONSENTS_START:
			return getPortalConsentsStart(state, action);
		case actionTypes.GET_PORTAL_CONSENTS_SUCCESS:
			return getPortalConsentsSuccess(state, action);

		case actionTypes.GET_USER_CONSENT_START:
			return getUserConsentStart(state, action);
		case actionTypes.GET_USER_CONSENT_SUCCESS:
			return getUserConsentSuccess(state, action);

		case actionTypes.ADD_USER_CONSENT_START:
			return addUserConsentStart(state, action);
		case actionTypes.ADD_USER_CONSENT_SUCCESS:
			return addUserConsentSuccess(state, action);

		default:
			return state;
	}
};

export default reducer;
