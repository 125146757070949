class Permissions {
	permissions = [];
	roles = [];

	set define(data) {
		if (!this.permissions.length) {
			this.permissions = data.role_permissions;
		}
		if (!this.roles.length) {
			this.roles = data.roles;
		}
	}

	get getList() {
		return this.permissions;
	}

	check() {
		let returned = true;

		for (var i = 0; i < arguments.length; i++) {
			returned = this.permissions.includes(arguments[i]) && returned;
		}

		return returned;
	}

	getRoles() {
		return this.roles;
	}

	checkRole(roleName) {
		if (!this.roles.length) {
			return false;
		}

		return this.roles.findIndex((role) => role.name === roleName) > -1;
	}
}

export default new Permissions();
