import React from 'react';
import { Table, Tabs } from 'components';
import { AuxWrap } from 'hoc';
import tabsConfig from './Configs/tabs';
import tableConfig from './Configs/table';

import s from '../style.module.scss';

const History = (props) => {
	const { activeTab, data, loading, tabClickHandler } = props;
	const tableClasses = [s.historyTable];

	if (!activeTab) {
		tableClasses.push(s.stateLeft);
	}

	return (
		<AuxWrap>
			<Tabs
				className={s.tableTabs}
				activeClassName={s.activeTab}
				data={tabsConfig}
				activeItem={activeTab}
				onItemClick={tabClickHandler}
			/>
			<Table
				className={tableClasses.join(' ')}
				loading={loading}
				config={tableConfig[activeTab]}
				data={data}
			/>
		</AuxWrap>
	);
};

export default History;
