import React from 'react';
import T from 'prop-types';

const Notifications = (props) => (
	<svg {...props} className={props.className}>
		<path d='M13.732 21h-3.464a2 2 0 0 0 3.464 0zm-4.561 0H7a3 3 0 0 1-3-3c0-1.732-.26-2.347 1.006-3.477.109-.098.592-.32.66-.38C5.865 13.966 6 13.636 6 13c0-.036.002-.072.006-.108l-.001-.491v-.523c-.001-.78-.001-1.108.002-1.171a5.997 5.997 0 0 1 3.088-4.958 3 3 0 1 1 5.81 0 5.997 5.997 0 0 1 3.088 4.958c.003.063.003.391.003 1.171l-.001.523v.491A1.3 1.3 0 0 1 18 13c0 .636.135.966.334 1.143.068.06.551.282.66.38C20.261 15.653 20 16.268 20 18a3 3 0 0 1-3 3h-2.17a3.001 3.001 0 0 1-5.66 0zM12 19h5a1 1 0 0 0 1-1c0-1.147.516-1.214-.318-1.968.122.107.247.157-.004.042a2.657 2.657 0 0 1-.677-.44c-.619-.553-.976-1.39-1-2.514a1.01 1.01 0 0 1-.007-.12l.001-.602V10.803a4 4 0 0 0-7.99 0V13c0 .04-.002.08-.006.12-.024 1.124-.381 1.961-1 2.514-.214.192-.437.33-.677.44-.25.115-.126.065-.004-.042C5.484 16.786 6 16.852 6 18a1 1 0 0 0 1 1h5zm.997-13.917a1 1 0 1 0-1.993 0 6.034 6.034 0 0 1 1.993 0z' />
	</svg>
);

Notifications.defaultProps = {
	fill: '#999',
	width: 24,
	height: 24,
	viewBox: '0 0 24 24',
};

Notifications.propTypes = {
	className: T.string,
};

export default Notifications;
