import React from 'react';
import T from 'prop-types';

const TrashBin = (props) => (
	<svg {...props} className={props.className}>
		<path d='M9 4V3a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v1h4a1 1 0 0 1 0 2H5a1 1 0 1 1 0-2h4zm8 5a1 1 0 0 1 2 0v11a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V9a1 1 0 1 1 2 0v11h10V9zm-6.5 2a.5.5 0 0 1 .5.5v6a.5.5 0 1 1-1 0v-6a.5.5 0 0 1 .5-.5zm3 0a.5.5 0 0 1 .5.5v6a.5.5 0 1 1-1 0v-6a.5.5 0 0 1 .5-.5z' />
	</svg>
);

TrashBin.defaultProps = {
	fill: '#E04829',
	width: 24,
	height: 24,
	viewBox: '0 0 24 24',
};

TrashBin.propTypes = {
	className: T.string,
};

export default TrashBin;
