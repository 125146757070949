import { coreInstance as axios, api } from 'settings';
import { logger } from 'helpers';
import * as actionTypes from './actionTypes';

const getErrorsStart = () => {
	return {
		type: actionTypes.GET_ERRORS_START,
	};
};

const getErrorsSuccess = (data) => {
	return {
		type: actionTypes.GET_ERRORS_SUCCESS,
		data: data,
	};
};

export const clearErrors = () => {
	return {
		type: actionTypes.CLEAR_ERRORS,
	};
};

export const getErrors = (args = {}) => {
	const { params, successHandler, errorHandler } = args;

	return (dispatch) => {
		dispatch(getErrorsStart());

		axios
			.get(api.concentrator_errors.index, { params })
			.then((response) => {
				logger('[Action] Get errors');

				dispatch(getErrorsSuccess(response.data.data));
				successHandler && successHandler(response.data.data);
			})
			.catch(() => {
				logger('[Action] Get errors fail');

				errorHandler && errorHandler();
			});
	};
};
