export default {
	index: '/',
	concentrators: '/concentrators',
	reports: '/reports',
	profile: '/profile',
	notifications: '/notifications',
	firmware: '/firmware',
	users: '/users',
	usersSearch: '/users/search',
	usersProvider: (id) => `/users/provider/${id ? id : ':id'}`,
	usersProviderEdit: (id) => `/users/provider/${id ? id : ':id'}/edit`,
	engineering: '/engineering',
	consent: '/consent',
	patientDetail: '/patient_detail',
	detail: '/error',
	patientDashboardIndex: '/patient_dashboard',
	appStatistics: '/app_statistics',
};
