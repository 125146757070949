import React from 'react';
import T from 'prop-types';

const PatientClose = (props) => (
	<svg {...props} className={props.className}>
		<path
			d='M8.7818 19.4934L13.5918 14.6834L18.4018 19.4934L19.9185 17.9767L15.1084 13.1667L19.9185 8.35675L18.4018 6.84007L13.5918 11.6501L8.7818 6.84007L7.26512 8.35675L12.0752 13.1667L7.26512 17.9767L8.7818 19.4934ZM13.5918 26.1667C11.8079 26.1667 10.1251 25.8255 8.54347 25.143C6.96181 24.4605 5.58236 23.5306 4.40512 22.3534C3.2279 21.1762 2.29805 19.7967 1.61555 18.2151C0.933047 16.6334 0.591797 14.9506 0.591797 13.1667C0.591797 11.3684 0.933047 9.67841 1.61555 8.09675C2.29805 6.51508 3.2279 5.13925 4.40512 3.96925C5.58236 2.79925 6.96181 1.873 8.54347 1.1905C10.1251 0.507998 11.8079 0.166748 13.5918 0.166748C15.3901 0.166748 17.0801 0.507998 18.6618 1.1905C20.2435 1.873 21.6193 2.79925 22.7893 3.96925C23.9593 5.13925 24.8855 6.51508 25.568 8.09675C26.2505 9.67841 26.5918 11.3684 26.5918 13.1667C26.5918 14.9506 26.2505 16.6334 25.568 18.2151C24.8855 19.7967 23.9593 21.1762 22.7893 22.3534C21.6193 23.5306 20.2435 24.4605 18.6618 25.143C17.0801 25.8255 15.3901 26.1667 13.5918 26.1667ZM13.5918 24.0001C16.6107 24.0001 19.171 22.9457 21.2726 20.8367C23.3743 18.7279 24.4251 16.1712 24.4251 13.1667C24.4251 10.1479 23.3743 7.58758 21.2726 5.48589C19.171 3.38423 16.6107 2.33339 13.5918 2.33339C10.5873 2.33339 8.03068 3.38423 5.9218 5.48589C3.81289 7.58758 2.75844 10.1479 2.75844 13.1667C2.75844 16.1712 3.81289 18.7279 5.9218 20.8367C8.03068 22.9457 10.5873 24.0001 13.5918 24.0001Z'
			fill='#999999'
		/>
	</svg>
);

PatientClose.defaultProps = {
	fill: 'none',
	width: 27,
	height: 27,
	viewBox: '0 0 27 27',
};

PatientClose.propTypes = {
	className: T.string,
};

export default PatientClose;
