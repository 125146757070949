import React from 'react';

import s from './style.module.scss';

const List = (props) => {
	return (
		<ul className={`${s.list} ${props.className || ''}`}>{props.children}</ul>
	);
};

export default List;
