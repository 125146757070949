import React from 'react';
import { View } from 'components/Icons';

import s from '../style.module.scss';

export default function (args) {
	this.currentPassword = {
		elementType: 'input',
		elementConfig: {
			type: 'password',
			placeholder: 'current_password',
		},
		theme: 'login',
		className: s.input,
		value: '',
		validation: {
			required: true,
			minLength: 6,
			maxLength: 255,
		},
		valid: false,
		errorMsg: 'auth_password_invalid',
		touched: false,
		icon: <View onClick={() => args.showHidePassword('currentPassword')} />,
	};
	this.password = {
		name: 'password',
		elementType: 'input',
		elementConfig: {
			type: 'password',
			placeholder: 'new_password',
		},
		theme: 'login',
		className: s.input,
		value: '',
		validation: {
			required: true,
			minLength: 6,
			maxLength: 255,
		},
		valid: false,
		errorMsg: 'auth_password_invalid',
		touched: false,
		icon: <View onClick={() => args.showHidePassword('password')} />,
	};
	this.repeatPassword = {
		name: 'passwordRepeat',
		elementType: 'input',
		elementConfig: {
			type: 'password',
			placeholder: 'auth_repeat_new_password',
		},
		theme: 'login',
		className: s.input,
		value: '',
		validation: {
			required: true,
			minLength: 6,
			maxLength: 255,
		},
		valid: false,
		errorMsg: 'auth_password_invalid',
		touched: false,
		icon: <View onClick={() => args.showHidePassword('repeatPassword')} />,
	};
}
