import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
	data: null,
};

const getFirmwareStart = (state) => {
	return updateObject(state, { loading: true });
};

const getFirmwareSuccess = (state, action) => {
	return updateObject(state, { data: action.data, loading: false });
};

const clearFirmware = () => {
	return initialState;
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_FIRMWARE_START:
			return getFirmwareStart(state, action);
		case actionTypes.GET_FIRMWARE_SUCCESS:
			return getFirmwareSuccess(state, action);
		case actionTypes.CLEAR_FIRMWARE_BY_ID:
			return clearFirmware(state, action);

		default:
			return state;
	}
};

export default reducer;
