import React, { PureComponent } from 'react';
import { Button } from 'components';
import { routers } from 'settings';
import { checkValidity, logger } from 'helpers';
import { AuxWrap } from 'hoc';
import s from './style.module.scss';
import i18next from 'i18next';
import ProviderFields from '../ProviderFields';
import { Country, State } from 'country-state-city';
import { countryConstants } from '../../Configs/constants';
import { Close } from 'components/Icons';

class AddProvider extends PureComponent {
	state = {
		controls: {
			provider: {
				checked: {},
			},
			newProvider: {
				name: {
					elementType: 'input',
					elementConfig: {
						type: 'text',
						placeholder: 'provider_name',
					},
					className: s.input,
					value: '',
					validation: {
						required: true,
						isname: true,
					},
					valid: false,
					errorMsg: 'provider_name_invalid',
					touched: false,
				},
				provider_admin: {
					elementType: 'input',
					elementConfig: {
						type: 'text',
						placeholder: 'provider_admin',
					},
					className: s.input,
					value: '',
					validation: {
						required: true,
						isEmail: true,
					},
					valid: false,
					errorMsg: 'auth_invalid_email_name',
					touched: false,
				},
				address_1: {
					elementType: 'input',
					elementConfig: {
						type: 'text',
						placeholder: 'address_line1',
					},
					className: s.input,
					value: '',
					validation: {
						required: true,
						isaddress: true,
						maxLength: 100,
					},
					valid: false,
					errorMsg: 'invalid_address',
					touched: false,
				},
				address_2: {
					elementType: 'input',
					elementConfig: {
						type: 'text',
						placeholder: 'address_line2',
					},
					className: s.input,
					value: '',
					validation: {
						required: false,
						isaddressoptional: true,
						maxLength: 100,
					},
					valid: true,
					errorMsg: 'invalid_address',
					touched: true,
				},
				phone_number: {
					elementType: 'input',
					elementConfig: {
						type: 'text',
						placeholder: 'provider_phone',
					},
					className: s.input,
					value: '',
					validation: {
						required: true,
						isPhone: true,
					},
					valid: false,
					errorMsg: 'invalid_phone',
					touched: false,
				},
				fax: {
					elementType: 'input',
					elementConfig: {
						type: 'text',
						placeholder: 'fax',
					},
					className: s.input,
					value: '',
					validation: {
						required: false,
						isfax: true,
					},
					valid: true,
					errorMsg: 'invalid_fax',
					touched: true,
				},
				email_address: {
					elementType: 'input',
					elementConfig: {
						type: 'text',
						placeholder: 'provider_email',
					},
					className: s.input,
					value: '',
					validation: {
						required: true,
						isEmail: true,
					},
					valid: false,
					errorMsg: 'auth_invalid_email_name',
					touched: false,
				},
				website_url: {
					elementType: 'input',
					elementConfig: {
						type: 'text',
						placeholder: 'weburl',
					},
					className: s.input,
					value: '',
					validation: {
						required: false,
						isurl: true,
					},
					valid: true,
					errorMsg: 'invalid_url',
					touched: false,
				},
				pin: {
					elementType: 'mask',
					elementConfig: {
						type: 'text',
						placeholder: '__',
						guide: true,
					},
					className: s.pinInput,
					value: '',
					validation: {
						required: true,
						// minLength: 2,
						// maxLength: 2
					},
					valid: false,
					errorMsg: '',
					touched: false,
				},
				city: {
					elementType: 'input',
					elementConfig: {
						type: 'text',
						placeholder: 'city',
					},
					className: s.input,
					value: '',
					validation: {
						required: true,
						iscities: true,
					},
					valid: false,
					errorMsg: 'invalid_city_name',
					touched: false,
				},
				postal_code: {
					elementType: 'input',
					elementConfig: {
						type: 'text',
						placeholder: 'postal_code',
					},
					className: s.input,
					value: '',
					validation: {
						required: true,
						postal: false,
						isCanadianPostal: false,
						isUSPostal: true,
					},
					valid: false,
					errorMsg: 'invalid_postal_code',
					touched: false,
				},
				customer_care_number: {
					elementType: 'input',
					elementConfig: {
						type: 'text',
						placeholder: 'customer_care_number',
					},
					className: s.input,
					value: '',
					validation: {
						required: true,
						isPhone: true,
					},
					valid: false,
					errorMsg: 'invalid_phone',
					touched: false,
				},
				info: {
					elementType: 'textarea',
					elementConfig: {
						type: 'textarea',
						placeholder: 'info_optional',
					},
					className: s.textarea,
					value: '',
					validation: {
						required: false,
						isinfo: true,
						maxLength: 100,
					},
					valid: true,
					errorMsg: 'invalid_info',
					touched: true,
				},
			},
		},
		providerView: 'new',
		codePartTwo: null,
		countries: [],
		selectedCountry: Country.getAllCountries().find(
			(country) => country.isoCode === 'US',
		),
		selectedState: null,
		statesOfSelectedCountry: State.getStatesOfCountry(
			Country.getAllCountries().find((country) => country.isoCode === 'US')
				?.isoCode,
		),
		country_code: Country.getAllCountries().find(
			(country) => country.isoCode === 'US',
		).phonecode,
		isLoading: false,
	};
	componentDidMount() {
		logger('[AddProvider] componentDidMount');
	}

	onItemClick = (data, type) => {
		this.setState((prevState) => {
			const controls = { ...prevState.controls };

			controls[type].checked = { [data.id]: data };

			return { controls: controls };
		});
	};

	inputChangedHandler = (event, name) => {
		var value = '';
		if (event && event.target) {
			const target = event.target;
			value = target.value;
		} else {
			value = name ? event : event;
		}
		this.setState((prevState) => {
			const state = { ...prevState.controls };
			const controls = state.newProvider[name];
			let formIsValid = true;
			controls.value = value;
			controls.valid = checkValidity(value, controls.validation);
			for (let control in state.newProvider) {
				if (
					control === 'name' ||
					control === 'email_address' ||
					control === 'address_1' ||
					control === 'city' ||
					control === 'postal_code' ||
					control === 'provider_admin' ||
					control === 'phone_number' ||
					control === 'customer_care_number'
				) {
					formIsValid = state.newProvider[control].valid && formIsValid;
				}
			}
			return {
				controls: state,
				formIsValid: formIsValid,
				formValidMessage: null,
			};
		});
	};
	inputFocusOutHandler = (event, name) => {
		this.setState((prevState) => {
			const state = { ...prevState.controls };
			const controls = state.newProvider[name];
			controls.touched = true;
			return { controls: state };
		});
	};

	handleCountryChange = (selectedCountry) => {
		this.setState((prevState) => {
			const state = { ...prevState.controls };

			// Clear phone_number field
			state.newProvider.phone_number.value = '';
			state.newProvider.phone_number.valid = false;
			state.newProvider.phone_number.touched = false;

			// Clear city field
			state.newProvider.city.value = '';
			state.newProvider.city.valid = false;
			state.newProvider.city.touched = false;

			// Clear postal_code field
			state.newProvider.postal_code.value = '';
			state.newProvider.postal_code.valid = false;
			state.newProvider.postal_code.touched = false;
			if (countryConstants.includes(selectedCountry.isoCode)) {
				state.newProvider.postal_code.validation.isCanadianPostal = false;
				state.newProvider.postal_code.validation.isUSPostal = true;
			} else if (selectedCountry.isoCode === 'CA') {
				state.newProvider.postal_code.validation.isCanadianPostal = true;
				state.newProvider.postal_code.validation.isUSPostal = false;
			} else {
				state.newProvider.postal_code.validation.isCanadianPostal = false;
				state.newProvider.postal_code.validation.isUSPostal = false;
			}

			// Clear Address Fields
			state.newProvider.address_1.value = '';
			state.newProvider.address_1.valid = false;
			state.newProvider.address_1.touched = false;

			state.newProvider.address_2.value = '';
			state.newProvider.address_2.valid = true;
			state.newProvider.address_2.touched = false;

			// Clear phone_number field
			state.newProvider.customer_care_number.value = '';
			state.newProvider.customer_care_number.valid = false;
			state.newProvider.customer_care_number.touched = false;

			// Clear fax field
			state.newProvider.fax.value = '';
			state.newProvider.fax.valid = true;
			state.newProvider.fax.touched = true;

			return {
				controls: state,
				selectedCountry,
				country_code: selectedCountry.phonecode,
				selectedState: null,
				statesOfSelectedCountry: State.getStatesOfCountry(
					selectedCountry?.isoCode,
				),
			};
		});
	};

	handleStateChange = (selectedState) => {
		this.setState({
			selectedState,
		});
	};

	render() {
		const { formValidMessage, isLoading } = this.state;
		const { addProvider, history, close } = this.props;
		const {
			selectedCountry,
			selectedState,
			statesOfSelectedCountry,
			country_code,
		} = this.state;
		const fullPhoneNumber = `+${country_code}-${this.state.controls.newProvider.phone_number.value}`;
		const CustomerfullPhoneNumber = `+${country_code}-${this.state.controls.newProvider.customer_care_number.value}`;

		let validMessage;

		const createHandler = () => {
			this.setState({ isLoading: true });
			const data = {
				name: this.state.controls.newProvider.name.value,
				provider_admin: this.state.controls.newProvider.provider_admin.value,
				address_1: this.state.controls.newProvider.address_1.value,
				address_2: this.state.controls.newProvider.address_2.value,
				country: selectedCountry.name,
				state: selectedState.name,
				city: this.state.controls.newProvider.city.value,
				postal_code: this.state.controls.newProvider.postal_code.value,
				phone_number: fullPhoneNumber,
				fax: this.state.controls.newProvider.fax.value
					? this.state.controls.newProvider.fax.value
					: '',
				email_address: this.state.controls.newProvider.email_address.value,
				customer_care_number: CustomerfullPhoneNumber,
				website_url: this.state.controls.newProvider.website_url.value
					? this.state.controls.newProvider.website_url.value
					: '',
				description: this.state.controls.newProvider.info.value,
			};
			const success = ({ id }) => {
				history.push(routers.usersProvider(id));
				close();
			};
			const error = (data) =>
				this.setState({ formValidMessage: data, isLoading: false });
			addProvider(data, success, error);
		};
		if (formValidMessage) {
			validMessage = (
				<ul className={s.errorText}>
					{Object.values(formValidMessage).map((error, index) => {
						return <li key={index}>{error}</li>;
					})}
				</ul>
			);
		}
		return (
			<AuxWrap>
				{/* <h2 className={s.title}>{i18next.t("create_provider")}</h2> */}
				<div>
					<div className={s.titleAlign}>
						<div className={s.iconTitle}>
							<svg
								width='36'
								height='36'
								viewBox='0 0 36 36'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M26.4519 15.9519H23.25V12.2019H19.5V9H23.25V5.25H26.4519V9H30.2019V12.2019H26.4519V15.9519Z'
									fill='#26251F'
								/>
								<path
									d='M20.9076 32.6221L9.10081 29.1981V31.2346H2.27881V18.1328H12.7701L22.145 21.6319C22.7239 21.8434 23.2181 22.1992 23.6277 22.6992C24.0373 23.1992 24.2421 23.9357 24.2421 24.9088H28.9355C29.8604 24.9088 30.6008 25.2454 31.1566 25.9187C31.7123 26.592 31.9902 27.5034 31.9902 28.653V29.2241L20.9076 32.6221ZM3.98067 29.5327H7.36145V19.8347H3.98067V29.5327ZM20.772 30.8597L30.2277 27.9779C30.1258 27.5029 29.9672 27.1563 29.7518 26.938C29.5364 26.7198 29.2643 26.6106 28.9355 26.6106H21.4037C20.7018 26.6106 20.0225 26.563 19.3658 26.4679C18.709 26.3727 18.1172 26.2424 17.5903 26.077L14.5095 25.1251L15.1902 23.4549L17.9133 24.3838C18.5191 24.5838 19.1176 24.7213 19.709 24.7963C20.3003 24.8713 21.2085 24.9088 22.4335 24.9088C22.4335 24.5222 22.3677 24.1804 22.2359 23.8832C22.1042 23.5861 21.8864 23.3751 21.5826 23.2501L12.5105 19.8347H9.10081V27.3981L20.772 30.8597Z'
									fill='#26251F'
								/>
							</svg>

							<span className={s.titleTypography}>
								{i18next.t('add_provider')}
							</span>
						</div>
						<Close
							className={s.closeBtn}
							width={36}
							height={36}
							fill='#6D6D6D'
							onClick={this.props.close}
						/>
					</div>
					<div className={s.contentContainer}>
						<ProviderFields
							data={this.props.providersData}
							state={this.state}
							inputChanged={(event, type) =>
								this.inputChangedHandler(event, type)
							}
							inputBlur={(event, type) =>
								this.inputFocusOutHandler(event, type)
							}
							selectItemClick={this.onItemClick}
							countries_data={Country.getAllCountries()}
							country_data={selectedCountry}
							code={`+${country_code}`}
							state_data={selectedState}
							Country={this.handleCountryChange}
							State={this.handleStateChange}
							selected_state={statesOfSelectedCountry}
							newDesign={true}
						/>
						{validMessage}
						<div className={s.addContainer}>
							<div className={[s.buttons].join(' ')}>
								<Button
									btnType='type-20'
									disabled={
										!this.state.formIsValid ||
										!this.state.selectedCountry ||
										!this.state.selectedState ||
										!this.state.controls.newProvider.address_2.valid ||
										!this.state.controls.newProvider.fax.valid ||
										!this.state.controls.newProvider.website_url.valid ||
										!this.state.controls.newProvider.info.valid ||
										isLoading
									}
									clicked={createHandler}
									className={s.addProviderBtn}
								>
									{isLoading ? 'Loading...' : i18next.t('add_provider')}
								</Button>
							</div>
						</div>
					</div>
				</div>
			</AuxWrap>
		);
	}
}

export default AddProvider;
