import React from 'react';
import T from 'prop-types';

const BatteryIcon = (props) => (
	<svg {...props} className={props.className}>
		<path
			d='M6.96031 22C6.68666 22 6.45819 21.9084 6.2749 21.7251C6.09163 21.5418 6 21.3156 6 21.0464V4.75196C6 4.48276 6.09163 4.25541 6.2749 4.06988C6.45819 3.88437 6.68666 3.79162 6.96031 3.79162H9.45301V2H14.1596V3.79162H16.659C16.9282 3.79162 17.1545 3.88437 17.3378 4.06988C17.521 4.25541 17.6127 4.48276 17.6127 4.75196V21.0464C17.6127 21.3156 17.521 21.5418 17.3378 21.7251C17.1545 21.9084 16.9282 22 16.659 22H6.96031ZM7.91395 8.16905H15.6987V5.70557H7.91395V8.16905Z'
			fill='#6C7DDF'
		/>
	</svg>
);

BatteryIcon.defaultProps = {
	fill: 'none',
	width: 24,
	height: 24,
};

BatteryIcon.propTypes = {
	className: T.string,
};

export default BatteryIcon;
