import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
	current: {
		period: null,
		rows: null,
	},
	byHour: {
		period: null,
		data: [],
	},
	month: {
		period: null,
		rows: null,
	},
	totalRows: null,
	loading: false,
};

const getConnectedStart = (state) => {
	return updateObject(state, { loading: true });
};

const getConnectedSuccess = (state, action) => {
	return updateObject(state, {
		current: {
			period: action.current.period,
			rows: action.current.rows,
		},
		byHour: {
			period: action.byHour.period,
			data: action.byHour.data,
		},
		month: {
			period: action.month.period,
			data: action.month.rows,
		},
		totalRows: action.totalRows,
		loading: false,
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.ENGINEERING_GET_CURRENTLY_CONNECTED_START:
			return getConnectedStart(state, action);
		case actionTypes.ENGINEERING_GET_CURRENTLY_CONNECTED_SUCCESS:
			return getConnectedSuccess(state, action);

		default:
			return state;
	}
};

export default reducer;
