import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { TableFilter, TableDateFilter } from 'components';
import { date, errors, models } from '../Configs/mapFilters';
import { AuxWrap } from 'hoc';
import { Delete, LocationOne } from 'components/Icons';

import { initMap, refreshMap } from './mapMethods';

import s from './style.module.scss';
import i18next from 'i18next';

const Legend = () => (
	<ul className={s.legend}>
		<li>
			<LocationOne fill='#6AB221' width='18' height='18' />{' '}
			{i18next.t('gps_inside')}
		</li>
		<li>
			<LocationOne fill='#e04829' width='18' height='18' />{' '}
			{i18next.t('gps_outside')}
		</li>
		<li>
			<LocationOne fill='#999999' width='18' height='18' />{' '}
			{i18next.t('ip_everywhere')}
		</li>
	</ul>
);

class MapView extends Component {
	componentDidUpdate(prevProps) {
		const { data, headings, markerClicked } = this.props;

		if (prevProps.data !== data) {
			refreshMap(data, headings, markerClicked);
		}
	}

	componentDidMount() {
		const { data, headings, markerClicked } = this.props;
		const args = {
			node: this.mapNode,
			data: data,
			callback: () => {
				refreshMap(data, headings, markerClicked, () =>
					this.setState({ isLoading: false }),
				);
			},
		};

		initMap(args);
	}

	render() {
		const {
			count,
			filters,
			errorData,
			modelsData,
			onSetParams,
			onFilterDelete,
			errorProps,
			modelsProps,
			dateProps,
		} = this.props;
		const filterArray = Object.values(filters);
		const filtersGroup = Object.keys(filters);
		const mapFilter = [];

		if (filterArray.length) {
			filterArray.map((el, i) => {
				mapFilter.push(
					Object.values(el).map((el, index) => {
						return (
							<span className={s.filterItem} key={index} data-id={el.id}>
								{el.name || el.label || el.description || el.code}
								<Delete
									className={s.icon}
									onClick={() => onFilterDelete(filtersGroup[i], el.id)}
								/>
							</span>
						);
					}),
				);

				return el;
			});
		}

		return (
			<AuxWrap>
				{mapFilter}

				<div className={s.mapView}>
					<div className={s.mapHead}>
						<span>{`${count} ${i18next.t('concentrators')}`}</span>

						<div className={s.filters}>
							<TableFilter
								className={s.errorFilter}
								config={models}
								data={modelsData}
								onSetParams={onSetParams}
								{...modelsProps}
							/>
							<TableDateFilter
								className={s.dateFilter}
								config={date}
								onSetParams={onSetParams}
								{...dateProps}
							/>
							<TableFilter
								className={s.errorFilter}
								config={errors}
								data={errorData}
								onSetParams={onSetParams}
								{...errorProps}
							/>
						</div>
					</div>
					<div className={s.map}>
						<div
							id='map'
							ref={(node) => (this.mapNode = node)}
							style={{ width: '100%', height: '100%' }}
						/>
					</div>
				</div>

				<Legend />
			</AuxWrap>
		);
	}
}

MapView.defaultProps = {
	data: [],
};

MapView.propTypes = {
	data: PropTypes.array,
};

export default MapView;
