import { combineReducers } from 'redux';
import connected from './connected';
import recentError from './recentError';
import appConnected from './appConnected';
import columnLife from './columnLife';
import softwareVersion from './softwareVersion';
import params from './params';
import o2purity from './o2purity';
import systemPower from './systemPower';
import lifeClock from './lifeClock';
import flowSettings from './flowSettings';
import batteryStatus from './batteryStatus';
import recentErrorStatus from './RecentErrorStatus';

const reducer = combineReducers({
	appConnected,
	connected,
	recentError,
	columnLife,
	softwareVersion,
	params,
	o2purity,
	systemPower,
	lifeClock,
	flowSettings,
	batteryStatus,
	recentErrorStatus,
});

export default reducer;
