import { coreInstance as axios, api } from 'settings';
import { logger } from 'helpers';
import * as actionTypes from '../actionTypes';

const getPushNotificationsFirmwaresStart = () => {
	return {
		type: actionTypes.GET_PUSH_NOTIFICATIONS_FIRMWARES_START,
	};
};

const getPushNotificationsFirmwaresSuccess = (data) => {
	return {
		type: actionTypes.GET_PUSH_NOTIFICATIONS_FIRMWARES_SUCCESS,
		data: data,
	};
};

export const getPushNotificationsFirmwares = (args = {}) => {
	const defaultParams = {
		orderBy: 'version',
		sortedBy: 'asc',
	};

	const params = { ...defaultParams, ...args.params };

	return (dispatch) => {
		dispatch(getPushNotificationsFirmwaresStart());

		axios
			.get(api.concentrator_firmwares.index, { params })
			.then((response) => {
				logger('[Action] Get push notification firmware');

				dispatch(getPushNotificationsFirmwaresSuccess(response.data.data));
			})
			.catch(() => {
				logger('[Action] Get push notification firmware fail');
			});
	};
};
