import React, { PureComponent } from 'react';
import { Input, Button } from 'components';
import { routers } from 'settings';
import { AuxWrap } from 'hoc';

import s from './style.module.scss';
import { withTranslation } from 'react-i18next';

class DeleteProvider extends PureComponent {
	state = {
		controls: {
			deleted: {
				elementType: 'input',
				elementConfig: {
					type: 'checkbox',
				},
				theme: 'checkbox',
				className: s.checkbox,
				value: false,
				valid: true,
				label: 'all_users_linked',
			},
		},
	};

	inputChangedHandler = (event) => {
		const target = event.target;
		const value = target.checked;

		this.setState((prevState) => {
			const state = { ...prevState.controls };
			const control = state.deleted;

			control.value = value;

			return { controls: state };
		});
	};

	deleteHandler = () => {
		const {
			modalData: { id },
			close,
			history,
		} = this.props;

		const success = () => {
			close();
			history.push(routers.users);
		};
		const error = () => this.setState({ isDeleting: false });

		this.setState({ isDeleting: true });
		this.props.deleteProvider(id, success, error);
	};

	render() {
		const { modalData, close, t } = this.props;
		const {
			controls: { deleted },
			isDeleting,
		} = this.state;

		return (
			<AuxWrap>
				<h2 className={s.title}>Delete {modalData.name}</h2>
				<div className={s.container}>
					<div className={s.field}>Warning: this cannot be undone.</div>
					<div className={s.field}>
						<Input
							label={t(deleted.label)}
							theme={deleted.theme}
							className={deleted.className}
							elementType={deleted.elementType}
							elementConfig={deleted.elementConfig}
							checked={deleted.value}
							changed={this.inputChangedHandler}
						/>
					</div>
					<div className={s.buttons}>
						<Button
							className={s.deleteBtn}
							btnType={'type-10'}
							disabled={!deleted.value || isDeleting}
							clicked={this.deleteHandler}
						>
							{t('yes_delete_this_provider')}
						</Button>
						<Button className={s.cancelBtn} btnType={'type-11'} clicked={close}>
							{t('cancel_keep_provider')}
						</Button>
					</div>
				</div>
			</AuxWrap>
		);
	}
}

export default withTranslation()(DeleteProvider);
