import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
	data: null,
	count: 0,
	loading: false,
};

const getLocationStart = (state) => {
	return updateObject(state, { loading: true });
};

const getLocationSuccess = (state, action) => {
	// if (action.isMerged) {
	//     const data = [...state.data, ...action.data];

	//     return updateObject(state, {
	//         data: data
	//     });
	// }

	return updateObject(state, {
		data: action.data,
		headings: action.headings,
		loading: false,
	});
};

const getLocationCountSuccess = (state, action) => {
	return updateObject(state, {
		count: action.data,
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_LOCATION_START:
			return getLocationStart(state, action);
		case actionTypes.GET_LOCATION_SUCCESS:
			return getLocationSuccess(state, action);
		case actionTypes.GET_LOCATION_COUNT_SUCCESS:
			return getLocationCountSuccess(state, action);

		default:
			return state;
	}
};

export default reducer;
