const arrayToObject = (array, keyField) => {
	try {
		return array.reduce((obj, item) => {
			obj[item[keyField]] = item;
			return obj;
		}, {});
	} catch (e) {
		console.log(e);
	}
};

export default arrayToObject;
