import React from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import {
	Spinner,
	TableFilter,
	TableDateFilter,
	FilterSelect,
} from 'components';
import { Delete, Info } from 'components/Icons';
import { AuxWrap } from 'hoc';

import s from './style.module.scss';
import ReactTooltip from 'react-tooltip';
import { withTranslation } from 'react-i18next';

const Table = (props) => {
	const filterArray = Object.values(props.filters);
	const filtersGroup = Object.keys(props.filters);
	let tableFilter = [];
	let viewAllBtn = null;
	let table = null;
	let tableHead = null;
	let tableBody = null;
	let tableFoot = null;
	const { t } = props;
	const spinnerRow = (
		<tr className={s.spinnerRow} key={0}>
			<td
				colSpan={
					props.config.dataBody && Object.values(props.config.dataBody).length
				}
			>
				<Spinner />
			</td>
		</tr>
	);

	const searchInObj = (column, data) => {
		let array = column.path;
		let value = data;

		array.map((el) => {
			if (!value[el]) return (value = '');
			return (value = value[el]);
		});

		if (value === null) {
			array = column.alterPath;
			value = data;

			array.map((el) => (value = value[el]));
		}

		return value;
	};

	if (filterArray.length) {
		filterArray.map((el, i) => {
			tableFilter.push(
				Object.values(el).map((el, index) => {
					return (
						<span className={s.filterItem} key={index} data-id={el.id}>
							{el.name || el.label || el.description || el.code}
							<Delete
								className={s.icon}
								onClick={() => props.onFilterDelete(filtersGroup[i], el.id)}
							/>
						</span>
					);
				}),
			);

			return el;
		});
	}

	if (props.config.dataHead) {
		tableHead = (
			<thead>
				<tr>
					{props.config.dataHead.map((el, index) => {
						let label = t(el.displayText);
						let sortedIcon;
						let styles = {};

						if (el.textAlign) {
							styles.textAlign = el.textAlign;
						}

						if (el.filter) {
							switch (el.filter.type) {
								case 'date':
									label = (
										<TableDateFilter
											config={el.filter}
											onSetParams={props.onSetParams}
											{...props[el.filter.propsKey]}
										/>
									);
									break;
								case 'errors':
									label = (
										<TableFilter
											config={el.filter}
											onSetParams={props.onSetParams}
											{...props[el.filter.propsKey]}
										/>
									);
									break;
								case 'status':
									label = (
										<FilterSelect
											config={el.filter}
											data={el.filter.data}
											onSetParams={props.onSetParams}
										/>
									);
									break;
								default:
									label = (
										<TableFilter
											config={el.filter}
											onSetParams={props.onSetParams}
											{...props[el.filter.propsKey]}
										/>
									);
									break;
							}
						}

						if (el.view) {
							label = el.view(label, props);
						}

						if (el.sorted) {
							const { orderBy, sortedBy } = props.requestParams;
							// const isActive = orderBy === el.sorted.orderBy;
							const isActive =
								orderBy && orderBy.toString() === el.sorted.orderBy.toString();
							const sortType = Array.isArray(sortedBy) ? sortedBy[0] : sortedBy;

							const clickHandler = () => {
								if (isActive) {
									if (Array.isArray(sortedBy)) {
										el.sorted.sortedBy = sortedBy.map((el) =>
											el === 'desc' ? 'asc' : 'desc',
										);
									} else {
										el.sorted.sortedBy = sortedBy === 'desc' ? 'asc' : 'desc';
									}
								}

								props.onSetParams(el.sorted);
							};

							sortedIcon = (
								<i
									className={isActive ? s[sortType] : ''}
									onClick={clickHandler}
								/>
							);
						}

						if (el.hide && el.hide()) {
							label = null;
						}

						return (
							<th style={styles} key={index}>
								{label}
								{sortedIcon}
							</th>
						);
					})}
				</tr>
			</thead>
		);
	}

	if (props.data) {
		const { dataBody, rowView } = props.config;
		const dataArray = dataBody && Object.values(dataBody);

		if (props.data.length > props.maxRows) {
			viewAllBtn = (
				<button className={s.viewAllBtn} onClick={props.onViewAll}>
					{props.viewAll ? 'Show less' : `View All (${props.data.length})`}
				</button>
			);
		}

		tableBody = props.data.length ? (
			props.data.map((row, rowIndex) => {
				const rowClasses = [];

				if (!row) return null;

				if (!props.viewAll && rowIndex >= props.maxRows) return null;

				if (rowView) {
					rowView(row, rowClasses, s);
				}

				return (
					<tr
						key={rowIndex}
						className={rowClasses.join(' ')}
						onMouseOver={(e) => props.onRowOver(e, row, rowIndex)}
						onMouseOut={(e) => props.onRowOut(e, row, rowIndex)}
						onClick={() => props.onRowClicked(row, rowIndex)}
					>
						{dataArray &&
							dataArray.map((column, index) => {
								const path = column.path;
								let value = row[column.path];
								let styles = {};

								if (typeof path === 'object' && Array.isArray(path)) {
									value = searchInObj(column, row);
								}

								if (column.textAlign) {
									styles.textAlign = column.textAlign;
								}

								if (Array.isArray(value) && column.search) {
									value = column.search(value);
								}

								if (column.view) {
									value = column.view({
										value,
										props,
										row,
										rowIndex,
									});
								}

								if (column.value === 'index') {
									value = ++rowIndex;
								}

								return (
									<td style={styles} key={index}>
										{value}
									</td>
								);
							})}
					</tr>
				);
			})
		) : (
			<tr className={s.noResult}>
				<td colSpan={dataArray && dataArray.length}>{t('nothing_found')}</td>
			</tr>
		);
	}

	if (props.loading) {
		tableBody = <tbody>{spinnerRow}</tbody>;
	} else if (props.infinite && props.data) {
		tableBody = (
			<InfiniteScroll
				element='tbody'
				initialLoad={false}
				hasMore={props.hasMoreData}
				loadMore={props.loadMoreHandler}
				loader={spinnerRow}
			>
				{tableBody}
			</InfiniteScroll>
		);
	} else {
		tableBody = <tbody>{tableBody}</tbody>;
	}

	if (props.config.dataFoot) {
		tableFoot = (
			<tfoot>
				{props.config.dataFoot.map((el) => (
					<tr key={el.id}>
						<td>{el.id}</td>
					</tr>
				))}
			</tfoot>
		);
	}

	table = (
		<>
			<div className={s.stylingTable} style={{ borderRadius: '8px' }}>
				<table className={[s[props.theme], props.className].join(' ')}>
					{tableHead}
					{tableBody}
					{tableFoot}
				</table>
			</div>
		</>
	);

	return (
		<AuxWrap>
			<div className={s.caption}>
				{props.title}
				{props.help ? (
					<Info
						fill='#a5bad4'
						data-tip=''
						data-for='help'
						className={s.infoIconTrigger}
					/>
				) : null}
				{props.help ? (
					<ReactTooltip
						id='help'
						className={s.help}
						place='bottom'
						type='light'
						effect='solid'
					>
						<Info fill='#a5bad4' className={s.infoIconHelp} />
						<p>{props.help}</p>
					</ReactTooltip>
				) : null}
				{viewAllBtn}
			</div>
			<div>{tableFilter}</div>

			{props.topText}
			{table}
		</AuxWrap>
	);
};

Table.defaultProps = {
	theme: 'table',
	config: {},
	filters: {},
	onRowOver: () => {},
	onRowOut: () => {},
	onRowClicked: () => {},
};

Table.propTypes = {
	className: PropTypes.string,
	theme: PropTypes.string,
	config: PropTypes.object,
	filters: PropTypes.object,
	onRowOver: PropTypes.func,
	onRowOut: PropTypes.func,
	onRowClicked: PropTypes.func,
};

export default withTranslation()(Table);
