import React from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';

import s from './style.module.scss';
import { withTranslation } from 'react-i18next';

class ToggleList extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isOpen: false,
			selectedValues: props.data.length
				? props.data.filter((filter) => filter.isActive)
				: [],
		};
	}

	toggleOpen = () => {
		this.setState({
			isOpen: !this.state.isOpen,
		});
	};

	// used in onClickOutside component
	handleClickOutside = () => {
		this.setState({ isOpen: false });
	};

	componentDidUpdate(prevProps) {
		if (prevProps.data !== this.props.data) {
			this.setState({
				selectedValues: this.props.data.length
					? this.props.data.filter((filter) => filter.isActive)
					: [],
			});
		}
	}

	toggleItem = (index) => {
		const newData = this.props.data.map((item, i) => {
			if (i === index) {
				return {
					...item,
					isActive: !item.isActive,
				};
			}

			if (!this.props.multiselect && i !== index) {
				return {
					...item,
					isActive: false,
				};
			}

			return item;
		});

		return this.props.onToggle(newData[index], newData);
	};

	resetSelectedValues = () => {
		if (
			!this.props.data.length ||
			!this.props.reset ||
			!this.state.selectedValues.length
		)
			return;

		const filtersList = this.props.data.map((filter) => {
			return {
				...filter,
				isActive: false,
			};
		});

		this.setState({
			selectedValues: [],
		});

		return this.props.reset(filtersList);
	};

	render() {
		const { isOpen, selectedValues } = this.state;

		const {
			data,
			noSelectionLabel,
			className,
			t,
			excludeFromList, // to exclude text 'All' from list
		} = this.props;

		return (
			<div
				className={`${s.toggleContainer} ${isOpen ? s.open : ''} ${className}`}
			>
				<div className={s.toggleTrigger} onClick={this.toggleOpen}>
					{selectedValues.length
						? selectedValues.reduce((resultString, current) => {
								if (!resultString) {
									return current.label;
								}
								return resultString + ', ' + current.label;
						  }, '')
						: noSelectionLabel || 'Select value'}
				</div>
				{isOpen ? (
					<div className={s.listContainer}>
						<ul className={`${s.list}`}>
							{!excludeFromList ? (
								<li
									className={`${s.listItem} ${s.separator} ${
										!selectedValues.length ? s.active : ''
									}`}
									onClick={() => this.resetSelectedValues()}
								>
									<span className={s.label}>{t('all')}</span>
								</li>
							) : (
								''
							)}
							{data.length
								? data.map((item, index) => (
										<li
											className={`${s.listItem} ${
												item.isActive ? s.active : ''
											}`}
											key={index}
											onClick={() => this.toggleItem(index, item)}
										>
											<span className={s.label}>{item.label}</span>
										</li>
								  ))
								: null}
						</ul>
					</div>
				) : null}
			</div>
		);
	}
}

ToggleList.defaultProps = {
	data: [],
	preselected: [],
	multiselect: false,
	onToggle: () => {},
};

ToggleList.propTypes = {
	data: PropTypes.array,
	preselected: PropTypes.array,
	multiselect: PropTypes.bool,
	onToggle: PropTypes.func,
};

export default withTranslation()(onClickOutside(ToggleList));
