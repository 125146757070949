import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { Button, Input } from 'components';
import { Info } from 'components/Icons';
import { checkValidity } from 'helpers';
import * as actions from 'store/actions';
import { getProvider } from './Configs/paramsCreator';

import s from './style.module.scss';
import { withTranslation } from 'react-i18next';

class PinCode extends Component {
	constructor(props) {
		super(props);
		this.state = {
			controls: {
				pin3: {
					elementType: 'mask',
					elementConfig: {
						type: 'text',
						placeholder: '__',
						guide: true,
					},
					className: s.input,
					value: '',
					validation: {
						required: true,
						isEmail: true,
					},
					valid: false,
					errorMsg: '',
					touched: false,
				},
				pin4: {
					elementType: 'mask',
					elementConfig: {
						type: 'text',
						placeholder: '___',
						guide: true,
					},
					className: s.input,
					value: '',
					validation: {
						required: true,
						isEmail: true,
					},
					valid: false,
					errorMsg: '',
					touched: false,
				},
				pinDescription: {
					elementType: 'input',
					elementConfig: {
						type: 'text',
						placeholder: 'e.g Texas',
					},
					className: s.input,
					value: '',
				},
			},
			formValidMessage: null,
		};
	}

	inputChangedHandler = (event, name) => {
		const target = event.target;
		let value = target.value;

		if (name !== 'pinDescription') {
			value = target.value.toUpperCase().replace(/[_]/g, '');
		}

		this.setState((prevState) => {
			const state = { ...prevState.controls };
			const control = state[name];

			control.value = value;
			control.valid = checkValidity(value, control.validation);

			return { controls: state, formValidMessage: null };
		});
	};

	inputFocusOutHandler = (event, name) => {
		this.setState((prevState) => {
			const state = { ...prevState.controls };
			const control = state[name];

			control.touched = true;

			return { controls: state };
		});
	};

	updateHandler = () => {
		const {
			type,
			providerId,
			clear,
			modal,
			getManageProvider,
			getCurrentUser,
			onAdd,
			onSave,
		} = this.props;
		const {
			controls: { pin3, pin4, pinDescription },
		} = this.state;

		const onInitHandler = (id) => {
			const args = { id: id, options: getProvider() };

			getManageProvider(args);
		};

		const onRefresh = (id) => {
			clear();
			modal.close();
			onInitHandler(id);
			getCurrentUser();
		};

		const args = {
			id: providerId,
			data: { provider_id: providerId },
			success: () => onRefresh(providerId),
			error: (err) =>
				this.setState({ formValidMessage: err.response.data.errors }),
		};

		if (pin3.value || pin4.value) {
			args.data.code_part_3 = `${pin3.value}${pin4.value}`;
		}

		if (pinDescription.value) {
			args.data.description = pinDescription.value;
		}

		if (type === 'add') {
			onAdd(args);
		} else {
			delete args.data.provider_id;

			onSave(args);
		}
	};

	render() {
		const { type, providerName, codePartOne, codePartTwo, t } = this.props;
		const {
			controls: { pin3, pin4, pinDescription },
			formValidMessage,
		} = this.state;

		this.validMessage = null;

		if (formValidMessage) {
			this.validMessage = (
				<ul className={s.errorText}>
					{Object.values(formValidMessage).map((error, index) => {
						return <li key={index}>{error}</li>;
					})}
				</ul>
			);
		}

		return (
			<Fragment>
				<h2 className={s.title}>
					{type === 'add' ? 'New PIN code' : 'Complete Provider PIN code'}
				</h2>
				<div className={s.container}>
					<div className={s.selectField}>
						<div className={s.selectHead}>
							<span>{t('provider_pin_code')}</span>
						</div>
						<ul className={s.pinField}>
							<li>
								<div className={s.inputBlock}>{codePartOne}</div>
							</li>
							<li>
								<div className={s.inputBlock}>{codePartTwo}</div>
							</li>
							<li>
								<Input
									className={s.pinInput}
									elementType={pin3.elementType}
									elementConfig={pin3.elementConfig}
									value={pin3.value}
									invalid={!pin3.valid}
									invalidMsg={pin3.errorMsg}
									touched={pin3.touched}
									changed={(event) => this.inputChangedHandler(event, 'pin3')}
								/>
							</li>
							<li>
								<Input
									mask={[/[a-z,A-Z,0-9]/, /[a-z,A-Z,0-9]/, /[a-z,A-Z,0-9]/]}
									className={s.pinInput}
									elementType={pin4.elementType}
									elementConfig={pin4.elementConfig}
									value={pin4.value}
									invalid={!pin4.valid}
									invalidMsg={pin4.errorMsg}
									touched={pin4.touched}
									changed={(event) => this.inputChangedHandler(event, 'pin4')}
								/>

								<Info data-tip='hello world' className={s.infoIcon} />
							</li>
						</ul>
						<ReactTooltip
							className={s.tooltip}
							place='right'
							type='light'
							effect='solid'
						>
							<div className={s.tipTitle}>{t('pin_code_consists_of')}</div>
							<ul className={s.tipList}>
								<li>{t('pin_code_aa')}</li>
								<li>{t('pin_code_bbb')}</li>
								<li>{t('pin_code_cc')}</li>
								<li>{t('pin_code_ddd')}</li>
							</ul>
						</ReactTooltip>
					</div>

					{type !== 'complete' && (
						<div className={s.selectField}>
							<div className={s.selectHead}>
								<span>{t('name')} (optional)</span>
							</div>
							<ul className={s.nameField}>
								<li>
									<div className={s.inputBlock}>{providerName}</div>
								</li>
								<li>
									<Input
										className={s.pinInput}
										elementType={pinDescription.elementType}
										elementConfig={pinDescription.elementConfig}
										value={pinDescription.value}
										changed={(event) =>
											this.inputChangedHandler(event, 'pinDescription')
										}
									/>
								</li>
							</ul>
						</div>
					)}

					{this.validMessage}

					<div className={s.button}>
						<Button
							btnType='type-6'
							clicked={this.updateHandler}
							disabled={!pin3.value && !pin4.value && !pinDescription.value}
						>
							{type === 'add' ? 'ADD' : 'SAVE'}
						</Button>
					</div>
				</div>
			</Fragment>
		);
	}
}

PinCode.defaultProps = {
	clear: () => {},
	onAdd: () => {},
	onSave: () => {},
	inputChangedHandler: () => {},
	inputFocusOutHandler: () => {},
	getCurrentUser: () => {},
	getManageProvider: () => {},
};

PinCode.propTypes = {
	clear: PropTypes.func,
	onAdd: PropTypes.func,
	onSave: PropTypes.func,
	inputChangedHandler: PropTypes.func,
	inputFocusOutHandler: PropTypes.func,
	getCurrentUser: PropTypes.func,
	getManageProvider: PropTypes.func,
};

const mapStateToProps = (state) => {
	const { user } = state;
	const { provider = {} } = user.data;
	const { id, name, provider_main_code = {} } = provider;

	return {
		providerName: name,
		providerId: id,
		codePartOne: provider_main_code.code_part_1,
		codePartTwo: provider_main_code.code_part_2,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onAdd: (args) => dispatch(actions.updatePinCodes(args)),
		onSave: (args) => dispatch(actions.updateProvider(args)),
		getManageProvider: (args) => dispatch(actions.getManageProvider(args)),
		clear: () => dispatch(actions.clearManageProvider()),
		getCurrentUser: () => dispatch(actions.getCurrentUser()),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withTranslation()(PinCode));
