import React from 'react';
import T from 'prop-types';

const Advertisements = (props) => {
	if (props.newLogo === true) {
		return (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='24'
				height='24'
				viewBox='0 0 24 24'
				fill='none'
				style={{ marginRight: '8px' }}
			>
				<path
					d='M4.74992 20.4999C4.35825 20.4999 4.01995 20.3575 3.73502 20.0725C3.45008 19.7876 3.30762 19.4493 3.30762 19.0576V4.94232C3.30762 4.55066 3.45008 4.21236 3.73502 3.92743C4.01995 3.64248 4.35825 3.5 4.74992 3.5H13.7729C13.9337 3.5 14.0684 3.55484 14.1771 3.66452C14.2859 3.77419 14.3402 3.91008 14.3402 4.0722C14.3402 4.23432 14.2859 4.36858 14.1771 4.475C14.0684 4.5814 13.9337 4.6346 13.7729 4.6346H4.74992C4.66017 4.6346 4.58644 4.66345 4.52874 4.72115C4.47104 4.77885 4.44219 4.85257 4.44219 4.94232V19.0576C4.44219 19.1474 4.47104 19.2211 4.52874 19.2788C4.58644 19.3365 4.66017 19.3654 4.74992 19.3654H18.8652C18.955 19.3654 19.0287 19.3365 19.0864 19.2788C19.1441 19.2211 19.1729 19.1474 19.1729 19.0576V10.0596C19.1729 9.89887 19.2278 9.76413 19.3374 9.6554C19.4471 9.54667 19.583 9.4923 19.7451 9.4923C19.9073 9.4923 20.0415 9.54667 20.1479 9.6554C20.2543 9.76413 20.3075 9.89887 20.3075 10.0596V19.0576C20.3075 19.4493 20.1651 19.7876 19.8801 20.0725C19.5952 20.3575 19.2569 20.4999 18.8652 20.4999H4.74992ZM11.0229 16.2577L9.23259 13.9904C9.15568 13.9032 9.06208 13.8596 8.95182 13.8596C8.84157 13.8596 8.74798 13.9064 8.67104 14L7.02302 16.1384C6.92302 16.2653 6.90811 16.3923 6.97829 16.5192C7.04849 16.6461 7.15378 16.7096 7.29417 16.7096H16.4133C16.5485 16.7096 16.65 16.6461 16.7176 16.5192C16.7852 16.3923 16.7806 16.2653 16.7037 16.1384L14.2479 12.8481C14.171 12.7545 14.0742 12.7077 13.9575 12.7077C13.8409 12.7077 13.7439 12.7553 13.6665 12.8506L11.0229 16.2577Z'
					fill='#999999'
				/>
				<path
					d='M19.3339 8.8961L17.9724 7.81727L16.5916 8.91532C16.448 9.02559 16.306 9.02816 16.1657 8.92302C16.0253 8.81789 15.9769 8.67687 16.0205 8.49995L16.5647 6.7865L15.1455 5.61345C15.0121 5.5032 14.9705 5.36794 15.0205 5.20767C15.0705 5.04743 15.1839 4.9673 15.3609 4.9673H17.0474L17.6416 3.25C17.6954 3.08333 17.8057 3 17.9724 3C18.139 3 18.2525 3.08333 18.3127 3.25L18.8974 4.9673H20.5839C20.7544 4.9673 20.8646 5.04743 20.9146 5.20767C20.9646 5.36794 20.9262 5.5032 20.7993 5.61345L19.3704 6.7865L19.905 8.49032C19.955 8.66084 19.9082 8.79867 19.7646 8.9038C19.621 9.00892 19.4775 9.00635 19.3339 8.8961Z'
					fill='#999999'
				/>
			</svg>
		);
	} else {
		return (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='24'
				height='24'
				viewBox='0 0 24 24'
				fill='none'
				style={{ marginRight: '8px' }}
			>
				<path
					d='M4.74992 20.4999C4.35825 20.4999 4.01995 20.3575 3.73502 20.0725C3.45008 19.7876 3.30762 19.4493 3.30762 19.0576V4.94232C3.30762 4.55066 3.45008 4.21236 3.73502 3.92743C4.01995 3.64248 4.35825 3.5 4.74992 3.5H13.7729C13.9337 3.5 14.0684 3.55484 14.1771 3.66452C14.2859 3.77419 14.3402 3.91008 14.3402 4.0722C14.3402 4.23432 14.2859 4.36858 14.1771 4.475C14.0684 4.5814 13.9337 4.6346 13.7729 4.6346H4.74992C4.66017 4.6346 4.58644 4.66345 4.52874 4.72115C4.47104 4.77885 4.44219 4.85257 4.44219 4.94232V19.0576C4.44219 19.1474 4.47104 19.2211 4.52874 19.2788C4.58644 19.3365 4.66017 19.3654 4.74992 19.3654H18.8652C18.955 19.3654 19.0287 19.3365 19.0864 19.2788C19.1441 19.2211 19.1729 19.1474 19.1729 19.0576V10.0596C19.1729 9.89887 19.2278 9.76413 19.3374 9.6554C19.4471 9.54667 19.583 9.4923 19.7451 9.4923C19.9073 9.4923 20.0415 9.54667 20.1479 9.6554C20.2543 9.76413 20.3075 9.89887 20.3075 10.0596V19.0576C20.3075 19.4493 20.1651 19.7876 19.8801 20.0725C19.5952 20.3575 19.2569 20.4999 18.8652 20.4999H4.74992ZM11.0229 16.2577L9.23259 13.9904C9.15568 13.9032 9.06208 13.8596 8.95182 13.8596C8.84157 13.8596 8.74798 13.9064 8.67104 14L7.02302 16.1384C6.92302 16.2653 6.90811 16.3923 6.97829 16.5192C7.04849 16.6461 7.15378 16.7096 7.29417 16.7096H16.4133C16.5485 16.7096 16.65 16.6461 16.7176 16.5192C16.7852 16.3923 16.7806 16.2653 16.7037 16.1384L14.2479 12.8481C14.171 12.7545 14.0742 12.7077 13.9575 12.7077C13.8409 12.7077 13.7439 12.7553 13.6665 12.8506L11.0229 16.2577Z'
					fill='#999999'
				/>
				<path
					d='M19.3339 8.8961L17.9724 7.81727L16.5916 8.91532C16.448 9.02559 16.306 9.02816 16.1657 8.92302C16.0253 8.81789 15.9769 8.67687 16.0205 8.49995L16.5647 6.7865L15.1455 5.61345C15.0121 5.5032 14.9705 5.36794 15.0205 5.20767C15.0705 5.04743 15.1839 4.9673 15.3609 4.9673H17.0474L17.6416 3.25C17.6954 3.08333 17.8057 3 17.9724 3C18.139 3 18.2525 3.08333 18.3127 3.25L18.8974 4.9673H20.5839C20.7544 4.9673 20.8646 5.04743 20.9146 5.20767C20.9646 5.36794 20.9262 5.5032 20.7993 5.61345L19.3704 6.7865L19.905 8.49032C19.955 8.66084 19.9082 8.79867 19.7646 8.9038C19.621 9.00892 19.4775 9.00635 19.3339 8.8961Z'
					fill='#999999'
				/>
			</svg>
		);
	}
};

Advertisements.defaultProps = {
	fill: '#EFB815',
	width: 22,
	height: 22,
	viewBox: '0 0 22 22',
	style: { marginRight: '8px' },
};

Advertisements.propTypes = {
	className: T.string,
};

export default Advertisements;
