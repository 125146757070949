import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
	data: [],
	loading: false,
};

const getPinCodesStart = (state) => {
	return updateObject(state, { loading: true });
};

const getPinCodesSuccess = (state, action) => {
	const data = action.data.map((el) => {
		const { code_part_1, code_part_2, code_part_3 } = el;

		el.label = `${code_part_1}-${code_part_2}-${code_part_3.slice(
			0,
			2,
		)}-${code_part_3.slice(-3)}`;

		return el;
	});

	return updateObject(state, { data: data, loading: false });
};

const clearPinCodes = () => {
	return initialState;
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_PINCODES_START:
			return getPinCodesStart(state, action);
		case actionTypes.GET_PINCODES_SUCCESS:
			return getPinCodesSuccess(state, action);

		case actionTypes.CLEAR_PINCODES:
			return clearPinCodes(state, action);

		default:
			return state;
	}
};

export default reducer;
