import React from 'react';
import T from 'prop-types';

const DataLog = (props) => {
	if (props.newLogo === true) {
		if (props.theme === 'dark') {
			return (
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='36'
					height='36'
					viewBox='0 0 36 36'
					fill='none'
					style={{ marginRight: '8px' }}
				>
					<path
						d='M10.9971 33C10.4525 33 9.98356 32.797 9.59012 32.3911C9.19671 31.9852 9 31.5014 9 30.9395V5.06047C9 4.49866 9.19671 4.01479 9.59012 3.60886C9.98356 3.20295 10.4525 3 10.9971 3H25.0029C25.5475 3 26.0164 3.20295 26.4099 3.60886C26.8033 4.01479 27 4.49866 27 5.06047V9.91759H25.429V7.76648H10.571V28.2335H25.429V26.0824H27V30.9395C27 31.5014 26.8033 31.9852 26.4099 32.3911C26.0164 32.797 25.5475 33 25.0029 33L10.9971 33ZM10.571 29.8544V30.9395C10.571 31.0494 10.6154 31.1502 10.7041 31.2418C10.7929 31.3334 10.8905 31.3791 10.9971 31.3791H25.0029C25.1094 31.3791 25.2071 31.3334 25.2959 31.2418C25.3846 31.1502 25.429 31.0494 25.429 30.9395V29.8544H10.571ZM10.571 6.14562H25.429V5.06047C25.429 4.95056 25.3846 4.84982 25.2959 4.75822C25.2071 4.66665 25.1094 4.62086 25.0029 4.62086H10.9971C10.8905 4.62086 10.7929 4.66665 10.7041 4.75822C10.6154 4.84982 10.571 4.95056 10.571 5.06047V6.14562Z'
						fill='#26251F'
					/>
					<path
						d='M25.2862 18.0115L21.2802 13.5652C21.1326 13.3865 21.0528 13.1765 21.0406 12.9351C21.0285 12.6938 21.1083 12.4765 21.2802 12.2834C21.4426 12.0945 21.6345 12 21.8558 12C22.0771 12 22.269 12.0945 22.4315 12.2834L26.8886 17.1758C27.0006 17.2995 27.0832 17.426 27.1365 17.5553C27.1897 17.6846 27.2163 17.8285 27.2163 17.9868C27.2163 18.1368 27.1897 18.2785 27.1365 18.412C27.0832 18.5455 27.0008 18.6737 26.8892 18.7965L22.4091 23.724C22.2466 23.9028 22.0557 23.9947 21.8362 23.9998C21.6168 24.0049 21.4193 23.913 21.2437 23.724C21.0812 23.531 21 23.3163 21 23.0801C21 22.8439 21.0812 22.6313 21.2437 22.4423L25.2862 18.0115ZM32.5699 18.0115L28.5638 13.5652C28.4163 13.3865 28.3365 13.1765 28.3243 12.9351C28.3122 12.6938 28.392 12.4765 28.5638 12.2834C28.7263 12.0945 28.9182 12 29.1395 12C29.3608 12 29.5527 12.0945 29.7152 12.2834L34.1722 17.1758C34.2843 17.2995 34.3669 17.426 34.4201 17.5553C34.4734 17.6846 34.5 17.8285 34.5 17.9868C34.5 18.1368 34.4734 18.2785 34.4201 18.412C34.3669 18.5455 34.2845 18.6737 34.1729 18.7965L29.6928 23.724C29.5303 23.9028 29.3393 23.9947 29.1199 23.9998C28.9005 24.0049 28.703 23.913 28.5274 23.724C28.3649 23.531 28.2837 23.3163 28.2837 23.0801C28.2837 22.8439 28.3649 22.6313 28.5274 22.4423L32.5699 18.0115Z'
						fill='#26251F'
					/>
				</svg>
			);
		} else {
			return (
				<svg
					xmlns='http://www.w3.org/2000/svg'
					width='24'
					height='24'
					viewBox='0 0 24 24'
					fill='none'
					style={{ marginRight: '8px' }}
				>
					<path
						d='M7.33138 22C6.96837 22 6.65571 21.8647 6.39342 21.5941C6.13114 21.3235 6 21.0009 6 20.6264V3.37365C6 2.99911 6.13114 2.67653 6.39342 2.40591C6.65571 2.1353 6.96837 2 7.33138 2H16.6686C17.0316 2 17.3443 2.1353 17.6066 2.40591C17.8689 2.67653 18 2.99911 18 3.37365V6.61173H16.9527V5.17765H7.04733V18.8223H16.9527V17.3883H18V20.6264C18 21.0009 17.8689 21.3235 17.6066 21.5941C17.3443 21.8647 17.0316 22 16.6686 22L7.33138 22ZM7.04733 19.9029V20.6264C7.04733 20.6996 7.07691 20.7668 7.13608 20.8279C7.19527 20.8889 7.26037 20.9194 7.33138 20.9194H16.6686C16.7396 20.9194 16.8047 20.8889 16.8639 20.8279C16.9231 20.7668 16.9527 20.6996 16.9527 20.6264V19.9029H7.04733ZM7.04733 4.09708H16.9527V3.37365C16.9527 3.30038 16.9231 3.23321 16.8639 3.17215C16.8047 3.1111 16.7396 3.08057 16.6686 3.08057H7.33138C7.26037 3.08057 7.19527 3.1111 7.13608 3.17215C7.07691 3.23321 7.04733 3.30038 7.04733 3.37365V4.09708Z'
						fill='#999999'
					/>
					<path
						d='M16.8575 12.0076L14.1868 9.04349C14.0884 8.92433 14.0352 8.78431 14.0271 8.62341C14.019 8.4625 14.0722 8.31769 14.1868 8.18896C14.2951 8.06299 14.423 8 14.5706 8C14.7181 8 14.846 8.06299 14.9543 8.18896L17.9257 11.4505C18.0004 11.533 18.0555 11.6173 18.091 11.7035C18.1265 11.7897 18.1442 11.8856 18.1442 11.9912C18.1442 12.0912 18.1265 12.1857 18.091 12.2747C18.0555 12.3637 18.0005 12.4491 17.9261 12.531L14.9394 15.816C14.8311 15.9352 14.7038 15.9964 14.5575 15.9999C14.4112 16.0033 14.2795 15.942 14.1625 15.816C14.0542 15.6873 14 15.5442 14 15.3867C14 15.2292 14.0542 15.0875 14.1625 14.9615L16.8575 12.0076ZM21.7132 12.0076L19.0426 9.04349C18.9442 8.92433 18.891 8.78431 18.8829 8.62341C18.8748 8.4625 18.928 8.31769 19.0426 8.18896C19.1509 8.06299 19.2788 8 19.4263 8C19.5739 8 19.7018 8.06299 19.8101 8.18896L22.7815 11.4505C22.8562 11.533 22.9113 11.6173 22.9468 11.7035C22.9823 11.7897 23 11.8856 23 11.9912C23 12.0912 22.9823 12.1857 22.9468 12.2747C22.9113 12.3637 22.8563 12.4491 22.7819 12.531L19.7952 15.816C19.6869 15.9352 19.5596 15.9964 19.4133 15.9999C19.267 16.0033 19.1353 15.942 19.0183 15.816C18.9099 15.6873 18.8558 15.5442 18.8558 15.3867C18.8558 15.2292 18.9099 15.0875 19.0183 14.9615L21.7132 12.0076Z'
						fill='#999999'
					/>
				</svg>
			);
		}
	} else {
		return (
			<svg {...props} className={props.className}>
				<path
					fill='#6AB221'
					fillRule='evenodd'
					d='M12 7.108V4.102c0-1.1.703-1.431 1.563-.744l6.874 5.5c.863.69.86 1.813 0 2.5l-6.875 5.5c-.862.69-1.562.356-1.562-.744v-3.006H4.006A2.004 2.004 0 0 1 2 11.111V9.106c0-1.103.897-1.998 2.006-1.998H12z'
				/>
			</svg>
		);
	}
};

DataLog.defaultProps = {
	fill: '#999',
	width: 24,
	height: 24,
	viewBox: '0 0 24 24',
	style: { marginRight: '8px' },
};

DataLog.propTypes = {
	className: T.string,
};

export default DataLog;
