import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
	data: [],
	names: {},
	loading: false,
};

const getUserDeviceStart = (state) => {
	return updateObject(state, { loading: true });
};

const getUserDeviceSuccess = (state, action) => {
	return updateObject(state, {
		data: action.data,
		names: action.names,
		loading: false,
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_USER_DEVICE_START:
			return getUserDeviceStart(state, action);
		case actionTypes.GET_USER_DEVICE_SUCCESS:
			return getUserDeviceSuccess(state, action);

		default:
			return state;
	}
};

export default reducer;
