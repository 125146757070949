import * as actionTypes from './actionTypes';
import { coreInstance as axios, api } from 'settings';
import i18next from 'i18next';
import { updateTranslations } from '../../i18n';
export const fetchTranslationsRequest = () => ({
	type: actionTypes.FETCH_TRANSLATIONS_REQUEST,
});

export const fetchTranslationsSuccess = (translations) => ({
	type: actionTypes.FETCH_TRANSLATIONS_SUCCESS,
	payload: translations,
});

export const fetchTranslationsFailure = (error) => ({
	type: actionTypes.FETCH_TRANSLATIONS_FAILURE,
	payload: error,
});

export const fetchTranslations = () => {
	return async (dispatch) => {
		try {
			const response = await axios.get(
				`${api.web_translation.index}?localization=${i18next.language}`,
			);

			const translations = {
				[i18next.language]: response.data.data,
			};

			dispatch({ type: 'FETCH_TRANSLATIONS_SUCCESS', payload: response.data });
			updateTranslations(translations);
		} catch (error) {
			dispatch({ type: 'FETCH_TRANSLATIONS_FAIL' });
		}
	};
};
