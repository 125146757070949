import React from 'react';
import { Button, PinCode } from 'components';
import { company } from 'config';

import s from './style.module.scss';

const NotComplete = (props) => {
	const { modal } = props;

	const openModalHandler = () => {
		modal.open(<PinCode type='complete' modal={modal} />);
	};

	return (
		<div className={s.notComplete}>
			<div className={s.inner}>
				<h2 className={s.title}>Your Provider Code is not complete</h2>
				<p>
					Please input the remaining digits of your Provider Code to allow your
					patients to use the {company} mobile app
				</p>
				<Button btnType='type-6' clicked={openModalHandler}>
					COMPLETE PROVIDER PIN CODE
				</Button>
			</div>
		</div>
	);
};

export default NotComplete;
