import React from 'react';
import T from 'prop-types';

const WarningTwo = (props) => (
	<svg {...props} className={props.className}>
		<path d='M11.087 13.864c0-.29-.115-.573-.317-.777a1.093 1.093 0 0 0-.77-.32 1.09 1.09 0 0 0-1.087 1.097A1.09 1.09 0 0 0 10 14.962a1.09 1.09 0 0 0 1.087-1.098zm-.217-6.952a.874.874 0 0 0-.87-.878c-.48 0-.87.393-.87.878v4.084c0 .484.39.878.87.878s.87-.394.87-.878V6.912zM.87 18a.868.868 0 0 1-.752-.436.885.885 0 0 1-.005-.875L9.243.445A.869.869 0 0 1 10 0c.313 0 .602.17.756.445l9.13 16.244a.884.884 0 0 1-.004.875.868.868 0 0 1-.752.436H.87z' />
	</svg>
);

WarningTwo.defaultProps = {
	fill: '#FF8300',
	width: 20,
	height: 18,
	viewBox: '0 0 20 18',
};

WarningTwo.propTypes = {
	className: T.string,
};

export default WarningTwo;
