import { coreInstance as axios, api } from 'settings';
import { logger } from 'helpers';
import * as actionTypes from './actionTypes';

const getengineeringValueStart = () => {
	return {
		type: actionTypes.GET_ENGINEERINGVALUES_START,
	};
};

const getengineeringValueSuccess = (data) => {
	return {
		type: actionTypes.GET_ENGINEERINGVALUESS_SUCCESS,
		data: data,
	};
};

export const getengineeringValue = (concentratorProfileId) => {
	return (dispatch) => {
		dispatch(getengineeringValueStart());
		const request = {
			uri: api.engineering_vals.index,
			params: {
				search: `concentrator_profile_id:${concentratorProfileId}`,
				with: 'concentratorFirmware',
			},
		};

		axios
			.get(request.uri, { params: request.params })
			.then((response) => {
				logger('[Action] Get Engineering values');

				dispatch(getengineeringValueSuccess(response.data));
			})
			.catch(() => {
				logger('[Action] Get Engineering Value fail');
			});
	};
};
