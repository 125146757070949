export const actionsParams = {
	MOST_RECENT_ERROR: 'MOST_RECENT_ERROR',
	APP_CONNECTED: 'APP_CONNECTED',
	COLUMN_LIFE: 'COLUMN_LIFE',
	CURRENTLY_CONNECTED: 'CURRENTLY_CONNECTED',
	SOFTWARE_VERSIONS: 'SOFTWARE_VERSIONS',
	DASH_PARAMS: 'DASH_PARAMS',
	RESET_PARAMS: 'RESET_PARAMS',
};

export const engineeringParams = {
	AIR_CAL_PARAMETER: 1,
	BOARD_TEMP: 2,
	CALIBRATION_DEVIATION_FS1: 3,
	CALIBRATION_DEVIATION_FS2: 4,
	CALIBRATION_DEVIATION_FS3: 5,
	CALIBRATION_DEVIATION_FS4: 6,
	CALIBRATION_DEVIATION_FS5: 7,
	CALIBRATION_DEVIATION_FS6: 8,
	FLOW_SETTING: 9,
	RUN_TIME_DEVIATION: 10,
	SOFTWARE_VERSION: 11,
	SYSTEM_POWER: 12,
	SYSTEM_VOLTAGE: 13,
	ACCUMULATOR_PRESSURE: 14,
	O2_CALIBRATION: 15,
	MOTOR_SPEED_ACTUAL: 16,
	SIEVE_LIFE: 17,
	LIFE_CLOCK: 18,
	OXYGEN_PURITY: 19,
};
