import React from 'react';
import s from './style.module.scss';
import i18next from 'i18next';
import moment from 'moment';

export default function DetailsPanel(props) {
	const { mapper, data } = props;

	// Define a regular expression pattern for the ISO 8601 date format
	const iso8601Pattern = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}[+-]\d{2}:\d{2}$/;

	return (
		<div className={s.ppdBottom}>
			<div className={s.ppdWrapper}>
				{mapper.map((item, index) => (
					<div className={s.ppdColumns} key={index}>
						<div className={s.ppdLabel}>{i18next.t(item.translateKey)}</div>
						<div className={s.ppdValues}>
							{item.translateKey === 'release_date'
								? iso8601Pattern.test(data?.[item.key])
									? new Date(data?.[item.key]).toLocaleDateString('en-US', {
											month: '2-digit',
											day: '2-digit',
											year: 'numeric',
									  })
									: 'NA'
								: item.type === 'date'
								  ? data?.[item.key]
										? moment(data?.[item.key]).format('MM/DD/YYYY')
										: 'NA'
								  : item.type === 'date_and_time'
								    ? data?.[item.key]
											? moment(data?.[item.key]).format('MM/DD/YYYY hh:mm:ss')
											: 'NA'
								    : item.translateKey === 'product'
								      ? data?.[item.key] === 10
												? 'G4'
												: data?.[item.key] === 11
												  ? 'G5'
												  : data?.[item.key] === 12
												    ? 'G4HF'
												    : data?.[item.key] === 13
												      ? 'G5V2'
												      : 'NA'
								      : data?.[item.key] || 'NA'}
						</div>
					</div>
				))}
			</div>
		</div>
	);
}
