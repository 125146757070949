/*colors fetched as per the error_id*/
export const colors = {
	1: '#c56cdf',
	2: '#30adfa',
	3: '#6ab221',
	4: '#e04829',
	5: '#d9d9d9',
	6: '#efb815',
	7: '#e04829',
	8: '#a6bbd6',
	9: '#0b3090',
	10: '#1d7adf',
	11: '#0d9347',
	12: '#ff8300',
	13: '#30adfa',
	14: '#c56cdf',
	15: '#6ab221',
	16: '#30adfa',
	17: '#d9d9d9',
	18: '#0d9347',
	19: '#6c7ddf',
	20: '#333437',
	21: '#e04829',
	22: '#efb815',
	23: '#e04829',
	24: '#e04829',
	27: '#0d9347',
	28: '#a6bbd6',
	31: '#767676',
	36: '#0b3090',
	39: '#efb815',
	40: '#e04829',
	41: '#333437',
	43: '#1d7adf',
	44: '#0b3090',
};
export const chartData = (data = []) =>
	data.map((el) => ({
		name: el.concentrator_error.description,
		value: el.amount,
	}));
