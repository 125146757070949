import React from 'react';
import T from 'prop-types';

const Microsoft = (props) => (
	<svg {...props} className={props.className}>
		<g fill='none' fillRule='evenodd'>
			<path fill='#f25224' d='M0 0h11v11H0z' />
			<path fill='#80bb05' d='M12 0h11v11H12z' />
			<path fill='#feb907' d='M12 12h11v11H12z' />
			<path fill='#04a5ef' d='M0 12h11v11H0z' />
		</g>
	</svg>
);

Microsoft.defaultProps = {
	width: 23,
	height: 23,
	viewBox: '0 0 23 23',
};

Microsoft.propTypes = {
	className: T.string,
};

export default Microsoft;
