import { coreInstance as axios, api } from 'settings';
import { logger } from 'helpers';
import * as actionTypes from '../actionTypes';

const getFirmwaresStart = () => {
	return {
		type: actionTypes.GET_FIRMWARES_START,
	};
};

const getFirmwaresSuccess = (data) => {
	return {
		type: actionTypes.GET_FIRMWARES_SUCCESS,
		data: data,
	};
};

export const getFirmwares = () => {
	const options = {
		orderBy: 'version',
		sortedBy: 'asc',
	};

	return (dispatch) => {
		dispatch(getFirmwaresStart());

		axios
			.get(api.concentrator_firmwares.index, { params: options })
			.then((response) => {
				logger('[Action] Get firmwares');

				dispatch(getFirmwaresSuccess(response.data.data));
			})
			.catch(() => {
				logger('[Action] Get firmwares fail');
			});
	};
};
