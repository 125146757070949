import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
	data: [],
	loading: false,
};

const getTimezonesStart = (state) => {
	return updateObject(state, { loading: true });
};

const getTimezonesSuccess = (state, action) => {
	return updateObject(state, { data: action.data, loading: false });
};

const timezones = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_TIMEZONES_START:
			return getTimezonesStart(state, action);
		case actionTypes.GET_TIMEZONES_SUCCESS:
			return getTimezonesSuccess(state, action);

		default:
			return state;
	}
};

export default timezones;
