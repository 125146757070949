import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { permissions } from 'helpers';
import onClickOutside from 'react-onclickoutside';
import cn from 'classnames';

import s from './style.module.scss';
import i18next from 'i18next';

class ProviderCodesSelect extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isOpen: false,
			opens: {},
		};

		this.config = props.config;

		if (permissions.check('providers.main.filter')) {
			this.config = {
				defaultValue: 'all_providers',
				displayField: 'name',
				label: { path: 'name' },
				mainCheckedKey: 'providers',
				subCheckedKey: 'pinCodes',
				subDataKey: 'provider_codes',
			};
		}
	}

	getCurrentValues = () => {
		const { mainChecked } = this.props;
		const checkedArray = Object.values(mainChecked);
		const values = checkedArray.map((obj) =>
			i18next.t(obj[this.config.displayField]),
		);

		return values.join(', ') || i18next.t(this.config.defaultValue);
	};

	getDefaultValue = () => {
		const { mainChecked, subChecked } = this.props;

		if (this.config.defaultValue) {
			const isNotSet =
				!Object.values(mainChecked).length && !Object.values(subChecked).length;

			return (
				<Fragment>
					<li
						className={cn({ [s.active]: isNotSet, [s.exist]: !isNotSet })}
						onClick={this.onDefaultClick}
					>
						<span>{i18next.t(this.config.defaultValue)}</span>
					</li>
					<li className={s.separator}></li>
				</Fragment>
			);
		}
	};

	getItemstList = () => {
		const { data, mainChecked, subChecked } = this.props;
		const { opens } = this.state;

		return data.map((el) => {
			let isSubExist = false;

			el[this.config.subDataKey] &&
				el[this.config.subDataKey].forEach((el) => {
					subChecked[el.id] && (isSubExist = true);
				});

			return (
				<li
					key={el.id}
					className={cn({
						[s.active]: mainChecked[el.id],
						[s.open]: opens[el.id],
						[s.exist]: isSubExist,
					})}
					onClick={() => this.onItemClick(el, 'mainCheckedKey')}
				>
					<span>
						{el[this.config.label.path]}
						{el[this.config.subDataKey] &&
							el[this.config.subDataKey].length > 1 && (
								<i onClick={(e) => this.onSubMenu(e, el)} />
							)}
					</span>

					{opens[el.id] && el[this.config.subDataKey].length > 1 && (
						<ul className={s.subList}>
							{el[this.config.subDataKey].map((sub) => (
								<li
									key={sub.id}
									className={cn({ [s.active]: subChecked[sub.id] })}
									onClick={(e) =>
										this.onSubItemClick(e, sub, 'subCheckedKey', el)
									}
								>
									<span title={sub.label || sub.code}>
										{i18next.t(sub.label || sub.code)}
									</span>
								</li>
							))}
						</ul>
					)}
				</li>
			);
		});
	};

	onSelectClick = () => {
		this.setState((prevState) => ({ isOpen: !prevState.isOpen, opens: {} }));
	};

	onDefaultClick = () => {
		this.props.onItemClick();
	};

	onItemClick = (item, key) => {
		const { onItemClick } = this.props;

		onItemClick(item, this.config[key]);
	};

	onSubItemClick = (e, item, key, parent) => {
		e.stopPropagation();

		const { onItemClick } = this.props;

		onItemClick(item, this.config[key], parent);
	};

	onSubMenu = (e, el) => {
		e.stopPropagation();

		this.setState(({ opens }) => {
			opens[el.id] ? delete opens[el.id] : (opens[el.id] = el);

			return { opens };
		});
	};

	handleClickOutside = () => {
		this.setState({ isOpen: false });
	};

	render() {
		const { isOpen } = this.state;
		const classes = [s.filterWrap, this.props.className];

		if (isOpen) {
			classes.push(s.open);
		}

		return (
			<div className={classes.join(' ')}>
				<div className={s.filterSelect} onClick={this.onSelectClick}>
					{this.getCurrentValues()}
				</div>

				{isOpen && (
					<div className={s.filterList}>
						<ul className={s.list}>
							{this.getDefaultValue()}
							{this.getItemstList()}
						</ul>
					</div>
				)}
			</div>
		);
	}
}

ProviderCodesSelect.defaultProps = {
	mainChecked: {},
	subChecked: {},
	config: {
		defaultValue: 'aii_pin',
		displayField: 'fullLabel',
		label: { path: 'fullLabel' },
		mainCheckedKey: 'pinCodes',
	},
	data: [],
	onItemClick: () => {},
};

ProviderCodesSelect.propTypes = {
	mainChecked: PropTypes.object,
	subChecked: PropTypes.object,
	config: PropTypes.object,
	data: PropTypes.array,
	onItemClick: PropTypes.func,
};

export default onClickOutside(ProviderCodesSelect);
