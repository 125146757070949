import { coreInstance as axios, api, messages } from 'settings';
import { logger, toast } from 'helpers';
import * as actionTypes from './actionTypes';

const getPinCodesStart = () => {
	return {
		type: actionTypes.GET_PINCODES_START,
	};
};

const getPinCodesSuccess = (data) => {
	return {
		type: actionTypes.GET_PINCODES_SUCCESS,
		data: data,
	};
};

export const getPinCodes = (args = {}) => {
	const { params = {}, success, error } = args;

	return (dispatch) => {
		dispatch(getPinCodesStart());

		axios
			.get(api.provider_codes.index, { params })
			.then((response) => {
				logger('[Action] Get pin codes');

				dispatch(getPinCodesSuccess(response.data.data));

				success && success();
			})
			.catch((err) => {
				logger('[Action] Get pin codes fail');

				error && error(err);
			});
	};
};

export const updatePinCodes = (args = {}) => {
	const { data, success, error } = args;

	toast.success(messages.pinCreated);

	return () => {
		axios
			.post(api.provider_codes.index, data)
			.then(() => {
				logger('[Action] Update Pin Codes');

				success && success();
			})
			.catch((err) => {
				logger('[Action] Update Pin Codes fail');

				error && error(err);
			});
	};
};

export const deletePinCodes = (args = {}) => {
	const { id, success, error } = args;

	toast.success(messages.pinDeleted);

	return () => {
		axios
			.delete(api.provider_codes.index + `/${id}`)
			.then(() => {
				logger('[Action] Delete Pin Code');

				success && success();
			})
			.catch(() => {
				logger('[Action] Delete Pin Code fail');

				error && error();
			});
	};
};
