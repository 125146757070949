import React from 'react';
import T from 'prop-types';

const Back = (props) => (
	<svg {...props} className={props.className}>
		<path d='M7.8 11l5.6-5.6L12 4l-8 8 8 8 1.4-1.4L7.8 13H20v-2z' />
	</svg>
);

Back.defaultProps = {
	fill: '#0b3090',
	width: 24,
	height: 24,
	viewBox: '0 0 24 24',
};

Back.propTypes = {
	className: T.string,
};

export default Back;
