import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
	data: [],
	loading: false,
};

const getTermsStart = (state) => {
	return updateObject(state, { loading: true });
};

const getTermsSuccess = (state, action) => {
	return updateObject(state, { data: action.data, loading: false });
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_TERMS_START:
			return getTermsStart(state, action);
		case actionTypes.GET_TERMS_SUCCESS:
			return getTermsSuccess(state, action);

		default:
			return state;
	}
};

export default reducer;
