import React from 'react';
import PropTypes from 'prop-types';
// import { Input } from "components";

import s from '../style.module.scss';
import i18next from 'i18next';

const CheckboxListFirmware = (props) => {
	const { data, onChange, config, className } = props;

	return (
		<ul className={`${s.checkboxList} ${className}`}>
			{data &&
				data.map((el, index) => (
					<li key={index} className={s.test}>
						<span className={s.spanName}>{el.name}</span>

						<input
							type='checkbox'
							checked={config.value1[el.latest_version] || config.checkAll}
							onClick={(event) => onChange(event, el, 'FirmwareData_LV')}
							onChange={() => {}} // to avoid warning
						/>
						<span>{i18next.t('latest version')}</span>
						<input
							type='checkbox'
							checked={config.value2[el.old_version] || config.checkAll}
							onClick={(event) => onChange(event, el, 'FirmwareData_OV')}
							onChange={() => {}} // to avoid warning
						/>
						<span>{i18next.t('old version')}</span>
					</li>
				))}
		</ul>
	);
};

CheckboxListFirmware.defaultProps = {
	data: [],
	labelKey: 'label',
	config: {},
	onChange: () => {},
	className: '',
};

CheckboxListFirmware.propTypes = {
	data: PropTypes.array,
	labelKey: PropTypes.string,
	config: PropTypes.object,
	onChange: PropTypes.func,
	className: PropTypes.string,
};

export default CheckboxListFirmware;
