import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { routers } from 'settings';
import { timeConvert, permissions } from 'helpers';
import { ErrorRecall } from 'components/Icons';
import 'react-toastify/dist/ReactToastify.min.css';
import s from '../style.module.scss';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

const DeviceInfo = (props) => {
	const {
		data,
		location,
		sendUpdateNotification,
		isSending,
		isAllowUpdate,
		t,
	} = props;
	const isEngineer = permissions.checkRole('engineer');
	const locationArr = [];
	const {
		concentrator_error_recall_last,
		provider_code,
		provider: { name, id },
		concentrator_firmware,
	} = data;
	const {
		code = '',
		description = '',
		deleted_at = null,
	} = provider_code || {};

	const redirectHandler = () => {
		this.bodyNode.style.overflow = 'auto';
		this.rootNode.style.overflow = 'auto';
	};

	let sendNotificationBtn;
	let lastErrorView;
	let locationView;
	let providerName = `${code} ${name} ${description ? description : ''}`;
	let nameView = providerName;

	if (permissions.check('notifications.firmware_update') && isAllowUpdate) {
		sendNotificationBtn = (
			<button
				className={s.updateBtn}
				onClick={sendUpdateNotification}
				disabled={isSending}
			>
				{i18next.t('send_notification_to_update_software')}
			</button>
		);
	}

	if (concentrator_error_recall_last && concentrator_error_recall_last.code) {
		let elapsed, description;

		if (concentrator_error_recall_last.elapsed_time) {
			elapsed = (
				<span className={s.elapsed}>
					{timeConvert(concentrator_error_recall_last.elapsed_time)}
				</span>
			);
		}

		if (concentrator_error_recall_last.concentrator_error.code === 0) {
			description = i18next.t('no_errors');
		} else {
			description = isEngineer
				? concentrator_error_recall_last.concentrator_error.description_engineer
				: concentrator_error_recall_last.concentrator_error.description;
			// description = isEngineer ? 'concentrator_error_engineer_' + concentrator_error_recall_last.concentrator_error.code : 'concentrator_error_' + concentrator_error_recall_last.concentrator_error.code;
		}

		lastErrorView = (
			<tr>
				<td>
					<span>{t('error_recall')}</span> <ErrorRecall className={s.icon} />
				</td>
				<td>
					<b>{t(description)}</b>
				</td>
				<td>
					<div className={s.cellFlex}>{elapsed}</div>
				</td>
			</tr>
		);
	} else {
		lastErrorView = (
			<tr>
				<td>
					<span>{t('error_recall')}</span> <ErrorRecall className={s.icon} />
				</td>
				<td></td>
				<td></td>
			</tr>
		);
	}

	if (permissions.check('users') && !deleted_at) {
		nameView = (
			<Link to={routers.usersProvider(id)} onClick={redirectHandler}>
				{t(providerName)}
			</Link>
		);
	}

	if (location.streetNumber && location.streetName) {
		locationArr.push(`${location.streetNumber} ${location.streetName}`);
	}

	location.locality && locationArr.push(location.locality);

	let location_part = [];

	if (location.countryCode === 'US') {
		location.adminLevelCode && location_part.push(location.adminLevelCode);
	} else {
		location.adminLevelName && location_part.push(location.adminLevelName);
	}

	location.postalCode && location_part.push(location.postalCode);

	if (location.countryCode !== 'US') {
		location.country && location_part.push(location.country);
	}

	locationArr.push(location_part.join(' '));

	if (locationArr.length) {
		locationView = (
			<tr>
				<td>
					<span>{t('location')}</span>
				</td>
				<td>
					<b>{locationArr.join(', ')}</b>
				</td>
				<td>
					<div className={s.cellFlex}>
						{location.updated_location_at &&
							moment(location.updated_location_at)
								.format('DD MMM YYYY')
								.toUpperCase()}
					</div>
				</td>
			</tr>
		);
	} else {
		locationView = (
			<tr>
				<td>
					<span>{t('location')}</span>
				</td>
				<td colSpan='2'></td>
			</tr>
		);
	}

	return (
		<div className={s.pane}>
			<div className={s.paneHead}>
				<h3>{t('device_info')}</h3>
			</div>
			<div className={s.paneBody}>
				<table className={s.deviceInfo}>
					<tbody>
						<tr>
							<td>
								<span>{t('software_version')}</span>
							</td>
							<td>
								<b>
									{concentrator_firmware &&
										`v.${concentrator_firmware.version}`}
								</b>
							</td>
							<td>{sendNotificationBtn}</td>
						</tr>
						<tr>
							<td>
								<span>{t('provider')}</span>
							</td>
							<td className={s.providerCell}>
								<b className={s.providerName}>{nameView}</b>
								{deleted_at && <span className={s.label}>Deleted</span>}
							</td>
							<td></td>
						</tr>
						{lastErrorView}
						{locationView}
					</tbody>
				</table>
			</div>
		</div>
	);
};

export default withTranslation()(DeviceInfo);
