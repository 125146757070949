import React, { memo, Fragment } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cs from 'classnames';
import ReactTooltip from 'react-tooltip';
import { Info } from 'components/Icons';

import ps from '../style.module.scss';
import s from './style.module.scss';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

const chunkBy =
	(number) =>
	(ch = 10) => {
		const chunk = ch;
		const value = number / chunk;
		const chunks = [0];

		while (chunks.length < chunk) {
			const current = chunks[chunks.length - 1] + value;

			chunks.push(
				number < chunk
					? +current
							.toFixed(1)
							.replace(i18next.language === 'fr' ? '.' : 'x', ',')
					: Math.round(current),
			);
		}

		chunks.push(number);

		return chunks;
	};

const ColumnLife = (props) => {
	const { data, t } = props;
	const customData = data.map((el) => {
		el.ranges = Object.values(el).filter((el) => Number.isInteger(el));
		el.max = Math.max(...el.ranges);
		el.total = el.ranges.reduce((acc, val) => (acc += val));

		return el;
	});
	const maxTotalRangle = Math.max(...customData.map((el) => el.total));
	const content = (
		<Fragment>
			<ul className={s.legend}>
				<li>1-20 %</li>
				<li>21-40 %</li>
				<li>41-60 %</li>
				<li>61-80 %</li>
				<li>81-100 %</li>
			</ul>

			<div className={s.chart}>
				<div className={s.grid}>
					{chunkBy(maxTotalRangle)().map((el, i) =>
						i ? (
							<div key={i}>
								<span>{el}</span>
							</div>
						) : (
							<span key={i}>{i}</span>
						),
					)}
				</div>

				<ul className={s.list}>
					{customData.map((el, i) => (
						<li
							key={i}
							style={{ width: (el.total / maxTotalRangle) * 100 + ' %' }}
						>
							<h3 className={s.title}>
								{moment(el.month_updated_at).isSame(moment(), 'day')
									? i18next.t('today')
									: moment(el.month_updated_at)
											.locale(i18next.language)
											.format('Do MMMM')}
							</h3>

							<ul className={s.value}>
								{el.ranges.map((range, i) => (
									<li
										key={i}
										style={{ width: (range / el.total) * 100 + '%' }}
									/>
								))}
							</ul>
						</li>
					))}
				</ul>
			</div>
		</Fragment>
	);

	return (
		<div className={ps[props.width]}>
			<div className={cs(ps.panel, s.panel)}>
				<h2 className={ps.title}>
					{t('column_life')}
					<Info
						fill='#a5bad4'
						data-tip=''
						data-for='ColumnLife '
						className={ps.infoIconTrigger}
					/>
				</h2>
				<ReactTooltip
					id='ColumnLife '
					className={ps.tooltip}
					place='bottom'
					type='light'
					effect='solid'
				>
					<Info fill='#a5bad4' className={ps.infoIconTooltip} />
					<p>{t('last_known_column_life_value_in_the_last_30_months')}</p>
				</ReactTooltip>
				<div className={s.inner}>
					{data.length ? (
						content
					) : (
						<div className={s.noShow}>{t('nothing_to_show')}</div>
					)}
				</div>
			</div>
		</div>
	);
};

ColumnLife.defaultProps = {
	data: [],
	width: 'twoThird',
};

ColumnLife.propTypes = {
	data: PropTypes.array,
	width: PropTypes.string,
};

export default memo(withTranslation()(ColumnLife));
