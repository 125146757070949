import React from 'react';
import T from 'prop-types';

const Reports = (props) => (
	<svg {...props} className={props.className}>
		<path d='M5 6v3h3V6H5zm-.833-2h4.666C9.478 4 10 4.522 10 5.167v4.666C10 10.478 9.478 11 8.833 11H4.167A1.167 1.167 0 0 1 3 9.833V5.167C3 4.522 3.522 4 4.167 4zM14 4h6a1 1 0 0 1 0 2h-6a1 1 0 0 1 0-2zm0 5h6a1 1 0 0 1 0 2h-6a1 1 0 0 1 0-2zM4 14h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 5h12a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z' />
	</svg>
);

Reports.defaultProps = {
	fill: '#999',
	width: 24,
	height: 24,
	viewBox: '0 0 24 24',
};

Reports.propTypes = {
	className: T.string,
};

export default Reports;
