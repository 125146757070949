import React from 'react';
import PropTypes from 'prop-types';

const ValidateError = (props) => {
	const { data, className } = props;

	if (data) {
		let list;

		if (data.errors) {
			list = Object.values(data.errors).map((el, index) => (
				<li key={index}>{el.join(' ')}</li>
			));
		} else {
			list = <li>{data.message}</li>;
		}

		return <ul className={className}>{list}</ul>;
	} else {
		return null;
	}
};

ValidateError.defaultProps = {
	data: null,
	className: '',
};

ValidateError.propTypes = {
	data: PropTypes.object,
	className: PropTypes.string,
};

export default ValidateError;
