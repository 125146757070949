import React from 'react';
import moment from 'moment';

import s from '../style.module.scss';
import i18next from 'i18next';

const RecentError = (props) => {
	const { data } = props;

	let recentErrorsBody = (
		<div className={s.noFound}>{i18next.t('no_errors_were_found')}</div>
	);

	if (data.length) {
		recentErrorsBody = (
			<table className={s.recentError}>
				<thead>
					<tr>
						<th>{i18next.t('error')}</th>
						<th>{i18next.t('date')}</th>
						<th>{i18next.t('time')}</th>
					</tr>
				</thead>
				<tbody>
					{data.map((row) => {
						const error = row.concentrator_error || {};
						const code = row.code || '';
						const errorView = error.description || code;

						if (!errorView) return null;

						return (
							<tr key={row.id}>
								<td>
									<b>{i18next.t(errorView)}</b>
								</td>
								<td>{moment(row.happened_at).format('D MMM YYYY')}</td>
								<td>{moment(row.happened_at).format('H:mm')}</td>
							</tr>
						);
					})}
				</tbody>
			</table>
		);
	}

	return (
		<div className={s.pane}>
			<div className={s.paneHead}>
				<h3>{i18next.t('recent_error_records')}</h3>
			</div>
			<div className={s.paneBody}>{recentErrorsBody}</div>
		</div>
	);
};

export default RecentError;
