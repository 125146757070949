import s from './style.module.scss';
import i18next from 'i18next';

const initialStateCreator = (props, state = {}) => {
	const { controls, newProviderData } = state;
	let providerDefault = props.providersData[0]
		? { [props.providersData[0].id]: props.providersData[0] }
		: '';

	if (controls && controls.provider.checked) {
		providerDefault = controls.provider.checked;
	}

	if (newProviderData) {
		providerDefault = { [newProviderData.id]: newProviderData };
	}

	return {
		controls: {
			email: {
				elementType: 'input',
				elementConfig: {
					type: 'email',
					placeholder: 'user_email',
				},
				className: s.input,
				value: controls ? controls.email.value : '',
				validation: {
					required: true,
					isEmail: true,
				},
				valid: controls ? controls.email.valid : false,
				errorMsg: i18next.t('auth_invalid_email_name'),
				touched: false,
			},
			provider: {
				checked: providerDefault,
			},
			role: {},
			newProvider: {
				name: {
					elementType: 'input',
					elementConfig: {
						type: 'text',
						placeholder: 'name',
					},
					className: s.input,
					value: '',
					validation: {
						required: true,
					},
					valid: false,
					errorMsg: '',
					touched: false,
				},
				pin: {
					elementType: 'mask',
					elementConfig: {
						type: 'text',
						placeholder: '__',
						guide: true,
					},
					className: s.pinInput,
					value: '',
					validation: {
						required: true,
					},
					valid: false,
					errorMsg: '',
					touched: false,
				},
			},
		},
		newProviderData: null,
		providerView: null,
		formValidMessage: null,
		step: 1,
		buttonEnabled: false,
		userbuttonEnabled: true,
	};
};

export default initialStateCreator;
