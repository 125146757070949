import { coreInstance as axios, api } from 'settings';
import { logger, getDeep } from 'helpers';
import * as actionTypes from './actionTypes';

export const getModelsStart = () => {
	return {
		type: actionTypes.GET_MODELS_START,
	};
};

export const getModelsSuccess = (data) => {
	return {
		type: actionTypes.GET_MODELS_SUCCESS,
		data: data,
	};
};

export const clearModels = () => {
	return {
		type: actionTypes.CLEAR_MODELS,
	};
};

export const getModels = (args = {}) => {
	const { params, successHandler, errorHandler } = args;

	return (dispatch) => {
		dispatch(getModelsStart());

		axios
			.get(api.concentrator_models.index, { params })
			.then((response) => {
				logger('[Action] Get models');

				dispatch(getModelsSuccess(response.data.data));
				successHandler &&
					successHandler(getDeep(['data', 0, 'id'], response.data));
			})
			.catch(() => {
				logger('[Action] Get models fail');

				errorHandler && errorHandler();
			});
	};
};
