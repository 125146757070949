import React from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';

import s from './style.module.scss';
import theme from './theme.module.scss';
import i18next from 'i18next';

const SearchMultiSelect = (props) => {
	const {
		value,
		placeholder,
		suggestions,
		labelKey,
		className,
		disabled,
		onChange,
		onFetch,
		onClear,
		onSelected,
		onSubmit,
	} = props;

	const inputProps = {
		placeholder,
		value,
		onChange,
	};

	const submitHandler = (e) => {
		e.preventDefault();
		onSubmit(e);
	};

	const renderSuggestion = (suggestion) => (
		<div className={s.item}>{suggestion[labelKey]}</div>
	);

	const renderInputComponent = (inputProps) => (
		<div className={[s.searchInput, className].join(' ')}>
			<form onSubmit={submitHandler}>
				<input {...inputProps} disabled={disabled} />
			</form>
		</div>
	);

	return (
		<Autosuggest
			theme={theme}
			suggestions={suggestions}
			alwaysRenderSuggestions
			onSuggestionsFetchRequested={onFetch}
			onSuggestionsClearRequested={onClear}
			onSuggestionSelected={onSelected}
			getSuggestionValue={(suggestion) => suggestion[labelKey]}
			renderSuggestion={renderSuggestion}
			inputProps={inputProps}
			renderInputComponent={renderInputComponent}
		/>
	);
};

SearchMultiSelect.defaultProps = {
	value: '',
	placeholder: i18next.t('search_concentrators'),
	suggestions: [],
	labelKey: 'serial_number',
	className: '',
	onChange: () => {},
	onFetch: () => {},
	onClear: () => {},
	onSelected: () => {},
	onSubmit: () => {},
};

SearchMultiSelect.propTypes = {
	value: PropTypes.string,
	placeholder: PropTypes.string,
	suggestions: PropTypes.array,
	labelKey: PropTypes.string,
	className: PropTypes.string,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	onFetch: PropTypes.func,
	onClear: PropTypes.func,
	onSelected: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default SearchMultiSelect;
