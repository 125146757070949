import React from 'react';
import T from 'prop-types';

const DownloadThree = (props) => (
	<svg {...props} className={props.className}>
		<path
			fillRule='evenodd'
			strokeLinecap='round'
			d='M9.5 2.5v13M4.5 10.5l5.099 5.099M14.599 10.5L9.5 15.599'
		/>
	</svg>
);

DownloadThree.defaultProps = {
	stroke: '#30ADFA',
	fill: '#30ADFA',
	width: 20,
	height: 20,
	viewBox: '0 0 20 20',
};

DownloadThree.propTypes = {
	className: T.string,
};

export default DownloadThree;
