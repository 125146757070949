import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as actions from 'store/actions';
import { logger, permissions } from 'helpers';
import { makeGetAllFirmwaresState } from 'store/selectors';
import { Button, Table } from 'components';
import DetailsPopup from './Popups/Details';
import ManagePopup from './Popups/Manage';
import ConfirmPopup from './Popups/Confirm';
import RowTooltip from './RowTooltip';
import historyTableConfig from './Configs/historyTable';
import firmwareConfig from './Configs/Firmware';
import { modelsParams, filtersParams } from './Configs/paramsCreator';
import { ModalPopUp } from '../../components';
import s from './newStyle.module.scss';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Info } from 'components/Icons';
import ReactTooltip from 'react-tooltip';
import SelectComponent from '../../../src/components/UI/SelectComponent';

class Firmware extends Component {
	constructor(props) {
		super(props);
		const { getModels } = props;

		this.state = {
			activeTab: 0,
			historyViewAll: null,
			chosenOption: 'Recent Top 10',
			selectedValue: 'Inogen One G4',
			selectedFilter: i18next.t('recent'),
			modal: {
				isOpen: false,
				type: null,
			},
			filters: filtersParams,
		};
		this.closeTooltip = () => this.setState({ overRow: null });

		getModels({ params: modelsParams });

		window.addEventListener('scroll', this.closeTooltip);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.closeTooltip);
		this.props.clearFirmwares();
	}

	componentDidUpdate(prevProps) {
		logger('[Firmware] componentDidUpdate');

		const { params, getFirmwares } = this.props;
		const isParamsChange = params !== prevProps.params;

		if (isParamsChange) {
			getFirmwares({ params });
		}
	}

	onViewAll = () =>
		this.setState((prevState) => ({
			historyViewAll: !prevState.historyViewAll,
		}));

	rowOverHandler = (e, row, version) => {
		clearTimeout(this.tooltipTimer);
		let rowData = '';
		if (row.countValue) {
			rowData = row.countValue;
			row = '';
		}
		this.setState({
			overRow: {
				data: row,
				count: rowData,
				version: version, // New variable for version
				coords: e.currentTarget.getBoundingClientRect(),
			},
		});
	};

	rowOutHandler = () => {
		this.tooltipTimer = setTimeout(() => this.setState({ overRow: null }), 300);
	};

	toolTipOver = () => {
		clearTimeout(this.tooltipTimer);
	};

	toolTipOut = () => {
		this.rowOutHandler();
	};

	modalOpen = ({ type, data, restrictClosing }) => {
		if (!permissions.check('firmwares.details')) return;
		if (type === 'view' || type === 'deleteBtn') {
			this.props.getFirmwareById({ id: data.id });
		}

		this.setState({
			modal: {
				isOpen: true,
				type: type,
				restrictClosing,
			},
			overRow: null,
			errors: null,
		});
	};

	modalClose = () => {
		this.props.clearFirmwareById();
		this.setState({
			modal: {
				isOpen: false,
				type: null,
			},
			errors: null,
		});
	};

	handleModel = (data) => {
		let modelId;
		if (data === 'Inogen One G4') {
			modelId = 10;
		} else if (data === 'Inogen One G5') {
			modelId = 11;
		} else if (data === 'Inogen Rove 4') {
			modelId = 12;
		} else if (data === 'Inogen Rove 6') {
			modelId = 13;
		} else {
			modelId = data;
		}
		this.setState({ selectedValue: data });
		this.props.setRequestParam({ search: { concentrator_model_id: modelId } });
	};

	handleChange = (label) => {
		this.setState({ chosenOption: label });
	};
	errorHandler = ({ errors, message }) => {
		this.setState({
			errors: errors ? Object.values(errors) : message && [message],
		});
	};

	render() {
		const {
			loading,
			globalLoading,
			models,
			history,
			testing,
			drafts,
			recentfirmware,
			unknown,
			current,
			manageFirmware,
			deleteFirmware,
			copyFirmware,
			t,
		} = this.props;
		const { overRow, historyViewAll, modal, errors, filters } = this.state;
		let statusId = current?.status_id;

		let statusText;
		let labelColor;

		switch (statusId) {
			case 1:
				statusText = i18next.t('latest_status');
				labelColor = s.latest;
				break;
			case 2:
				statusText = i18next.t('upcoming_status');
				labelColor = s.upcoming;
				break;
			case 3:
				statusText = i18next.t('history_status');
				labelColor = s.history;
				break;
			case 4:
				statusText = i18next.t('test_status');
				labelColor = s.test;
				break;
			case 5:
				statusText = i18next.t('draft_status');
				labelColor = s.draft;
				break;
			case 6:
				statusText = i18next.t('unknown_status');
				labelColor = s.unknown;
				break;
			default:
				statusText = '';
		}
		let statusLabel = (
			<span className={`${s.label} ${labelColor}`}>{statusText}</span>
		);

		const modelOptions =
			models?.map((currentItem) => ({
				value: currentItem.description,
				label: currentItem.description,
			})) || [];

		const manageHandler = (data) => {
			manageFirmware({
				data,
				successHandler: this.modalClose,
				errorHandler: this.errorHandler,
			});
		};
		const deleteHandler = () => {
			deleteFirmware({
				id: current.id,
				successHandler: this.modalClose,
				errorHandler: this.errorHandler,
			});
		};

		let tableTooltip, modalContent;

		if (
			overRow &&
			((overRow.data && overRow.data.description) || overRow.count)
		) {
			tableTooltip = (
				<RowTooltip
					row={overRow}
					onMouseOver={this.toolTipOver}
					onMouseOut={this.toolTipOut}
					modalOpen={() =>
						this.modalOpen({
							data: overRow.data,
							type: 'view',
							restrictClosing: true,
						})
					}
					left={200}
				/>
			);
		}

		switch (modal.type) {
			case 'delete':
			case 'deleteBtn':
				modalContent = (
					<ConfirmPopup
						confirmHandler={deleteHandler}
						cancelHandler={this.modalClose}
						name={`${i18next.t('firmware_version_delete')} '${
							current?.version
						}'`}
					/>
				);
				break;

			case 'manage':
			case 'new':
				modalContent = (
					<ManagePopup
						data={current}
						models={models}
						errors={errors}
						loading={globalLoading}
						onManage={manageHandler}
						close={this.modalClose}
						errorHandler={this.errorHandler}
					/>
				);
				break;
			default:
				modalContent = (
					<DetailsPopup
						data={current}
						errors={errors}
						loading={globalLoading}
						onEdit={() =>
							this.modalOpen({ type: 'manage', restrictClosing: true })
						}
						onDelete={() =>
							this.modalOpen({ type: 'delete', restrictClosing: true })
						}
						onManage={manageHandler}
						onCopy={copyFirmware}
						title={`${current?.concentrator_model.name} ${current?.version}`}
						close={this.modalClose}
						additionalPlaceholder={modal.type !== 'delete' ? statusLabel : ''}
					/>
				);
				break;
		}

		return (
			<div className={s.page}>
				<div className={s.content}>
					<h1 className={s.pageTitle}>{i18next.t('firmwares')}</h1>
					<div className={s.secondaryHeader}>
						<div>
							<SelectComponent
								options={modelOptions}
								value={this.state.selectedValue}
								onChange={this.handleModel}
								disabled={false}
								isMulti={false}
								selectedLabel={this.state.selectedValue}
								className={s.selectfilterStyling}
							/>
							<SelectComponent
								options={filters}
								value={this.state.chosenOption}
								onChange={this.handleChange}
								isMulti={false}
								selectedLabel={this.state.selectedFilter}
								className={s.selectfilterStyling}
							/>
							{this.state.chosenOption === 'Unknown' ? (
								<>
									<Info fill='#6C7DDF' data-tip='' data-for='help' />
									<ReactTooltip
										id='help'
										className={s.help}
										place='bottom'
										type='light'
										effect='solid'
									>
										<Info fill='#a5bad4' className={s.infoIconHelp} />
										<p>{t('unique_firmware_help')}</p>
									</ReactTooltip>
								</>
							) : (
								''
							)}
						</div>
						{permissions.check('firmwares.create') && (
							<Button
								className={s.uploadNew}
								btnType={'type-20'}
								clicked={() =>
									this.modalOpen({ type: 'new', restrictClosing: true })
								}
							>
								<svg
									width='16'
									height='16'
									viewBox='0 0 16 16'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
									style={{ marginRight: '1.2rem' }}
								>
									<path
										d='M7.25 15.5V7.09993L4.78462 9.5653L3.7308 8.4807L7.99997 4.21152L12.2692 8.4807L11.2153 9.5653L8.74995 7.09993V15.5H7.25ZM0.5 5.0192V2.3077C0.5 1.80257 0.675 1.375 1.025 1.025C1.375 0.675 1.80257 0.5 2.3077 0.5H13.6922C14.1974 0.5 14.625 0.675 14.975 1.025C15.325 1.375 15.5 1.80257 15.5 2.3077V5.0192H14V2.3077C14 2.23077 13.9679 2.16024 13.9038 2.09613C13.8397 2.03203 13.7692 1.99998 13.6922 1.99998H2.3077C2.23077 1.99998 2.16024 2.03203 2.09612 2.09613C2.03202 2.16024 1.99997 2.23077 1.99997 2.3077V5.0192H0.5Z'
										fill='white'
									/>
								</svg>

								{i18next.t('upload_new_version')}
							</Button>
						)}
					</div>
					{/* <statusLabel status={current?.status_id} /> */}
					{this.state.chosenOption === 'Recent Top 10' ? (
						<Table
							className={s.table}
							config={firmwareConfig}
							data={recentfirmware}
							loading={loading}
							onDeleteItemClick={(event) =>
								this.modalOpen({
									data: overRow.data,
									type: event,
									restrictClosing: true,
								})
							}
							onRowClicked={(row) =>
								this.modalOpen({
									data: row,
									type: 'view',
									restrictClosing: true,
								})
							}
							onDeleteItemClickToolTip={this.rowOverHandler}
							onRowOver={this.rowOverHandler}
							onRowOut={this.rowOutHandler}
						/>
					) : (
						<></>
					)}
					{this.state.chosenOption === 'Test' ? (
						<Table
							className={s.table}
							config={firmwareConfig}
							data={testing}
							loading={loading}
							onRowClicked={(row) =>
								this.modalOpen({
									data: row,
									type: 'view',
									restrictClosing: true,
								})
							}
							onDeleteItemClick={(event) =>
								this.modalOpen({
									data: overRow.data,
									type: event,
									restrictClosing: true,
								})
							}
							onDeleteItemClickToolTip={this.rowOverHandler}
							onRowOver={this.rowOverHandler}
							onRowOut={this.rowOutHandler}
						/>
					) : (
						<></>
					)}
					{this.state.chosenOption === 'Unknown' ? (
						<>
							<Table
								className={s.table}
								config={firmwareConfig}
								data={unknown}
								loading={loading}
								onRowClicked={(row) =>
									this.modalOpen({
										data: row,
										type: 'view',
										restrictClosing: true,
									})
								}
								onRowOver={this.rowOverHandler}
								onRowOut={this.rowOutHandler}
							/>{' '}
						</>
					) : (
						<></>
					)}
					{this.state.chosenOption === 'Draft' ? (
						<Table
							className={s.table}
							config={firmwareConfig}
							data={drafts}
							loading={loading}
							onDeleteItemClick={(event) =>
								this.modalOpen({
									data: overRow.data,
									type: event,
									restrictClosing: true,
								})
							}
							onRowClicked={(row) =>
								this.modalOpen({
									data: row,
									type: 'view',
									restrictClosing: true,
								})
							}
							onDeleteItemClickToolTip={this.rowOverHandler}
							onRowOver={this.rowOverHandler}
							onRowOut={this.rowOutHandler}
						/>
					) : (
						<></>
					)}
					<div className={s.tableStyling}></div>

					<Table
						title={t('firmwares_history')}
						className={s.table}
						config={historyTableConfig}
						data={history}
						loading={loading}
						maxRows={5}
						viewAll={historyViewAll}
						onViewAll={this.onViewAll}
						onRowClicked={(row) =>
							this.modalOpen({ data: row, type: 'view', restrictClosing: true })
						}
						onRowOver={this.rowOverHandler}
						onDeleteItemClickToolTip={this.rowOverHandler}
						onRowOut={this.rowOutHandler}
					/>
					{tableTooltip}
					<ModalPopUp
						className={
							modal.type === 'delete' || modal.type === 'deleteBtn'
								? s.modalDelete
								: modal.type === 'new' || modal.type === 'manage'
								  ? s.modalCreate
								  : s.modal
						}
						show={modal.isOpen}
						modalTitle={
							modal.type !== 'delete'
								? `${current?.concentrator_model.name} ${current?.version}`
								: ''
						}
						modalClosed={this.modalClose}
						additionalPlaceholder={modal.type !== 'delete' ? statusLabel : ''}
						restrictClosing={modal.restrictClosing}
						loading={modal.type !== 'new' && !current}
						hideTitle={modal.restrictClosing}
					>
						{modalContent}
					</ModalPopUp>
				</div>
			</div>
		);
	}
}

const mapStateToProps = () => {
	const getFirmwaresState = makeGetAllFirmwaresState();

	return (props, state) => getFirmwaresState(props, state);
};

const mapDispatchToProps = (dispatch) => {
	return {
		getModels: (args) => dispatch(actions.getModels(args)),
		getFirmwares: (args) => dispatch(actions.getAllFirmwares(args)),
		clearFirmwares: (args) => dispatch(actions.clearFirmwares(args)),
		getFirmwareById: (args) => dispatch(actions.getFirmwareById(args)),
		manageFirmware: (args) => dispatch(actions.manageFirmware(args)),
		copyFirmware: (args) => dispatch(actions.copyFirmware(args)),
		deleteFirmware: (args) => dispatch(actions.deleteFirmware(args)),
		clearFirmwareById: (args) => dispatch(actions.clearFirmwareById(args)),
		setRequestParam: (params) =>
			dispatch(actions.setAllFirmwaresRequestParam(params)),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withTranslation()(Firmware));
