export const getSearchInputParams = (value) => ({
	search: {
		title: value ? encodeURIComponent(value) : null,
		body: value ? encodeURIComponent(value) : null,
		'user.name': value ? encodeURIComponent(value) : null,
	},
	searchFields: {
		title: value ? 'ilike' : null,
		body: value ? 'ilike' : null,
		'user.name': value ? 'ilike' : null,
	},
	searchJoin: {
		title: value ? 'or' : null,
		body: value ? 'or' : null,
		'user.name': value ? 'or' : null,
	},
});

export const errorsParams = {
	orderBy: 'description',
	sortedBy: 'asc',
};

export const getStatesParams = () => ({
	params: {
		search: {
			country_id: 238,
		},
		searchFields: {
			country_id: '=',
		},
		with: 'timezone',
	},
});

export const getRolesParams = () => ({
	params: {
		search: {
			'permissions.name': 'notifications.patient_service_notification',
		},
		searchFields: {
			'permissions.name': '=',
		},
	},
});

export const providersParams = {};

export const getFiltersParam = (providerIds = null, pinCodeIds = null) => ({
	search: {
		'concentratorNotificationHistory.concentratorProfile.provider_id':
			providerIds,
		'concentratorNotificationHistory.concentratorProfile.provider_code_id':
			pinCodeIds,
	},
	searchFields: {
		'concentratorNotificationHistory.concentratorProfile.provider_id':
			providerIds && 'in',
		'concentratorNotificationHistory.concentratorProfile.provider_code_id':
			pinCodeIds && 'in',
	},
});

//Param for Model query
export const modelsParams = {
	orderBy: 'id',
};
