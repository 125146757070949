import * as actionTypes from '../../actions/actionTypes';

const initialState = {};

const setDashParam = (state, action) => {
	return {
		...state,
		...action.params,
		search: {
			...state.search,
			...action.params.search,
		},
		searchFields: {
			...state.searchFields,
			...action.params.searchFields,
		},
	};
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.SET_DASH_PARAM:
			return setDashParam(state, action);

		default:
			return state;
	}
};

export default reducer;
