import React, { Component, Fragment } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';
import { makeGetAllAppState } from 'store/selectors';
import { routers } from 'settings';
import { permissions } from 'helpers';
import * as actions from 'store/actions';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
	Auth,
	Dashboard,
	Firmware,
	Profile,
	PushNotifications,
	Users,
	UsersProviders,
	PatientDetails,
	DbStatistics,
} from 'containers';
import PatientDashboard from './containers/PatientDashboard';
import {
	GlobalLoader,
	Layout,
	Modal,
	Terms,
	Consent,
	LoadScripts,
} from 'components';
import './analytics';
import Errors from './components/Error';

import { Close } from 'components/Icons';

import s from './App.css';

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			modal: {
				defaultOpen: true,
				isOpen: false,
				restrictClosing: false,
				data: null,
			},
			isConsentGiven: false,
		};
	}

	componentDidMount() {
		this.props.getCountryName();
		this.props.onTryAutoSignup();
	}

	componentDidUpdate(prevProps) {
		const { isAuthenticated, userConsent } = this.props;

		const { defaultOpen } = this.state.modal;

		if (isAuthenticated) {
			if (prevProps.userConsent !== this.props.userConsent) {
				if (defaultOpen && userConsent.length === 0) {
					this.setState({
						modal: {
							defaultOpen: false,
							isOpen: true,
							restrictClosing: true,
							data: (
								<Consent
									modal={{
										open: this.modalOpenHandler,
										close: this.modalCloseHandler,
									}}
								/>
							),
						},
					});
				} else {
					this.setState((prevState) => {
						return {
							...prevState,
							isConsentGiven: true,
						};
					});
				}
			}
		}
	}

	modalOpenHandler = (data) => this.setState({ modal: { isOpen: true, data } });

	modalCloseHandler = () =>
		this.setState({ modal: { isOpen: false } }, this.redirect);

	render() {
		const {
			loading,
			isAuthenticated,
			userName,
			userPermissions,
			terms,
			userRoles,
		} = this.props;
		const {
			modal: { isOpen, data },
			isConsentGiven,
		} = this.state;

		const CloseBtn = ({ closeToast }) => (
			<Close
				className='Toastify__close-button'
				fillOpacity='.54'
				onClick={closeToast}
			/>
		);

		// eslint-disable-next-line react/display-name
		const withModal = (Component) => () => (
			<Component
				modal={{
					open: this.modalOpenHandler,
					close: this.modalCloseHandler,
				}}
			/>
		);

		const termsModalOpenHandler = () => {
			return this.modalOpenHandler(<Terms data={terms?.terms_of_use?.value} />);
		};

		const layoutProps = {
			user: userName,
			termsModal: {
				open: termsModalOpenHandler,
				close: () => this.setState({ modal: { isOpen: false } }),
			},
			isConsentGiven,
		};

		let appContent;

		if (isAuthenticated) {
			if (userPermissions) {
				if (userPermissions.length) {
					appContent = (
						<Layout {...layoutProps}>
							<Switch>
								{userRoles?.[0]?.name === 'provideradmin' && (
									<Route
										exact
										path='/'
										render={() => <Redirect to='/users' />}
									/>
								)}
								{permissions.check('dashboard') && isConsentGiven && (
									<Route path={routers.index} exact component={Dashboard} />
								)}
								{permissions.check('dashboard_patient') && isConsentGiven && (
									<Route
										path={
											permissions.check('dashboard')
												? routers.patientDashboardIndex
												: routers.index
										}
										exact
										component={PatientDashboard}
									/>
								)}
								{permissions.check('notifications') && isConsentGiven && (
									<Route
										path={routers.notifications}
										exact
										component={PushNotifications}
									/>
								)}
								{permissions.check('db_statistics') && isConsentGiven && (
									<Route
										path={routers.appStatistics}
										exact
										component={DbStatistics}
									/>
								)}
								{permissions.check('users') && isConsentGiven && (
									<Route
										path={routers.users}
										render={withModal(UsersProviders)}
									/>
								)}
								{permissions.check('users_provider_admin') &&
									isConsentGiven && (
										<Route path={routers.users} render={withModal(Users)} />
									)}
								{permissions.check('firmwares') && isConsentGiven && (
									<Route path={routers.firmware} component={Firmware} />
								)}
								<Route path={routers.profile} exact component={Profile} />

								<Route
									path={routers.patientDetail}
									exact
									component={PatientDetails}
								/>
								{isConsentGiven && <Redirect to={routers.index} />}
							</Switch>
						</Layout>
					);
				} else {
					appContent = (
						<Switch>
							<Route path={routers.detail}>
								<Errors />
							</Route>
							<Route path={routers.index} component={Auth} />;
						</Switch>
					);
				}
			}
		} else {
			appContent = (
				<Switch>
					<Route path={routers.detail}>
						<Errors />
					</Route>
					<Route path={routers.index} component={Auth} />;
				</Switch>
			);
		}

		return (
			<Fragment>
				{loading && <GlobalLoader />}

				{appContent}

				<ToastContainer
					position='bottom-right'
					hideProgressBar
					closeOnClick
					pauseOnVisibilityChange
					draggable
					closeButton={<CloseBtn />}
					pauseOnHover={false}
				/>

				<Modal
					className={s.modal}
					show={isOpen}
					restrictClosing={this.state.modal.restrictClosing}
					modalClosed={this.modalCloseHandler}
				>
					{data}
				</Modal>
				<div>
					<LoadScripts isAuthenticated={isAuthenticated} />
				</div>
			</Fragment>
		);
	}
}

const mapStateToProps = () => {
	const getAllAppState = makeGetAllAppState();

	return (props, state) => getAllAppState(props, state);
};

const mapDispatchToProps = (dispatch) => {
	return {
		onTryAutoSignup: () => dispatch(actions.authCheckState()),
		getCountryName: () => dispatch(actions.getCountryName()),
	};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
