import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
	data: [],
	loading: false,
};

const getHealthcheckStart = (state) => {
	return updateObject(state, { loading: true });
};

const getHealthcheckSuccess = (state, action) => {
	const data = action.data.map((el) => {
		if (el.label === 'Column Life') {
			el.label = 'Columns';
		}

		el.id = el.healthcheck_parameter_id;

		return el;
	});

	return updateObject(state, { data: data, loading: false });
};

const clearHealthcheck = () => {
	return initialState;
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_HEALTHCHECK_START:
			return getHealthcheckStart(state, action);
		case actionTypes.GET_HEALTHCHECK_SUCCESS:
			return getHealthcheckSuccess(state, action);

		case actionTypes.CLEAR_CONCENTRATORS:
			return clearHealthcheck(state, action);

		default:
			return state;
	}
};

export default reducer;
