/**
 * A generic table component that supports sorting, filtering, and pagination.
 *
 * @component
 * @example
 * // Usage example:
 * import React from 'react';
 * import ListView from './ListView';
 *
 * const columnSettings = [
 *   { Header: 'ID', accessor: 'id', _filter: true },
 *   { Header: 'Name', accessor: 'name', _sort: true },
 *   // ... other columns
 * ];
 *
 * const data = [
 *   { id: 1, name: 'Alice', age: 25, date: '2023-08-25T10:30:00Z' },
 *   // ... other data
 * ];
 *
 * const App = () => {
 *   return (
 *     <ListView
 *       columnSettings={columnSettings}
 *       data={data}
 *       pageSize={10}
 *       defaultSortColumn="last_occured"
 *       defaultSortColumnDirection="desc"
 *       onPageChange={handlePageChange}
 *       onSortChange={handleSortChange}
 *       onFilterChange={handleFilterChange}
 *     />
 *   );
 * };
 *
 * @param {Object} props - Component props
 * @param {Array} props.columnSettings - An array of column settings for the table
 * @param {Array} props.data - The data to be displayed in the table
 * @param {number} [props.pageSize=10] - The number of rows to display per page
 * @param {number} [props.defaultSortColumn] - Default column to be sorted on load
 * @param {number} [props.defaultSortColumnDirection] - Default column to be sorted in direction on load
 * @param {Function} [props.onPageChange] - Callback function for page change event
 * @param {Function} [props.onSortChange] - Callback function for sort change event
 * @param {Function} [props.onFilterChange] - Callback function for filter change event
 * @returns {JSX.Element} - The rendered table component
 */

/* eslint-disable no-unused-vars */
import React, { useMemo, useState, useEffect } from 'react';
import s from './style.module.scss';
import i18next from 'i18next';

export const defaultData = [
	{ id: 1, name: 'Alice', age: 25, date: '2023-08-25T10:30:00Z' },
	{ id: 2, name: 'Bob', age: 30, date: '2023-08-24T14:45:00Z' },
	{ id: 3, name: 'Charlie', age: 22, date: '2023-08-26T08:15:00Z' },
	{ id: 4, name: 'Alice', age: 25, date: '2023-08-25T10:30:00Z' },
	{ id: 5, name: 'Bob', age: 30, date: '2023-08-24T14:45:00Z' },
	{ id: 6, name: 'Charlie', age: 22, date: '2023-08-26T08:15:00Z' },
	// ... more data
];

export default function ListView(props) {
	const defaultColumns = useMemo(
		() => [
			{
				Header: '',
				accessor: 'id',
				Cell: ({ value }) => (
					<input
						type='checkbox'
						checked={selectedRows.includes(value)}
						onChange={() => handleRowSelection(value)}
					/>
				),
			},
			{ Header: 'ID', accessor: 'id', _filter: true },
			{ Header: 'Name', accessor: 'name', _sort: true },
			{ Header: 'Errors', accessor: 'errors' },
			{
				Header: 'Date',
				accessor: 'date',
				Cell: ({ value }) => new Date(value).toLocaleString(),
			},
		],
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	let {
		columnSettings: propColumnSetting,
		data: propData,
		pageSize: propPageSize,
		clickedRow,

		onPageChange,
		onSortChange,
		onFilterChange,
		onClickChange,

		defaultSortColumn,
		defaultSortColumnDirection,
		calcRowColor,
		stylingProps,
		staticData = true,
		targetRowRef,
	} = props;
	const columns = useMemo(
		() => propColumnSetting || defaultColumns,
		[propColumnSetting, defaultColumns],
	);
	const data = propData || defaultData;

	const pageSize = propPageSize || 2;

	const [filters, setFilters] = useState({});
	const [sortColumn, setSortColumn] = useState(defaultSortColumn);
	const [sortDirection, setSortDirection] = useState(
		defaultSortColumnDirection,
	);
	const [pageIndex, setPageIndex] = useState(0);
	const [selectedRows, setSelectedRows] = useState([]);
	const [globalSearch, setGlobalSearch] = useState('');

	useEffect(() => {
		setSortColumn(defaultSortColumn);
		setSortDirection(defaultSortColumnDirection);
	}, [defaultSortColumn, defaultSortColumnDirection]);

	const filteredData = useMemo(() => {
		let filteredData;
		if (staticData === true) {
			filteredData = data;

			if (onFilterChange) {
				filteredData = onFilterChange(filters, filteredData);
			} else {
				Object.keys(filters).forEach((columnId) => {
					const filterValue = filters[columnId];
					if (filterValue) {
						filteredData = filteredData.filter(
							(row) => row[columnId].toString() === filterValue,
						);
					}
				});
			}

			return filteredData; // Return a new array
		}
	}, [filters, data, onFilterChange, staticData]);

	const sortedData = useMemo(() => {
		if (sortColumn && staticData === true) {
			let sortedData = filteredData.slice(); // Create a new array

			if (onSortChange) {
				sortedData = onSortChange(sortColumn, sortDirection);
			} else {
				sortedData = sortedData.sort((a, b) => {
					const aValue = a[sortColumn];
					const bValue = b[sortColumn];

					if (sortDirection === 'asc') {
						return aValue > bValue ? 1 : -1;
					} else {
						return aValue < bValue ? 1 : -1;
					}
				});
			}

			return sortedData; // Return a new array
		}

		return filteredData;
	}, [filteredData, sortColumn, sortDirection, onSortChange, staticData]);

	useEffect(() => {
		onSortChange(sortColumn, sortDirection);
	}, [sortColumn, sortDirection, onSortChange]);

	let pageCount = 0;
	let paginatedData = [];
	if (staticData === true) {
		pageCount =
			sortedData &&
			Array.isArray(sortedData) &&
			Math.ceil(sortedData.length / pageSize);
		paginatedData =
			(sortedData &&
				Array.isArray(sortedData) &&
				sortedData.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)) ||
			[];
	} else {
		pageCount =
			(data && Array.isArray(data) && Math.ceil(data.length / pageSize)) || 0;
		paginatedData =
			(data &&
				Array.isArray(data) &&
				data.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize)) ||
			[];
	}

	const handleFilterChange = (columnId, value) => {
		setFilters((prevFilters) => ({
			...prevFilters,
			[columnId]: value,
		}));

		setPageIndex(0);
	};

	const handleSortChange = (columnId) => {
		if (sortColumn === columnId) {
			setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		} else {
			setSortColumn(columnId);
			setSortDirection('asc');
		}
	};

	const handleRowSelection = (rowId) => {};
	function getValueFromNestedObject(obj, keys) {
		let value = obj;
		for (let i = 0; i < keys.length; i++) {
			value = value[keys[i]];
			if (value === undefined) {
				return undefined;
			}
		}
		return value;
	}
	return (
		<div>
			<table className={[props.className].join(' ')}>
				<thead>
					<tr>
						{columns.map((column, index) => (
							<th key={`${index}-th`}>
								{/* <div> */}
								<div className={s.tableHeader}>
									{column.hideTitle === true ? (
										''
									) : (
										<div
											onClick={() =>
												column.sort === true
													? handleSortChange(column.accessor)
													: null
											}
											className={s.tableHeaderBlock}
										>
											<div>{column.Header}</div>

											<span className={s.tableSortBtn}>
												{sortColumn === column.accessor && (
													<svg
														width='24'
														height='24'
														viewBox='0 0 24 24'
														fill='none'
														xmlns='http://www.w3.org/2000/svg'
													>
														<path
															d='M12 18.5L7 13.525H17L12 18.5Z'
															fill={
																sortDirection === 'asc' ? '#999999' : '#050505'
															}
														/>
														<path
															d='M7 11.525L12 6.5L17 11.525H7Z'
															fill={
																sortDirection === 'desc' ? '#999999' : '#050505'
															}
														/>
													</svg>
												)}
												{sortColumn !== column.accessor &&
													column.sort === true && (
														<svg
															width='24'
															height='24'
															viewBox='0 0 24 24'
															fill='none'
															xmlns='http://www.w3.org/2000/svg'
														>
															<path
																d='M12 18.5L7 13.525H17L12 18.5Z'
																fill='#999999'
															/>
															<path
																d='M7 11.525L12 6.5L17 11.525H7Z'
																fill='#999999'
															/>
														</svg>
													)}
											</span>
										</div>
									)}
								</div>
								{/* </div> */}

								<div key={`${index}-th-div`}>
									{column.filter === true ? (
										<select
											value={filters[column.accessor] || ''}
											onChange={(e) =>
												handleFilterChange(column.accessor, e.target.value)
											}
										>
											<option value=''>
												{column.filterPlaceHolderText
													? column.filterPlaceHolderText
													: 'All'}
											</option>
											{Array.from(
												new Set(data.map((row) => row[column.accessor])),
											).map((value, index3) => (
												<option key={`${value}-${index3}`} value={value}>
													{value}
												</option>
											))}
										</select>
									) : (
										''
									)}
								</div>
							</th>
						))}
					</tr>
					{/* <hr /> */}
				</thead>
				<tbody>
					{paginatedData && paginatedData.length ? (
						paginatedData.map((row, index1) => (
							<tr
								key={`${row.id}-${index1}}`}
								className={
									row.id === clickedRow
										? `${stylingProps['clickedRow']} ${
												calcRowColor ? calcRowColor(row) : ''
										  }`
										: calcRowColor
										  ? calcRowColor(row)
										  : ''
								}
								ref={row.id === clickedRow ? targetRowRef : null}
								data-attr={row.id === clickedRow ? 'red' : ''}
								id={row?.id}
								onClick={() => onClickChange(row)}
							>
								{columns.map((column, index2) => {
									let computedValue = row[column.accessor];
									if (Array.isArray(column.accessor)) {
										computedValue = getValueFromNestedObject(
											row,
											column.accessor,
										);
									}

									return (
										<td key={`${row.id}${computedValue}${index2}`}>
											{typeof column.Cell === 'function'
												? column.Cell({ value: computedValue })
												: computedValue}
											{/* {`${row.id}${computedValue}`} */}
										</td>
									);
								})}
							</tr>
						))
					) : (
						<tr className={s.noData}>
							<td colSpan={columns.length}>{i18next.t('no_data_found')}</td>
						</tr>
					)}
				</tbody>
			</table>
			{props.infiniteScroll !== true && (
				<div className={s.tablePagination}>
					<button
						className={s.paginationButton}
						onClick={() =>
							setPageIndex((prevIndex) => Math.max(prevIndex - 1, 0))
						}
						disabled={pageIndex === 0}
					>
						Previous
					</button>
					<div className={s.paginationText}>
						<span>
							{/* {`${i18next.t("page")} ${pageIndex + 1} ${i18next.t("of")} ${pageCount}`} */}
							Page {pageIndex + 1} of {pageCount}
						</span>
					</div>

					<button
						className={s.paginationButton}
						onClick={() =>
							setPageIndex((prevIndex) =>
								Math.min(prevIndex + 1, pageCount - 1),
							)
						}
						disabled={pageIndex === pageCount - 1}
					>
						Next
					</button>
				</div>
			)}
		</div>
	);
}
