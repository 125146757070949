import * as actionTypes from './actionTypes';
import { coreInstance as axios, api, messages } from 'settings';
import { logger, toast } from 'helpers';
import { getUnresolvedErrors } from './unresolvedErrors';

export const resolveDashboardErrorsStart = () => {
	return {
		type: actionTypes.POST_UNRESOLVED_DASHBOARD_ERRORS_START,
	};
};

export const resolveDashboardErrors = (args = {}) => {
	const { error_details, unresolvedErrorsParams } = args;

	return async (dispatch) => {
		dispatch(resolveDashboardErrorsStart());
		await axios
			.post(`${api.patient_dashboard.mark_resolved}`, { error_details })
			.then((response) => {
				dispatch(resolveDashboardErrorsSuccess(response.data));
				toast.success(messages.resolvedSelected, {
					style: { fontSize: '16px' },
				});
				dispatch(getUnresolvedErrors({ params: unresolvedErrorsParams }));
			})
			.catch((err) => {
				const res = {
					success: true,
					data: {},
					message: err.message,
				};
				dispatch(resolveDashboardErrorsSuccess(res.data));
				logger(`[Error] Failed while resolving Errors" ${err}`);
				toast.error(messages.wentWrong, {
					style: { fontSize: '16px' },
				});
			});
	};
};

export const resolveDashboardErrorsSuccess = (data) => {
	return {
		type: actionTypes.POST_UNRESOLVED_DASHBOARD_ERRORS_SUCCESS,
		data: data,
	};
};
