import React from 'react';
import T from 'prop-types';

const SearchIcon = (props) => (
	<svg {...props} className={props.className}>
		<path
			d='M15.3704 14.4361L16.1246 13.6819L14.0505 11.6078C14.2233 11.3407 14.3687 11.0629 14.4865 10.7745C14.6044 10.4861 14.6633 10.1825 14.6633 9.86373C14.6633 8.9681 14.3569 8.21389 13.7441 7.6011C13.1313 6.9883 12.3771 6.68191 11.4815 6.68191C10.5859 6.68191 9.83165 6.98889 9.21885 7.60287C8.60606 8.21686 8.29966 8.97106 8.29966 9.86549C8.29966 10.7599 8.60606 11.5136 9.21885 12.1264C9.83165 12.7391 10.5859 13.0455 11.4815 13.0455C11.8285 13.0455 12.1549 12.9866 12.4606 12.8688C12.7663 12.7509 13.0663 12.6056 13.3604 12.4327L15.3704 14.4361ZM11.4825 11.8671C10.9319 11.8671 10.4602 11.671 10.0673 11.2789C9.67452 10.8868 9.47811 10.4154 9.47811 9.86476C9.47811 9.31413 9.67428 8.8424 10.0666 8.44958C10.4589 8.05677 10.9306 7.86036 11.4815 7.86036C12.0314 7.86036 12.5028 8.05642 12.8956 8.44855C13.2884 8.84066 13.4848 9.31204 13.4848 9.86269C13.4848 10.4133 13.2888 10.885 12.8967 11.2779C12.5045 11.6707 12.0332 11.8671 11.4825 11.8671ZM2.2395 20.3248C2.02732 20.3248 1.85102 20.2534 1.71061 20.1106C1.5702 19.9678 1.5 19.7909 1.5 19.5798C1.5 19.3687 1.5702 19.1931 1.71061 19.0531C1.85102 18.913 2.02732 18.843 2.2395 18.843H21.7605C21.9727 18.843 22.149 18.9142 22.2894 19.0566C22.4298 19.199 22.5 19.3754 22.5 19.5859C22.5 19.7963 22.4298 19.9721 22.2894 20.1132C22.149 20.2543 21.9727 20.3248 21.7605 20.3248H2.2395ZM4.03725 17.4289C3.6036 17.4289 3.22755 17.2697 2.90912 16.9512C2.5907 16.6328 2.4315 16.2568 2.4315 15.8231V4.61141C2.4315 4.17622 2.5907 3.79886 2.90912 3.47932C3.22755 3.15978 3.6036 3 4.03725 3H19.9627C20.3979 3 20.7753 3.15978 21.0948 3.47932C21.4144 3.79886 21.5742 4.17622 21.5742 4.61141V15.8231C21.5742 16.2568 21.4144 16.6328 21.0948 16.9512C20.7753 17.2697 20.3979 17.4289 19.9627 17.4289H4.03725ZM4.03725 15.8231H19.9627V4.61141H4.03725V15.8231Z'
			fill='#26251F'
		/>
	</svg>
);

SearchIcon.defaultProps = {
	width: 24,
	height: 24,
};

SearchIcon.propTypes = {
	className: T.string,
};

export default SearchIcon;
