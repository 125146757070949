import React, { useEffect, useState } from 'react';
import s from './style.module.scss';
import Button from '../../../components/UI/Button';
import { Spinner, DetailsPanel, ModalPopUp } from '../../../components';
import { routers } from 'settings';
import { useHistory } from 'react-router-dom';
import { Back } from 'components/Icons';
import i18next from 'i18next';
import { connect } from 'react-redux';
import Notification from '../Notification/index';
import * as actions from '../../../store/actions/patientData';
import { CloseButton } from '../../../components/Icons';
import { permissions } from '../../../helpers';

function PatientPersonalDetails(props) {
	let PATIENT_DETAILS_FIELDS = [
		{
			key: 'date_of_birth',
			label: 'Date Of Birth',
			type: 'date',
			translateKey: 'dob',
		},
		{
			key: 'phone',
			label: 'Mobile Number',
			type: 'text',
			translateKey: 'mobile_number',
		},
		{
			key: 'mobile_device_name',
			label: 'Mobile Device',
			type: 'text',
			translateKey: 'mobile_device',
		},
		{
			key: 'email',
			label: 'E-mail',
			type: 'text',
			translateKey: 'email',
		},
	];
	let {
		getPatientDemographicInfo,
		serial_number,
		patientId,
		loading,
		resetPatientData,
	} = props;
	const [showSendNotification, setShowSendNotification] = useState(false);
	useEffect(() => {
		getPatientDemographicInfo({ patientId: patientId });
		return () => {
			resetPatientData();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getPatientDemographicInfo, patientId]);
	const history = useHistory();

	if (loading) {
		return <Spinner small />;
	}

	const content = (
		<div>
			<div>
				<div className={s.titleAlign}>
					<div className={s.modalTitle}>{i18next.t('create_notification')}</div>
					<div className={s.modalIcon}>
						<CloseButton
							className={s.closeBtn}
							onClick={() => {
								setShowSendNotification(false);
							}}
						/>
					</div>
				</div>
				<Notification
					serialNo={serial_number}
					closeNotification={() => {
						setShowSendNotification(false);
					}}
				/>
			</div>
		</div>
	);
	function handleButtonClick() {
		resetPatientData();
		const redirectUrl = permissions.check('dashboard')
			? routers.patientDashboardIndex
			: routers.index;
		history.push(redirectUrl);
	}

	return (
		<div>
			<div className={s.ppdTop}>
				<div className={s.ppdName}>
					<div onClick={handleButtonClick}>
						<span className={s.arrowDiv}>
							<Back width='40' fill='#000' height='40' />
						</span>
					</div>
					<span>{props?.SelectedPatientDetails?.full_name || 'NA'}</span>
				</div>
				<div className={s.ppdButton}>
					<Button
						className={s.ppdButtonStyling}
						clicked={() => {
							setShowSendNotification(true);
						}}
						disabled={
							props?.SelectedPatientDetails?.concentrator_profile_id &&
							serial_number
								? false
								: true
						}
					>
						<span>{i18next.t('send_notification')}</span>
					</Button>
				</div>
			</div>
			<div className={s.patientDetails}>
				<DetailsPanel
					mapper={PATIENT_DETAILS_FIELDS}
					data={props?.SelectedPatientDetails}
				/>
			</div>

			<ModalPopUp
				className={s.newModal}
				loading={loading}
				newDesign={true}
				show={showSendNotification}
				restrictClosing={true}
			>
				{content}
			</ModalPopUp>
		</div>
	);
}

const mapStateToProps = (state) => {
	return {
		SelectedPatientDetails: state.patientData.SelectedPatientDetails,
		loading: state.patientData.loading,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getPatientDemographicInfo: (args) =>
			dispatch(actions.getPatientDemographicInfo(args)),
		resetPatientData: () => dispatch(actions.resetPatientData()),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(PatientPersonalDetails);
