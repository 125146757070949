import React from 'react';
import T from 'prop-types';

const PatientDashboard = (props) => (
	<svg {...props} className={props.className}>
		<g clipPath='url(#clip0_6912_584)'>
			<path
				d='M2 21C1.45 21 0.979167 20.8042 0.5875 20.4125C0.195833 20.0208 0 19.55 0 19V5C0 4.45 0.195833 3.97917 0.5875 3.5875C0.979167 3.19583 1.45 3 2 3H22C22.55 3 23.0208 3.19583 23.4125 3.5875C23.8042 3.97917 24 4.45 24 5V19C24 19.55 23.8042 20.0208 23.4125 20.4125C23.0208 20.8042 22.55 21 22 21H2ZM15.9 19H22V5H2V19H2.1C2.8 17.75 3.76667 16.7708 5 16.0625C6.23333 15.3542 7.56667 15 9 15C10.4333 15 11.7667 15.3542 13 16.0625C14.2333 16.7708 15.2 17.75 15.9 19ZM9 14C9.83333 14 10.5417 13.7083 11.125 13.125C11.7083 12.5417 12 11.8333 12 11C12 10.1667 11.7083 9.45833 11.125 8.875C10.5417 8.29167 9.83333 8 9 8C8.16667 8 7.45833 8.29167 6.875 8.875C6.29167 9.45833 6 10.1667 6 11C6 11.8333 6.29167 12.5417 6.875 13.125C7.45833 13.7083 8.16667 14 9 14ZM4.55 19H13.45C12.8833 18.3667 12.2125 17.875 11.4375 17.525C10.6625 17.175 9.85 17 9 17C8.15 17 7.34167 17.175 6.575 17.525C5.80833 17.875 5.13333 18.3667 4.55 19ZM9 12C8.71667 12 8.47917 11.9042 8.2875 11.7125C8.09583 11.5208 8 11.2833 8 11C8 10.7167 8.09583 10.4792 8.2875 10.2875C8.47917 10.0958 8.71667 10 9 10C9.28333 10 9.52083 10.0958 9.7125 10.2875C9.90417 10.4792 10 10.7167 10 11C10 11.2833 9.90417 11.5208 9.7125 11.7125C9.52083 11.9042 9.28333 12 9 12Z'
				// fill="#999999"
			/>
			<path
				d='M13.2875 7.7125C13.4792 7.90417 13.7167 8 14 8H20C20.2833 8 20.5208 7.90417 20.7125 7.7125C20.9042 7.52083 21 7.28333 21 7C21 6.71667 20.9042 6.47917 20.7125 6.2875C20.5208 6.09583 20.2833 6 20 6H14C13.7167 6 13.4792 6.09583 13.2875 6.2875C13.0958 6.47917 13 6.71667 13 7C13 7.28333 13.0958 7.52083 13.2875 7.7125Z'
				// fill="#999999"
			/>
			<path
				d='M13.2875 11.7125C13.4792 11.9042 13.7167 12 14 12H20C20.2833 12 20.5208 11.9042 20.7125 11.7125C20.9042 11.5208 21 11.2833 21 11C21 10.7167 20.9042 10.4792 20.7125 10.2875C20.5208 10.0958 20.2833 10 20 10H14C13.7167 10 13.4792 10.0958 13.2875 10.2875C13.0958 10.4792 13 10.7167 13 11C13 11.2833 13.0958 11.5208 13.2875 11.7125Z'
				// fill="#999999"
			/>
		</g>
		<defs>
			<clipPath id='clip0_6912_584'>
				<rect width='24' height='24' fill='white' />
			</clipPath>
		</defs>
	</svg>
);

PatientDashboard.defaultProps = {
	xmlns: 'http://www.w3.org/2000/svg',
	fill: '#999',
	width: 24,
	height: 24,
	viewBox: '0 0 24 24',
};

PatientDashboard.propTypes = {
	className: T.string,
};

export default PatientDashboard;
