import React from 'react';
import T from 'prop-types';

const CloseIcon = () => (
	<svg
		xmlns='http://www.w3.org/2000/svg'
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
	>
		<path
			d='M12 13.0538L15.0731 16.1269C15.2116 16.2653 15.3856 16.3362 15.5952 16.3394C15.8048 16.3426 15.982 16.2718 16.1269 16.1269C16.2718 15.982 16.3442 15.8064 16.3442 15.6C16.3442 15.3936 16.2718 15.2179 16.1269 15.0731L13.0538 12L16.1269 8.92688C16.2654 8.78843 16.3362 8.61439 16.3394 8.40478C16.3426 8.19518 16.2718 8.01794 16.1269 7.87308C15.982 7.72819 15.8064 7.65575 15.6 7.65575C15.3936 7.65575 15.218 7.72819 15.0731 7.87308L12 10.9462L8.92691 7.87308C8.78846 7.73461 8.61442 7.66378 8.40481 7.66058C8.19521 7.65736 8.01797 7.72819 7.87311 7.87308C7.72822 8.01794 7.65578 8.19358 7.65578 8.39998C7.65578 8.60638 7.72822 8.78201 7.87311 8.92688L10.9462 12L7.87311 15.0731C7.73464 15.2115 7.66381 15.3856 7.66061 15.5952C7.65739 15.8048 7.72822 15.982 7.87311 16.1269C8.01797 16.2718 8.19361 16.3442 8.40001 16.3442C8.60641 16.3442 8.78204 16.2718 8.92691 16.1269L12 13.0538ZM12.0017 21.5C10.6877 21.5 9.45271 21.2506 8.29658 20.752C7.14043 20.2533 6.13475 19.5765 5.27953 18.7217C4.4243 17.8669 3.74724 16.8616 3.24836 15.706C2.74947 14.5504 2.50003 13.3156 2.50003 12.0017C2.50003 10.6877 2.74936 9.45268 3.24803 8.29655C3.7467 7.1404 4.42345 6.13472 5.27828 5.2795C6.13313 4.42427 7.13837 3.74721 8.29401 3.24833C9.44962 2.74944 10.6844 2.5 11.9983 2.5C13.3123 2.5 14.5473 2.74933 15.7034 3.248C16.8596 3.74667 17.8653 4.42342 18.7205 5.27825C19.5757 6.1331 20.2528 7.13834 20.7517 8.29398C21.2505 9.44959 21.5 10.6844 21.5 11.9983C21.5 13.3122 21.2506 14.5473 20.752 15.7034C20.2533 16.8596 19.5766 17.8652 18.7217 18.7205C17.8669 19.5757 16.8616 20.2527 15.706 20.7516C14.5504 21.2505 13.3156 21.5 12.0017 21.5Z'
			fill='#999999'
		/>
	</svg>
);

CloseIcon.defaultProps = {
	fill: '#6AB221',
	width: 24,
	height: 24,
	viewBox: '0 0 24 24',
};

CloseIcon.propTypes = {
	className: T.string,
};

export default CloseIcon;
