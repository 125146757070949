import i18next from 'i18next';

export function handleLabel(data) {
	return Array.isArray(data)
		? data.map((item) => {
				return {
					...item,
					label: item.label ? i18next.t(item.label) : '',
				};
		  })
		: [];
}
