import store from 'store';
import moment from 'moment';
import objectToFormData from 'object-to-formdata';
import { coreInstance as axios, api } from 'settings';
import { logger, toast } from 'helpers';
import { getAllFirmwares, loadingOn, loadingOff } from 'store/actions';
import * as actionTypes from '../actionTypes';

const getFirmwareStart = () => {
	return {
		type: actionTypes.GET_FIRMWARE_START,
	};
};

const getFirmwareSuccess = (data) => {
	return {
		type: actionTypes.GET_FIRMWARE_SUCCESS,
		data: data,
	};
};

export const getFirmwareById = (args = {}) => {
	const { id = 0, loader = true, params = {} } = args;

	params.with = 'testConcentrators;serialNumbers';

	return (dispatch) => {
		loader && dispatch(getFirmwareStart());

		axios
			.get(api.concentrator_firmwares.index + `/${id}`, { params })
			.then((response) => {
				logger('[Action] Get firmware by ID');

				dispatch(getFirmwareSuccess(response.data.data));
			})
			.catch(() => {
				logger('[Action] Get firmware by ID fail');
			});
	};
};

export const clearFirmwareById = () => {
	return {
		type: actionTypes.CLEAR_FIRMWARE_BY_ID,
	};
};

const manageFirmwareStart = () => {
	return {
		type: actionTypes.MANAGE_FIRMWARE_START,
	};
};

const manageFirmwareSuccess = (data) => {
	return {
		type: actionTypes.MANAGE_FIRMWARE_SUCCESS,
		data: data,
	};
};

export const manageFirmware = (args = {}) => {
	const { data = {}, loader = true, successHandler, errorHandler } = args;
	let url = api.concentrator_firmwares.index;

	if (data.id) {
		url = api.concentrator_firmwares.index + `/${data.id}`;
	}

	return (dispatch) => {
		loader && dispatch(manageFirmwareStart());
		dispatch(loadingOn());

		axios
			.post(url, objectToFormData(data))
			.then((response) => {
				logger('[Action] Manage firmware');

				dispatch(manageFirmwareSuccess(response.data.data));
				dispatch(loadingOff());
				dispatch(
					getAllFirmwares({
						loader: false,
						params: store.getState().firmware.all.requestParams,
					}),
				);

				successHandler && successHandler(response.data.data);
				toast.success(response.data.message);
			})
			.catch((err) => {
				logger('[Action] Manage firmware fail');
				dispatch(loadingOff());

				errorHandler && errorHandler(err.response.data);
				toast.error(err.response.data.message);
			});
	};
};

export const deleteFirmware = (args = {}) => {
	const { id, successHandler, errorHandler } = args;

	return (dispatch) => {
		dispatch(loadingOn());

		axios
			.delete(api.concentrator_firmwares.index + `/${id}`)
			.then((response) => {
				logger('[Action] Delete firmware');

				dispatch({ type: actionTypes.DELETE_FIRMWARE });
				dispatch(loadingOff());
				dispatch(
					getAllFirmwares({
						loader: false,
						params: store.getState().firmware.all.requestParams,
					}),
				);

				successHandler && successHandler();
				toast.success(response.data.message);
			})
			.catch((err) => {
				logger('[Action] Delete firmware fail');
				dispatch(loadingOff());

				errorHandler && errorHandler(err.response.data);
				toast.error(err.response.data.message);
			});
	};
};

export const copyFirmware = (args = {}) => {
	const { id, successHandler, errorHandler } = args;

	return (dispatch) => {
		dispatch(loadingOn());

		axios
			.post(api.concentrator_firmwares.copy + `/${id}`)
			.then((response) => {
				logger('[Action] Copy firmware');

				const { data } = response.data;

				data.released_at = moment();

				dispatch({ type: actionTypes.COPY_FIRMWARE });
				dispatch(getFirmwareSuccess(data));
				dispatch(loadingOff());
				dispatch(
					getAllFirmwares({
						loader: false,
						params: store.getState().firmware.all.requestParams,
					}),
				);

				successHandler && successHandler();
				toast.success(response.data.message);
			})
			.catch((err) => {
				logger('[Action] Copy firmware fail');
				dispatch(loadingOff());

				errorHandler && errorHandler(err.response.data);
				toast.error(err.response.data.message);
			});
	};
};
