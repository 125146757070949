import React from 'react';
import PropTypes from 'prop-types';
import DropdownList from './DropdownList';

const ProvidersDropdownList = (props) => {
	const data = [];
	const dataObj = {};
	const config = {
		label: {
			path: 'label',
			view: function (value) {
				return `${value[this.path]} (${value.data.length})`;
			},
		},
	};

	for (let item of props?.data) {
		const name = item?.name?.[0]?.toUpperCase();

		if (dataObj[name]) {
			dataObj[name].push(item);
		} else {
			dataObj[name] = [item];
		}
	}

	for (let key in dataObj) {
		data.push({ label: key, data: dataObj[key] });
	}

	data.sort((a, b) => {
		if (a.label < b.label) return -1;
		if (a.label > b.label) return 1;
		return 0;
	});

	return <DropdownList {...props} data={data} config={config} />;
};

ProvidersDropdownList.defaultProps = {
	data: [],
};

ProvidersDropdownList.propTypes = {
	data: PropTypes.array,
};

export default ProvidersDropdownList;
