import { coreInstance as axios, api } from 'settings';
import { logger } from 'helpers';
import * as actionTypes from '../actionTypes';

const getLocationStart = () => {
	return {
		type: actionTypes.GET_LOCATION_START,
	};
};

const getLocationSuccess = (data, headings) => {
	return {
		type: actionTypes.GET_LOCATION_SUCCESS,
		data: data,
		headings: headings,
	};
};

const getLocationCountSuccess = (data) => {
	return {
		type: actionTypes.GET_LOCATION_COUNT_SUCCESS,
		data: data,
	};
};

export const getLocationCount = ({
	search,
	searchFields,
	searchJoin,
	codeFilter,
}) => {
	const locParams = {
		search: search ? search : null,
		searchFields: searchFields ? searchFields : null,
		searchJoin: searchJoin ? searchJoin : null,
		codeFilter: codeFilter ? codeFilter : null,
		count: 1,
	};

	return (dispatch) => {
		axios
			.get(api.concentrator_locations.all, { params: locParams })
			.then((response) => {
				logger('[Action] Get location count');

				dispatch(getLocationCountSuccess(response.data.rows));
			})
			.catch(() => {
				logger('[Action] Get location count fail');
			});
	};
};

export const getLocation = (args = {}) => {
	const { params, itemPerRq, loadByStep, success } = args;

	const locParams = { ...params };

	if (loadByStep) {
		locParams.limit = itemPerRq;
		locParams.offset = 0;
	}

	return (dispatch) => {
		dispatch(getLocationStart());

		const getRequest = () => {
			axios
				.get(api.concentrator_locations.all, { params: locParams })
				.then((response) => {
					logger('[Action] Get location');

					const { data, headings } = response.data;

					dispatch(getLocationSuccess(data, headings));

					success && success(data);
				})
				.catch(() => {
					logger('[Action] Get location fail');
				});
		};

		getRequest();
	};
};
