// import Axios from 'axios';
import { coreInstance, patientInstance, api } from 'settings';
import { logger, toast } from 'helpers';
import {
	getCurrentUser,
	getTerms,
	loadingOn,
	loadingOff,
	getUserConsent,
	fetchTranslations,
} from 'store/actions';
// import { getCurrentUser, getTerms, loadingOn, loadingOff, getUserConsent} from 'store/actions';
import * as actionTypes from './actionTypes';
import Cookies from 'universal-cookie';
import { routers, messages } from 'settings';
import CryptoJS from 'crypto-js';
import axios from 'axios';

var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY;
key = CryptoJS.enc.Utf8.parse(key);
var iv = process.env.REACT_APP_PASSWORD_ENCRYPTION_IV;
iv = CryptoJS.enc.Utf8.parse(iv);
const cookies = new Cookies();

const encryptPassword = (password) => {
	var encrypted = CryptoJS.AES.encrypt(password, key, {
		iv: iv,
		mode: CryptoJS.mode.CBC,
	});
	encrypted = encrypted.toString();
	return encrypted;
};

export const scriptLoaded = () => ({
	type: actionTypes.UPDATE_SCRIPT_LOADED_FLAG,
});

const authStart = () => {
	return {
		type: actionTypes.AUTH_START,
	};
};

const authSuccess = (token, tokenType, expiresIn, refreshToken) => {
	return {
		type: actionTypes.AUTH_SUCCESS,
		token: token,
		refreshToken: refreshToken,
		tokenType: tokenType,
		expiresIn: expiresIn,
	};
};

const authFail = (error) => {
	return {
		type: actionTypes.AUTH_FAIL,
		error: error,
	};
};

// const updateToken = () => {
//     const refreshToken = localStorage.getItem('refreshToken') || sessionStorage.getItem('refreshToken');
//     const authData = {
//         grant_type:'refresh_token',
//         client_id: 2,
//         client_secret: 'qc0AeWT7ejiRsJPhO6JFv56getR2jIHfbWHuCkBP',
//         refresh_token: refreshToken
//     };
//     const storage = localStorage;

//     axios.post(api.authentication.index, authData)
//         .then((response) => {
//             logger('[Action] Authentication');

//             const { access_token, token_type, expires_in, refresh_token } = response.data;

//             cookies.set('access_token', access_token);
//             storage.setItem('token', access_token);
//             storage.setItem('expiresIn', expires_in);
//             storage.setItem('refreshToken', refresh_token);

//             setAxiosInterceptors(access_token, token_type);
//         })
//         .catch((err) => {
//             logger('[Action] Authentication fail');

//         });
// }

export const logout = () => {
	return (dispatch) => {
		dispatch(loadingOn());
		coreInstance
			.get(api.authentication.logout, {})
			.then(() => {
				logger('[Action] Logut');

				localStorage.clear();
				sessionStorage.removeItem('token');
				cookies.remove('access_token');
				cookies.remove('INOGEN_ACCESS_TOKEN', { domain: '.inogenconnect.com' });
				window.location.href = routers.index;

				dispatch({
					type: actionTypes.AUTH_LOGOUT,
				});
				dispatch(loadingOff());
			})
			.catch(() => {
				logger('[Action] Logut fail');
				dispatch(loadingOff());
			});
	};
};

export const setCoreAxiosInterceptors = (token, tokenType) => {
	return coreInstance.interceptors.request.use(
		(config) => {
			logger('[Action] Set axios interceptors');

			config.headers.common['Authorization'] = `${tokenType} ${token}`;
			return config;
		},
		(error) => {
			logger('[Action] Set axios interceptors fail');

			return Promise.reject(error);
		},
	);
};

export const setPatientAxiosInterceptors = (token, tokenType) => {
	return patientInstance.interceptors.request.use(
		(config) => {
			logger('[Action] Set axios interceptors');

			config.headers.common['Authorization'] = `${tokenType} ${token}`;
			return config;
		},
		(error) => {
			logger('[Action] Set axios interceptors fail');

			return Promise.reject(error);
		},
	);
};

export const auth = ({ email, password, remember, success, error }) => {
	return (dispatch) => {
		dispatch(authStart());

		var encrypted_password = encryptPassword(password);
		const storage = remember ? localStorage : sessionStorage;
		const authData = {
			grant_type: 'password',
			client_id: process.env.REACT_APP_OAUTH_PASS_CLIENT_ID,
			client_secret: process.env.REACT_APP_OAUTH_PASS_CLIENT_SECRET,
			username: email,
			password: encrypted_password,
		};
		var formBody = [];
		for (var property in authData) {
			var encodedKey = encodeURIComponent(property);
			var encodedValue = encodeURIComponent(authData[property]);
			formBody.push(encodedKey + '=' + encodedValue);
		}
		formBody = formBody.join('&');
		coreInstance
			.post(api.authentication.index, formBody)
			.then((response) => {
				logger('[Action] Authentication');
				const { access_token, token_type, expires_in, refresh_token } =
					response.data;

				cookies.set('access_token', access_token);
				storage.setItem('token', access_token);
				storage.setItem('expiresIn', expires_in);
				storage.setItem('refreshToken', refresh_token);

				setCoreAxiosInterceptors(access_token, token_type);
				setPatientAxiosInterceptors(access_token, token_type);
				dispatch(
					authSuccess(access_token, token_type, expires_in, refresh_token),
				);
				dispatch(getCurrentUser());
				dispatch(getTerms());
				dispatch(getUserConsent());
				dispatch(fetchTranslations());
				// dispatch(getPortalConsents());

				success && success();
			})
			.catch((err) => {
				logger('[Action] Authentication fail');

				try {
					dispatch(authFail(err.response.data));
					error && error(err.response.data);
				} catch (e) {
					console.error(e);
				}
			});
	};
};

export const authCheckState = () => {
	return (dispatch) => {
		const msToken = cookies.get('INOGEN_ACCESS_TOKEN');

		if (msToken) {
			localStorage.setItem('token', msToken);
			cookies.remove('INOGEN_ACCESS_TOKEN');
		}

		const token =
			localStorage.getItem('token') || sessionStorage.getItem('token');
		const expiresIn =
			localStorage.getItem('expiresIn') || sessionStorage.getItem('expiresIn');
		const refreshToken =
			localStorage.getItem('refreshToken') ||
			sessionStorage.getItem('refreshToken');
		const tokenType = 'Bearer';

		if (!token) {
			logger('[Action] Autoauthentication fail');

			localStorage.removeItem('token');
			sessionStorage.removeItem('token');
			cookies.remove('access_token');
		} else {
			logger('[Action] Autoauthentication');

			cookies.set('access_token', token);

			setCoreAxiosInterceptors(token, tokenType);
			setPatientAxiosInterceptors(token, tokenType);
			dispatch(authSuccess(token, tokenType, expiresIn, refreshToken));
			dispatch(getCurrentUser());
			dispatch(getTerms());
			dispatch(fetchTranslations());
			// dispatch(getUserConsent());
			// dispatch(getPortalConsents());

			// updateToken();
		}
	};
};

export const getCountryName = () => {
	return async () => {
		try {
			// Try to get country name from IP
			const response = await axios.get('https://ipapi.co/json/');
			console.log(
				'response1=>',
				response.data.country_name,
				response.data.country,
			);
			storeCountryInlocalStorage(response.data.country);
		} catch (error) {
			console.error('Failed to get country name from IP:', error);

			// If that fails, try to get country name from Geolocation API
			if (navigator.geolocation) {
				navigator.geolocation.getCurrentPosition(async (position) => {
					try {
						const { latitude, longitude } = position.coords;
						const response = await axios.get(
							`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_GOOGLE_MAPS_JS_API_KEY}`,
						);
						console.log('response2=>', response.data);
						const countryComponent =
							response.data.results[0].address_components.find((component) =>
								component.types.includes('country'),
							);
						const countryName = countryComponent.long_name;
						const countryCode = countryComponent.short_name;
						console.log('response2=>', countryName, countryCode);
						storeCountryInlocalStorage(countryCode);
					} catch (error) {
						console.log('response3=>', 'not_found');
						console.error(
							'Failed to get country name from Geolocation API:',
							error,
						);
						storeCountryInlocalStorage('not_found');
					}
				});
			} else {
				console.log('response4=>', 'not_found');
				console.log('Geolocation is not supported by this browser.');
				storeCountryInlocalStorage('not_found');
			}
		}
	};
};

const storeCountryInlocalStorage = (country) => {
	localStorage.setItem('country', country);
};

export const resetPassword = (args = {}) => {
	const { email, success } = args;
	const postData = {
		email: email,
	};

	return (dispatch) => {
		dispatch(loadingOn());

		coreInstance
			.post(api.authentication.email, postData)
			.then(() => {
				logger('[Action] Reset password');

				success && success();
				dispatch(loadingOff());
			})
			.catch((err) => {
				logger('[Action] Reset password fail');
				if (err?.message.includes('404')) {
					toast.error('User Not Found', {
						style: { fontSize: '16px' },
					});
				} else {
					toast.error(messages.wentWrong, {
						style: { fontSize: '16px' },
					});
				}
				dispatch(loadingOff());
			});
	};
};

export const setNewPassword = (args = {}) => {
	const { token, password, email, success, error } = args;

	var encrypted_password = encryptPassword(password);
	const postData = {
		token,
		password: encrypted_password,
		email,
	};

	return (dispatch) => {
		dispatch(loadingOn());

		coreInstance
			.post(api.authentication.reset, postData)
			.then(() => {
				logger('[Action] Set new password');

				const successArgs = {
					email,
					password,
				};
				success && success(successArgs);
			})
			.catch((err) => {
				logger('[Action] Set new password fail');

				error && error(err.response.data);
				dispatch(loadingOff());
			});
	};
};

export const changePassword = (args = {}) => {
	const { oldPassword, newPassword, success, error } = args;
	var old_encrypted_password = '',
		new_encrypted_password = '';
	old_encrypted_password = encryptPassword(oldPassword);
	new_encrypted_password = encryptPassword(newPassword);

	const postData = {
		password_old: old_encrypted_password,
		password_new: new_encrypted_password,
	};

	return (dispatch) => {
		dispatch(loadingOn());

		coreInstance
			.post(api.authentication.change, postData)
			.then(() => {
				logger('[Action] Change password');

				// const successArgs = {
				//     email,
				//     password
				// };

				success && success();
				dispatch(loadingOff());
			})
			.catch((err) => {
				logger('[Action] Change password fail');

				error && error(err.response.data);
				dispatch(loadingOff());
			});
	};
};
