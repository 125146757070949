import { coreInstance as axios, api } from 'settings';
import { logger } from 'helpers';
import * as actionTypes from '../../actionTypes';

const getRecentErrorStatusStart = () => {
	return {
		type: actionTypes.RECENT_ERROR_STATUS_START,
	};
};

const getRecentErrorStatusSuccess = (data) => {
	return {
		type: actionTypes.RECENT_ERROR_STATUS_SUCCESS,
		data: data,
	};
};

export const getRecentErrorStatus = (args = {}) => {
	const params = {
		...args.params,
		search: {
			...args.params.search,
			error_id:
				args.errorData && args.errorData.error_id
					? args.errorData.error_id
					: '',
		},
	};

	delete params.searchFields;
	return (dispatch) => {
		dispatch(getRecentErrorStatusStart());
		axios
			.get(api.concentrator_error_recalls.error_status, { params })
			.then((response) => {
				logger('[Action] GET ERROR STATUS');
				const { data } = response.data;
				const total = data.reduce((acc, el) => (acc += el.count), 0);
				let popupData = [];
				data &&
					data.map((responseData) => {
						popupData.push({
							SerailNum: responseData.serial_number,
							count: responseData.count,
						});
						return '';
					});
				const modifiedData = {
					data: popupData,
					total: total,
					Error_Name: args.errorData.description,
					loading: false,
				};
				dispatch(
					getRecentErrorStatusSuccess(
						modifiedData,
						actionTypes.RECENT_ERROR_STATUS_SUCCESS,
					),
				);
			})
			.catch(() => {
				logger('[Action] Get ERROR STATUS fail');
			});
	};
};
