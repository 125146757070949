import React from 'react';
import T from 'prop-types';

const NotificationsTwo = (props) => (
	<svg {...props} className={props.className}>
		<g fill='none' fillRule='evenodd'>
			<path fillRule='nonzero' d='M12 16h1a3 3 0 0 1-6 0h1a2 2 0 1 0 4 0z' />
			<path d='M10.837 3.473c-.11-.038-1.563-.038-1.674 0C5.753 4.649 4.361 6.446 4.361 10c0 1.644-.312 2.24-1.143 2.735l-.011.007c-.376.224-.48.35-.58.856-.278 1.39-.113 2.123.51 2.422 1.09.316 3.392.48 6.863.48 3.471 0 5.773-.164 6.79-.453.696-.326.86-1.058.584-2.45-.1-.505-.205-.631-.58-.855l-.012-.007c-.831-.496-1.143-1.09-1.143-2.735 0-3.554-1.391-5.35-4.802-6.527z' />
			<path d='M8 3a2 2 0 1 1 3.852.757c-.057.139-.174.053-.352-.257h-.634a1 1 0 1 0-1.732 0H8.063A2.004 2.004 0 0 1 8 3z' />
		</g>
	</svg>
);

NotificationsTwo.defaultProps = {
	fill: '#30ADFA',
	stroke: '#30ADFA',
	width: 20,
	height: 20,
	viewBox: '0 0 20 20',
};

NotificationsTwo.propTypes = {
	className: T.string,
};

export default NotificationsTwo;
