/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useMemo, useState, useEffect } from 'react';
import s from './style.module.scss';
import { routers } from 'settings';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Spinner from '../UI/Spinner';
import i18next from 'i18next';

function ListViewDashboard(props) {
	let {
		columnSettings: propColumnSetting,
		data: propData,
		onSortChange,
		defaultSortColumn,
		defaultSortColumnDirection,
	} = props;
	const columns = useMemo(() => propColumnSetting, [propColumnSetting]);

	const data = propData;

	const [filters, setFilters] = useState({});
	const [sortColumn, setSortColumn] = useState(defaultSortColumn);
	const [sortDirection, setSortDirection] = useState(
		defaultSortColumnDirection,
	);

	useEffect(() => {
		setSortColumn(defaultSortColumn);
		setSortDirection(defaultSortColumnDirection);
	}, [defaultSortColumn, defaultSortColumnDirection]);

	const handleFilterChange = (columnId, value) => {
		setFilters((prevFilters) => ({
			...prevFilters,
			[columnId]: value,
		}));
	};

	const handlePatientIds = (patient_id, error_id) => {
		const patientIds = { patientId: patient_id, errorId: error_id };
		props.setPatientId(patientIds);
	};
	const handleSortChange = (columnId) => {
		if (sortColumn === columnId) {
			setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
		} else {
			setSortColumn(columnId);
			setSortDirection('asc');
		}
		onSortChange(columnId, sortDirection === 'asc' ? 'desc' : 'asc');
	};

	return (
		<div className={s.tableBorder}>
			<table className={s.dashboardListView}>
				<thead>
					<tr>
						{columns.map((column, id) => (
							<th key={id}>
								<div>
									<div className={s.tableHeader}>
										{column.hideTitle === true ? (
											''
										) : (
											<div
												onClick={() => handleSortChange(column.accessor)}
												className={s.tableHeaderTitle}
											>
												{column.Header}
												<span className={s.tableSortBtn}>
													{sortColumn === column.accessor && (
														<svg
															width='24'
															height='24'
															viewBox='0 0 24 24'
															fill='none'
															xmlns='http://www.w3.org/2000/svg'
														>
															<path
																d='M12 18.5L7 13.525H17L12 18.5Z'
																fill={
																	sortDirection === 'asc'
																		? '#999999'
																		: '#050505'
																}
															/>
															<path
																d='M7 11.525L12 6.5L17 11.525H7Z'
																fill={
																	sortDirection === 'desc'
																		? '#999999'
																		: '#050505'
																}
															/>
														</svg>
													)}
													{sortColumn !== column.accessor &&
														column.sort === true && (
															<svg
																width='24'
																height='24'
																viewBox='0 0 24 24'
																fill='none'
																xmlns='http://www.w3.org/2000/svg'
															>
																<path
																	d='M12 18.5L7 13.525H17L12 18.5Z'
																	fill='#999999'
																/>
																<path
																	d='M7 11.525L12 6.5L17 11.525H7Z'
																	fill='#999999'
																/>
															</svg>
														)}
												</span>
											</div>
										)}
									</div>
								</div>

								<div key={id}>
									{column.filter === true ? (
										<select
											value={filters[column.accessor] || ''}
											onChange={(e) =>
												handleFilterChange(column.accessor, e.target.value)
											}
										>
											<option value=''>
												{column.filterPlaceHolderText
													? column.filterPlaceHolderText
													: 'All'}
											</option>
											{Array.from(
												new Set(data.map((row) => row[column.accessor])),
											).map((value) => (
												<option key={value} value={value}>
													{value}
												</option>
											))}
										</select>
									) : (
										''
									)}
								</div>
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{props.loading && <Spinner className={s.alignCenter} />}
					{data && data.length ? (
						data?.map((row, index) => (
							<tr
								data-testid={`${index}-${row.patient_id}`}
								key={`${index}'+'{$row.patient_id}`}
								onClick={() => {
									handlePatientIds(row.patient_id, row.id);
									props.history.push(routers.patientDetail);
								}}
							>
								{columns.map((column) => (
									<td key={column.accessor}>
										{typeof column.Cell === 'function'
											? column.Cell({ value: row[column.accessor] })
											: row[column.accessor]}
									</td>
								))}
							</tr>
						))
					) : (
						<tr className={s.noData}>
							<td colSpan={columns.length}>{i18next.t('no_data_found')}</td>
						</tr>
					)}
				</tbody>
			</table>
		</div>
	);
}

export default withRouter(withTranslation()(ListViewDashboard));
