import React from 'react';
import T from 'prop-types';
import { uniqueClass } from 'config';

const LocationOne = (props) => (
	<svg {...props} className={props.className}>
		<path d='M11.672 21.4a1 1 0 0 1-1.344 0C5.443 16.962 3 12.828 3 9a8 8 0 1 1 16 0c0 3.828-2.443 7.962-7.328 12.4zM11 13a4 4 0 1 0 0-8 4 4 0 0 0 0 8z' />
	</svg>
);

let fill = '#30ADFA';

switch (uniqueClass) {
	case 'oxygo':
		fill = '#00b6dd';
		break;
	default:
		break;
}

LocationOne.defaultProps = {
	fill: fill,
	width: 22,
	height: 22,
	viewBox: '0 0 22 22',
};

LocationOne.propTypes = {
	className: T.string,
};

export default LocationOne;
