import React from 'react';
import T from 'prop-types';

const Pair = (props) => (
	<svg {...props} className={props.className}>
		<path d='M7 1h9a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2zm1 2a1 1 0 0 0-1 1v11a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1H8zm3.5 17a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z' />
	</svg>
);

Pair.defaultProps = {
	fill: '#6C7DDF',
	width: 22,
	height: 22,
	viewBox: '0 0 22 22',
};

Pair.propTypes = {
	className: T.string,
};

export default Pair;
