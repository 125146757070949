import ipType from './Icons/IpType.png';
import notUsaType from './Icons/NotUsaType.png';
import usaType from './Icons/UsaType.png';
// import canadaType from './Icons/CanadaType.png';
import m1 from './Icons/Markers/M1.png';
import m2 from './Icons/Markers/M2.png';
import m3 from './Icons/Markers/M3.png';
import m4 from './Icons/Markers/M4.png';
import m5 from './Icons/Markers/M5.png';
import countryGeoBoundry from '../../settings/countryGeoBoundry.json';
import style from './style';

const internals = {};

export const init = (args) => {
	return new Promise((resolve) => {
		const { node } = args;
		const { Map, event } = window.google.maps;

		internals.map = new Map(node, {
			center: {
				lat: 38.399308,
				lng: -100.577004,
			},
			zoom: 4,
			styles: style,
			disableDefaultUI: true,
			zoomControl: true,
		});

		event.addListenerOnce(internals.map, 'tilesloaded', () => resolve());
	});
};
function isPointInUSA(lat, lng, country) {
	return (
		lat <= country.north &&
		lat >= country.south &&
		lng >= country.west &&
		lng <= country.east
	);
}

export const createMarkers = (args) => {
	const { data, headings, onClick } = args;
	const { LatLngBounds } = window.google.maps;
	const ids = headings.indexOf('concentrator_profile_ids');
	const modelIds = headings.indexOf('concentrator_model_ids');
	const latitude = headings.indexOf('latitude');
	const longitude = headings.indexOf('longitude');
	const bounds = new LatLngBounds();
	const markers = [];

	if (internals.cluster instanceof window.markerClusterer.MarkerClusterer) {
		internals.cluster.clearMarkers();
	}

	internals.cluster = new window.markerClusterer.MarkerClusterer(
		internals.map,
		markers,
		{
			styles: [
				{
					url: m1,
					height: 52,
					width: 53,
				},
				{
					url: m2,
					height: 55,
					width: 56,
				},
				{
					url: m3,
					height: 65,
					width: 66,
				},
				{
					url: m4,
					height: 77,
					width: 78,
				},
				{
					url: m5,
					height: 89,
					width: 90,
				},
			],
			maxZoom: 12,
			minimumClusterSize: 10,
		},
	);

	function processLargeArrayAsync(array, fn, maxTimePerChunk) {
		maxTimePerChunk = maxTimePerChunk || 200;

		let index = 0;

		function now() {
			return new Date().getTime();
		}

		function doChunk() {
			const startTime = now();

			while (index < array.length && now() - startTime <= maxTimePerChunk) {
				const markersIcon = {
					url: ipType,
					size: new window.google.maps.Size(34, 34),
				};
				markersIcon.url = notUsaType;
				if (
					isPointInUSA(
						array[index][latitude],
						array[index][longitude],
						countryGeoBoundry.USA,
					)
				) {
					markersIcon.url = usaType;
				} else {
					markersIcon.url = notUsaType;
				}
				const marker = new window.google.maps.Marker({
					position: {
						lat: Number(array[index][latitude]),
						lng: Number(array[index][longitude]),
					},
					icon: markersIcon,
					map: internals.map,
				});
				const id = array[index][ids];
				const modelId = array[index][modelIds];
				const row = { itemId: id, model: modelId };
				const location = new window.google.maps.LatLng(
					marker.position.lat(),
					marker.position.lng(),
				);

				bounds.extend(location);
				marker.addListener('click', () => onClick(row));
				markers.push(marker);
				++index;
			}

			internals.cluster.addMarkers(markers);

			if (index < array.length) {
				setTimeout(doChunk, 1);
			}
		}

		doChunk();
	}

	processLargeArrayAsync(data);

	if (markers.length && internals.firstLoad) {
		const { event } = window.google.maps;

		event.addListener(internals.map, 'bounds_changed', () => {
			if (internals.map.getZoom() > 14) internals.map.setZoom(14);
		});

		internals.map.fitBounds(bounds);
		internals.map.panToBounds(bounds);

		setTimeout(() => {
			event.clearListeners(internals.map, 'bounds_changed');
		}, 300);
	}

	data.length && (internals.firstLoad = true);
};
