import React from 'react';

import s from '../style.module.scss';

const ListItem = (props) => {
	return (
		<li className={`${s.listItem} ${props.className || ''}`}>
			{props.children}
		</li>
	);
};

export default ListItem;
