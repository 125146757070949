import React from 'react';
import { Link } from 'react-router-dom';
import { Table, SearchInput, Button } from 'components';
import { AuxWrap } from 'hoc';
import searchUsers from '../Configs/searchUsers';
import searchProvider from '../Configs/searchProvider';
import { routers } from 'settings';
import { Back } from 'components/Icons';

import s from '../style.module.scss';
import i18next from 'i18next';

let isLoaded;

const SearchResults = (props) => {
	const { addprovidertype, addusertype, data } = props;
	if (!isLoaded) {
		isLoaded = props.providersLoading || props.usersLoading;
	}

	!isLoaded && props.history.push(routers.users);

	const onProviderClicked = (el) => {
		props.history.push(routers.usersProvider(el.id));
	};

	const onUserClicked = (row, index) => {
		const args = {
			type: 'UserDetails',
			subType: 'search',
			data: row,
			index: index,
			restrictClosing: true,
		};

		if (!row.roles.length) {
			args.type = 'PendingRequest';
		}

		props.openModal(args);
	};

	return (
		<AuxWrap>
			<div className={s.titleAlign}>
				<Link to={routers.users}>
					<Back width='40' fill='#000' height='40' />
				</Link>
				<h1 className={s.searchTitle}>{i18next.t('search')}</h1>
			</div>

			<div className={s.topContainer}>
				<div>
					<SearchInput
						className={s.searchForm}
						theme='violet'
						btnType='searchViolet'
						value={props.searchValue}
						placeholder={'Search user or provider'}
						disabled={
							props.providersLoading || props.usersLoading || !props.searchValue
						}
						onChange={props.onSearchTyping}
						onSearchSubmit={props.onSearchSubmit}
						onClear={props.onSearchClear}
					/>
				</div>
				<div>
					<Button
						className={s.addProvider}
						clicked={addprovidertype}
						btnType={'type-20'}
					>
						<svg
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M17.6346 10.6346H15.5V8.1346H13V6H15.5V3.5H17.6346V6H20.1346V8.1346H17.6346V10.6346Z'
								fill='white'
							/>
							<path
								d='M13.9387 21.7484L6.06753 19.4657V20.8234H1.51953V12.0889H8.51371L14.7637 14.4216C15.1496 14.5626 15.4791 14.7998 15.7522 15.1331C16.0252 15.4664 16.1618 15.9575 16.1618 16.6062H19.2906C19.9073 16.6062 20.4009 16.8306 20.7714 17.2795C21.1419 17.7284 21.3271 18.336 21.3271 19.1023V19.483L13.9387 21.7484ZM2.65411 19.6888H4.90796V13.2235H2.65411V19.6888ZM13.8483 20.5734L20.1522 18.6523C20.0842 18.3356 19.9784 18.1045 19.8349 17.959C19.6913 17.8135 19.5099 17.7407 19.2906 17.7407H14.2695C13.8015 17.7407 13.3486 17.709 12.9108 17.6456C12.473 17.5821 12.0785 17.4952 11.7272 17.385L9.67333 16.7504L10.1272 15.6369L11.9426 16.2562C12.3464 16.3895 12.7454 16.4812 13.1397 16.5312C13.5339 16.5812 14.1393 16.6062 14.956 16.6062C14.956 16.3485 14.9121 16.1206 14.8243 15.9225C14.7365 15.7244 14.5913 15.5837 14.3887 15.5004L8.34063 13.2235H6.06753V18.2657L13.8483 20.5734Z'
								fill='white'
							/>
						</svg>{' '}
						{i18next.t('add_provider')}
					</Button>
					<Button
						className={s.addProvider}
						clicked={addusertype}
						btnType={'type-20'}
						disabled={!data.length}
					>
						<svg
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M12 11.6923C11.0375 11.6923 10.2135 11.3496 9.52813 10.6642C8.84271 9.97879 8.5 9.15484 8.5 8.19236C8.5 7.22986 8.84271 6.40591 9.52813 5.72051C10.2135 5.03509 11.0375 4.69238 12 4.69238C12.9625 4.69238 13.7864 5.03509 14.4718 5.72051C15.1572 6.40591 15.5 7.22986 15.5 8.19236C15.5 9.15484 15.1572 9.97879 14.4718 10.6642C13.7864 11.3496 12.9625 11.6923 12 11.6923ZM4.5 19.3077V17.0846C4.5 16.5949 4.63302 16.1414 4.89905 15.7241C5.16507 15.3068 5.52051 14.986 5.96537 14.7616C6.95384 14.277 7.95096 13.9135 8.95672 13.6712C9.96249 13.4289 10.9769 13.3078 12 13.3078C13.023 13.3078 14.0375 13.4289 15.0432 13.6712C16.049 13.9135 17.0461 14.277 18.0346 14.7616C18.4794 14.986 18.8349 15.3068 19.1009 15.7241C19.3669 16.1414 19.5 16.5949 19.5 17.0846V19.3077H4.5ZM5.99997 17.8077H18V17.0846C18 16.8821 17.9413 16.6946 17.824 16.5221C17.7067 16.3497 17.5474 16.209 17.3461 16.1C16.4846 15.6757 15.6061 15.3542 14.7107 15.1356C13.8152 14.917 12.9117 14.8077 12 14.8077C11.0883 14.8077 10.1847 14.917 9.28927 15.1356C8.39384 15.3542 7.51536 15.6757 6.65382 16.1C6.45254 16.209 6.29325 16.3497 6.17595 16.5221C6.05863 16.6946 5.99997 16.8821 5.99997 17.0846V17.8077ZM12 10.1924C12.55 10.1924 13.0208 9.99653 13.4125 9.60486C13.8041 9.21319 14 8.74236 14 8.19236C14 7.64236 13.8041 7.17153 13.4125 6.77986C13.0208 6.38819 12.55 6.19236 12 6.19236C11.45 6.19236 10.9791 6.38819 10.5875 6.77986C10.1958 7.17153 9.99997 7.64236 9.99997 8.19236C9.99997 8.74236 10.1958 9.21319 10.5875 9.60486C10.9791 9.99653 11.45 10.1924 12 10.1924Z'
								fill='white'
							/>
						</svg>{' '}
						{i18next.t('add_user')}
					</Button>
				</div>
			</div>
			<h2 className={s.listTitle}>{i18next.t('providers')}</h2>
			<Table
				className={s.fullTable}
				config={searchProvider}
				data={props.providersData}
				loading={props.usersLoading || props.providersLoading}
				onRowClicked={onProviderClicked}
			/>

			<h2 className={s.listTitle}>{i18next.t('users')}</h2>
			<Table
				className={[s.fullTable, s.searchUsersTable].join(' ')}
				config={searchUsers}
				data={props.usersData}
				loading={props.usersLoading || props.providersLoading}
				currentUser={props.currentUser}
				changeUsersStatus={props.changeUsersStatus}
				onRowClicked={onUserClicked}
			/>
		</AuxWrap>
	);
};

export default SearchResults;
