import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
	unresolvedErrors: { total_rows: '', data: [] },
	loading: false,
};

export const getUnresolvedErrorsStart = (state) => {
	return updateObject(state, { loading: true });
};

export const getUnresolvedErrorsSuccess = (state, action) => {
	return updateObject(state, {
		unresolvedErrors: action.data,
		loading: false,
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_UNRESOLVED_ERRORS_START:
			return getUnresolvedErrorsStart(state, action);
		case actionTypes.GET_UNRESOLVED_ERRORS:
			return getUnresolvedErrorsSuccess(state, action);
		case actionTypes.GET_UNRESOLVED_ERRORS_SUCCESS:
			return getUnresolvedErrorsSuccess(state, action);

		default:
			return state;
	}
};

export default reducer;
