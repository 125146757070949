import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
	data: [],
	period: null,
	loading: false,
};

const getRecentErrorStart = (state) => {
	return updateObject(state, { loading: true });
};

const getRecentErrorSuccess = (state, action) => {
	return updateObject(state, {
		data: action.data,
		period: action.period,
		loading: false,
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_RECENT_ERROR_START:
			return getRecentErrorStart(state, action);
		case actionTypes.GET_RECENT_ERROR_SUCCESS:
			return getRecentErrorSuccess(state, action);

		default:
			return state;
	}
};

export default reducer;
