import * as actionTypes from '../actions/actionTypes';

const initialState = {
	translations: {},
	loading: false,
	error: null,
};
const translationReducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.FETCH_TRANSLATIONS_REQUEST:
			return {
				...state,
				loading: false,
			};

		case actionTypes.FETCH_TRANSLATIONS_SUCCESS:
			return {
				...state,
				loading: true,
				translations: action.payload,
				error: null,
			};

		case actionTypes.FETCH_TRANSLATIONS_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			};

		default:
			return state;
	}
};

export default translationReducer;
