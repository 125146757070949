import React, { PureComponent } from 'react';
import ProviderSelect from '../ProviderSelect';
import BlockUser from '../BlockUser';
import { Button, Spinner, Select } from 'components';
import { coreInstance as axios, api } from 'settings';
import { logger, generatePin } from 'helpers';
import { AuxWrap } from 'hoc';
import { EditIcon, Close } from 'components/Icons';
import initialStateCreator from './initialStateCreator';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { handleLabel } from 'helpers';

import s from './style.module.scss';

class UserDetails extends PureComponent {
	constructor(props) {
		super(props);
		this.state = initialStateCreator(props);
	}

	componentDidMount() {
		logger('[UserDetails] componentDidMount');

		this.getUserDetails();
	}

	getUserDetails = (callback) => {
		const { id, user_id } = this.props.modalData;

		axios
			.get(api.users.index + `/${id || user_id}`, {
				params: { with: ['roles', 'provider'] },
			})
			.then((response) => {
				logger('[UserDetails] Get user details');

				this.setState((prevState) => {
					const { data } = response.data;
					const { controls, details } = prevState;

					controls.role = {
						checked: { [data.roles[0].id]: data.roles[0] },
					};
					controls.provider = {
						checked: { [data.provider_id]: data.provider },
					};

					if (details && details.provider_id !== data.provider_id) {
						return { controls, details: data, step: 'role' };
					}

					return {
						controls,
						details: data,
						selectedProvider: data?.provider?.name,
						selectedRole: data?.roles?.[0]?.label,
					};
				});

				callback && callback();
			})
			.catch(() => {
				logger('[UserDetails] Get user details fail');
			});
	};

	generateUniqueCode = () => {
		const success = (response) => {
			this.setState({ codePartTwo: response.data.data.code_part_2 });
		};

		generatePin(success);
	};

	createProvider = (callback) => {
		const { controls, codePartTwo } = this.state;
		const data = {
			name: controls.newProvider.name.value,
			code_part_1: controls.newProvider.pin.value.replace(/[_]/g, ''),
			code_part_2: codePartTwo,
		};

		const success = (data) => {
			this.setState({ newProviderData: data, formValidMessage: null });
			callback && callback(data);
		};
		const error = (data) => this.setState({ formValidMessage: data });

		this.props.addProvider(data, success, error);
	};

	onItemClick = (data, type) => {
		if (data) {
			this.setState({ buttonEnabled: false });
			if (data.label) {
				this.setState({ userbuttonEnabled: false });
			}
			this.setState((prevState) => {
				const controls = { ...prevState.controls };

				controls[type].checked = { [data.id]: data };

				return { controls: controls };
			});
		} else {
			this.setState({ buttonEnabled: true });
			this.setState({ userbuttonEnabled: true });
		}
	};

	inputChangedHandler = (event, name) => {
		const target = event.target;
		const value = name === 'pin' ? target.value.toUpperCase() : target.value;

		this.setState((prevState) => {
			const state = { ...prevState.controls };
			const control = state.newProvider[name];

			control.value = value;

			return { controls: state, formValidMessage: null };
		});
	};

	mainView = () => {
		const { details } = this.state;
		const {
			type,
			rowIndex,
			modalData,
			changeUserStatus,
			changeSearchUsersStatus,
			t,
		} = this.props;
		const {
			user_id,
			id,
			status = 0,
			user_status = 0,
			role_label,
			roles,
		} = modalData;
		// const userStatus = status || user_status;
		const userStatus = details ? details.status : status || user_status;

		// let isBlocked = null;
		let fields = <Spinner className={s.spinner} />;

		const setStatusHandler = () => {
			const args = {
				id: id || user_id,
				index: rowIndex,
				label: role_label || roles[0].label,
				status: userStatus ? 0 : 1,
				callback: this.getUserDetails,
			};

			if (!args.status) {
				return this.setState({ blockUserPopup: true });
			}

			switch (type) {
				case 'search':
					changeSearchUsersStatus(args);
					break;
				default:
					changeUserStatus(args);
					break;
			}
		};

		const changeStep = (step) =>
			this.setState((prevState) => {
				const { controls, details } = prevState;

				controls.role = {
					checked: { [details.roles[0].id]: details.roles[0] },
				};
				controls.provider = {
					checked: { [details.provider_id]: details.provider },
				};

				return {
					controls,
					step: step,
				};
			});

		// if (!userStatus) {
		// 	isBlocked = <span className={s.blocked}>Blocked</span>;
		// }

		if (details) {
			const config = {
				displayKey: 'label',
				label: {
					path: 'label',
				},
			};
			fields = (
				<AuxWrap>
					<div className={s.containerEdit}>
						<div className={s.field}>
							<span className={s.label}>{i18next.t('provider')}</span>
							<div className={s.value}>
								<Select
									type={'role'}
									className={s.selectIconGap}
									config={config}
									data={[]}
									newDesign={true}
									defaultValue={this.state.selectedProvider || ''}
									isDisabled={true}
								/>
								{!!userStatus && (
									<EditIcon
										className={s.editBtn}
										onClick={() => changeStep('provider')}
									/>
								)}
							</div>
						</div>
					</div>
					<div className={s.containerEdit}>
						<div className={s.field}>
							<span className={s.label}>{i18next.t('user_role')}</span>
							<div className={s.value}>
								<Select
									type={'role'}
									className={s.selectIconGap}
									config={config}
									data={[]}
									newDesign={true}
									defaultValue={this.state.selectedRole || ''}
									isDisabled={true}
								/>
								{!!userStatus && (
									<EditIcon
										className={s.editBtn}
										onClick={() => changeStep('role')}
									/>
								)}
							</div>
						</div>
					</div>
				</AuxWrap>
			);
		}

		return (
			<AuxWrap>
				<div className={s.titleAlign}>
					<div className={s.iconTitle}>
						<span className={s.titleTypography}>
							{t('editUser')} {"'"}
							{modalData.email || modalData.user_email}
							{"'"}
						</span>
					</div>
					<Close
						className={s.closeBtn}
						width={36}
						height={36}
						fill='#6D6D6D'
						onClick={this.props.close}
					/>
				</div>
				<div className={s.contentContainer}>
					{fields}

					<div className={[s.buttons, s.mainBtn].join(' ')}>
						<Button
							className={[s.btn, !userStatus && s.unblock].join(' ')}
							btnType='type-16'
							clicked={setStatusHandler}
						>
							{userStatus ? i18next.t('block') : i18next.t('unBlock')}
						</Button>
					</div>
				</div>
			</AuxWrap>
		);
	};

	editProviderView = () => {
		const { type, modalData, providersData, changeUserProvider, close } =
			this.props;
		const { controls, details, providerView } = this.state;

		const saveHandler = () => {
			const checkedProvider = Object.values(controls.provider.checked)[0];
			const initials = {
				role: controls.role,
				provider: controls.provider,
				selectedProvider: checkedProvider.name,
				selectedRole: details.roles[0].label,
			};

			if (details.provider_id !== checkedProvider.id) {
				initials.step = 'role';
			}

			const args = {
				type: type,
				id: modalData.id || modalData.user_id,
				role: modalData.role_label || modalData.roles[0].label,
				data: {
					provider_id: Object.values(controls.provider.checked)[0].id,
				},
				success: () => {
					switch (type) {
						case 'pending':
							this.setState(initialStateCreator(initials), close);
							break;
						case 'byProvider':
							this.setState(initialStateCreator(initials), this.getUserDetails);
							break;
						default:
							this.setState(initialStateCreator(initials), this.getUserDetails);
							break;
					}
				},
			};

			if (providerView === 'new') {
				this.createProvider((data) => {
					args.data.provider_id = data.id;
					changeUserProvider(args);
				});
			} /*if (!isNoChange)*/ else {
				changeUserProvider(args);
			}
		};

		const changeView = (value) => {
			if (value === 'new') {
				this.generateUniqueCode();
			}

			this.setState((prevState) => {
				const newState = { ...prevState };

				newState.controls.newProvider.name.value = '';
				newState.controls.newProvider.pin.value = '';
				newState.providerView = value;
				newState.formValidMessage = null;

				return newState;
			});
		};

		return (
			<AuxWrap>
				<div className={s.titleAlign}>
					<div className={s.iconTitle}>
						<span className={s.titleTypography}>
							{i18next.t('editUser')} {"'"}
							{modalData.email || modalData.user_email}
							{"'"}
						</span>
					</div>
					<Close
						className={s.closeBtn}
						width={36}
						height={36}
						fill='#6D6D6D'
						onClick={this.props.close}
					/>
				</div>
				<div className={s.contentContainer}>
					<div className={s.containerEdit}>
						<ProviderSelect
							data={providersData}
							state={this.state}
							inputChanged={(event, type) =>
								this.inputChangedHandler(event, type)
							}
							selectItemClick={this.onItemClick}
							changeView={changeView}
							newDesign={true}
							defaultValue={this.state.selectedProvider || ''}
						/>
					</div>
				</div>
				<div className={s.buttons}>
					<Button
						btnType='type-22'
						className={s.cancel}
						clicked={() =>
							this.setState(
								initialStateCreator({
									selectedRole: this.state.selectedRole,
									selectedProvider: this.state.selectedProvider,
								}),
							)
						}
					>
						{i18next.t('cancel')}
					</Button>
					<Button
						btnType='type-21'
						disabled={this.state.buttonEnabled}
						clicked={saveHandler}
					>
						{i18next.t('save')}
					</Button>
				</div>
			</AuxWrap>
		);
	};

	editRoleView = () => {
		const { modalData, rolesData, changeUserRole, currentUser } = this.props;
		const { controls, details, userbuttonEnabled } = this.state;
		const userId = modalData.id || modalData.user_id;
		const showSuperAdmin =
			currentUser?.roles[0]?.name === 'superadmin' &&
			details?.provider?.is_inogen
				? true
				: false;
		const roles = showSuperAdmin
			? rolesData
			: rolesData?.filter(
					(el) => el?.name !== 'superadmin' && el.name !== 'provideradmin',
			  );
		const UserRoles = roles ? roles : [];
		//  && roles.length > 0 && roles.filter((data) => {
		//     return data.name && data.name !== i18next.t('firmware_admin')
		// });

		const config = {
			displayKey: 'label',
			label: {
				path: 'label',
			},
		};

		const saveHandler = () => {
			const args = {
				id: userId,
				data: {
					role_ids: [Object.values(controls.role.checked)[0].id],
				},
				oldRole: modalData.role_label || modalData.roles[0].label,
				newRole: Object.values(controls.role.checked)[0].label,
				success: () =>
					this.setState(initialStateCreator(), this.getUserDetails),
			};

			changeUserRole(args);
		};

		return (
			<AuxWrap>
				{/* <h2 className={s.title}>{i18next.t("edit_user_role")}</h2>
				<div className={s.subText}>
					{modalData.email || modalData.user_email}
				</div> */}
				<div className={s.titleAlign}>
					<div className={s.iconTitle}>
						<span className={s.titleTypography}>
							{i18next.t('editUser')} {"'"}
							{modalData.email || modalData.user_email}
							{"'"}
						</span>
					</div>
					<Close
						className={s.closeBtn}
						width={36}
						height={36}
						fill='#6D6D6D'
						onClick={this.props.close}
					/>
				</div>
				<div className={s.contentContainer}>
					<div className={s.containerEdit}>
						<div className={s.selectField}>
							<div className={s.selectHead}>
								<span>{i18next.t('user_role')}</span>
							</div>
							<Select
								type={'role'}
								config={config}
								data={handleLabel(UserRoles)}
								checked={this.state.controls.role.checked}
								isOpen={this.state.controls.role.isOpen}
								onItemClick={this.onItemClick}
								newDesign={true}
								defaultValue={this.state.selectedRole || ''}
							/>
						</div>
					</div>
				</div>

				<div className={s.buttons}>
					<Button
						btnType='type-22'
						className={s.cancel}
						clicked={() =>
							this.setState(
								initialStateCreator({
									selectedRole: this.state.selectedRole,
									selectedProvider: this.state.selectedProvider,
								}),
							)
						}
					>
						{i18next.t('cancel')}
					</Button>
					<Button
						btnType='type-21'
						disabled={userbuttonEnabled}
						clicked={saveHandler}
					>
						{i18next.t('save')}
					</Button>
				</div>
			</AuxWrap>
		);
	};

	renderSteps = () => {
		let step = this.mainView();

		switch (this.state.step) {
			case 'provider':
				step = this.editProviderView();
				break;
			case 'role':
				step = this.editRoleView();
				break;
			default:
				break;
		}

		return step;
	};

	render() {
		const { details, blockUserPopup } = this.state;
		const {
			type,
			rowIndex,
			modalData,
			changeUserStatus,
			changeSearchUsersStatus,
		} = this.props;
		const {
			user_id,
			id,
			status = 0,
			user_status = 0,
			role_label,
			roles,
		} = modalData;
		const userStatus = details ? details.status : status || user_status;

		const setStatusHandler = () => {
			const args = {
				id: id || user_id,
				index: rowIndex,
				label: role_label || roles[0].label,
				status: userStatus ? 0 : 1,
				callback: () => {
					this.getUserDetails(() => this.setState({ blockUserPopup: false }));
				},
			};

			switch (type) {
				case 'search':
					changeSearchUsersStatus(args);
					break;
				default:
					changeUserStatus(args);
					break;
			}
		};

		if (blockUserPopup) {
			return (
				<BlockUser
					email={modalData.email || modalData.user_email}
					approveHandler={setStatusHandler}
					cancelHandler={() => this.setState({ blockUserPopup: false })}
				/>
			);
		}
		return this.renderSteps();
	}
}

export default withTranslation()(UserDetails);
