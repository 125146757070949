import React from 'react';
import T from 'prop-types';

const Block = (props) => (
	<svg {...props} className={props.className}>
		<path d='M2.929 17.071c-3.905-3.905-3.905-10.237 0-14.142 3.905-3.905 10.237-3.905 14.142 0 3.905 3.905 3.905 10.237 0 14.142-3.905 3.905-10.237 3.905-14.142 0zM4 9v2h12V9H4z' />
	</svg>
);

Block.defaultProps = {
	fill: '#FF8300',
	width: 20,
	height: 20,
	viewBox: '0 0 20 20',
};

Block.propTypes = {
	className: T.string,
};

export default Block;
