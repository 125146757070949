import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
	data: null,
	blockedData: null,
	blockedLoading: false,
	loading: false,
};

const getManageProviderStart = (state) => {
	return updateObject(state, { loading: true });
};

const getManageProviderSuccess = (state, action) => {
	return updateObject(state, {
		data: action.data,
		loading: false,
	});
};

// const changeManageUsersStatusStart = (state, action) => {
//     const newData = {...state.data};
//     const changeRow = newData.users_by_roles[action.label][action.index];

//     changeRow.loading = true;

//     return updateObject(state, {
//         data: newData
//     });
// };

const changeManageUsersStatusSuccess = (state, action) => {
	const newData = { ...state.data };

	newData.users_by_roles[action.label] = newData.users_by_roles[
		action.label
	].map((el) => {
		if (el.user_id === action.id) {
			el.user_status = el.user_status ? 0 : 1;
		}

		return el;
	});

	return updateObject(state, {
		data: newData,
	});
};

const addUserStart = (state) => {
	return updateObject(state, { loading: true });
};

const addUserSuccess = (state, action) => {
	try {
		const newData = { ...state.data };
		const userData = {
			role_label: action.data.roles[0].label,
			user_id: action.data.id,
			user_email: action.data.email,
			user_status: action.data.status,
		};
		const usersByRoles = newData.users_by_roles;

		if (Array.isArray(usersByRoles)) {
			newData.users_by_roles = {
				[userData.role_label]: [userData],
			};
		} else {
			if (Array.isArray(usersByRoles[userData.role_label])) {
				usersByRoles[userData.role_label].push(userData);
			} else {
				usersByRoles[userData.role_label] = [userData];
			}
		}

		return updateObject(state, {
			data: newData,
		});
	} catch (e) {
		console.error(e);

		return state;
	}
};

const changeUserRoleSuccess = (state, action) => {
	if (!state.data) return state;

	try {
		const newData = { ...state.data };
		const oldRole = newData.users_by_roles[action.oldRole];
		const newRole = newData.users_by_roles[action.newRole];

		let currentUser;

		newData.users_by_roles[action.oldRole] = oldRole.filter((el) => {
			if (el.user_id === action.id) {
				currentUser = el;
			}

			return el.user_id !== action.id;
		});

		currentUser.role_label = action.newRole;

		if (newRole) {
			newRole.push(currentUser);
		} else {
			newData.users_by_roles[action.newRole] = [currentUser];
		}

		return updateObject(state, {
			data: newData,
		});
	} catch (e) {
		console.warn(e);

		return state;
	}
};

const changeUserProviderSuccess = (state, action) => {
	try {
		switch (action.subType) {
			case 'search':
				return state;
			default: {
				const newData = { ...state.data };
				const oldRole = newData.users_by_roles[action.role];

				newData.users_by_roles[action.role] = oldRole.filter((el) => {
					return el.user_id !== action.id;
				});

				return updateObject(state, {
					data: newData,
				});
			}
		}
	} catch (e) {
		console.warn(e);

		return state;
	}
};

const approveUsersPendingStart = (state) => {
	return updateObject(state, { pendingLoading: true });
};

const approveUsersPendingSuccess = (state, action) => {
	if (!state.data) return state;

	try {
		const newData = { ...state.data };
		const roleArray = newData.users_by_roles[action.data.role_label];

		roleArray.push(action.data);

		return updateObject(state, {
			data: newData,
		});
	} catch (e) {
		console.warn(e);

		return state;
	}
};

const updateProvider = (state, action) => {
	const newData = { ...state.data };

	newData.description = action.data.description;

	return updateObject(state, {
		data: newData,
	});
};

const clearManageProvider = () => {
	return initialState;
};

const provider = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_MANAGE_PROVIDER_START:
			return getManageProviderStart(state, action);
		case actionTypes.GET_MANAGE_PROVIDER_SUCCESS:
			return getManageProviderSuccess(state, action);
		// case actionTypes.CHANGE_MANAGE_USERS_STATUS_START: return changeManageUsersStatusStart(state, action);
		case actionTypes.CHANGE_MANAGE_USERS_STATUS_SUCCESS:
			return changeManageUsersStatusSuccess(state, action);
		case actionTypes.UPDATE_PROVIDER:
			return updateProvider(state, action);

		case actionTypes.ADD_USER_START:
			return addUserStart(state, action);
		case actionTypes.ADD_USER_SUCCESS:
			return addUserSuccess(state, action);
		case actionTypes.CHANGE_USER_ROLE_SUCCESS:
			return changeUserRoleSuccess(state, action);
		case actionTypes.CHANGE_USER_PROVIDER_SUCCESS:
			return changeUserProviderSuccess(state, action);

		case actionTypes.APPROVE_USERS_PENDING_START:
			return approveUsersPendingStart(state, action);
		case actionTypes.APPROVE_USERS_PENDING_SUCCESS:
			return approveUsersPendingSuccess(state, action);

		case actionTypes.CLEAR_MANAGE_PROVIDER:
			return clearManageProvider(state, action);

		default:
			return state;
	}
};

export default provider;
