import React from 'react';
import T from 'prop-types';

const Delete = (props) => (
	<svg {...props} className={props.className}>
		<g fill='none' fillRule='evenodd'>
			<circle cx='8' cy='8' r='8' fill='#fff' />
			<path
				d='M6 10.243L10.243 6m0 4.243L6 6'
				stroke='#30ADFA'
				strokeLinecap='round'
				strokeWidth='2'
			/>
		</g>
	</svg>
);

Delete.defaultProps = {
	width: 16,
	height: 16,
	viewBox: '0 0 16 16',
};

Delete.propTypes = {
	className: T.string,
};

export default Delete;
