import React from 'react';
import T from 'prop-types';
import { uniqueClass } from 'config';

const Billing = (props) => (
	<svg {...props} className={props.className}>
		<path d='M7 18.118l-2.106 1.053A2 2 0 0 1 2 17.382V5a3 3 0 0 1 3-3h12a3 3 0 0 1 3 3v12.382a2 2 0 0 1-2.894 1.789L15 18.118l-3.106 1.553a2 2 0 0 1-1.788 0L7 18.118zM5 4a1 1 0 0 0-1 1v12.382l3-1.5 4 2 4-2 3 1.5V5a1 1 0 0 0-1-1H5zm3 3h6a1 1 0 0 1 0 2H8a1 1 0 1 1 0-2zm0 4h6a1 1 0 0 1 0 2H8a1 1 0 0 1 0-2z' />
	</svg>
);

let fill = '#6C7DDF';

switch (uniqueClass) {
	case 'oxygo':
		fill = '#017cc4';
		break;
	default:
		break;
}

Billing.defaultProps = {
	fill: fill,
	width: 22,
	height: 22,
	viewBox: '0 0 22 22',
};

Billing.propTypes = {
	className: T.string,
};

export default Billing;
