import { useEffect } from 'react';
import { connect } from 'react-redux';
import * as actions from 'store/actions';

const LoadScripts = ({ isAuthenticated, dispatch }) => {
	useEffect(() => {
		if (isAuthenticated) {
			console.log('Loading Google Maps script...');
			// Load the Google Maps script
			const googleMapsScript = document.createElement('script');
			googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_JS_API_KEY}`;
			googleMapsScript.async = true;
			document.body.appendChild(googleMapsScript);

			// Load the MarkerClusterer script
			const markerClustererScript = document.createElement('script');
			markerClustererScript.src =
				'https://unpkg.com/@googlemaps/markerclusterer/dist/index.min.js';
			markerClustererScript.async = true;
			markerClustererScript.onload = function () {
				dispatch(actions.scriptLoaded());
			};
			document.body.appendChild(markerClustererScript);
		}
	}, [isAuthenticated, dispatch]);

	return null;
};

export default connect()(LoadScripts);
