export const getParam = ({ provider_id = null, modelIds = null }) => {
	// const provider_code_id = Object.keys(providers).length
	// 	? Object.values(providers).reduce(
	// 			(acc, el) => [...acc, ...el.provider_codes.map((el) => el.id)],
	// 			[]
	// 	  )
	// 	: null;

	return {
		search: {
			concentrator_model_id: modelIds,
			provider_id: provider_id,
		},
		searchFields: {
			concentrator_model_id: modelIds && 'in',
			provider_id: provider_id && 'in',
		},
	};
};

export const models = {
	param: 'concentrator_model_id',
	valuesField: 'id',
	displayField: 'description',
	propsKey: 'modelsFilterProps',
	defaultValue: 'all_models',
	label: {
		path: 'description',
	},
};

export const modelsParams = {
	orderBy: 'id',
};
