import React from 'react';
import T from 'prop-types';

const ErrorRecall = (props) => (
	<svg {...props} className={props.className}>
		<path d='M8.087 9.864c0-.29-.115-.573-.317-.777a1.093 1.093 0 0 0-.77-.32 1.09 1.09 0 0 0-1.087 1.097A1.09 1.09 0 0 0 7 10.962a1.09 1.09 0 0 0 1.087-1.098zM7.87 4.733C7.87 4.328 7.48 4 7 4s-.87.328-.87.733V7.14c0 .405.39.733.87.733s.87-.328.87-.733V4.733zM.609 13a.605.605 0 0 1-.526-.315.657.657 0 0 1-.004-.632L6.471.32A.605.605 0 0 1 7 0c.219 0 .421.123.53.321l6.39 11.732a.656.656 0 0 1-.003.632.604.604 0 0 1-.526.315H.61z' />
	</svg>
);

ErrorRecall.defaultProps = {
	fill: '#999',
	width: 14,
	height: 13,
	viewBox: '0 0 14 13',
};

ErrorRecall.propTypes = {
	className: T.string,
};

export default ErrorRecall;
