import React, { Component } from 'react';
import { Backdrop, Spinner } from 'components';
import { logger } from 'helpers';
import { Close, Refresh } from 'components/Icons';

import sOld from './style.module.scss';
import sLatest from './newStyle.module.scss';

const blockScroll = (nextProps, props) => {
	const body = document.querySelectorAll('body')[0];
	const rootEl = document.getElementById('root');

	if (nextProps.show && props.show !== nextProps.show) {
		body.style.overflow = 'hidden';

		if (rootEl?.offsetHeight > window?.innerHeight)
			rootEl.style.overflow = 'scroll';
	} else if (!nextProps.show && props.show !== nextProps.show) {
		body.style.overflow = 'auto';

		if (rootEl?.offsetHeight > window?.innerHeight)
			rootEl.style.overflow = 'auto';
	}
};

class Modal extends Component {
	// shouldComponentUpdate(nextProps, nextState) {
	//     return nextProps.show !== this.props.show || nextProps.children !== this.props.children;
	// }

	componentDidUpdate(prevProps) {
		logger('[Modal] componentDidUpdate');

		blockScroll(this.props, prevProps);
	}

	render() {
		const s = this.props.newDesign ? sLatest : sOld;
		const wrapClasses = [s.wrapper, this.props.wrapClassName];
		const modalClasses = [s.modal, this.props.className];
		let modal = <Spinner className={s.spinner} />;
		let refresh;

		if (this.props.show) {
			wrapClasses.push(s.open);
		}

		if (this.props.modalRefresh) {
			refresh = (
				<Refresh
					className={s.refreshBtn}
					width={34}
					height={34}
					fillOpacity='.54'
					onClick={this.props.modalRefresh}
				/>
			);
		}

		if (!this.props.loading) {
			modal = (
				<div className={modalClasses.join(' ')}>
					{refresh}
					{!this.props.restrictClosing ? (
						<Close
							className={s.closeBtn}
							width={34}
							height={34}
							fillOpacity='.54'
							onClick={this.props.modalClosed}
						/>
					) : (
						''
					)}
					{this.props.children}
				</div>
			);
		}

		return (
			<Backdrop
				show={this.props.show}
				clicked={
					!this.props.restrictClosing ? this.props.modalClosed : () => false
				}
			>
				{modal}
			</Backdrop>
		);
	}
}

Modal.defaultProps = {
	modalRefresh: false,
};

export default Modal;
