import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Input } from 'components';

import s from '../style.module.scss';
import { withTranslation } from 'react-i18next';

const ChangeSummary = ({ data, onChange, t, statusId }) => {
	return (
		<Fragment>
			<div className={s.btnContainer}>
				<div className={s.editLabel}>
					{t('change_summary')}
					{statusId === 2 || statusId === 4 ? (
						<span className={s.mandatory}> {'*'}</span>
					) : null}
				</div>
				<div className={s.btnContainer}>
					{data &&
						data.map((el, i) => {
							const config = {
								type: 'text',
								placeholder: el ? '' : `${t('change_summary')} #${i + 1}`,
							};

							return (
								<Input
									className={[s.input, s.inputAlignment].join(' ')}
									key={i}
									elementType='input'
									elementConfig={config}
									value={el || ''}
									changed={(event) => onChange(event, i)}
									newDesign={true}
								/>
							);
						})}

					<button className={s.addBtn} onClick={onChange}>
						+ {t('add_change')}
					</button>
				</div>
			</div>
		</Fragment>
	);
};

ChangeSummary.defaultProps = {
	data: [],
};

ChangeSummary.propTypes = {
	data: PropTypes.array,
};

export default withTranslation()(ChangeSummary);
