import * as actionTypes from '../../actions/actionTypes';

const initialState = {
	data: {},
	flow_settings_statistics: {},
	loading: false,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_SYSTEM_POWER_START:
			return { ...state, loading: true };
		case actionTypes.GET_SYSTEM_POWER_SUCCESS:
			return {
				loading: false,
				data: action.payload.data,
				flow_settings_statistics: action.payload.flow_settings_statistics,
			};

		default:
			return state;
	}
};

export default reducer;
