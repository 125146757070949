import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
	Bar,
	BarChart,
	Brush,
	CartesianGrid,
	ReferenceArea,
	ReferenceLine,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';
import ReactTooltip from 'react-tooltip';
import systemPowerAdapter from '../Configs/systemPowerAdapter';
import { Button, ToggleList } from 'components';
import { FLOW_SETTINGS_FILTERS, POINTS } from './constants';
import { Info } from 'components/Icons';
import SystemPowerDialog from './SystemPowerDialog';
import {
	getAppliedFilters,
	getDeviationValue,
	getFilters,
	getMax,
	getMedianValue,
	renderDeviationLabel,
	renderMedianLabel,
	sortKeys,
} from './chartUtils';

import ps from '../style.module.scss';
import s from './style.module.scss';
import { useTranslation, withTranslation } from 'react-i18next';
import i18next from 'i18next';

const hideFirstTick = (tickValue) => {
	return tickValue || '';
};

const TooltipContent = (props) => {
	const { active, payload, data, filters } = props;
	const { t } = useTranslation();
	const enabledFilters = filters
		.filter((filter) => filter.isActive)
		.map((filter) => filter.value);

	const emptyValueLayout = () => (
		<div className={s.details}>
			<span>{t('all_settings')}</span>
			<span className={s.detailsValue}>0</span>
		</div>
	);
	const layoutWithEnabledFilters = (enabledFilters, hoveredItem) =>
		enabledFilters.map((filterKey) => (
			<div className={s.details} key={filterKey}>
				<span>Flow setting {filterKey}</span>
				<span className={s.detailsValue}>{hoveredItem[filterKey] || 0}</span>
			</div>
		));

	const layoutWithDisabledFilters = (hoveredItem) =>
		sortKeys(Object.keys(hoveredItem)).map((valueKey) => {
			if (valueKey === 'all') {
				return (
					<div className={s.details} key={valueKey}>
						<span>{t('all_settings')}</span>
						<span className={s.detailsValue}>{hoveredItem[valueKey]}</span>
					</div>
				);
			}
			return (
				<div className={s.details} key={valueKey}>
					<span>Flow setting {valueKey}</span>
					<span className={s.detailsValue}>{hoveredItem[valueKey]}</span>
				</div>
			);
		});

	if (active && payload.length) {
		const hoveredItem = data[payload[0].payload.name];

		return (
			<div className={s.tooltip}>
				<div className={s.value}>
					{parseFloat(payload[0].payload.name).toFixed(2)} W
				</div>
				{hoveredItem
					? enabledFilters.length
						? layoutWithEnabledFilters(enabledFilters, hoveredItem)
						: layoutWithDisabledFilters(hoveredItem)
					: emptyValueLayout()}
			</div>
		);
	}

	return null;
};

const SystemPower = (props) => {
	const [filters, setFilters] = useState(FLOW_SETTINGS_FILTERS);
	const [isDialogOpen, setDialogState] = useState(false);
	const { data, flowSettingsStatistics, minValue, step, modelData, t } = props;

	useEffect(() => {
		resetFilters(getFilters(FLOW_SETTINGS_FILTERS, props.params));
	}, [props.params]);

	const setFiltersOnToggle = (filterItem, filtersList) => {
		setFilters(filtersList);
	};

	const resetFilters = (filtersList) => {
		setFilters(filtersList);
	};

	return (
		<div className={ps.twoThird}>
			<div className={ps.panel}>
				<div className={s.headerContainer}>
					<h2 className={`${ps.title} ${s.title}`}>
						{t('system_power')}
						<Info
							fill='#a5bad4'
							data-tip=''
							data-for='SystemPower '
							className={ps.infoIconTrigger}
						/>
						<span>{t('last_30_days')}</span>
					</h2>
					<ReactTooltip
						id='SystemPower '
						className={ps.tooltip}
						place='bottom'
						type='light'
						effect='solid'
					>
						<Info fill='#a5bad4' className={ps.infoIconTooltip} />
						<p>{t('system_power_app_checks')}</p>
					</ReactTooltip>
					<ToggleList
						className={s.toggleList}
						data={filters}
						onToggle={setFiltersOnToggle}
						noSelectionLabel={t('all_flow_settings')}
						reset={resetFilters}
					/>
					{Object.keys(flowSettingsStatistics).length ? (
						<Button
							btnType='type-4'
							className={s.detailsBtn}
							clicked={() => setDialogState(true)}
						>
							{i18next.t('view_details')}
						</Button>
					) : null}
				</div>
				<ResponsiveContainer height={230}>
					<BarChart
						data={systemPowerAdapter(
							data,
							minValue,
							getMax(data),
							step,
							filters,
						)}
						margin={{ top: 22, right: 20, bottom: 0, left: -30 }}
					>
						<CartesianGrid
							vertical={false}
							strokeDasharray='2 6'
							stroke='#e0e0e0'
						/>
						<XAxis
							dataKey='name'
							tickLine={false}
							tickSize={3}
							stroke='#e0e0e0'
							tick={{ fill: '#333437', fontSize: 10 }}
							minTickGap={25}
						/>
						<YAxis
							tickLine={false}
							tickSize={4}
							tick={{ fill: '#999999', fontSize: 10 }}
							stroke='#e0e0e0'
							tickFormatter={hideFirstTick}
							allowDecimals={false}
						/>
						<Brush
							dataKey='name'
							height={30}
							stroke='#efb815'
							travellerWidth={3}
						/>
						<ReferenceArea
							x1={getDeviationValue(
								POINTS.x1,
								flowSettingsStatistics,
								getAppliedFilters(filters),
								getMax(data),
								minValue,
							)}
							x2={getDeviationValue(
								POINTS.x2,
								flowSettingsStatistics,
								getAppliedFilters(filters),
								getMax(data),
								minValue,
							)}
							fill='#dddddd'
							stroke='#dddddd'
							label={renderDeviationLabel}
						/>
						<ReferenceArea
							x1={getDeviationValue(
								POINTS.x11,
								flowSettingsStatistics,
								getAppliedFilters(filters),
								getMax(data),
								minValue,
							)}
							x2={getDeviationValue(
								POINTS.x22,
								flowSettingsStatistics,
								getAppliedFilters(filters),
								getMax(data),
								minValue,
							)}
							fill='#eeeeee'
							stroke='#dddddd'
						/>
						<ReferenceLine
							x={getMedianValue(
								flowSettingsStatistics,
								getAppliedFilters(filters),
							)}
							stroke='black'
							label={renderMedianLabel}
						/>
						<Bar dataKey='value' fill='#efb815' />
						<Tooltip
							content={<TooltipContent data={data} filters={filters} />}
						/>
					</BarChart>
				</ResponsiveContainer>
			</div>
			<SystemPowerDialog
				isOpen={isDialogOpen}
				model={modelData}
				data={flowSettingsStatistics}
				closeDialog={() => setDialogState(false)}
			/>
		</div>
	);
};

SystemPower.defaultProps = {
	minValue: 60,
	step: 0.25,
};

SystemPower.propTypes = {
	minValue: PropTypes.number,
	step: PropTypes.number,
	data: PropTypes.object.isRequired,
};

export default withTranslation()(SystemPower);
