import React from 'react';
import T from 'prop-types';

const Document = (props) => (
	<svg {...props} className={props.className}>
		<path
			fillRule='evenodd'
			d='M19.84 24.398c0 .834-.669 1.511-1.493 1.511h-7.694a1.502 1.502 0 0 1-1.492-1.511c0-.835.668-1.512 1.492-1.512h7.694c.824 0 1.492.677 1.492 1.512zM3.411 34.545V3.455h10.235v10.329c0 .954.724 1.761 1.666 1.761h10.275v19H3.412zm22.172-22.454H17.06V3.455h.076l8.45 8.636zm2.92-1.961L19.054.51a1.694 1.694 0 0 0-1.21-.51H1.706C.764 0 0 .773 0 1.727v34.546C0 37.227.764 38 1.706 38h25.588c.942 0 1.706-.773 1.706-1.727V11.347c0-.456-.178-.894-.496-1.217z'
		/>
	</svg>
);

Document.defaultProps = {
	fill: '#000',
	width: 29,
	height: 38,
	viewBox: '0 0 29 38',
};

Document.propTypes = {
	className: T.string,
};

export default Document;
