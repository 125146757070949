import React from 'react';
import T from 'prop-types';

const Dashboard = (props) => (
	<svg {...props} className={props.className}>
		<path
			fillRule='evenodd'
			d='M3.618 13.726c.524 4.087 4.205 7.263 8.653 7.263 4.807 0 8.718-3.911 8.718-8.718 0-4.368-3.234-8.109-7.263-8.65v9.1c0 .555-.45 1.005-1.005 1.005H3.617zM12.27 23C6.355 23 1.542 18.389 1.542 12.72c0-.555.45-1.005 1.006-1.005h9.167V2.548c0-.556.45-1.006 1.006-1.006C18.293 1.542 23 6.455 23 12.271 23 18.187 18.187 23 12.271 23zM1.817 7.532h5.715V1.817a6.667 6.667 0 0 0-5.715 5.715zm6.595 1.76H.88a.88.88 0 0 1-.88-.88C0 3.773 3.774 0 8.412 0a.88.88 0 0 1 .88.88v7.532a.88.88 0 0 1-.88.88z'
		/>
	</svg>
);

Dashboard.defaultProps = {
	fill: '#999',
	width: 24,
	height: 24,
	viewBox: '0 0 24 24',
};

Dashboard.propTypes = {
	className: T.string,
};

export default Dashboard;
