import { coreInstance as axios, api } from 'settings';
import { logger } from 'helpers';
import * as actionTypes from './actionTypes';

const getStatesStart = () => {
	return {
		type: actionTypes.GET_STATES_START,
	};
};

const getStatesSuccess = (data) => {
	return {
		type: actionTypes.GET_STATES_SUCCESS,
		data: data,
	};
};

export const getStates = (args = {}) => {
	return (dispatch) => {
		dispatch(getStatesStart());

		if (args.params) {
			args.params.orderBy = 'state_name';
		} else {
			args.params = { orderBy: 'state_name' };
		}

		axios
			.get(api.states.index, { params: args.params })
			.then((response) => {
				logger('[Action] Get states');

				dispatch(getStatesSuccess(response.data.data));
			})
			.catch(() => {
				logger('[Action] Get states fail');
			});
	};
};
