import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Table, Spinner, Button, DetailsPanel } from 'components';
import { routers } from 'settings';
import { logger } from 'helpers';
import manageProvider from '../Configs/manageProvider';
import blockedUsers from '../Configs/blockedUsers';
import { Back, EditIcon as Edit } from 'components/Icons';
// TrashBin
import s from '../style.module.scss';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

let PATIENT_DETAILS_FIELDS = [
	{
		key: 'email_address',
		label: 'Provider Email-Id',
		type: 'text',
		translateKey: 'provider_email_id',
	},
	{
		key: 'phone_number',
		label: 'Phone Number',
		type: 'text',
		translateKey: 'provider_phone',
	},
	{
		key: 'customer_care_number',
		label: 'Customer Care Number',
		type: 'text',
		translateKey: 'customer_care_number',
	},
	{
		key: 'address_1',
		label: 'Address',
		type: 'text',
		translateKey: 'address',
	},
	{
		key: 'postal_code',
		label: 'PIN Code',
		type: 'text',
		translateKey: 'pinCode',
	},
];
class Provider extends Component {
	componentDidMount() {
		logger('[ManageUsers Provider] componentDidMount');

		const { data, match, onInit, clear } = this.props;

		clear();

		if (!this.isLoaded) {
			this.isLoaded = data;
		}

		if (match.params.id === '1') {
			this.isInogen = true;
		}

		onInit(match.params.id);
	}

	render() {
		const {
			data,
			usersPendingData,
			currentUser,
			history,
			match,
			changeUsersPendingStatus,
			changeManageUsersStatus,
			openModal,
		} = this.props;

		let providerView = <Spinner />;
		if (data) {
			const usersByRoles = data.users_by_roles;
			const blockedUsersData =
				usersPendingData && usersPendingData.filter((el) => !el.status);
			providerView = (
				<Fragment>
					<div className={s.providerWrapper}>
						<div className={s.providerTitle}>
							<div className={s.displayFlex}>
								<Link className={s.backBtn} to={routers.users}>
									<span>
										<Back width='40' fill='#000' height='40' />
									</span>
								</Link>
								<span className={[s.pageTitle, s.providerTitle].join(' ')}>
									{data.name || i18next.t('provider_name')}
								</span>
							</div>
							<div className={s.displayFlex}>
								{/* <Button
									className={s.editButton}
									btnType="type-23"
									clicked={() =>
										openModal({
											type: "DeleteProvider",
											data,
											restrictClosing: true,
										})
									}
								>
									<TrashBin fill={"#6c7ddf"} />
									{i18next.t("delete")}
								</Button> */}

								<Button
									className={s.editButton}
									btnType='type-20'
									clicked={() =>
										history.push(routers.usersProviderEdit(match.params.id))
									}
								>
									{i18next.t('edit')}
									<Edit
										fill={'white'}
										style={{ float: 'left', marginLeft: '8px' }}
									/>
								</Button>
							</div>
						</div>
						<div className={s.providerDetails}>
							<DetailsPanel
								mapper={PATIENT_DETAILS_FIELDS}
								data={data}
								style={{
									boxShadow: '0px 4px 8px 0px rgba(0,0,0,0.2)',
								}}
							/>
						</div>
						{usersByRoles && !Array.isArray(usersByRoles) ? (
							Object.keys(data.users_by_roles).map((el, index) => {
								if (usersByRoles[el].length) {
									return (
										<Fragment key={index}>
											<div className={s.tableContent}>
												<h2 className={s.listTitle}>
													{i18next.t(el)} ({usersByRoles[el].length})
												</h2>
												<Table
													className={[s.largeTable, s.providerTable].join(' ')}
													config={manageProvider}
													data={usersByRoles[el]}
													currentUser={currentUser}
													changeUsersStatus={changeManageUsersStatus}
													onRowClicked={(row, index) =>
														openModal({
															type: 'UserDetails',
															subType: 'byProvider',
															data: row,
															index: index,
															restrictClosing: true,
														})
													}
												/>
											</div>
										</Fragment>
									);
								} else {
									return null;
								}
							})
						) : (
							<p className={s.noUsersText}>
								{i18next.t('there_are_no_users_for_this_provider')}
							</p>
						)}

						{this.isInogen && (
							<Fragment>
								<h2 className={s.listTitle}>{i18next.t('blocked')}</h2>
								<Table
									className={[s.largeTable, s.providerTable].join(' ')}
									config={blockedUsers}
									data={blockedUsersData}
									currentUser={currentUser}
									changeUsersPendingStatus={changeUsersPendingStatus}
									onRowClicked={(row) =>
										openModal({ type: 'PendingRequest', data: row })
									}
								/>
							</Fragment>
						)}
					</div>
				</Fragment>
			);
		}

		return <Fragment>{providerView}</Fragment>;
	}
}

Provider.defaultProps = {
	data: {},
};

Provider.propTypes = {
	data: PropTypes.object,
};

export default withTranslation()(Provider);
