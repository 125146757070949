/* eslint-disable eqeqeq */
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Modal, Spinner } from 'components';
import { NavigationIcon, WarningIcon, Refresh } from 'components/Icons';

import s from './style.module.scss';
import ListView from '../../../components/ListView';
// import MOCK_DATA from "../../../mocks/errors-mock-data.json";
import SelectComponent from '../../../components/UI/SelectComponent';
import * as actions from '../../../store/actions/patientData';
import i18next from 'i18next';

const options = {
	year: 'numeric',
	month: 'short',
	day: 'numeric',
	hour: '2-digit',
	minute: '2-digit',
	second: '2-digit',
};

export const columnProps = [
	// { Header: "Id", accessor: "id", sort: true },
	{ Header: i18next.t('errors'), accessor: 'error_description', sort: true },
	{
		Header: i18next.t('dateandtime'),
		accessor: 'happened_at',
		Cell: ({ value }) => {
			const date = new Date(value);
			const year = date.getFullYear();
			let month = date.toLocaleString(i18next.language, { month: 'short' });
			month = month.replace('.', '');
			const capitalizedMonth = month.charAt(0).toUpperCase() + month.slice(1);
			const day = date.getDate().toString().padStart(2, '0');
			const time = date.toLocaleTimeString('en-US', { options });
			return `${capitalizedMonth} ${day} ${year}, ${time}`;
		},
		sort: true,
	},
];

function PatientErrorDetails(props) {
	const [selectedError, setSelectedError] = useState(null);
	const [clickedRow, setClickedRow] = useState(null);
	const [pageSize, setPageSize] = useState(50);
	const [selectedErrorType, setSelectedErrorType] = useState([]);
	const [resolveBtnStatus, setResolveBtnStatus] = useState(false);
	const [showResolveModal, setShowResolveModal] = useState(false);
	const [errorOptions, setErrorOptions] = useState(null);
	const [sortColumn, setSortColumn] = useState('happened_at');
	const [sortDirection, setSortDirection] = useState('desc');
	const [hasLoadMore, sethasLoadMore] = useState(false);
	const [previousSelectedErrorCode, setPreviousSelectedErrorCode] =
		useState(null);
	const [isRefresh, setIsRefresh] = useState(false);
	const [targetDiv, setTargetDiv] = useState(null);
	const targetRowRef = useRef(null);
	let {
		selectedErrorId,
		onShowHistory,
		getPatientDeviceErrors,
		resolveErrors,
		SelectedPatientDetails,
		selectedPatientDeviceErrors,
		errorCodes,
		loadingSelectedPatientDeviceErrors,
		resolvingError,
		getTroubleShootingGuide,
		selectedErrorTroubleshootingGuide,
		loadingErrorTroubleshootingGuide,
	} = props;
	let listView = <Spinner className={s.spinner} />;

	// It will convert errorCodes object to array of objects
	useEffect(() => {
		const combinedArray = errorCodes?.reduce((eachItem, currentItem) => {
			const existingObjIndex = eachItem.findIndex(
				(obj) => obj.code_string === currentItem.code_string,
			);

			if (existingObjIndex !== -1) {
				eachItem[existingObjIndex].value += `,${
					currentItem.code + ':' + currentItem.id
				}`;
			} else {
				eachItem.push({
					code: currentItem.code,
					code_string: currentItem.code_string,
					value: currentItem.code + ':' + currentItem.id,
					concentrator_model_id: currentItem.concentrator_model_id,
					label: currentItem.description,
					description_engineer: currentItem.description_engineer,
					id: currentItem.id,
					type: currentItem.type,
				});
			}
			return eachItem;
		}, []);

		setErrorOptions(combinedArray);
	}, [errorCodes]);

	// It will fetch the errors for the selected concentrator, filter, sort etc
	useEffect(() => {
		const params = {
			search: {
				concentrator_profile_id:
					SelectedPatientDetails?.concentrator_profile_id,
				// concentrator_profile_id: 619,
			},
			searchFields: {
				concentrator_profile_id: 'in',
				code: 'in',
			},
			limit: pageSize,
			sortedBy: sortDirection,
			orderBy: sortColumn,
		};

		let errorCodes = [];
		let errorIds = [];
		if (selectedErrorType.length > 0) {
			selectedErrorType.forEach((item) => {
				let codeString = item.split(',');
				codeString.forEach((i) => {
					let parts = i.split(':');
					if (parts.length > 1) {
						let code = parts[0];
						let id = parts[1];
						errorCodes.push(code);
						errorIds.push(id);
					}
				});
			});
		}

		var uniqueErrorIds = errorIds.filter(
			(item, index) => errorIds.indexOf(item) === index,
		);

		if (errorCodes?.length) {
			params.search.code = errorCodes.join(',');
		}
		if (uniqueErrorIds?.length) {
			params.search.error_id = uniqueErrorIds.join(',');
			params.searchFields.error_id = 'in';
		}
		if (SelectedPatientDetails?.concentrator_profile_id) {
			getPatientDeviceErrors(params);
		}
	}, [
		getPatientDeviceErrors,
		SelectedPatientDetails.concentrator_profile_id,
		sortColumn,
		sortDirection,
		selectedErrorType,
		pageSize,
		isRefresh,
	]);

	// It will filter and set the selected error in the state
	useEffect(() => {
		setResolveBtnStatus(false);
		if (
			selectedPatientDeviceErrors?.length > 0 &&
			selectedPatientDeviceErrors?.some(
				(eachError) => eachError?.is_resolved === false,
			)
		) {
			setResolveBtnStatus(true);
		}
		sethasLoadMore(false);
		// on error item click logic
		if (
			selectedPatientDeviceErrors?.length &&
			selectedErrorId !== ':error_id'
		) {
			let filteredError = selectedPatientDeviceErrors.filter(
				(eachError) => eachError.id == selectedErrorId,
			);
			if (filteredError?.length) {
				setSelectedError(filteredError[0]);
				setClickedRow(filteredError[0]?.id);
			}
		}
		// on patient click logic
		if (
			selectedErrorId === ':error_id' &&
			selectedPatientDeviceErrors?.length
		) {
			if (selectedPatientDeviceErrors[0]?.is_resolved === false) {
				setSelectedError(selectedPatientDeviceErrors[0]);
				setClickedRow(selectedPatientDeviceErrors[0].id);
			}
		}
	}, [selectedPatientDeviceErrors, selectedErrorId]);
	// console.log("isRefresh....", isRefresh);
	// It will fetch the troubleshooting guide for the selected error
	useEffect(() => {
		if (
			selectedError?.error_id &&
			(previousSelectedErrorCode !== selectedError?.error_id ||
				previousSelectedErrorCode === null)
		) {
			setPreviousSelectedErrorCode(selectedError?.error_id);
			getTroubleShootingGuide({ error_id: selectedError?.error_id });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedError, getTroubleShootingGuide]);

	// Autoscrolling to the selected error
	useEffect(() => {
		const container = document.querySelector(`.${s.errorDetailsListcontainer}`);
		const target = targetRowRef?.current;

		if (target && container) {
			const targetOffsetTop = target.offsetTop - 42;
			if (typeof container.scrollTo === 'function') {
				container.scrollTo({
					top: targetOffsetTop,
					behavior: 'smooth',
				});
			} else {
				container.scrollTop = targetOffsetTop;
			}
		}
	}, [targetDiv]);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(() => {
		if (targetRowRef && targetRowRef.current) {
			const target = targetRowRef.current;
			setTargetDiv(target);
		}
	});

	const calcRowColor = (row) => {
		return row.is_resolved === true ? s['itemDisabled'] : ''; // Define a CSS class for highlighting
	};

	const handleScroll = (e) => {
		const element = e.target;

		// Calculate how far the user has scrolled from the top
		const scrollPosition = element.scrollTop;

		// Calculate the maximum scroll position (total scrollable height - container height)
		const maxScroll = element.scrollHeight - element.clientHeight;

		// Calculate the threshold as a percentage of the container's height
		const thresholdPercentage = 10; // You can adjust this percentage as needed
		const threshold = (element.clientHeight * thresholdPercentage) / 100;

		// Check if the user has scrolled to the bottom within the threshold
		if (maxScroll - scrollPosition <= threshold && !hasLoadMore) {
			sethasLoadMore(true);
			setPageSize((prevPageSize) => prevPageSize + 15);
		}
	};

	const modalCloseHandler = () => setShowResolveModal(false);
	const modalOpenHandler = () => setShowResolveModal(true);
	const handleErrorTypeChange = (value) => {
		setSelectedErrorType(value);
	};
	const handleRowClick = (row) => {
		setClickedRow((prevSelectedRow) => {
			return prevSelectedRow === row.id ? null : row.id;
		});
		setSelectedError(row);
	};
	const handleSortChange = (sortColumn, sortDirection) => {
		setSortColumn(sortColumn);
		setSortDirection(sortDirection);
	};

	if (SelectedPatientDetails?.concentrator_profile_id == null) {
		listView = <div className={s.noDataFound}>No Concentrator Found!</div>;
	}
	if (
		selectedPatientDeviceErrors &&
		Array.isArray(selectedPatientDeviceErrors)
	) {
		let errorDescription = selectedPatientDeviceErrors.map((item) => {
			return {
				...item,
				error_description: i18next.t(item.error_description),
			};
		});

		listView = (
			<ListView
				data={errorDescription}
				columnSettings={columnProps}
				pageSize={pageSize}
				defaultSortColumn={sortColumn}
				defaultSortColumnDirection={sortDirection}
				className={s.table}
				infiniteScroll={true}
				staticData={false}
				stylingProps={s}
				calcRowColor={calcRowColor}
				onClickChange={handleRowClick}
				onSortChange={handleSortChange}
				clickedRow={clickedRow}
				targetRowRef={targetRowRef}
			/>
		);
	}

	if (loadingSelectedPatientDeviceErrors === true && hasLoadMore === false) {
		listView = <Spinner className={s.spinner} />;
	}

	const handleResolveAllErrors = async () => {
		const paramsForErrorReq = {
			search: {
				concentrator_profile_id:
					SelectedPatientDetails?.concentrator_profile_id,
				// concentrator_profile_id: 619,
			},
			searchFields: {
				concentrator_profile_id: 'in',
				code: 'in',
			},
			limit: pageSize,
			sortedBy: sortDirection,
			orderBy: sortColumn,
		};
		if (selectedErrorType?.length) {
			paramsForErrorReq.search.code = selectedErrorType.join(',');
		}

		const params = {
			concentrator_profile_id: SelectedPatientDetails?.concentrator_profile_id,
			getErrorRequest: paramsForErrorReq,
			modalCloseHandler: modalCloseHandler,
		};
		resolveErrors(params);
	};

	let selectedErrorDescription = {
		...selectedError?.error_description,
		error_description: i18next.t(selectedError?.error_description),
	};

	let translateError = errorOptions?.map((item) => {
		return {
			...item,
			label: i18next.t(item.label),
		};
	});
	function handleFields(errorObject) {
		return errorObject
			? {
					...errorObject,
					description: errorObject.description
						? i18next.t(errorObject.description)
						: '',
					steps: errorObject.steps ? i18next.t(errorObject.steps) : '',
			  }
			: null;
	}

	let handleSelectedErrorTroubleshootingGuide = handleFields(
		selectedErrorTroubleshootingGuide,
	);

	return (
		<>
			<Modal
				className={s.confirmationModal}
				show={showResolveModal}
				modalClosed={modalCloseHandler}
				restrictClosing={true}
			>
				<div>
					<div className={s.modalHeader}>
						<div className={s.modalHeadeerIcon}>
							<WarningIcon />
						</div>
						<div className={s.modalHeaderTitle}>
							{i18next.t("resolve_error's")}
						</div>
						<div className={s.closeButton} onClick={() => modalCloseHandler()}>
							<svg
								xmlns='http://www.w3.org/2000/svg'
								width='36'
								height='36'
								viewBox='0 0 36 36'
								fill='none'
							>
								<path
									d='M9.42478 28.325L7.6748 26.5751L16.2498 18L7.6748 9.42502L9.42478 7.67505L17.9998 16.2501L26.5748 7.67505L28.3248 9.42502L19.7498 18L28.3248 26.5751L26.5748 28.325L17.9998 19.75L9.42478 28.325Z'
									fill='#6D6D6D'
								/>
							</svg>
						</div>
					</div>
					<div className={s.modalMessage}>
						{i18next.t('resolve_all_errors')}
					</div>
					<div className={s.modalBtns}>
						<button className={s.modalDeclineBtn} onClick={modalCloseHandler}>
							{i18next.t('no')}
						</button>
						<button
							className={s.modalConfirmBtn}
							onClick={handleResolveAllErrors}
						>
							{resolvingError && resolvingError === true ? (
								<>
									<Spinner className={s.spinnerForBtn} small />
								</>
							) : (
								i18next.t('yes')
							)}
						</button>
					</div>
				</div>
			</Modal>
			<div className={s.errorDetailsTopbar}>
				<div className={s.errorDetailsTitlebar}>
					<div className={s.errorsDetailsFilter}>
						<div className={s.errorDetailsHeader}>
							{i18next.t('error_history')}
						</div>
						<div className={s.errorDetailsHistoryBtn} onClick={onShowHistory}>
							<NavigationIcon />
						</div>
					</div>
					<div className={s.refreshAlign}>
						<Refresh
							fill='#6C7DDF'
							onClick={() => {
								setIsRefresh(!isRefresh);
							}}
							style={{ cursor: 'pointer' }}
						/>
						<SelectComponent
							options={translateError}
							value={selectedErrorType}
							onChange={handleErrorTypeChange}
							isMulti={true}
							placeholder={i18next.t('error_type_all')}
							className={s.selectfilterStyling}
						/>
					</div>
				</div>
				<div className={s.errorDetailsCta}>
					<button
						className={s.errorDetailsResolveBtn}
						disabled={
							selectedPatientDeviceErrors?.length == 0 || !resolveBtnStatus
						}
						onClick={() => modalOpenHandler()}
					>
						{i18next.t('resolve')}
					</button>
				</div>
			</div>
			<div className={s.errorDetailsContent}>
				<div className={s.errorDetailsHolder}>
					{hasLoadMore && selectedPatientDeviceErrors?.length && (
						<div className={s.loadMoreSpinner}>
							<Spinner />
						</div>
					)}
					<div
						className={s.errorDetailsListcontainer}
						onScroll={(e) => handleScroll(e)}
					>
						{listView}
					</div>
					<div className={s.errorDetialsTroubleShootContainer}>
						<div className={s.errorDetailsTroubleShootHeader}>
							{i18next.t('troubleshooting_guide')}
						</div>

						<div className={s.errorDetailsTroubleShootBody}>
							<div className={s.errorDetailsTSHeader}>
								{selectedErrorDescription?.error_description}
							</div>
							{loadingErrorTroubleshootingGuide === false &&
							selectedErrorTroubleshootingGuide?.description ? (
								<>
									<div>
										<div className={s.errorDetailsTSTitle}>
											{i18next.t('description_of_error')}
										</div>
										<p className={s.errorDetailsTSPara}>
											{handleSelectedErrorTroubleshootingGuide?.description}
										</p>
									</div>
									<div>
										<div className={s.errorDetailsTSTitle}>
											{i18next.t('resolution_steps')}
										</div>
										<div
											className={s.errorDetailsTroubleShootSteps}
											dangerouslySetInnerHTML={{
												__html: handleSelectedErrorTroubleshootingGuide?.steps,
											}}
										/>
									</div>
								</>
							) : (
								<>
									<div className={s.noDataFound}>
										{i18next.t('no_data_found')}
									</div>
								</>
							)}
						</div>
						{loadingErrorTroubleshootingGuide === true &&
							!selectedErrorTroubleshootingGuide?.description && (
								<div>
									<Spinner />
								</div>
							)}
					</div>
				</div>
			</div>
		</>
	);
}

const mapStateToProps = (state) => {
	return {
		SelectedPatientDetails: state.patientData.SelectedPatientDetails,
		selectedPatientDeviceErrors: state.patientData.selectedPatientDeviceErrors,
		loadingSelectedPatientDeviceErrors:
			state.patientData.loadingSelectedPatientDeviceErrors,
		selectedErrorTroubleshootingGuide:
			state.patientData.selectedErrorTroubleshootingGuide,
		loadingErrorTroubleshootingGuide:
			state.patientData.loadingErrorTroubleshootingGuide,
		resolvingError: state.patientData.resolvingError,
		errorCodes: state.errors.errorCodes,
		loading: state.patientData.loading,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getPatientDeviceErrors: (args) =>
			dispatch(actions.getPatientDeviceErrors(args)),
		getTroubleShootingGuide: (args) =>
			dispatch(actions.getTroubleShootingGuide(args)),
		resolveErrors: (args) => dispatch(actions.resolveErrors(args)),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(PatientErrorDetails);
