import React, { PureComponent, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Input, Button } from 'components';

import s from './style.module.scss';
import { withTranslation } from 'react-i18next';

class ConfirmPinDelete extends PureComponent {
	state = {
		controls: {
			deleted: {
				elementType: 'input',
				elementConfig: {
					type: 'checkbox',
				},
				theme: 'checkbox',
				className: s.checkbox,
				value: false,
				valid: true,
				label: 'all_users_devices',
			},
		},
	};

	inputChangedHandler = (event) => {
		const target = event.target;
		const value = target.checked;

		this.setState((prevState) => {
			const state = { ...prevState.controls };
			const control = state.deleted;

			control.value = value;

			return { controls: state };
		});
	};

	confirmHandler = () => {
		const {
			modalData: { id, provider_id },
			confirmHandler,
			updateProviderHandler,
			close,
		} = this.props;
		const error = () => this.setState({ isDeleting: false });
		const success = () => {
			const args = { id: provider_id };

			updateProviderHandler(args);
			close();
		};

		this.setState({ isDeleting: true });

		confirmHandler({ id, success, error });
	};

	render() {
		const { modalData, close, t } = this.props;
		const { code_part_1, code_part_2, code_part_3 } = modalData;
		const {
			controls: { deleted },
			isDeleting,
		} = this.state;

		return (
			<Fragment>
				<h2 className={s.title}>
					{t('are_you_sure_you_would_like_to_delete_provider_pin_code')}
					<span>
						{' '}
						{code_part_1}-{code_part_2}-{code_part_3.slice(0, 2)}-
						{code_part_3.slice(-3)}{' '}
					</span>
				</h2>
				<div className={s.container}>
					<div className={s.field}>Warning: this cannot be undone.</div>
					<div className={s.field}>
						<Input
							label={t(deleted.label)}
							theme={deleted.theme}
							className={deleted.className}
							elementType={deleted.elementType}
							elementConfig={deleted.elementConfig}
							checked={deleted.value}
							changed={this.inputChangedHandler}
						/>
					</div>
					<div className={s.buttons}>
						<Button
							className={s.deleteBtn}
							btnType={'type-10'}
							disabled={!deleted.value || isDeleting}
							clicked={this.confirmHandler}
						>
							{t('yes_delete_this_pin_code')}
						</Button>
						<Button className={s.cancelBtn} btnType={'type-11'} clicked={close}>
							{t('cancel_keep_pin_code')}
						</Button>
					</div>
				</div>
			</Fragment>
		);
	}
}

ConfirmPinDelete.defaultProps = {
	modalData: {},
	confirmHandler: () => {},
};

ConfirmPinDelete.propTypes = {
	modalData: PropTypes.object,
	confirmHandler: PropTypes.func,
};

export default withTranslation()(ConfirmPinDelete);
