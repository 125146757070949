import React from 'react';
import T from 'prop-types';

const HistoryIcon = (props) => (
	<svg {...props} className={props.className}>
		<path
			d='M8.98075 17.5C6.81537 17.5 4.9282 16.7871 3.31923 15.3615C1.71026 13.9359 0.783989 12.1487 0.540405 9.99998H2.07113C2.3237 11.7269 3.10094 13.1586 4.40285 14.2952C5.70479 15.4317 7.23075 16 8.98075 16C10.9308 16 12.5849 15.3208 13.9433 13.9625C15.3016 12.6041 15.9808 10.95 15.9808 8.99998C15.9808 7.04998 15.3016 5.39581 13.9433 4.03748C12.5849 2.67914 10.9308 1.99998 8.98075 1.99998C7.88845 1.99998 6.86474 2.2426 5.90961 2.72785C4.95449 3.2131 4.13206 3.88073 3.44231 4.73073H6.05768V6.2307H0.98083V1.15383H2.48078V3.52308C3.29231 2.56539 4.2641 1.82213 5.39615 1.29328C6.5282 0.764425 7.72307 0.5 8.98075 0.5C10.1602 0.5 11.2654 0.723084 12.2961 1.16925C13.3269 1.6154 14.2256 2.2218 14.9923 2.98845C15.7589 3.75512 16.3653 4.65383 16.8115 5.6846C17.2576 6.71537 17.4807 7.82049 17.4807 8.99998C17.4807 10.1795 17.2576 11.2846 16.8115 12.3154C16.3653 13.3461 15.7589 14.2448 14.9923 15.0115C14.2256 15.7782 13.3269 16.3846 12.2961 16.8307C11.2654 17.2769 10.1602 17.5 8.98075 17.5ZM11.9827 13.0269L8.25961 9.30383V3.99998H9.75958V8.69613L13.0365 11.9731L11.9827 13.0269Z'
			fill='#6C7DDF'
		/>
	</svg>
);

HistoryIcon.defaultProps = {
	fill: 'none',
	width: 18,
	height: 18,
	viewBox: '0 0 18 18',
};

HistoryIcon.propTypes = {
	className: T.string,
};

export default HistoryIcon;
