import React from 'react';
import { uniqueClass } from 'config';

import s from './style.module.scss';

const Button = (props) => (
	<button
		disabled={props.disabled}
		data-testid='btn-test-id'
		className={[
			s.btn,
			s[uniqueClass],
			s[props.btnType],
			s[props.btnTheme],
			props.className,
		].join(' ')}
		onClick={props.clicked}
	>
		{props.children}
	</button>
);

export default Button;
