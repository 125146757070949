import React, { memo, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { Info } from 'components/Icons';
import { uniqBy } from 'lodash';

import ps from '../style.module.scss';
import s from './style.module.scss';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';

const UserDevice = (props) => {
	const [tooltip, setTooltip] = useState({});
	const {
		data,
		names: {
			device_types = [],
			os_types = [],
			device_names = [],
			device_groups = [],
		},
	} = props;
	const totalAmount = data.reduce((acc, val) => (acc += val.amount), 0);
	const deviceTypes = [...device_types];
	const osTypes = [...os_types];

	let deviceNames = [...device_names];
	let timer;

	const overHandler = (e, el = []) => {
		clearTimeout(timer);
		setTooltip({
			data: el.sort((a, b) => (a.piece >= b.piece ? -1 : 1)),
			x: e.target.offsetLeft,
			w: e.target.offsetWidth,
		});
	};
	const outHandler = () => (timer = setTimeout(() => setTooltip({}), 200));

	osTypes.forEach((el) => {
		el.amount = data.reduce(
			(acc, val) => (acc += val.os_type_id === el.id ? val.amount : 0),
			0,
		);
		return el;
	});
	osTypes.sort((a, b) => b.amount - a.amount);

	deviceTypes.forEach((el) => {
		el.amount = data.reduce(
			(acc, val) => (acc += val.device_type_id === el.id ? val.amount : 0),
			0,
		);
		return el;
	});
	deviceTypes.sort((a, b) => b.amount - a.amount);

	deviceNames.forEach((el) => {
		el.amount = data.reduce(
			(acc, val) => (acc += val.device_name_id === el.id ? val.amount : 0),
			0,
		);
		el.piece = +((el.amount / totalAmount) * 100);
		return el;
	});

	deviceNames = deviceNames.filter((e) => e.amount > 0);
	deviceNames = deviceNames.map((device) => {
		if (device.group_id) {
			const deviceGroup = device_groups.find(
				(group) => group.id === device.group_id,
			);
			device.amount = deviceNames.reduce((acc, val) => {
				return (acc += val.group_id === device.group_id ? val.amount : 0);
			}, 0);
			device.name = deviceGroup ? deviceGroup.name : device.name;

			return device;
		}

		return device;
	});
	deviceNames = uniqBy(deviceNames, 'name');

	deviceNames.sort((a, b) => b.amount - a.amount);

	// const mainDeviceNames = deviceNames.filter(el => el.piece > 4);
	// const otherDeviceNames = deviceNames.filter(el => el.piece <= 4);

	const mainDeviceNames = deviceNames.slice(0, 7);
	const otherDeviceNames = deviceNames.slice(7, deviceNames.length);
	const otherTotalAmount = otherDeviceNames.reduce(
		(acc, val) => (acc += val.amount),
		0,
	);
	const translateDeviceType = (deviceType) => {
		switch (deviceType) {
			case 'Mobile':
				return i18next.t('Mobile');
			case 'Tablet':
				return i18next.t('Tablet');
			default:
				return deviceType;
		}
	};

	const content = (
		<Fragment>
			<ul className={s.chartLine}>
				{osTypes.map((el, i) => (
					<li key={el.id}>
						<span className={s.label}>{el.name}</span>
						<span className={s.amount}>
							<i
								style={{
									width:
										i > 0
											? `${(el.amount / osTypes[0].amount) * 100}%`
											: '100%',
								}}
							/>
						</span>
						<div className={s.value}>
							{((el.amount / totalAmount) * 100)
								.toFixed(0)
								.replace(i18next.language === 'fr' ? '.' : 'x', ',')}{' '}
							%
						</div>
					</li>
				))}
			</ul>

			<ul className={s.chartLine}>
				{deviceTypes.map((el, i) => (
					<li key={el.id}>
						<span className={s.label}>{translateDeviceType(el.name)}</span>
						<span className={s.amount}>
							<i
								style={{
									width:
										i > 0
											? `${(el.amount / deviceTypes[0].amount) * 100}%`
											: '100%',
								}}
							/>
						</span>
						<div className={s.value}>
							{((el.amount / totalAmount) * 100)
								.toFixed(0)
								.replace(i18next.language === 'fr' ? '.' : 'x', ',')}{' '}
							%
						</div>
					</li>
				))}
			</ul>

			<div className={s.deviceLine} onMouseLeave={outHandler}>
				{tooltip.data && (
					<div
						className={s.tooltipWrapper}
						style={{ left: tooltip.x + tooltip.w }}
						onMouseLeave={outHandler}
					>
						<div className={s.tooltip}>
							<ul>
								{tooltip.data.map((el) => (
									<li key={el.id}>
										<span>
											{el.piece
												.toFixed(1)
												.replace(
													i18next.language === 'fr' ? '.' : 'x',
													',',
												)}{' '}
											%
										</span>
										{el.name}
									</li>
								))}
							</ul>
						</div>
					</div>
				)}

				<div className={s.line}>
					{mainDeviceNames.map((el) => (
						<span
							key={el.id}
							className={s[`piece${el.id}`]}
							style={{ width: `${el.piece}%` }}
							onMouseOver={(e) => overHandler(e, [el])}
						/>
					))}

					{!!otherTotalAmount && (
						<span
							className={s.pieceLast}
							onMouseOver={(e) => overHandler(e, otherDeviceNames)}
						/>
					)}
				</div>
			</div>

			<ul className={s.deviceLegend}>
				{mainDeviceNames.map((el) => (
					<li key={el.id} className={s[`piece${el.id}`]}>
						{el.name}
					</li>
				))}
				{!!otherTotalAmount && (
					<li className={s.other}>{i18next.t('other')}</li>
				)}
			</ul>
		</Fragment>
	);

	return (
		<div className={ps.twoThird}>
			<div className={ps.panel}>
				<h2 className={ps.title}>
					{i18next.t('user_device')}
					<Info
						fill='#a5bad4'
						data-tip=''
						data-for='UserDevice '
						className={ps.infoIconTrigger}
					/>
				</h2>

				<ReactTooltip
					id='UserDevice '
					className={ps.tooltip}
					place='bottom'
					type='light'
					effect='solid'
				>
					<Info fill='#a5bad4' className={ps.infoIconTooltip} />
					<p>{i18next.t('device_used_most_recently')}</p>
				</ReactTooltip>

				<div className={s.inner}>
					{data.length ? (
						content
					) : (
						<div className={s.noShow}>{i18next.t('nothing_to_show')}</div>
					)}
				</div>
			</div>
		</div>
	);
};

UserDevice.defaultProps = {
	data: [],
	names: {},
};

UserDevice.propTypes = {
	data: PropTypes.array,
	names: PropTypes.object,
};

export default memo(withTranslation()(UserDevice));
