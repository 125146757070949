import React from 'react';
import PropTypes from 'prop-types';
import { Button, Avatar } from 'components';

import s from './style.module.scss';

const UploadAvatar = (props) => {
	return (
		<div className={s.uploadAvatar}>
			<div className={s.uploadInputWrapper}>
				<label className={s.uploadLabel}>
					{props.uploadBtnText}
					<input
						className={s.uploadInput}
						type='file'
						name='img'
						accept='image/jpeg,image/png'
						onChange={props.onChange}
					/>
				</label>
				<span className={s.uploadDescription}>{props.description}</span>
				<Button
					className={s.removeImgBtn}
					btnType='type-16'
					clicked={props.onDeleteImg}
				>
					{props.removeBtnText}
				</Button>
			</div>
			<Avatar className={s.img} img={props.imgPreview} />
		</div>
	);
};

UploadAvatar.defaultProps = {
	uploadBtnText: 'Upload image',
	description: 'Please upload image',
	removeBtnText: 'Remove image',
};

UploadAvatar.propTypes = {
	description: PropTypes.string,
	removeBtnText: PropTypes.string,
	uploadBtnText: PropTypes.string,
	imgPreview: PropTypes.string,
};

export default UploadAvatar;
