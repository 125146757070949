import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
	data: null,
	loading: true,
};

const getRecentErrorStatusStart = (state) => {
	return updateObject(state, { loading: true });
};

const getRecentErrorStatusSuccess = (state, action) => {
	return updateObject(state, { data: action.data, loading: false });
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.RECENT_ERROR_STATUS_START:
			return getRecentErrorStatusStart(state, action);
		case actionTypes.RECENT_ERROR_STATUS_SUCCESS:
			return getRecentErrorStatusSuccess(state, action);

		default:
			return state;
	}
};

export default reducer;
