import React from 'react';
import s from './style.module.scss';

const setCoordinates = (
	auto,
	coordinates,
	offset,
	viewBox,
	tooltipWidth,
	tooltipHeight,
) => {
	if (!auto) {
		return `translate(${coordinates.x}px, ${coordinates.y}px)`;
	}

	const translateX = Math.max(
		coordinates.x + tooltipWidth + offset > 5 + viewBox.width
			? coordinates.x - tooltipWidth - offset
			: coordinates.x + offset,
		5,
	);

	const translateY = Math.max(
		coordinates.y + tooltipHeight + offset > 5 + viewBox.height
			? coordinates.y - tooltipHeight - offset
			: coordinates.y + offset,
		5,
	);

	return `translate(${translateX}px, ${translateY}px)`;
};

class Tooltip extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			style: {
				display: 'none',
			},
			mounted: false,
		};

		this.ref = React.createRef();
	}

	componentDidMount() {
		const { active } = this.props;

		this.setState((state) => {
			return {
				style: { ...state.style, display: active ? 'block' : 'none' },
			};
		});

		this.setState({
			mounted: true,
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (this.state.mounted === prevState.mounted) return;

		const { coordinates, auto, offset, viewBox, active } = this.props;

		const tooltipWidth = this.ref.current.clientWidth;
		const tooltipHeight = this.ref.current.clientHeight;

		this.setState((state) => {
			return {
				style: {
					...state.style,
					transform: setCoordinates(
						auto,
						coordinates,
						offset,
						viewBox,
						tooltipWidth,
						tooltipHeight,
					),
					display: active ? 'block' : 'none',
				},
			};
		});
	}

	render() {
		return (
			<div
				className={s.tooltip}
				style={this.state.style}
				ref={this.ref}
				onMouseLeave={this.props.onMouseLeave}
			>
				{this.props.children}
			</div>
		);
	}
}

Tooltip.defaultProps = {
	coordinates: {
		x: 0,
		y: 0,
	},
	auto: false,
	offset: 10,
};

export default Tooltip;
