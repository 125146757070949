import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
	data: null,
	hasMoreData: true,
	loading: false,
	rows: 0,
	requestParams: {
		with: ['user', 'pushType', 'pushNotificationSchedule'],
		orderBy: 'id',
		sortedBy: 'desc',
		limit: 40,
	},
};

const setPushNotificationsRequestParam = (state, action) => {
	return {
		...state,
		requestParams: {
			...state.requestParams,
			...action.params,
			search: {
				...state.requestParams.search,
				...action.params.search,
			},
			searchFields: {
				...state.requestParams.searchFields,
				...action.params.searchFields,
			},
		},
	};
};

const getPushNotificationsHistoryStart = (state) => {
	return updateObject(state, { loading: true });
};

const getPushNotificationsHistorySuccess = (state, action) => {
	if (action.isMerge) {
		const data = [...state.data, ...action.data];

		return updateObject(state, {
			data: data,
			hasMoreData: action.data.length >= state.requestParams.limit,
		});
	}

	return updateObject(state, {
		data: action.data,
		rows: action.rows,
		loading: false,
		hasMoreData: action.data.length >= state.requestParams.limit,
	});
};

const clearNotificationsHistory = () => {
	return initialState;
};

// const addNotificationHistory = (state, action) => {
//     const newData = [ ...state.data ];

//     newData.unshift(action.data);

//     return { data: newData };
// };

const history = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_PUSH_NOTIFICATIONS_HISTORY_START:
			return getPushNotificationsHistoryStart(state, action);
		case actionTypes.GET_PUSH_NOTIFICATIONS_HISTORY_SUCCESS:
			return getPushNotificationsHistorySuccess(state, action);

		case actionTypes.SET_PUSH_NOTIFICATIONS_REQUEST_PARAM:
			return setPushNotificationsRequestParam(state, action);

		case actionTypes.CLEAR_PUSH_NOTIFICATIONS:
			return clearNotificationsHistory(state, action);
		// case actionTypes.ADD_PUSH_NOTIFICATIONS_HISTORY: return addNotificationHistory(state, action);

		default:
			return state;
	}
};

export default history;
