import React, { useState, useEffect, useRef } from 'react';
import s from '../style.module.scss';

const Complete = (props) => {
	const [value, setValue] = useState(props.value || ''); // Use props.value as the initial value
	const [showSuggestions, setShowSuggestions] = useState(false);
	const suggestions = props.data
		? props.data.filter((data) =>
				data.toLowerCase().includes(value.toLowerCase()),
		  )
		: [];
	const autocompleteRef = useRef();
	const [isFrozen, setIsFrozen] = useState(false); // New state variable to manage freezing behavior

	useEffect(() => {
		const handleClick = (event) => {
			if (
				autocompleteRef.current &&
				!autocompleteRef.current.contains(event.target)
			) {
				setShowSuggestions(false);
			}
		};
		document.addEventListener('click', handleClick);
		return () => {
			document.removeEventListener('click', handleClick);
		};
	}, []);

	useEffect(() => {
		// Update the value if props.value changes (e.g., when the selected value changes)
		setValue(props.value || '');
	}, [props.value]);

	const handleChange = (event) => {
		if (!isFrozen) {
			// Check if the value is not frozen
			if (event.target.value === '') {
				props.onSubmit(event.target.value);
				setShowSuggestions(true);
			}
			setValue(event.target.value);
		}
	};

	const clear = () => {
		setShowSuggestions(true);
	};

	const handleSuggestionClick = (suggestion) => {
		if (!isFrozen) {
			// Check if the value is not frozen
			props.onSubmit(suggestion);
			setValue(suggestion);
			setShowSuggestions(false);
			setIsFrozen(true); // Freeze the value once a suggestion is selected
		}
	};

	return (
		<div className={s.selectList} ref={autocompleteRef}>
			<input
				value={value}
				onChange={handleChange}
				onClick={clear}
				className={s.select}
				placeholder={props.label}
				onFocus={() => setShowSuggestions(true)}
			/>
			{showSuggestions && (
				<ul className={s.list}>
					{suggestions.map((suggestion) => (
						<li
							onClick={() => handleSuggestionClick(suggestion)}
							key={suggestion}
						>
							{suggestion}
						</li>
					))}
				</ul>
			)}
		</div>
	);
};

export default Complete;
