import React from 'react';
import T from 'prop-types';

const Close = (props) => (
	<svg {...props} className={props.className}>
		<path d='M11.243 11.657l-4.95-4.95a1 1 0 0 1 1.414-1.414l4.95 4.95 4.95-4.95a1 1 0 0 1 1.414 1.414l-4.95 4.95 4.95 4.95a1 1 0 0 1-1.414 1.414l-4.95-4.95-4.95 4.95a1 1 0 1 1-1.414-1.414l4.95-4.95z' />
	</svg>
);

Close.defaultProps = {
	fill: '#000',
	width: 24,
	height: 24,
	viewBox: '0 0 24 24',
};

Close.propTypes = {
	className: T.string,
};

export default Close;
