import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { siteTitle } from 'config';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import i18n from './i18n';
import { I18nextProvider } from 'react-i18next';
import App from './App';

import store from 'store';
import { unregister } from './registerServiceWorker';

import './index.css';

document.title = siteTitle;

const app = (
	<Provider store={store}>
		<BrowserRouter>
			<I18nextProvider i18n={i18n}>
				<App />
			</I18nextProvider>
		</BrowserRouter>
	</Provider>
);

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(app, document.getElementById('root'));
unregister();
