import React from 'react';
import moment from 'moment';
import { Unplugged } from 'components/Icons';

import s from '../style.module.scss';
import 'react-toastify/dist/ReactToastify.css';
import i18next from 'i18next';

const HealthCheck = (props) => {
	const { healthCheck, info } = props;
	let healthCheckBody;
	let healthCheckInfo;

	if (healthCheck) {
		const groups = {};
		const others = [];

		healthCheck.forEach((item) => {
			if (item.group) {
				groups[item.group] = groups[item.group] || [];
				groups[item.group].push(item);
			} else {
				others.push(item);
			}
		});

		const groupsView = Object.keys(groups).map((el, index) => {
			return (
				<li key={index}>
					<h4>{i18next.t(el)}</h4>
					<ul>
						{groups[el]
							.filter((el) => {
								// remove charge current & battery_voltage
								return (
									el.name !== 'battery_current' && el.name !== 'battery_voltage'
								);
							})
							.map((el, index) => {
								let className = [s.item];
								let updatedAt = 'N/A';
								let icon = <i />;

								if (el.is_failed === true) className.push(s.failed);
								if (el.is_failed === false) className.push(s.check);
								if (el.is_actual !== true) className.push(s.disabled);
								if (el.updated_at) {
									let format = 'D MMM';

									if (moment().year() - moment(el.updated_at).year())
										format = 'D MMM YYYY';

									updatedAt = moment(el.updated_at).format(format);
								}

								if (
									el.name === 'external_voltage' &&
									parseInt(el.value, 10) === 0
								) {
									className = className.filter((el) => el !== s.failed);
									icon = <Unplugged className={s.icon} />;
								}

								return (
									<li key={index} className={className.join(' ')}>
										{icon}
										<span>{i18next.t(el.label)}</span>
										{el.is_actual || <span>{updatedAt}</span>}
									</li>
								);
							})}
					</ul>
				</li>
			);
		});

		const othersView = others.map((el, index) => {
			const className = [s.item];
			let updatedAt = 'N/A';

			if (el.is_failed === true) className.push(s.failed);
			if (el.is_failed === false) className.push(s.check);
			if (el.is_actual !== true) className.push(s.disabled);
			if (el.updated_at) {
				let format = 'D MMM';

				if (moment().year() - moment(el.updated_at).year())
					format = 'D MMM YYYY';

				updatedAt = moment(el.updated_at).format(format);
			}

			return (
				<li key={index} className={className.join(' ')}>
					<i></i>
					<span>{i18next.t(el.label)}</span>
					{el.is_actual || <span>{updatedAt}</span>}
				</li>
			);
		});

		healthCheckBody = (
			<ul className={s.healthcheckList}>
				{groupsView}
				{othersView}
			</ul>
		);
	} else {
		healthCheckBody = i18next.t('no_health_check_parameters_were_found');
	}

	if (info && info.length === 1) {
		healthCheckInfo = (
			<div className={s.info}>{i18next.t(info.toString())}</div>
		);
	} else if (info && info.length > 1) {
		healthCheckInfo = (
			<ol className={s.infoList}>
				{info && info.map((el, index) => <li key={index}>{el}</li>)}
			</ol>
		);
	}

	return (
		<div className={s.pane}>
			<div className={s.paneHead}>
				<h3>{i18next.t('healthcheck')}</h3>
			</div>
			<div className={s.paneBody}>
				{healthCheckBody}
				{healthCheckInfo}
			</div>
		</div>
	);
};

export default HealthCheck;
