const api = {
	authentication: {
		index: '/oauth/v2/token',
		microsoft: '/api/v1/auth/live',
		login: 'saml2/login',
		logout: '/logout',
		callback: '/auth/live/callback',
		email: '/password/email',
		reset: '/password/reset',
		change: '/password/change',
	},
	concentrator_data_logs: {
		index: '/concentrator_data_logs',
		download: '/concentrator_data_logs/download',
	},
	concentrator_errors: {
		index: '/concentrator_errors',
	},
	concentrator_error_recalls: {
		index: '/concentrator_error_recalls',
		error_status: '/concentrator_error_recalls/requestamonth',
		requestayear: '/concentrator_error_recalls/requestayear',
	},
	concentrator_error_statistics: {
		index: '/concentrator_error_statistics',
	},
	concentrator_firmwares: {
		index: '/concentrator_firmwares',
		download: '/concentrator_firmwares/download',
		latest: '/concentrator_firmwares/latest',
		copy: '/concentrator_firmwares/copy',
	},
	concentrator_firmware_histories: {
		index: '/concentrator_firmware_histories',
	},
	concentrator_firmware_statistics: {
		index: '/concentrator_firmware_statistics',
	},
	concentrator_models: {
		index: '/concentrator_models',
	},
	providers_notifiable: {
		index: '/providers_notifiable',
	},
	firmwares_notifiable: {
		index: '/firmwares_notifiable',
	},
	concentrator_profiles: {
		index: '/concentrator_profiles',
		create_or_update: '/concentrator_profiles/create_or_update',
		location: '/concentrator_profiles/location',
		notifications: '/concentrator_profiles/notifications',
		notifiable: '/concentrator_profiles/notifiable',
		statistics: '/concentrator_profiles/statistics',
		export: '/concentrator_profiles/export',
	},
	concentrator_locations: {
		index: '/concentrator_locations',
		all: '/concentrator_locations/all',
		request: '/concentrator_locations/request',
	},
	concentrator_notification_history: {
		index: '/concentrator_notification_history',
	},
	concentrator_mobile_device_pairing_history: {
		index: 'concentrator_mobile_device_pairing_history',
	},
	concentrator_connection_history: {
		index: 'concentrator_connection_history',
	},
	healthcheck_value_statistics: {
		index: 'healthcheck_value_statistics',
	},
	push_notification_history: {
		index: '/push_notification_history',
	},
	push_notification_types: {
		index: '/push_notification_types',
	},
	healthcheck_values: {
		index: '/healthcheck_values',
		create_or_update: '/healthcheck_values/create_or_update',
		summary: '/healthcheck_values/summary',
	},
	providers: {
		index: '/providers',
		generate_unique_code: '/providers/generate_unique_code',
	},
	provider_codes: {
		index: '/provider_codes',
		qrCode: '/provider_codes/qr_code',
	},
	settings: {
		index: '/settings',
	},
	user: {
		index: '/user',
	},
	users: {
		index: '/users',
		pending: '/users/pending',
	},
	roles: {
		index: '/roles',
	},
	states: {
		index: '/states',
	},
	timezones: {
		index: '/timezones',
	},
	cities: {
		index: '/cities',
		notifiable: '/cities/notifiable',
	},
	user_device_statistics: {
		index: '/user_device_statistics',
	},
	engineering_params_statistics: {
		index: '/engineering_params_statistics',
	},
	system_power: {
		index: '/engineering_power_flow_statistics',
	},
	engineering_params: {
		index: '/engineering_params',
	},
	engineering_vals: {
		index: '/engineering_vals',
	},
	validate_link: {
		index: '/validation/active_url',
	},
	battery_status: {
		index: '/concentrator_battery_statistics',
	},
	consents: {
		index: '/user_consent',
	},
	accept_consent: {
		index: '/user_consent',
	},
	get_terms_of_use: {
		index: '/terms_use',
	},
	patient_details: {
		// get_demographic_info: "/patient,
		get_demographic_info: '/instance',
		// search_patients: "/search_patients",
		search_patients: '/search',
		get_device_errors: '/concentrator_error_details',
		post_resolve_errors: '/resolve_errors',
		get_troubleshooting_guide: '/troubleshooting',
	},
	patient_dashboard: {
		get_unresolved_errors: '/concentrator_error_history_tracking',
		mark_resolved: '/resolve_errors',
	},
	web_translation: {
		index: '/web-translations',
	},
	db_statistics: {
		index: '/db_stats',
	},
};

export default api;
