import { coreInstance as axios, api } from 'settings';
import { logger } from 'helpers';
import * as actionTypes from '../actionTypes';

const getSearchProvidersStart = () => {
	return {
		type: actionTypes.GET_SEARCH_PROVIDERS_START,
	};
};

const getSearchProvidersSuccess = (data) => {
	return {
		type: actionTypes.GET_SEARCH_PROVIDERS_SUCCESS,
		data: data,
	};
};

export const getSearchProviders = (options, withLoader = true) => {
	return (dispatch) => {
		withLoader && dispatch(getSearchProvidersStart());

		axios
			.get(api.providers.index, { params: options })
			.then((response) => {
				logger('[Action] Get search providers');

				dispatch(getSearchProvidersSuccess(response.data.data));
			})
			.catch(() => {
				logger('[Action] Get search providers fail');
			});
	};
};
