import React from 'react';
import sLatest from '../newStyle.module.scss';
import i18next from 'i18next';

const RowTooltip = (props) => {
	const { row, onMouseOver, onMouseOut, modalOpen, left } = props;
	const { description, version } = row.data;
	const count = row.count;
	const deleteversion = row.version;
	let parsedDescription = [];
	try {
		parsedDescription = description ? JSON.parse(description) : [];
	} catch (e) {
		console.error('Failed to parse description:', e);
		parsedDescription = [description];
	}
	const decriptionCheck =
		Array.isArray(parsedDescription) &&
		parsedDescription.every((el) => el === null);

	return (
		<div
			style={
				count
					? {
							left: left
								? row.coords.left + window.scrollX - left
								: row.coords.left + window.scrollX,
							top: row.coords.top + window.scrollY,
					  }
					: { right: row.coords.left, top: row.coords.top }
			}
			className={count ? sLatest.deleteToolTip : sLatest.tableTooltip}
			onMouseOver={onMouseOver}
			onMouseOut={onMouseOut}
		>
			<h2>{version}</h2>

			<ol className={sLatest.list}>
				{decriptionCheck ? (
					<></>
				) : (
					parsedDescription?.map((el, i) => {
						if (i > 2 || !el) return null;

						return (
							<li key={i}>
								{el.substring(0, 110)}
								{el.length > 110 && '...'}
							</li>
						);
					})
				)}
			</ol>

			{count === 4 ? (
				<>
					<div className={sLatest.versioning}>{deleteversion}</div>
					<div className={sLatest.deleteSpacing}></div>
					<div className={sLatest.inactiveMassgae}>
						{i18next.t('testing_delete')}
					</div>
				</>
			) : (
				<></>
			)}

			{count === 1 || count === 2 || count === 3 ? (
				<>
					<div className={sLatest.versioning}>{deleteversion}</div>
					<div className={sLatest.deleteSpacing}></div>
					<div className={sLatest.inactiveMassgae}>
						{i18next.t('deleteInactive')}
					</div>
				</>
			) : (
				<></>
			)}

			{count > 0 ? (
				<></>
			) : (
				<div className={sLatest.moreDiv}>
					<button
						className={sLatest.more}
						onClick={() => modalOpen({ data: row.data, type: 'view' })}
					>
						{i18next.t('more')}
					</button>
				</div>
			)}
		</div>
	);
};

export default RowTooltip;
