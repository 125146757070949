import React, { PureComponent, Fragment } from 'react';
import ReactGA from 'react-ga';
import moment from 'moment';
import { Table, Modal, Button } from 'components';
import RecentError from './RecentError';
import HistoryList from './HistoryList';
import History from './History';
import AdvancedBlock from './AdvancedBlock';
import AdvancedDataDialog from './AdvancedDataDialog';
import HealthCheck from './HealthCheck';
import DataLogButton from './DataLogButton';
import DeviceInfo from './DeviceInfo';
import messages from './DeviceInfo/messages';
import { coreInstance as axios, api } from 'settings';
import { logger, toast, permissions } from 'helpers';
import { Back } from 'components/Icons';
import config from './config';
import s from './style.module.scss';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';

moment.updateLocale('en', {
	relativeTime: {
		future: '%s',
		past: `%s ${i18next.t('ago')}`,
		s: `${i18next.t(`last_connected`)} ${i18next.t('a_few_seconds')}`,
		ss: `${i18next.t(`last_connected`)} %d ${i18next.t('seconds')}`,
		m: `${i18next.t(`last_connected`)} ${i18next.t('a_minute')}`,
		mm: `${i18next.t(`last_connected`)} %d ${i18next.t('minutes')}`,
		h: `${i18next.t(`last_connected`)} ${i18next.t('an_hour')}`,
		hh: `${i18next.t(`last_connected`)} %d ${i18next.t('hours')}`,
		d: `${i18next.t(`last_connected`)} ${i18next.t('a_day')}`,
		dd: `${i18next.t(`last_connected`)} %d ${i18next.t('days')}`,
		M: `${i18next.t(`last_connected`)} ${i18next.t('a_month')}`,
		MM: `${i18next.t(`last_connected`)} %d ${i18next.t('months')}`,
		y: `${i18next.t(`last_connected`)} ${i18next.t('a_year')}`,
		yy: `${i18next.t(`last_connected`)} %d ${i18next.t('years')}`,
	},
});

class ItemModal extends PureComponent {
	state = {
		data: null,
		groupsData: null,
		recentErrors: null,
		loading: true,
		viewType: 'less',
	};

	bodyNode = document.querySelectorAll('body')[0];

	rootNode = document.getElementById('root');

	componentDidUpdate(prevProps) {
		const { itemId, groups } = this.props;
		const isChange = prevProps !== this.props;

		if (isChange) {
			if (itemId) {
				this.getData(itemId);
			} else if (groups) {
				this.getGroupData(groups);
			} else {
				this.setState({
					data: null,
					loading: true,
					history: null,
					advanced: null,
					viewType: 'less',
					isAllowUpdate: false,
				});
			}
		}
	}

	getGroupData = (ids) => {
		const params = {
			with: 'concentratorModel',
			search: {
				id: ids,
			},
			searchFields: {
				id: 'in',
			},
		};

		axios
			.get(api.concentrator_profiles.index, { params })
			.then((response) => {
				logger('[Action] Get profile groups modal');

				this.setState({ groupsData: response.data.data, loading: false });
			})
			.catch(() => {
				logger('[Action] Get profile groups modal fail');
			});
	};

	getData = (id, isUpdate) => {
		const getProfile = () => {
			const options = {
				with: [
					'concentratorModel',
					'concentratorFirmware',
					'provider',
					'concentratorErrorRecallLast.concentratorError',
					'concentratorDataLogsLast',
				],
				append: ['all_healthcheck_parameters'],
			};

			return axios.get(`${api.concentrator_profiles.index}/${id}`, {
				params: options,
			});
		};

		const getErrors = () => {
			const options = {
				with: 'concentratorError',
				search: {
					concentrator_profile_id: id,
					'concentratorError.error_type': 2,
				},
				searchFields: {
					concentrator_profile_id: '=',
					'concentratorError.error_type': '=',
				},
				orderBy: 'happened_at',
				sortedBy: 'desc',
				limit: 5,
			};

			return axios.get(api.concentrator_error_recalls.index, {
				params: options,
			});
		};

		const getLocation = () => {
			const params = {
				search: {
					concentrator_profile_id: id,
				},
			};

			return axios.get(api.concentrator_locations.request, { params });
		};

		Promise.all([getProfile(), getErrors(), getLocation()])
			.then((response) => {
				logger('[Action] Get profile modal');

				ReactGA.event({
					category: 'Concentrators',
					action: 'Click',
					label: i18next.t('concentrators_details_popup'),
				});

				const { concentrator_data_logs_last: dataLogs } = response[0].data.data;

				this.setState(
					{
						data: response[0].data.data,
						recentErrors: response[1].data.data,
						location: response[2].data.data,
						loading: false,
					},
					this.checkUpdate,
				);

				if (dataLogs) {
					const { temp_file_link: link } = dataLogs;

					if (!isUpdate) {
						axios
							.get(link.slice(7))
							.then((link) => {
								logger('[ItemModal] Get logs link');

								this.setState({
									logsLink: link.data.data,
								});
							})
							.catch(() => {
								logger('[ItemModal] Get logs link fail');
							});
					}
				}
			})
			.catch((err) => {
				logger('[Action] Get profile modal fail');
				console.log(err);
			});
	};

	historyHandler = (name, index) => {
		const { itemId, modelId } = this.props;
		let request = '';
		if (name === 'ErrorRecall') {
			request = {
				uri: api.concentrator_error_recalls.requestayear,
				params: {
					search: {
						concentrator_model_id: modelId,
						concentrator_profile_id: itemId,
					},
				},
			};
		} else {
			request = {
				uri: api.concentrator_firmware_histories.index,
				params: {
					with: 'concentratorFirmware',
					search: {
						concentrator_profile_id: itemId,
					},
					searchFields: {
						concentrator_profile_id: '=',
					},
					orderBy: 'id',
					sortedBy: 'desc',
					limit: 100,
				},
			};
		}

		if (name === 'pairing') {
			request.uri = api.concentrator_mobile_device_pairing_history.index;
			request.params.with = 'deviceName';
		}

		if (name === 'connection') {
			request.uri = api.concentrator_connection_history.index;
			request.params.with = null;
		}

		this.setState({
			history: {
				loading: true,
				active: index,
			},
		});

		axios
			.get(request.uri, { params: request.params })
			.then((response) => {
				this.setState({
					history: {
						loading: false,
						active: index,
						data: response.data.data,
						activeTabName: name,
					},
				});
			})
			.catch((err) => {
				console.log(err);
			});
	};

	advancedHandler = () => {
		const { data } = this.state;

		const params = {
			search: { concentrator_profile_id: data.id },
			with: 'concentratorFirmware',
		};

		this.setState({
			advanced: { loading: true },
		});

		Promise.all([
			axios.get(api.engineering_params.index),
			axios.get(api.engineering_vals.index, { params }),
		]).then((response) => {
			const lastUpdate =
				response[1].data &&
				response[1].data.data[0] &&
				moment(response[1].data.data[0].updated_at).format(
					'DD MMM YYYY, h:mm A',
				);
			this.setState({
				advanced: {
					loading: false,
					engineeringParams: response[0].data.data,
					engineeringVals: response[1].data.data,
					engineeringValsLasUpdate: lastUpdate,
				},
			});
		});
	};

	resetAdvancedHandler = () => {
		this.setState({ advanced: null });
	};

	sendUpdateNotification = (type) => {
		const { data } = this.state;
		let params = {
			title: i18next.t('update_software'),
			body: i18next.t('new_version_is_available_to_install'),
			concentrator_profiles_filter: `search=serial_number:${data.serial_number}&searchFields=serial_number:in`,
			push_type_id: 8,
		};
		const sendingReady = () => {
			setTimeout(() => {
				this.setState({ isSending: false });
			}, 5000);
		};

		if (type === 'logs') {
			params = {
				title: i18next.t('transfer_data'),
				body: i18next.t('please_perform_data_transfer'),
				concentrator_profiles_filter: `search=serial_number:${data.serial_number}&searchFields=serial_number:in`,
				push_type_id: 9,
			};
		}

		this.setState({ isSending: true });

		axios
			.post(api.push_notification_history.index, params)
			.then(() => {
				toast.success(messages.softwareUpdateSuccess);
				sendingReady();
			})
			.catch(() => {
				toast.error(messages.softwareUpdateError);
				sendingReady();
			});
	};

	checkUpdate = () => {
		const { serial_number, concentrator_model_id, concentrator_firmware } =
			this.state.data;

		if (concentrator_firmware) {
			const params = {
				concentrator_model_id,
				version: concentrator_firmware && concentrator_firmware.version,
			};

			const headers = {
				'X-CONCENTRATOR-SN': serial_number,
			};

			axios
				.get(api.concentrator_firmwares.latest, { params, headers })
				.then((response) => {
					logger('[Action] Get firmwares details');

					// eslint-disable-next-line no-prototype-builtins
					if (response.data.hasOwnProperty('data')) {
						this.setState({ isAllowUpdate: true });
					} else {
						this.setState({ isAllowUpdate: false });
					}
				})
				.catch(() => {
					logger('[Action] Get firmwares details fail');
				});
		}
	};

	modalHead = () => {
		const { data } = this.state;
		let lastConnect;

		if (data) {
			if (moment(data.connected_at).isBefore(moment().subtract(3, 'd'))) {
				lastConnect = `${i18next.t(`last_connected`)} ${moment(
					data.connected_at,
				).format('DD MMM YYYY')}`;
			} else {
				lastConnect = moment(data.connected_at).fromNow();
			}
		}

		if (data)
			return (
				<div className={s.head}>
					<div className={s.desc}>
						<h2>
							{`SN-${data.serial_number}`}
							<span>{lastConnect}</span>
						</h2>
						<p>{data.concentrator_model.description}</p>
					</div>
				</div>
			);
	};

	modalBody = () => {
		const {
			data,
			recentErrors,
			location,
			viewType,
			logsLink,
			isSending,
			isAllowUpdate,
		} = this.state;
		const { itemId } = this.props;
		const btnClasses = [s.btn];
		let moreContent;

		const clickHandler = () =>
			this.setState((prevState) => ({
				viewType: prevState.viewType === 'more' ? 'less' : 'more',
			}));

		const updateHandler = () => {
			this.getData(itemId, true);
		};

		if (viewType === 'more') btnClasses.push(s.btnLess);

		if (viewType === 'more') {
			moreContent = (
				<Fragment>
					<div className={s.col}>
						{data && (
							<HealthCheck
								healthCheck={data.all_healthcheck_parameters}
								info={data.healthcheck_summary_info}
							/>
						)}
						{data && (
							<DataLogButton
								logs={data.concentrator_data_logs_last}
								link={logsLink}
								isSending={isSending}
								sendUpdateNotification={this.sendUpdateNotification}
							/>
						)}
					</div>
					<div className={s.col}>
						{recentErrors && <RecentError data={recentErrors} />}
						<HistoryList checkHandler={this.historyHandler} />
						{permissions.check('concentrators.details.advanced') ? (
							<AdvancedBlock checkHandler={this.advancedHandler} />
						) : null}
					</div>
				</Fragment>
			);
		}

		if (data && recentErrors)
			return (
				<div className={s.body}>
					<div className={s.row}>
						<div className={s.colFull}>
							{data && (
								<DeviceInfo
									data={data}
									isAllowUpdate={isAllowUpdate}
									location={location}
									updateHandler={updateHandler}
									sendUpdateNotification={this.sendUpdateNotification}
									isSending={isSending}
								/>
							)}
						</div>
						{moreContent}
						<Button
							btnType={'type-4'}
							className={btnClasses.join(' ')}
							clicked={clickHandler}
						>
							{viewType === 'less'
								? i18next.t('show_more')
								: i18next.t('show_less')}
						</Button>
					</div>
				</div>
			);
	};

	groupsView = () => {
		const { groupsData } = this.state;
		const { onRowClicked } = this.props;

		let groupsLenght = 0;

		if (groupsData) {
			groupsLenght = groupsData.length;
		}

		const clickHandler = (row) => {
			this.setState({ loading: true }, () => onRowClicked(row));
		};

		return (
			<div className={s.tableWrap}>
				<div className={s.infoTable}>
					{groupsLenght} {i18next.t('concentrators')}
				</div>
				<Table
					className={s.table}
					config={config}
					data={groupsData}
					onRowClicked={clickHandler}
				/>
			</div>
		);
	};

	render() {
		const { data, history, loading, advanced } = this.state;
		const { itemId, groups, t } = this.props;

		let content = groups ? (
			this.groupsView()
		) : (
			<div>
				{this.modalHead()}
				{this.modalBody()}
			</div>
		);

		let updateHandler;

		if (!groups) {
			if (history) {
				updateHandler = () => {
					this.historyHandler(
						this.state.history.activeTabName,
						this.state.history.active,
					);
				};
			} else if (advanced) {
				updateHandler = () => {
					this.advancedHandler();
					this.getData(itemId);
				};
			} else {
				updateHandler = () => {
					this.getData(itemId);
				};
			}
		}

		if (history) {
			content = (
				<Fragment>
					<div className={s.historyHead}>
						<Button
							btnType='back'
							className={s.backBtn}
							clicked={() => {
								this.setState({ history: null });
							}}
						>
							<Back /> <span>{t('back')}</span>
						</Button>
						<h2>{`SN-${data.serial_number}`}</h2>
					</div>
					<History
						activeTab={history.active}
						data={history.data}
						loading={history.loading}
						tabClickHandler={this.historyHandler}
					/>
				</Fragment>
			);
		}

		if (advanced) {
			content = (
				<Fragment>
					<div className={`${s.head} ${s.advancedHead}`}>
						<Button
							btnType='back'
							className={s.backBtn}
							clicked={this.resetAdvancedHandler}
						>
							<Back /> <span>{t('back')}</span>
						</Button>
						<div className={s.serialWrapper}>
							<h2>{`SN-${data.serial_number}`}</h2>
							<p>{data.concentrator_model.description}</p>
						</div>
						<span className={s.lastUpdated}>
							{this.state.advanced.engineeringValsLasUpdate}
						</span>
					</div>
					<AdvancedDataDialog data={advanced} concentrator={data} />
				</Fragment>
			);
		}

		return (
			<Modal
				className={s.modal}
				loading={loading}
				show={this.props.isOpen}
				modalRefresh={updateHandler}
				modalClosed={this.props.closeModal}
			>
				{content}
			</Modal>
		);
	}
}

export default withTranslation()(ItemModal);
