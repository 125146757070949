import React from 'react';
import T from 'prop-types';

const NotificationsThree = (props) => (
	<svg {...props} className={props.className}>
		<path
			d='M2.61548 9.91343C2.61548 8.43909 2.92862 7.07821 3.5549 5.83078C4.18117 4.58334 5.02635 3.53014 6.09043 2.67118L7.01543 3.83845C6.12441 4.55897 5.41833 5.4423 4.89718 6.48845C4.37603 7.5346 4.11545 8.67626 4.11545 9.91343H2.61548ZM19.8847 9.91343C19.8847 8.67626 19.6241 7.533 19.1029 6.48365C18.5818 5.43428 17.8757 4.54935 16.9847 3.82885L17.9097 2.67118C18.9738 3.53014 19.8189 4.58334 20.4452 5.83078C21.0715 7.07821 21.3846 8.43909 21.3846 9.91343H19.8847ZM4.50008 18.8846V17.3846H6.30778V9.92305C6.30778 8.57818 6.72284 7.38908 7.55295 6.35575C8.38309 5.32242 9.4488 4.66153 10.7501 4.37308V3.75C10.7501 3.40385 10.8719 3.10898 11.1155 2.8654C11.359 2.6218 11.6539 2.5 12.0001 2.5C12.3462 2.5 12.6411 2.6218 12.8847 2.8654C13.1282 3.10898 13.25 3.40385 13.25 3.75V4.37308C14.5513 4.66153 15.617 5.32242 16.4472 6.35575C17.2773 7.38908 17.6923 8.57818 17.6923 9.92305V17.3846H19.5V18.8846H4.50008ZM12.0001 21.6923C11.5013 21.6923 11.0754 21.5157 10.7222 21.1625C10.369 20.8093 10.1924 20.3833 10.1924 19.8846H13.8077C13.8077 20.3833 13.6311 20.8093 13.2779 21.1625C12.9247 21.5157 12.4988 21.6923 12.0001 21.6923ZM7.80773 17.3846H16.1924V9.92305C16.1924 8.76535 15.7831 7.77721 14.9645 6.95863C14.1459 6.14003 13.1578 5.73073 12.0001 5.73073C10.8424 5.73073 9.85421 6.14003 9.03563 6.95863C8.21703 7.77721 7.80773 8.76535 7.80773 9.92305V17.3846Z'
			fill='#6C7DDF'
		/>
	</svg>
);

NotificationsThree.defaultProps = {
	fill: 'none',
	width: 24,
	height: 24,
	viewBox: '0 0 24 24',
	xmlns: 'http://www.w3.org/2000/svg',
};

NotificationsThree.propTypes = {
	className: T.string,
};

export default NotificationsThree;
