import React from 'react';
import T from 'prop-types';

const CloseButton = (props) => (
	<svg {...props} className={props.className}>
		{/* <g fill="none" fillRule="evenodd"> */}
		<path
			d='M9.42478 28.3248L7.6748 26.5748L16.2498 17.9998L7.6748 9.42478L9.42478 7.6748L17.9998 16.2498L26.5748 7.6748L28.3248 9.42478L19.7498 17.9998L28.3248 26.5748L26.5748 28.3248L17.9998 19.7498L9.42478 28.3248Z'
			fill='#6D6D6D'
		/>
		{/* </g> */}
	</svg>
);

CloseButton.defaultProps = {
	fill: '#6D6D6D',
	width: 36,
	height: 36,
};

CloseButton.propTypes = {
	className: T.string,
};

export default CloseButton;
