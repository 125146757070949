const tabsConfig = [
	{
		name: 'column_life',
		displayText: 'column_life',
		allow: 'reports.column_life',
	},
	{ name: 'purity_o2', displayText: 'o2_purity', allow: 'reports.purity_o2' },
	{
		name: 'list_of_errors',
		displayText: 'errors',
		allow: 'reports.list_of_errors',
	},
	{ name: 'software', displayText: 'software', allow: 'reports.software' },
	{ name: 'location', displayText: 'location', allow: 'reports.location' },
];

export default tabsConfig;
