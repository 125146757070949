export const getProvidersOptions = () => ({
	withCount: 'concentratorProfiles',
	sortedBy: 'asc',
	orderBy: 'name',
});

export const getSearchInputParams = (value) => ({
	sortedBy: 'desc',
	orderBy: 'created_at',
	limit: 40,
	offset: 1,
	search: {
		serial_number_or_name: value ? encodeURIComponent(value) : null,
	},
	searchFields: {
		serial_number_or_name: 'ilike',
	},
	searchJoin: 'and',
});

export const getFiltersParam = (
	errorIds = null,
	providerIds = null,
	pinCodeIds = null,
	errorValueId = null,
) => ({
	search: {
		'concentratorErrorRecallLast.code': errorIds,
		provider_id: providerIds,
		provider_code_id: pinCodeIds,
	},
	searchFields: {
		'concentratorErrorRecallLast.code': errorIds && 'in',
		provider_id: providerIds && 'in',
		provider_code_id: pinCodeIds && 'in',
	},
	codeFilter: {
		error_id: errorValueId,
	},
});

export const getHealthcheckAddParams = (id) => ({
	search: {
		'healthcheckValues.healthcheck_parameter_id': id,
		'healthcheckValues.is_failed': 1,
	},
	searchFields: {
		'healthcheckValues.healthcheck_parameter_id': '=',
		'healthcheckValues.is_failed': '=',
	},
});

export const getHealthcheckDeleteParams = () => ({
	search: {
		'healthcheckValues.healthcheck_parameter_id': null,
		'healthcheckValues.is_failed': null,
	},
	searchFields: {
		'healthcheckValues.healthcheck_parameter_id': null,
		'healthcheckValues.is_failed': null,
	},
});

export const getHealthcheckParams = () => ({
	search: {
		healthcheck_parameter_id: 5,
		is_failed: 1,
	},
	searchFields: {
		healthcheck_parameter_id: 'in',
		is_failed: '=',
	},
});

export const getSuggestionsParams = (options) => {
	const params = { ...options.prevParams };

	params.search = {
		...params.search,
		[options.key]: options.value,
	};

	params.searchFields = {
		...params.searchFields,
		[options.key]: 'like',
	};

	return params;
};

export const errorsParams = {
	orderBy: 'description',
	sortedBy: 'asc',
};

export const modelsParams = {
	orderBy: 'id',
};
