import React from 'react';
import T from 'prop-types';

const EditIcon = (props) => (
	<svg {...props} className={props.className}>
		<path d='M4.71453 20.4C4.23543 20.4 3.8299 20.234 3.49794 19.902C3.16598 19.5701 3 19.1646 3 18.6855V5.9908C3 5.5117 3.16598 5.10617 3.49794 4.77421C3.8299 4.44225 4.23543 4.27627 4.71453 4.27627H12.8147L11.392 5.69894H4.71453C4.64156 5.69894 4.57467 5.72933 4.51386 5.79013C4.45306 5.85094 4.42266 5.91783 4.42266 5.9908V18.6855C4.42266 18.7584 4.45306 18.8253 4.51386 18.8861C4.57467 18.9469 4.64156 18.9773 4.71453 18.9773H17.4092C17.4822 18.9773 17.5491 18.9469 17.6099 18.8861C17.6707 18.8253 17.7011 18.7584 17.7011 18.6854V11.9934L19.1237 10.5707V18.6855C19.1237 19.1646 18.9577 19.5701 18.6258 19.9021C18.2938 20.234 17.8883 20.4 17.4092 20.4H4.71453V20.4ZM15.3591 4.67572L16.3824 5.67522L10.1134 11.935V13.2866H11.4412L17.743 7.00306L18.748 7.99344L12.0559 14.7093H8.69074V11.3441L15.3591 4.67572ZM18.748 7.99344L15.3591 4.67572L17.5296 2.50522C17.8664 2.16841 18.2753 2 18.7562 2C19.2371 2 19.6411 2.17145 19.9682 2.51435L20.8948 3.44635C21.2219 3.77953 21.3854 4.1811 21.3854 4.65108C21.3854 5.12104 21.2188 5.52261 20.8857 5.85579L18.748 7.99344Z' />
	</svg>
);

EditIcon.defaultProps = {
	fill: '#6C7DDF',
	width: 24,
	height: 24,
	viewBox: '0 0 24 24',
	xmlns: 'http://www.w3.org/2000/svg',
	style: { float: 'right', cursor: 'pointer' },
};

EditIcon.propTypes = {
	className: T.string,
};

export default EditIcon;
