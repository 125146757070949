import * as actionTypes from './actionTypes';
import { coreInstance as axios, api, messages } from 'settings';
import { logger, toast } from 'helpers';

export const getUnresolvedErrorsStart = () => {
	return {
		type: actionTypes.GET_UNRESOLVED_ERRORS_START,
	};
};

export const getUnresolvedErrors = (args = {}) => {
	const { params } = args;

	return async (dispatch) => {
		dispatch(getUnresolvedErrorsStart());
		await axios
			.get(`${api.patient_dashboard.get_unresolved_errors}`, { params })
			.then((response) => {
				dispatch(getUnresolvedErrorsSuccess(response.data));
			})
			.catch((err) => {
				const res = {
					success: true,
					data: { total_rows: '', data: [] },
					message: err.message,
				};
				// console.error('In error', res)
				dispatch(getUnresolvedErrorsSuccess(res.data));
				logger(`[Error] Failed fetching Unresolved Error Data" ${err}`);
				toast.error(messages.wentWrong, {
					style: { fontSize: '16px' },
				});
			});
	};
};

export const getUnresolvedErrorsSuccess = (data) => {
	return {
		type: actionTypes.GET_UNRESOLVED_ERRORS_SUCCESS,
		data: data,
	};
};
