import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
	data: null,
	loading: false,
};

const getUsersPendingStart = (state) => {
	return updateObject(state, { loading: true });
};

const getUsersPendingSuccess = (state, action) => {
	return updateObject(state, {
		data: action.data,
		loading: false,
	});
};

const changeUsersPendingStatusStart = (state, action) => {
	try {
		const newData = [...state.data];

		newData.map((el) => {
			if (el.id === action.data.id) {
				el.loading = true;
			}

			return el;
		});

		return updateObject(state, {
			data: newData,
		});
	} catch (e) {
		console.error(e);

		return state;
	}
};

const changeUsersPendingStatusSuccess = (state, action) => {
	try {
		const newData = [...state.data];

		newData.map((el) => {
			if (el.id === action.data.id) {
				el.status = action.data.status;
				el.loading = false;
			}

			return el;
		});

		return updateObject(state, {
			data: newData,
		});
	} catch (e) {
		console.error(e);

		return state;
	}
};

const approveUsersPendingSuccess = (state, action) => {
	try {
		const newData = state.data.filter((el) => {
			return el.id !== action.data.user_id;
		});

		return updateObject(state, {
			data: newData,
		});
	} catch (e) {
		console.error(e);

		return state;
	}
};

const clearUsersPending = () => {
	return initialState;
};

const usersPending = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_USERS_PENDING_START:
			return getUsersPendingStart(state, action);
		case actionTypes.GET_USERS_PENDING_SUCCESS:
			return getUsersPendingSuccess(state, action);
		case actionTypes.CHANGE_USERS_PENDING_STATUS_START:
			return changeUsersPendingStatusStart(state, action);
		case actionTypes.CHANGE_USERS_PENDING_STATUS_SUCCESS:
			return changeUsersPendingStatusSuccess(state, action);
		case actionTypes.CHANGE_SEARCH_USERS_STATUS_SUCCESS:
			return changeUsersPendingStatusSuccess(state, action);
		case actionTypes.APPROVE_USERS_PENDING_SUCCESS:
			return approveUsersPendingSuccess(state, action);

		case actionTypes.CLEAR_USERS_PENDING:
			return clearUsersPending(state, action);

		default:
			return state;
	}
};

export default usersPending;
