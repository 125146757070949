import { coreInstance as axios, api } from 'settings';
import { logger } from 'helpers';
import * as actionTypes from '../../actions/actionTypes';

const getConnectedStart = (action) => {
	return {
		type: actionTypes[action],
	};
};

const getConnectedSuccess = (args) => {
	const { current, byHour, totalRows, month, action } = args;

	return {
		type: actionTypes[action],
		current,
		byHour,
		totalRows,
		month,
	};
};

export const getConnected = (args = {}) => {
	const params = {
		append: [
			'total_concentrators',
			'recently_connected_concentrators',
			'recently_connected_concentrators_by_hours',
			'recently_connected_concentrators_month',
		],
		...args.params,
	};

	const actions = args.actionTypes || {
		start: actionTypes.GET_CURRENTLY_CONNECTED_START,
		success: actionTypes.GET_CURRENTLY_CONNECTED_SUCCESS,
	};

	return (dispatch) => {
		dispatch(getConnectedStart(actions.start));

		axios
			.get(api.concentrator_profiles.statistics, { params })
			.then((response) => {
				logger('[Action] Get connected concentrators');

				const {
					recently_connected_concentrators,
					recently_connected_concentrators_by_hours,
					total_concentrators,
					recently_connected_concentrators_month,
				} = response.data.data;
				const data = {
					current: recently_connected_concentrators,
					byHour: recently_connected_concentrators_by_hours,
					totalRows: total_concentrators.rows,
					month: recently_connected_concentrators_month,
					action: actions.success,
				};

				dispatch(getConnectedSuccess(data));
			})
			.catch(() => {
				logger('[Action] Get connected concentrators fail');
			});
	};
};
