const constans = {
	PUSH_TYPE_ID_UPDATE: 8,
	PUSH_TYPE_ID_DATA_TRANSFER: 9,
	PUSH_TYPE_SERVICE: 5,
	minLength: 1,
	maxTitleLength: 50,
	maxDescLength: 100,
	SSO_DOMAINS: ['inogen.net'],
};
export default constans;
