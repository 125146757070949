import React from 'react';
import {
	Bar,
	BarChart,
	CartesianGrid,
	ResponsiveContainer,
	XAxis,
	YAxis,
} from 'recharts';

import ps from '../style.module.scss';
import { withTranslation } from 'react-i18next';

const hideFirstTick = (tickValue) => {
	return tickValue || '';
};

const LifeClock = (props) => {
	const { t } = props;
	return (
		<div className={ps.twoThird}>
			<div className={ps.panel}>
				<h2 className={ps.title}>{t('life_clock')}</h2>
				<ResponsiveContainer height={230}>
					<BarChart
						data={props.data}
						margin={{ top: 10, right: 20, bottom: 0, left: -30 }}
						maxBarSize={20}
					>
						<CartesianGrid
							vertical={false}
							strokeDasharray='2 6'
							stroke='#e0e0e0'
						/>
						<XAxis
							dataKey='range'
							tickLine={false}
							tickSize={3}
							stroke='#e0e0e0'
							tick={{ fill: '#333437', fontSize: 10 }}
						/>
						<YAxis
							tickLine={false}
							tickSize={4}
							tick={{ fill: '#999999', fontSize: 10 }}
							stroke='#e0e0e0'
							tickFormatter={hideFirstTick}
						/>
						<Bar dataKey='amount' fill='#6c7ddf' />
					</BarChart>
				</ResponsiveContainer>
			</div>
		</div>
	);
};

export default withTranslation()(LifeClock);
