import { coreInstance as axios, api } from 'settings';
import { logger } from 'helpers';
import * as actionTypes from '../../actionTypes';
import { engineeringParams } from '../../../../containers/EngineeringDashboard/Configs/constants';

const getFlowSettingsStart = () => {
	return {
		type: actionTypes.GET_FLOW_SETTINGS_START,
	};
};

const getFlowSettingsSuccess = (data) => {
	return {
		type: actionTypes.GET_FLOW_SETTINGS_SUCCESS,
		data: data,
	};
};

export const getFlowSettings = (args = {}) => {
	const params = {
		...args.params,
		search: {
			...(args.params && args.params.search),
			param_id: engineeringParams.FLOW_SETTING,
		},
		searchFields: {
			...(args.params && args.params.searchFields),
			param_id: 'in',
		},
		orderBy: 'range',
	};

	return (dispatch) => {
		dispatch(getFlowSettingsStart());

		axios
			.get(api.engineering_params_statistics.index, { params })
			.then((response) => {
				logger('[Action] Get flow settings');

				dispatch(getFlowSettingsSuccess(response.data.data));
			})
			.catch(() => {
				logger('[Action] Get flow settings fail');
			});
	};
};
