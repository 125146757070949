import React, { useState, useRef } from 'react';
import { Legend } from 'components';
import Tooltip from '../../Dashboard/Tooltip';

import ps from '../style.module.scss';
import { Cell, Pie, PieChart } from 'recharts';
import { colors } from './config';

import s from './style.module.scss';
import { withTranslation } from 'react-i18next';

const FlowSettings = (props) => {
	const { data, t } = props;

	const [isTooltipActive, setTooltipState] = useState(false);
	const pieChartRef = useRef(null);
	const [coordinate, setTooltipCoordinate] = useState({});
	const [tooltipPayload, setTooltipPayload] = useState({});
	const [percent, setPercent] = useState(0);

	const showTooltip = (e) => {
		setTooltipPayload(e.tooltipPayload);
		setPercent(e.percent);
		setTooltipState(true);
		setTooltipCoordinate(e.tooltipPosition);
	};

	const setViewBox = () => {
		return {
			width: pieChartRef.current.container.clientWidth,
			height: pieChartRef.current.container.clientHeight,
		};
	};

	return (
		<div className={ps.oneThird}>
			<div className={ps.panel}>
				<h2 className={ps.title}>
					{t('flow_setting')}
					<span>{t('last_24_hours')}</span>
				</h2>
				<div className={s.inner}>
					{data.length && !data.every((item) => item.amount === 0) ? (
						<div className={s.chart}>
							<div onMouseLeave={() => setTooltipState(false)}>
								<PieChart width={210} height={210} ref={pieChartRef}>
									<Pie
										data={data.filter((item) => item.amount)}
										dataKey='amount'
										cx={100}
										cy={100}
										innerRadius={50}
										outerRadius={100}
										minAngle={2}
										onMouseEnter={showTooltip}
									>
										{data
											.filter((item) => item.amount)
											.map((entry, index) => (
												<Cell
													key={index}
													fill={colors[entry.name]}
													stroke='none'
												/>
											))}
									</Pie>
								</PieChart>
								{isTooltipActive ? (
									<Tooltip
										auto={true}
										viewBox={setViewBox()}
										active={isTooltipActive}
										coordinates={coordinate}
										offset={10}
										onMouseLeave={() => setTooltipState(false)}
									>
										<div className={s.tooltip}>
											<h3 className={s.tipTitle}>
												<i
													style={{
														backgroundColor: tooltipPayload[0].payload.fill,
													}}
												/>
												{tooltipPayload[0].name} - {(percent * 100).toFixed(0)}%
											</h3>
										</div>
									</Tooltip>
								) : null}
							</div>
							<div className={s.legendWrapper}>
								<Legend
									className={s.legend}
									data={data.filter((item) => item.amount)}
									colors={colors}
								/>
							</div>
						</div>
					) : (
						<div className={s.noShow}>{t('nothing_to_show')}</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default withTranslation()(FlowSettings);
