import React, { Fragment } from 'react';

import s from './style.module.scss';
import { withTranslation } from 'react-i18next';

const Terms = ({ data, t }) => (
	<Fragment>
		<h2 className={s.title}>{t('terms_of_use')}</h2>
		<div className={s.terms} dangerouslySetInnerHTML={{ __html: data }} />
	</Fragment>
);

export default withTranslation()(Terms);
