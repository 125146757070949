import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { routers } from 'settings';
import { CloseIcon } from 'components/Icons';
import { logger } from 'helpers';
import * as actions from 'store/actions';
import { makeGetPatientDataState } from 'store/selectors';
import { Link, withRouter } from 'react-router-dom';
import { getSearchInputParams } from './Config/paramsCreator';

import s from './style.module.scss';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

class PatientSearch extends Component {
	constructor(props) {
		super(props);
		this.state = {
			searchValue: '',
			showSuggestions: false,
			selectedItem: null,
		};
		this.inputRef = React.createRef();
	}

	componentDidMount() {
		this.inputRef.current.focus();
	}

	componentDidUpdate(prevProps, prevState) {
		logger('[Patient Search] componentDidUpdate');

		if (
			this.inputRef.current &&
			this.state.selectedItem !== prevState.selectedItem
		) {
			if (
				this.props.patientSearchData &&
				this.state.selectedItem &&
				this.props.patientSearchData[this.state.selectedItem]['full_name']
			)
				this.inputRef.current.value =
					this.props.patientSearchData[this.state.selectedItem]['full_name'];
		}
	}

	handleInputChange = (event) => {
		this.setState({ showSuggestions: false });
		this.setState({ searchValue: event.target.value });
	};

	handleKeyDown = (e) => {
		if (e.key === 'ArrowDown') {
			e.preventDefault();
			this.setState((prevState) => ({
				selectedItem:
					prevState.selectedItem === null
						? 0
						: (prevState.selectedItem + 1) %
						  this.props.patientSearchData?.length,
			}));
		} else if (e.key === 'ArrowUp') {
			e.preventDefault();
			this.setState((prevState) => ({
				selectedItem:
					prevState.selectedItem === null
						? this.props.patientSearchData?.length - 1
						: (prevState.selectedItem -
								1 +
								this.props.patientSearchData?.length) %
						  this.props.patientSearchData?.length,
			}));
		} else if (e.key === 'Enter' && this.state.selectedItem !== null) {
			const selectedPatientId =
				this.props.patientSearchData[this.state.selectedItem]['id'];
			this.setSelectedPatientId(selectedPatientId);
			this.props.history.push(routers.patientDetail);
			this.setState(() => ({
				searchValue:
					this.props.patientSearchData[this.state.selectedItem]['full_name'],
				selectedItem: null,
			}));
		}
	};

	onSearchSubmit = (e) => {
		e.preventDefault(); // Prevent the default form submission behavior
		if (this.inputRef.current) {
			this.inputRef.current.focus();
		}
		const value = this.inputRef.current.value;
		const params = getSearchInputParams(value);

		ReactGA.event({
			category: 'Patient Search',
			action: 'Submit',
			label: 'Search field',
		});
		this.props.getPatientProfiles(params);
		this.setState({ showSuggestions: true });
	};

	onSearchClear = () => {
		this.setState({ searchValue: '', showSuggestions: false });
		this.props.clearPatientSearchData();
	};

	setSelectedPatientId = (item) => {
		const patientIds = {
			patientId: Number.isInteger(item) ? item : item?.id,
			errorId: null,
		};
		this.props.setPatientId(patientIds);
	};

	render() {
		const { searchValue, showSuggestions } = this.state;

		return (
			<div className={s.title}>
				<div className={s.content}>
					<div className={s.searchPanel}>
						<form onSubmit={this.onSearchSubmit}>
							<div className={s.inputwrapper}>
								<input
									className={s.searchinput}
									ref={this.inputRef}
									type='text'
									onChange={this.handleInputChange}
									onKeyDown={this.handleKeyDown}
									value={searchValue}
									placeholder={i18next.t('search_patient')}
								/>
								{searchValue && (
									<div className={s.spanStyle} onClick={this.onSearchClear}>
										<CloseIcon />
									</div>
								)}
								<button
									className={s.btnstyle}
									id='btnId'
									data-testid='search-button'
									disabled={!searchValue}
									type='submit'
								>
									{i18next.t('search')}
								</button>
							</div>
						</form>
						{showSuggestions && (
							<div className={s.autosuggest}>
								{searchValue && this.props?.patientSearchData?.message && (
									<div className={s.autosuggestItem}>
										<div className={s.itemName}>
											{this.props?.patientSearchData.message}
										</div>
									</div>
								)}
								{searchValue &&
									Array.isArray(this.props?.patientSearchData) &&
									this.props?.patientSearchData?.length > 0 && (
										<ul id='testul'>
											{this.props?.patientSearchData.map((item, index) => (
												<Link
													to={routers.patientDetail}
													key={`${item}-${index}`}
												>
													<li
														onClick={() => {
															this.setSelectedPatientId(item);
														}}
														className={s.autosuggestItem}
														style={{
															backgroundColor:
																index === this.state.selectedItem
																	? 'black'
																	: '#333437',
															fontWeight:
																index === this.state.selectedItem
																	? 'bold'
																	: 'normal',
														}}
													>
														<div className={s.itemName}> {item.full_name}</div>
														<div className={s.itemValue}>
															{' '}
															{'SN-' + item.serial_number}
														</div>
													</li>
												</Link>
											))}
										</ul>
									)}
							</div>
						)}
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = () => {
	const getAllConcentratorsState = makeGetPatientDataState();

	return (props, state) => getAllConcentratorsState(props, state);
};

const mapDispatchToProps = (dispatch) => {
	return {
		setRequestParam: (params) =>
			dispatch(actions.setPateintSearchParam(params)),
		clearPatientSearchData: () => dispatch(actions.clearPatientSearchData()),
		setPatientId: (data) => dispatch(actions.setPatientId(data)),
		getPatientProfiles: (args) => dispatch(actions.getPatientProfiles(args)),
	};
};

export default withRouter(
	connect(
		mapStateToProps,
		mapDispatchToProps,
	)(withTranslation()(PatientSearch)),
);
