const table = {
	dataHead: [
		{ displayText: 'event_category' },
		{ displayText: 'total_events' },
		{ displayText: '%age' },
	],
	dataBody: {
		category: {
			path: 'category',
		},
		totalEvents: {
			path: 'totalEvents',
		},
		age: {
			path: 'age',
		},
	},
};

export default table;
