import React from 'react';
import T from 'prop-types';

const Grid = (props) => (
	<svg {...props} className={props.className}>
		<path d='M13.5 15.75H6.75C6.13125 15.75 5.60156 15.5297 5.16094 15.0891C4.72031 14.6484 4.5 14.1188 4.5 13.5V6.75C4.5 6.13125 4.72031 5.60156 5.16094 5.16094C5.60156 4.72031 6.13125 4.5 6.75 4.5H13.5C14.1188 4.5 14.6484 4.72031 15.0891 5.16094C15.5297 5.60156 15.75 6.13125 15.75 6.75V13.5C15.75 14.1188 15.5297 14.6484 15.0891 15.0891C14.6484 15.5297 14.1188 15.75 13.5 15.75ZM6.75 13.5H13.5V6.75H6.75V13.5ZM13.5 31.5H6.75C6.13125 31.5 5.60156 31.2797 5.16094 30.8391C4.72031 30.3984 4.5 29.8688 4.5 29.25V22.5C4.5 21.8813 4.72031 21.3516 5.16094 20.9109C5.60156 20.4703 6.13125 20.25 6.75 20.25H13.5C14.1188 20.25 14.6484 20.4703 15.0891 20.9109C15.5297 21.3516 15.75 21.8813 15.75 22.5V29.25C15.75 29.8688 15.5297 30.3984 15.0891 30.8391C14.6484 31.2797 14.1188 31.5 13.5 31.5ZM6.75 29.25H13.5V22.5H6.75V29.25ZM29.25 15.75H22.5C21.8813 15.75 21.3516 15.5297 20.9109 15.0891C20.4703 14.6484 20.25 14.1188 20.25 13.5V6.75C20.25 6.13125 20.4703 5.60156 20.9109 5.16094C21.3516 4.72031 21.8813 4.5 22.5 4.5H29.25C29.8688 4.5 30.3984 4.72031 30.8391 5.16094C31.2797 5.60156 31.5 6.13125 31.5 6.75V13.5C31.5 14.1188 31.2797 14.6484 30.8391 15.0891C30.3984 15.5297 29.8688 15.75 29.25 15.75ZM22.5 13.5H29.25V6.75H22.5V13.5ZM29.25 31.5H22.5C21.8813 31.5 21.3516 31.2797 20.9109 30.8391C20.4703 30.3984 20.25 29.8688 20.25 29.25V22.5C20.25 21.8813 20.4703 21.3516 20.9109 20.9109C21.3516 20.4703 21.8813 20.25 22.5 20.25H29.25C29.8688 20.25 30.3984 20.4703 30.8391 20.9109C31.2797 21.3516 31.5 21.8813 31.5 22.5V29.25C31.5 29.8688 31.2797 30.3984 30.8391 30.8391C30.3984 31.2797 29.8688 31.5 29.25 31.5ZM22.5 29.25H29.25V22.5H22.5V29.25Z' />
	</svg>
);

Grid.defaultProps = {
	fill: 'none',
	width: 36,
	height: 36,
	viewBox: '0 0 36 36',
};

Grid.propTypes = {
	className: T.string,
};

export default Grid;
