import ipType from './Icons/IpType.png';
import notUsaType from './Icons/NotUsaType.png';
import usaType from './Icons/UsaType.png';
import canadaType from './Icons/CanadaType.png';
import m1 from './Icons/Markers/M1.png';
import m2 from './Icons/Markers/M2.png';
import m3 from './Icons/Markers/M3.png';
import m4 from './Icons/Markers/M4.png';
import m5 from './Icons/Markers/M5.png';

import mapStyles from './mapStyles';

const internals = {};

export const initMap = (args) => {
	const { node, data, callback } = args;
	const { Map, event } = window.google.maps;

	internals.map = new Map(node, {
		center: {
			lat: 38.399308,
			lng: -100.577004,
		},
		zoom: 4,
		styles: mapStyles,
		disableDefaultUI: true,
		zoomControl: true,
	});

	event.addListenerOnce(internals.map, 'tilesloaded', () => {
		data && callback();
	});
};

const createMarkers = (data = [], headings, clicked) => {
	const { LatLngBounds } = window.google.maps;
	const ids = headings.indexOf('concentrator_profile_ids');
	const modelIds = headings.indexOf('concentrator_model_ids');
	const latitude = headings.indexOf('latitude');
	const longitude = headings.indexOf('longitude');
	const is_usa = headings.indexOf('is_usa');
	const is_canada = headings.indexOf('is_canada');
	const markers = [];

	let bounds = new LatLngBounds();

	internals.cluster = new window.MarkerClusterer(internals.map, markers, {
		styles: [
			{
				url: m1,
				height: 52,
				width: 53,
			},
			{
				url: m2,
				height: 55,
				width: 56,
			},
			{
				url: m3,
				height: 65,
				width: 66,
			},
			{
				url: m4,
				height: 77,
				width: 78,
			},
			{
				url: m5,
				height: 89,
				width: 90,
			},
		],
		maxZoom: 12,
		minimumClusterSize: 10,
	});

	function processLargeArrayAsync(array, maxTimePerChunk) {
		maxTimePerChunk = maxTimePerChunk || 200;

		let index = 0;

		function now() {
			return new Date().getTime();
		}

		function doChunk() {
			const startTime = now();

			while (index < array.length && now() - startTime <= maxTimePerChunk) {
				const markersIcon = {
					url: ipType,
					size: new window.google.maps.Size(34, 34),
				};

				// callback called with args (value, index, array)
				markersIcon.url = notUsaType;
				if (array[index][is_usa]) {
					markersIcon.url = usaType;
				} else if (array[index][is_canada]) {
					markersIcon.url = canadaType;
				}
				const marker = new window.google.maps.Marker({
					position: {
						lat: array[index][latitude],
						lng: array[index][longitude],
					},
					icon: markersIcon,
				});
				const id = array[index][ids];
				const modelId = array[index][modelIds];
				const row = { itemId: id, model: modelId };
				const location = new window.google.maps.LatLng(
					marker.position.lat(),
					marker.position.lng(),
				);

				bounds.extend(location);
				marker.addListener('click', () => clicked(row));
				markers.push(marker);
				++index;
			}

			internals.cluster.addMarkers(markers);

			if (index < array.length) {
				setTimeout(doChunk, 1);
			}
		}

		doChunk();
	}

	processLargeArrayAsync(data);

	if (markers.length && internals.firstLoad) {
		const { event } = window.google.maps;

		event.addListener(internals.map, 'bounds_changed', () => {
			if (internals.map.getZoom() > 14) internals.map.setZoom(14);
		});

		internals.map.fitBounds(bounds);
		internals.map.panToBounds(bounds);

		setTimeout(() => {
			event.clearListeners(internals.map, 'bounds_changed');
		}, 300);
	}
};

export const refreshMap = (data, headings, clicked, callback) => {
	if (internals.cluster instanceof window.MarkerClusterer) {
		internals.cluster.clearMarkers();
	}

	createMarkers(data, headings, clicked);

	callback && callback();
	internals.firstLoad = true;
};
