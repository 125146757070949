import React from 'react';
import ReactGA from 'react-ga';
import moment from 'moment';
import { Spinner } from 'components';
import { AuxWrap } from 'hoc';
import { DownloadTwo } from 'components/Icons';
import ReactTooltip from 'react-tooltip';
import i18next from 'i18next';
import s from '../style.module.scss';

const tableConfig = [
	{
		dataHead: [
			{
				displayText: 'S/N',
				sorted: { orderBy: 'serial_number', sortedBy: 'desc' },
			},
			{
				filter: {
					param: 'concentrator_model_id',
					valuesField: 'id',
					displayField: 'description',
					propsKey: 'modelsFilterProps',
					defaultValue: 'all_models',
					label: {
						path: 'description',
					},
				},
			},
			{
				sorted: {
					orderBy: 'created_at',
					sortedBy: 'desc',
				},
				filter: {
					type: 'date',
					param: 'created_at',
					data: [
						{
							id: 1,
							label: 'all',
						},
						{
							id: 2,
							label: 'single_date',
							type: 'single',
						},
						{
							id: 3,
							label: 'period_of_time',
							type: 'period',
						},
					],
					defaultValue: 'first_connection',
					propsKey: 'firstConnectFilterProps',
				},
			},
			{
				sorted: {
					orderBy: 'connected_at',
					sortedBy: 'desc',
				},
				filter: {
					type: 'date',
					param: 'connected_at',
					data: [
						{
							id: 1,
							label: 'all',
						},
						{
							id: 2,
							label: 'single_date',
							type: 'single',
						},
						{
							id: 3,
							label: 'period_of_time',
							type: 'period',
						},
					],
					defaultValue: 'last_connection',
					propsKey: 'lastConnectFilterProps',
				},
			},
			{
				filter: {
					type: 'status',
					viewType: 'columnLife',
					data: [
						{
							id: 1,
							label: '0–20%',
							value: '<=',
						},
						{
							id: 2,
							label: '21–100%',
							value: '>',
						},
					],
					defaultValue: `status_percent`,
				},
				view: (label, props) => {
					const clickHandler = async () => {
						const args = {
							start: props.onDownloadStart,
							success: props.onDownloadEnd,
							error: props.onDownloadEnd,
							params: {
								...props.requestParams,
								export: 'column_life',
							},
						};

						delete args.params.limit;

						ReactGA.event({
							category: 'Reports',
							action: 'Downloads',
							label: i18next.t('amount_of_reports_downloads'),
						});

						ReactGA.event({
							category: 'Reports',
							action: 'Downloads',
							label: `Downloads Reports ${args.params.export}`,
						});

						props.downloadReport(args);
					};

					let icon = <Spinner smaller />;

					if (!props.reportLoading) {
						icon = (
							<>
								<DownloadTwo
									data-tip=''
									data-for='help'
									className={s.infoIconTrigger}
									onClick={clickHandler}
								/>
								<ReactTooltip
									id='help'
									className={s.help}
									place='bottom'
									type='light'
									effect='solid'
								>
									<p>{i18next.t('download_last_year_report')}</p>
								</ReactTooltip>
							</>
						);
					}
					if (props.loading) {
						icon = <DownloadTwo opacity='0.6' />;
					}
					if (props.data && props.data.length === 0) {
						icon = <DownloadTwo opacity='0.6' />;
					}
					return (
						<AuxWrap>
							{label}
							{icon}
						</AuxWrap>
					);
				},
			},
		],
		dataBody: {
			serial_number: {
				path: 'serial_number',
			},
			concentrator_model: {
				path: ['concentrator_model', 'description'],
			},
			created_at: {
				path: 'created_at',
				view: ({ value }) => value && moment(value).format('MM.DD.YYYY, HH:mm'),
			},
			connected_at: {
				path: 'connected_at',
				view: ({ value }) => value && moment(value).format('MM.DD.YYYY, HH:mm'),
			},
			healthcheck_values: {
				path: 'healthcheck_values',
				textAlign: 'right',
				search: (arr) => {
					for (let item of arr) {
						let id = item.healthcheck_parameter_id;

						if (id === 5) return item;
					}
				},
				view: ({ value: healthcheck }) => {
					if (healthcheck) {
						const per = healthcheck.value;
						return Math.round((per / 1000) * 10000) / 100 + '%';
					}

					return null;
				},
			},
		},
		rowView: (row, classesArr, style) => {
			const arr = row.healthcheck_values;
			let isFailed;

			if (arr) {
				for (let item of arr) {
					let id = item.healthcheck_parameter_id;

					if (id === 5) isFailed = item.value <= 200;
				}
			}

			if (isFailed) {
				classesArr.push(style['errorRow']);
			}
		},
	},
	{
		dataHead: [
			{
				displayText: 'S/N',
				sorted: { orderBy: 'serial_number', sortedBy: 'desc' },
			},
			{
				filter: {
					param: 'concentrator_model_id',
					valuesField: 'id',
					displayField: 'description',
					propsKey: 'modelsFilterProps',
					defaultValue: 'all_models',
					label: {
						path: 'description',
					},
				},
			},
			{
				sorted: {
					orderBy: 'created_at',
					sortedBy: 'desc',
				},
				filter: {
					type: 'date',
					param: 'created_at',
					data: [
						{
							id: 1,
							label: 'all',
						},
						{
							id: 2,
							label: 'single_date',
							type: 'single',
						},
						{
							id: 3,
							label: 'period_of_time',
							type: 'period',
						},
					],
					defaultValue: 'first_connection',
					propsKey: 'firstConnectFilterProps',
				},
			},
			{
				sorted: {
					orderBy: 'connected_at',
					sortedBy: 'desc',
				},
				filter: {
					type: 'date',
					param: 'connected_at',
					data: [
						{
							id: 1,
							label: 'all',
						},
						{
							id: 2,
							label: 'single_date',
							type: 'single',
						},
						{
							id: 3,
							label: 'period_of_time',
							type: 'period',
						},
					],
					defaultValue: 'last_connection',
					propsKey: 'lastConnectFilterProps',
				},
			},
			{
				filter: {
					type: 'status',
					data: [
						{
							id: 1,
							label: 'low',
							value: 1,
						},
						{
							id: 2,
							label: 'good',
							value: 0,
						},
					],
					defaultValue: 'purity',
				},
				view: (label, props) => {
					const clickHandler = async () => {
						const args = {
							start: props.onDownloadStart,
							success: props.onDownloadEnd,
							error: props.onDownloadEnd,
							params: {
								...props.requestParams,
								export: 'O2_Purity',
							},
						};

						delete args.params.limit;

						ReactGA.event({
							category: 'Reports',
							action: 'Downloads',
							label: i18next.t('amount_of_reports_downloads'),
						});

						ReactGA.event({
							category: 'Reports',
							action: 'Downloads',
							label: `Downloads Reports ${args.params.export}`,
						});

						props.downloadReport(args);
					};

					let icon = <Spinner smaller />;

					if (!props.reportLoading) {
						icon = (
							<>
								<DownloadTwo
									data-tip=''
									data-for='help'
									className={s.infoIconTrigger}
									onClick={clickHandler}
								/>
								<ReactTooltip
									id='help'
									className={s.help}
									place='bottom'
									type='light'
									effect='solid'
								>
									<p>{i18next.t('download_last_year_report')}</p>
								</ReactTooltip>
							</>
						);
					}
					if (props.loading) {
						icon = <DownloadTwo opacity='0.6' />;
					}
					if (props.data && props.data.length === 0) {
						icon = <DownloadTwo opacity='0.6' />;
					}
					return (
						<AuxWrap>
							{label}
							{icon}
						</AuxWrap>
					);
				},
			},
		],
		dataBody: {
			serial_number: {
				path: 'serial_number',
			},
			concentrator_model: {
				path: ['concentrator_model', 'description'],
			},
			created_at: {
				path: 'created_at',
				view: ({ value }) => value && moment(value).format('MM.DD.YYYY, HH:mm'),
			},
			connected_at: {
				path: 'connected_at',
				view: ({ value }) => value && moment(value).format('MM.DD.YYYY, HH:mm'),
			},
			healthcheck_values: {
				path: 'healthcheck_values',
				textAlign: 'right',
				search: (arr) => {
					for (let item of arr) {
						let id = item.healthcheck_parameter_id;

						if (id === 7) return item;
					}
				},
				view: ({ value }) => {
					if (value) {
						return value.is_failed ? i18next.t('low') : i18next.t('good');
					}
				},
			},
		},
		rowView: (row, classesArr, style) => {
			const arr = row.healthcheck_values;
			let isFailed;

			if (arr) {
				for (let item of arr) {
					let id = item.healthcheck_parameter_id;

					if (id === 7) isFailed = item.is_failed;
				}
			}

			if (isFailed) {
				classesArr.push(style['errorRow']);
			}
		},
	},
	{
		dataHead: [
			{
				displayText: 'S/N',
				sorted: { orderBy: 'serial_number', sortedBy: 'desc' },
			},
			{
				filter: {
					param: 'concentrator_model_id',
					valuesField: 'id',
					displayField: 'description',
					propsKey: 'modelsFilterProps',
					defaultValue: 'all_models',
					label: {
						path: 'description',
					},
				},
			},
			{
				sorted: {
					orderBy: 'created_at',
					sortedBy: 'desc',
				},
				filter: {
					type: 'date',
					param: 'created_at',
					data: [
						{
							id: 1,
							label: 'all',
						},
						{
							id: 2,
							label: 'single_date',
							type: 'single',
						},
						{
							id: 3,
							label: 'period_of_time',
							type: 'period',
						},
					],
					defaultValue: 'first_connection',
					propsKey: 'firstConnectFilterProps',
				},
			},
			{
				sorted: {
					orderBy: 'connected_at',
					sortedBy: 'desc',
				},
				filter: {
					type: 'date',
					param: 'connected_at',
					data: [
						{
							id: 1,
							label: 'all',
						},
						{
							id: 2,
							label: 'single_date',
							type: 'single',
						},
						{
							id: 3,
							label: 'period_of_time',
							type: 'period',
						},
					],
					defaultValue: 'last_connection',
					propsKey: 'lastConnectFilterProps',
				},
			},
			{
				filter: {
					type: 'errors',
					param: ['concentratorErrorRecallLast.code'],
					valuesField: ['code'],
					displayField: 'description',
					codeField: 'error_id',
					propsKey: 'errorsFilterProps',
					defaultValue: 'all_errors',
					label: {
						path: 'description',
					},
				},
				view: (label, props) => {
					const clickHandler = async () => {
						const args = {
							start: props.onDownloadStart,
							success: props.onDownloadEnd,
							error: props.onDownloadEnd,
							params: {
								...props.requestParams,
								export: 'errors',
							},
						};

						delete args.params.limit;

						ReactGA.event({
							category: 'Reports',
							action: 'Downloads',
							label: i18next.t('amount_of_reports_downloads'),
						});

						ReactGA.event({
							category: 'Reports',
							action: 'Downloads',
							label: `Downloads Reports ${args.params.export}`,
						});

						props.downloadReport(args);
					};

					let icon = <Spinner smaller />;

					if (!props.reportLoading) {
						icon = (
							<>
								<DownloadTwo
									data-tip=''
									data-for='help'
									className={s.infoIconTrigger}
									onClick={clickHandler}
								/>
								<ReactTooltip
									id='help'
									className={s.help}
									place='bottom'
									type='light'
									effect='solid'
								>
									<p>{i18next.t('download_last_year_report')}</p>
								</ReactTooltip>
							</>
						);
					}
					if (props.loading) {
						icon = <DownloadTwo opacity='0.6' />;
					}
					if (props.data && props.data.length === 0) {
						icon = <DownloadTwo opacity='0.6' />;
					}
					return (
						<AuxWrap>
							{label}
							{icon}
						</AuxWrap>
					);
				},
			},
		],
		dataBody: {
			serial_number: {
				path: 'serial_number',
			},
			concentrator_model: {
				path: ['concentrator_model', 'description'],
			},
			created_at: {
				path: 'created_at',
				view: ({ value }) => value && moment(value).format('MM.DD.YYYY, HH:mm'),
			},
			connected_at: {
				path: 'connected_at',
				view: ({ value }) => value && moment(value).format('MM.DD.YYYY, HH:mm'),
			},
			concentrator_error_recall_last: {
				path: [
					'concentrator_error_recall_last',
					'concentrator_error',
					'description',
				],
				alterPath: [
					'concentrator_error_recall_last',
					'concentrator_error',
					'code',
				],
				view: ({ value }) => i18next.t(value),
			},
		},
	},
	{
		dataHead: [
			{
				displayText: 'S/N',
				sorted: { orderBy: 'serial_number', sortedBy: 'desc' },
			},
			{
				filter: {
					param: 'concentrator_model_id',
					valuesField: 'id',
					displayField: 'description',
					propsKey: 'modelsFilterProps',
					defaultValue: 'all_models',
					label: {
						path: 'description',
					},
				},
			},
			{
				sorted: {
					orderBy: 'created_at',
					sortedBy: 'desc',
				},
				filter: {
					type: 'date',
					param: 'created_at',
					data: [
						{
							id: 1,
							label: 'all',
						},
						{
							id: 2,
							label: 'single_date',
							type: 'single',
						},
						{
							id: 3,
							label: 'period_of_time',
							type: 'period',
						},
					],
					defaultValue: 'first_connection',
					propsKey: 'firstConnectFilterProps',
				},
			},
			{
				sorted: {
					orderBy: 'connected_at',
					sortedBy: 'desc',
				},
				filter: {
					type: 'date',
					param: 'connected_at',
					data: [
						{
							id: 1,
							label: 'all',
						},
						{
							id: 2,
							label: 'single_date',
							type: 'single',
						},
						{
							id: 3,
							label: 'period_of_time',
							type: 'period',
						},
					],
					defaultValue: 'last_connection',
					propsKey: 'lastConnectFilterProps',
				},
			},
			{
				filter: {
					type: 'version',
					param: 'concentrator_firmware_id',
					valuesField: 'codes',
					displayField: 'version',
					propsKey: 'firmwaresFilterProps',
					defaultValue: 'all_versions',
					label: {
						path: 'version',
					},
				},
				textAlign: 'center',
			},
			{
				filter: {
					type: 'date',
					param: 'concentratorParameter.latest_firmware_created_at',
					data: [
						{
							id: 1,
							label: 'all',
						},
						{
							id: 2,
							label: 'single_date',
							type: 'single',
						},
						{
							id: 3,
							label: 'period_of_time',
							type: 'period',
						},
					],
					defaultValue: 'last_update',
					propsKey: 'lastUpdateFilterProps',
				},
				view: (label, props) => {
					const clickHandler = async () => {
						const args = {
							start: props.onDownloadStart,
							success: props.onDownloadEnd,
							error: props.onDownloadEnd,
							params: {
								...props.requestParams,
								export: 'software',
							},
						};

						delete args.params.limit;

						ReactGA.event({
							category: 'Reports',
							action: 'Downloads',
							label: i18next.t('amount_of_reports_downloads'),
						});

						ReactGA.event({
							category: 'Reports',
							action: 'Downloads',
							label: `Downloads Reports ${args.params.export}`,
						});

						props.downloadReport(args);
					};

					let icon = <Spinner smaller />;

					if (!props.reportLoading) {
						icon = (
							<>
								<DownloadTwo
									data-tip=''
									data-for='help'
									className={s.infoIconTrigger}
									onClick={clickHandler}
								/>
								<ReactTooltip
									id='help'
									className={s.help}
									place='bottom'
									type='light'
									effect='solid'
								>
									<p>{i18next.t('download_last_year_report')}</p>
								</ReactTooltip>
							</>
						);
					}
					if (props.loading) {
						icon = <DownloadTwo opacity='0.6' />;
					}
					if (props.data && props.data.length === 0) {
						icon = <DownloadTwo opacity='0.6' />;
					}
					return (
						<AuxWrap>
							{label}
							{icon}
						</AuxWrap>
					);
				},
			},
		],
		dataBody: {
			serial_number: {
				path: 'serial_number',
			},
			concentrator_model: {
				path: ['concentrator_model', 'description'],
			},
			created_at: {
				path: 'created_at',
				view: ({ value }) => value && moment(value).format('MM.DD.YYYY, HH:mm'),
			},
			connected_at: {
				path: 'connected_at',
				view: ({ value }) => value && moment(value).format('MM.DD.YYYY, HH:mm'),
			},
			concentrator_firmware: {
				path: ['concentrator_firmware', 'version'],
				textAlign: 'center',
			},
			updated_at: {
				path: ['concentrator_parameter', 'latest_firmware_created_at'],
				textAlign: 'right',
				view: ({ value }) => value && moment(value).format('MM.DD.YYYY'),
			},
		},
	},
	{
		dataHead: [
			{
				displayText: 'S/N',
				sorted: { orderBy: 'serial_number', sortedBy: 'desc' },
			},
			{
				filter: {
					param: 'concentrator_model_id',
					valuesField: 'id',
					displayField: 'description',
					propsKey: 'modelsFilterProps',
					defaultValue: 'all_models',
					label: {
						path: 'description',
					},
				},
			},
			{
				sorted: {
					orderBy: 'created_at',
					sortedBy: 'desc',
				},
				filter: {
					type: 'date',
					param: 'created_at',
					data: [
						{
							id: 1,
							label: 'all',
						},
						{
							id: 2,
							label: 'single_date',
							type: 'single',
						},
						{
							id: 3,
							label: 'period_of_time',
							type: 'period',
						},
					],
					defaultValue: 'first_connection',
					propsKey: 'firstConnectFilterProps',
				},
			},
			{
				sorted: {
					orderBy: 'connected_at',
					sortedBy: 'desc',
				},
				filter: {
					type: 'date',
					param: 'connected_at',
					data: [
						{
							id: 1,
							label: 'all',
						},
						{
							id: 2,
							label: 'single_date',
							type: 'single',
						},
						{
							id: 3,
							label: 'period_of_time',
							type: 'period',
						},
					],
					defaultValue: 'last_connection',
					propsKey: 'lastConnectFilterProps',
				},
			},
			{
				filter: {
					type: 'errors',
					param: 'concentratorLocationDetailed.state_id',
					valuesField: 'id',
					displayField: 'state_name',
					propsKey: 'statesFilterProps',
					defaultValue: 'all_areas',
					label: {
						path: 'state_name',
					},
				},
				view: (label, props) => {
					const clickHandler = async () => {
						const args = {
							start: props.onDownloadStart,
							success: props.onDownloadEnd,
							error: props.onDownloadEnd,
							params: {
								...props.requestParams,
								export: 'location',
							},
						};

						delete args.params.limit;

						ReactGA.event({
							category: 'Reports',
							action: 'Downloads',
							label: i18next.t('amount_of_reports_downloads'),
						});

						ReactGA.event({
							category: 'Reports',
							action: 'Downloads',
							label: `Downloads Reports ${args.params.export}`,
						});

						props.downloadReport(args);
					};

					let icon = <Spinner smaller />;

					if (!props.reportLoading) {
						icon = (
							<>
								<DownloadTwo
									data-tip=''
									data-for='help'
									className={s.infoIconTrigger}
									onClick={clickHandler}
								/>
								<ReactTooltip
									id='help'
									className={s.help}
									place='bottom'
									type='light'
									effect='solid'
								>
									<p>{i18next.t('download_last_year_report')}</p>
								</ReactTooltip>
							</>
						);
					}
					if (props.loading) {
						icon = <DownloadTwo opacity='0.6' />;
					}
					if (props.data && props.data.length === 0) {
						icon = <DownloadTwo opacity='0.6' />;
					}
					return (
						<AuxWrap>
							{label}
							{icon}
						</AuxWrap>
					);
				},
			},
		],
		dataBody: {
			serial_number: {
				path: 'serial_number',
			},
			concentrator_model: {
				path: ['concentrator_model', 'description'],
			},
			created_at: {
				path: 'created_at',
				view: ({ value }) => value && moment(value).format('MM.DD.YYYY, HH:mm'),
			},
			connected_at: {
				path: 'connected_at',
				view: ({ value }) => value && moment(value).format('MM.DD.YYYY, HH:mm'),
			},
			concentrator_location_detailed: {
				path: 'concentrator_location_detailed',
				view: ({ value }) => {
					if (value) {
						const { locality, adminLevelCode, country, countryCode } = value;
						const str = [];

						locality && str.push(locality);
						adminLevelCode && str.push(adminLevelCode);

						if (countryCode !== 'US') {
							str.push(country);
						}

						return str.join(', ');
					}

					return false;
				},
			},
		},
	},
];

export default tableConfig;
