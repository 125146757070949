import { coreInstance as axios, api } from 'settings';
import { logger } from 'helpers';
import * as actionTypes from '../../actions/actionTypes';

const getColumnLifeStart = (action) => {
	return {
		type: actionTypes[action],
	};
};

const getColumnLifeSuccess = (data, action) => {
	return {
		type: actionTypes[action],
		data: data,
	};
};

export const getColumnLife = (args = {}) => {
	const params = {
		...args.params,
	};

	const actions = args.actionTypes || {
		start: actionTypes.GET_COLUMN_LIFE_START,
		success: actionTypes.GET_COLUMN_LIFE_SUCCESS,
	};

	return (dispatch) => {
		dispatch(getColumnLifeStart(actions.start));

		axios
			.get(api.healthcheck_value_statistics.index, { params })
			.then((response) => {
				logger('[Action] Get column life');

				dispatch(getColumnLifeSuccess(response.data.data, actions.success));
			})
			.catch(() => {
				logger('[Action] Get column life fail');
			});
	};
};
