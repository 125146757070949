import React from 'react';
import T from 'prop-types';

const Replace = (props) => (
	<svg {...props} className={props.className}>
		<g>
			<path
				fill='#FF8300'
				d='M8.367 14.942h10.692a1 1 0 1 1 0 2H8.418l.713 2.17a1 1 0 0 1-1.52 1.135l-5.03-3.483a1 1 0 0 1 0-1.644l4.993-3.457a1 1 0 0 1 1.516 1.143l-.723 2.136z'
			/>
			<path
				fill='#6AB221'
				d='M13.791 6.942H3.15a1 1 0 0 1 0-2h10.692l-.723-2.136a1 1 0 0 1 1.516-1.143L19.63 5.12a1 1 0 0 1 0 1.644l-5.031 3.483a1 1 0 0 1-1.52-1.134l.713-2.171z'
			/>
		</g>
	</svg>
);

Replace.defaultProps = {
	fill: '',
	width: 22,
	height: 22,
	viewBox: '0 0 22 22',
};

Replace.propTypes = {
	className: T.string,
};

export default Replace;
