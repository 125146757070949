import { combineReducers } from 'redux';
import profiles from './profiles';
import healthcheck from './healthcheck';
import locations from './locations';

const reducer = combineReducers({
	profiles,
	healthcheck,
	locations,
});

export default reducer;
