import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
	data: null,
	loading: false,
};

const getSearchUsersStart = (state) => {
	return updateObject(state, { loading: true });
};

const getSearchUsersSuccess = (state, action) => {
	return updateObject(state, {
		data: action.data,
		loading: false,
	});
};

const changeSearchUsersStatusStart = (state, action) => {
	const actionData = action.data;
	const newData = [...state.data];

	newData[actionData.index].loading = true;

	return updateObject(state, {
		data: newData,
	});
};

const changeSearchUsersStatusSuccess = (state, action) => {
	const actionData = action.data;
	const newData = [...state.data];

	newData[actionData.index].status = actionData.status;
	newData[actionData.index].loading = false;

	return updateObject(state, {
		data: newData,
	});
};

const clearSearchUsers = () => {
	return initialState;
};

const searchUsers = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_SEARCH_USERS_START:
			return getSearchUsersStart(state, action);
		case actionTypes.GET_SEARCH_USERS_SUCCESS:
			return getSearchUsersSuccess(state, action);
		case actionTypes.CHANGE_SEARCH_USERS_STATUS_START:
			return changeSearchUsersStatusStart(state, action);
		case actionTypes.CHANGE_SEARCH_USERS_STATUS_SUCCESS:
			return changeSearchUsersStatusSuccess(state, action);

		case actionTypes.CLEAR_SEARCH_USERS:
			return clearSearchUsers(state, action);

		default:
			return state;
	}
};

export default searchUsers;
