import { toast } from 'react-toastify';
import { toastStyle } from 'settings';
import React from 'react';
const SuccessIcon = () => (
	<svg
		width='24'
		height='24'
		viewBox='0 0 24 24'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		style={{ width: '10%' }}
	>
		<path
			d='M10.5324 16.573L17.5659 9.53944L16.3155 8.30766L10.5324 14.0907L7.63046 11.1887L6.40458 12.4205L10.5324 16.573ZM11.9979 22C10.6276 22 9.33449 21.7383 8.11847 21.215C6.90246 20.6917 5.84038 19.9759 4.93222 19.0678C4.02407 18.1596 3.30832 17.0978 2.78498 15.8823C2.26166 14.6669 2 13.374 2 12.0038C2 10.6171 2.26166 9.31577 2.78498 8.09976C3.30832 6.88375 4.02376 5.82566 4.93129 4.9255C5.83884 4.02536 6.90049 3.31274 8.11626 2.78764C9.33201 2.26255 10.6252 2 11.9957 2C13.3827 2 14.6845 2.2624 15.9009 2.7872C17.1174 3.31198 18.1756 4.02419 19.0754 4.92383C19.9753 5.82346 20.6877 6.88136 21.2126 8.09752C21.7375 9.31369 22 10.6155 22 12.003C22 13.3737 21.7375 14.6671 21.2124 15.8831C20.6873 17.099 19.9746 18.1608 19.0745 19.0685C18.1743 19.9762 17.1165 20.6917 15.9011 21.215C14.6856 21.7383 13.3845 22 11.9979 22Z'
			fill='white'
		/>
	</svg>
);
const ErrorIcon = () => (
	<svg
		width='20'
		height='20'
		viewBox='0 0 20 20'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		style={{ width: '10%' }}
	>
		<path
			d='M6.3 14.8667L10 11.1666L13.7 14.8667L14.8667 13.7L11.1667 10L14.8667 6.3L13.7 5.13333L10 8.83335L6.3 5.13333L5.13333 6.3L8.83335 10L5.13333 13.7L6.3 14.8667ZM10 20C8.62778 20 7.33334 19.7375 6.11667 19.2125C4.90001 18.6875 3.83889 17.9722 2.93332 17.0667C2.02777 16.1611 1.3125 15.1 0.7875 13.8833C0.2625 12.6667 0 11.3722 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.02777 3.825 2.93332 2.925C3.83889 2.025 4.90001 1.3125 6.11667 0.7875C7.33334 0.2625 8.62778 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3722 19.7375 12.6667 19.2125 13.8833C18.6875 15.1 17.975 16.1611 17.075 17.0667C16.175 17.9722 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z'
			fill='white'
		/>
	</svg>
);
const WarnIcon = () => (
	<svg
		width='20'
		height='18'
		viewBox='0 0 20 18'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		style={{ width: '10%' }}
	>
		<path
			d='M1.42555 17.4999C1.25605 17.4999 1.10382 17.4584 0.968873 17.3756C0.833923 17.2927 0.728965 17.1835 0.653998 17.048C0.576015 16.9133 0.533123 16.7675 0.525323 16.6105C0.517523 16.4534 0.559898 16.2979 0.652448 16.1438L9.2132 1.35603C9.30575 1.202 9.42157 1.08813 9.56067 1.01441C9.69977 0.940676 9.84625 0.903809 10.0001 0.903809C10.1539 0.903809 10.3004 0.940676 10.4395 1.01441C10.5786 1.08813 10.6944 1.202 10.787 1.35603L19.3477 16.1438C19.4403 16.2979 19.4827 16.4534 19.4749 16.6105C19.4671 16.7675 19.4242 16.9133 19.3462 17.048C19.2712 17.1835 19.1663 17.2927 19.0313 17.3756C18.8964 17.4584 18.7441 17.4999 18.5746 17.4999H1.42555ZM10.0001 14.8076C10.2289 14.8076 10.4208 14.7302 10.5756 14.5754C10.7304 14.4206 10.8078 14.2288 10.8078 13.9999C10.8078 13.7711 10.7304 13.5792 10.5756 13.4244C10.4208 13.2696 10.2289 13.1922 10.0001 13.1922C9.77125 13.1922 9.57942 13.2696 9.42462 13.4244C9.26982 13.5792 9.19242 13.7711 9.19242 13.9999C9.19242 14.2288 9.26982 14.4206 9.42462 14.5754C9.57942 14.7302 9.77125 14.8076 10.0001 14.8076ZM10.0004 12.1922C10.213 12.1922 10.3911 12.1204 10.5347 11.9766C10.6783 11.8329 10.7501 11.6547 10.7501 11.4423V7.94221C10.7501 7.72971 10.6782 7.55159 10.5344 7.40786C10.3906 7.26411 10.2124 7.19223 9.99977 7.19223C9.78717 7.19223 9.60908 7.26411 9.4655 7.40786C9.32191 7.55159 9.25012 7.72971 9.25012 7.94221V11.4423C9.25012 11.6547 9.32202 11.8329 9.46582 11.9766C9.60964 12.1204 9.78784 12.1922 10.0004 12.1922Z'
			fill='white'
		/>
	</svg>
);
const InfoIcon = () => (
	<svg
		width='20'
		height='20'
		viewBox='0 0 20 20'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		style={{ width: '10%' }}
	>
		<path
			d='M9.26742 14.9926H10.8744V9.01797H9.26742V14.9926ZM9.99712 7.28408C10.2524 7.28408 10.4664 7.2001 10.639 7.03215C10.8117 6.86417 10.898 6.65605 10.898 6.40776C10.898 6.1411 10.8117 5.92017 10.6391 5.74499C10.4665 5.5698 10.2526 5.4822 9.99749 5.4822C9.73791 5.4822 9.52279 5.5698 9.35213 5.74499C9.18145 5.92017 9.0961 6.14011 9.0961 6.40481C9.0961 6.65555 9.18246 6.86479 9.35516 7.03251C9.52784 7.20022 9.74183 7.28408 9.99712 7.28408ZM10.0044 20C8.62211 20 7.32488 19.7383 6.11271 19.215C4.90054 18.6917 3.84038 17.9759 2.93222 17.0678C2.02407 16.1596 1.30832 15.0991 0.784983 13.8863C0.261661 12.6734 0 11.3751 0 9.99146C0 8.60777 0.261661 7.30923 0.784983 6.09584C1.30832 4.88244 2.02376 3.82566 2.93129 2.9255C3.83884 2.02536 4.89918 1.31274 6.11232 0.787643C7.32547 0.262548 8.62405 0 10.0081 0C11.3921 0 12.691 0.2624 13.9049 0.7872C15.1187 1.31198 16.1756 2.02419 17.0754 2.92383C17.9753 3.82346 18.6877 4.88136 19.2126 6.09752C19.7375 7.31369 20 8.61332 20 9.99641C20 11.3792 19.7375 12.6767 19.2124 13.8888C18.6873 15.1009 17.9746 16.1593 17.0745 17.064C16.1743 17.9686 15.1165 18.6841 13.9011 19.2105C12.6856 19.7368 11.3867 20 10.0044 20Z'
			fill='white'
		/>
	</svg>
);

const toastApi = {
	success(msg) {
		toast.success(
			<>
				<SuccessIcon />
				<span>{msg}</span>
			</>,
			{
				className: toastStyle.successMsg,
				position: toast.POSITION.TOP_CENTER,
				closeButton: false,
			},
		);
	},

	error(msg) {
		toast.error(
			<>
				<ErrorIcon />
				<span>{msg}</span>
			</>,
			{
				className: toastStyle.errorMsg,
				position: toast.POSITION.TOP_CENTER,
				closeButton: false,
			},
		);
	},

	warn(msg) {
		toast.warn(
			<>
				<WarnIcon />
				<span>{msg}</span>
			</>,
			{
				className: toastStyle.warnMsg,
				position: toast.POSITION.TOP_CENTER,
				closeButton: false,
			},
		);
	},

	info(msg) {
		toast.info(
			<>
				<InfoIcon />
				<span>{msg}</span>
			</>,
			{
				className: toastStyle.infoMsg,
				position: toast.POSITION.TOP_CENTER,
				closeButton: false,
			},
		);
	},
};

export default toastApi;
