import { coreInstance as axios, api } from 'settings';
import { logger } from 'helpers';
import * as actionTypes from '../actionTypes';

export const setAllFirmwaresRequestParam = (params) => {
	return {
		type: actionTypes.SET_ALL_FIRMWARES_REQUEST_PARAM,
		params: params,
	};
};

const getAllFirmwaresStart = () => {
	return {
		type: actionTypes.GET_ALL_FIRMWARES_START,
	};
};

const getAllFirmwaresSuccess = (data) => {
	return {
		type: actionTypes.GET_ALL_FIRMWARES_SUCCESS,
		data: data,
	};
};

export const getAllFirmwares = (args) => {
	const { loader = true, params, successHandler, errorHandler } = args;

	params.with = 'testConcentrators;serialNumbers';

	return (dispatch) => {
		loader && dispatch(getAllFirmwaresStart());

		axios
			.get(api.concentrator_firmwares.index, { params })
			.then((response) => {
				logger('[Action] Get all firmwares');

				dispatch(getAllFirmwaresSuccess(response.data.data));
				successHandler && successHandler(response.data.data);
			})
			.catch((err) => {
				logger('[Action] Get all firmwares fail');

				errorHandler && errorHandler(err.response.data);
			});
	};
};

export const clearFirmwares = () => {
	return {
		type: actionTypes.CLEAR_FIRMWARES,
	};
};
