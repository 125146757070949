import React from 'react';

import s from '../style.module.scss';
import { withTranslation } from 'react-i18next';

const AdvancedBlock = (props) => {
	const { t } = props;
	return (
		<div className={s.pane}>
			<div className={s.paneHead}>
				<h3>{t('advanced')}</h3>
			</div>
			<div className={s.paneBody}>
				<ul className={s.linkList}>
					<li>
						<span onClick={() => props.checkHandler()}>
							{t('engineering_data')}
						</span>
					</li>
				</ul>
			</div>
		</div>
	);
};

export default withTranslation()(AdvancedBlock);
