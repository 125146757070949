import React, { Component } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';

import s from './style.module.scss';
import i18next from 'i18next';

class FilterSelect extends Component {
	constructor(props) {
		super(props);

		const initialChecked = {};

		for (let item of props.data) {
			initialChecked[item.id] = item;
		}

		this.state = {
			isOpen: false,
			checked: initialChecked,
		};
	}

	componentDidUpdate(prevProps) {
		const { data } = this.props;

		const initialChecked = {};

		for (let item of data) {
			initialChecked[item.id] = item;
		}

		if (data !== prevProps.data) {
			this.setState({ checked: initialChecked });
		}
	}

	getCurrentValues = () => {
		const { defaultValue, viewType } = this.props.config;
		let values = i18next.t(defaultValue);
		const checkedArray = Object.values(this.state.checked);

		if (viewType === 'columnLife') {
			if (checkedArray.length === 1) {
				values = `${i18next.t('status')} (${i18next.t(checkedArray[0].label)})`;
			}
		} else {
			if (checkedArray.length) {
				values += `: ${checkedArray
					.map((obj) => i18next.t(obj.label))
					.join(', ')}`;
			}
		}

		return values;
	};

	getItemstList = () => {
		const data = this.props.data;
		const howManyChosen = Object.values(this.state.checked).length;

		return (
			data &&
			data.map((el) => {
				let isActive = s.active;
				let isAlone;

				if (howManyChosen) {
					isActive = this.state.checked[el.id] && s.active;
				}

				if (howManyChosen === 1 && this.state.checked[el.id]) {
					isAlone = s.disabled;
				}

				return (
					<li
						key={el.id}
						className={[isActive, isAlone].join(' ')}
						onClick={() => this.onItemClick(el)}
					>
						{i18next.t(el.label)}
					</li>
				);
			})
		);
	};

	onSelectClick = () => {
		this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
	};

	onItemClick = (data) => {
		const setParam = (value) => {
			if (typeof value[0] === 'number') {
				this.props.onSetParams({
					searchFields: { 'healthcheckValues.is_failed': 'in' },
					search: { 'healthcheckValues.is_failed': value || null },
				});
			} else {
				let parameter = value;

				if (value.length > 1) {
					parameter = null;
				}

				this.props.onSetParams({
					searchFields: { 'healthcheckValues.value': parameter },
					search: { 'healthcheckValues.value': parameter && 200 },
				});
			}
		};

		this.setState((prevState) => {
			const prevChecked = prevState.checked;
			const howManyChosen = Object.values(this.state.checked).length;

			if (prevChecked[data.id] && howManyChosen > 1) {
				delete prevChecked[data.id];
				setParam(Object.values(prevChecked).map((obj) => obj.value));
			} else if (!prevChecked[data.id]) {
				prevChecked[data.id] = data;
				setParam(Object.values(prevChecked).map((obj) => obj.value));
			}

			return {
				...prevState,
				checked: {
					...prevChecked,
				},
			};
		});
	};

	handleClickOutside = () => {
		this.setState({ isOpen: false });
	};

	render() {
		const classes = [s.filterWrap];

		if (this.state.isOpen) {
			classes.push(s.open);
		}

		return (
			<div className={classes.join(' ')}>
				<div className={s.filterSelect} onClick={this.onSelectClick}>
					{this.getCurrentValues()}
				</div>

				{this.state.isOpen && (
					<div className={s.filterList}>
						<ul className={s.list}>{this.getItemstList()}</ul>
					</div>
				)}
			</div>
		);
	}
}

FilterSelect.defaultProps = {
	config: {},
	filters: [],
};

FilterSelect.propTypes = {
	config: PropTypes.object,
	filters: PropTypes.array,
};

export default onClickOutside(FilterSelect);
