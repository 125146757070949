const timeConvert = (minute) => {
	const date = [];
	const state = {
		days: null,
		hours: null,
		minutes: minute,
	};

	if (minute > 60) {
		state.hours = Math.floor(minute / 60);
		state.minutes = minute % 60;
	}

	if (state.hours > 24) {
		state.days = Math.floor(state.hours / 24);
		state.hours = state.hours % 24;
	}

	for (const key in state) {
		const value = state[key];
		let label = key;

		if (value) {
			if (value === 1) label = label.slice(0, -1);
			date.push(`${state[key]} ${label}`);
		}
	}

	return date.join(' ');
};

export default timeConvert;
