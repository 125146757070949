import React from 'react';
import PropTypes from 'prop-types';
import { permissions } from 'helpers';
import { withTranslation } from 'react-i18next';

import { AuxWrap } from 'hoc';

import s from './style.module.scss';

const PatientTabs = (props) => {
	let { data, t } = props;

	data = data.filter((el) => {
		if (el.allow) {
			return permissions.check(el.allow);
		}

		return true;
	});

	return (
		<nav
			data-testid='tabs-test-id'
			className={[s.tabs, props.className].join(' ')}
		>
			<ul>
				{data.map((el, index) => {
					const itemClasses = [s.active];

					if (props.activeItem === index && !props.loading) {
						itemClasses.push(props.activeClassName);
					}

					return (
						<li
							key={index}
							className={itemClasses.join(' ')}
							data-testid='li-test-id'
							onClick={() =>
								props.onItemClick(el.name, index, el, props.loading)
							}
						>
							{el.displayText !== 'purity' ? (
								t(el.displayText)
							) : (
								<AuxWrap>
									O<sub>2</sub> {t('purity')}
								</AuxWrap>
							)}
						</li>
					);
				})}
			</ul>
		</nav>
	);
};

PatientTabs.defaultProps = {
	data: [],
};

PatientTabs.propTypes = {
	data: PropTypes.array,
	className: PropTypes.string,
	onClick: PropTypes.func,
};

export default withTranslation()(PatientTabs);
