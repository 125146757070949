import React from 'react';
import T from 'prop-types';

const RightChevron = (props) => (
	<svg {...props} className={props.className}>
		<path d='M12.5 24.5L11.0667 23.0667L17.6667 16.4667L11.0667 9.86667L12.5 8.43333L20.5333 16.4667L12.5 24.5Z' />
	</svg>
);

RightChevron.defaultProps = {
	fill: '#000000',
	width: 32,
	height: 33,
	viewBox: '0 0 32 33',
};

RightChevron.propTypes = {
	className: T.string,
};

export default RightChevron;
