import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
	data: [],
	loading: false,
};

const getModelsStart = (state) => {
	return updateObject(state, { loading: true });
};

const getModelsSuccess = (state, action) => {
	return updateObject(state, { data: action.data, loading: false });
};

const clearModels = () => {
	return initialState;
};

const models = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_MODELS_START:
			return getModelsStart(state, action);
		case actionTypes.GET_MODELS_SUCCESS:
			return getModelsSuccess(state, action);
		case actionTypes.CLEAR_MODELS:
			return clearModels(state, action);
		default:
			return state;
	}
};

export default models;
