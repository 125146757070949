import { coreInstance as axios, api } from 'settings';
import { logger } from 'helpers';
import * as actionTypes from './actionTypes';

const getFirmwaresNotifiableStart = () => {
	return {
		type: actionTypes.GET_FIRMWARES_NOTIFIABLE_START,
	};
};

const getFirmwaresNotifiableSuccess = (data) => {
	return {
		type: actionTypes.GET_FIRMWARES_NOTIFIABLE_SUCCESS,
		data: data,
	};
};

export const getFirmwaresNotifiable = (args = {}) => {
	const { successHandler, errorHandler } = args;

	return (dispatch) => {
		dispatch(getFirmwaresNotifiableStart());

		axios
			.get(api.firmwares_notifiable.index)
			.then((response) => {
				logger('[Action] Get models');
				dispatch(getFirmwaresNotifiableSuccess(response.data.data));
				successHandler && successHandler(response.data.data);
			})
			.catch(() => {
				logger('[Action] Get models fail');

				errorHandler && errorHandler();
			});
	};
};
