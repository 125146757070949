import React, { PureComponent } from 'react';
import onClickOutside from 'react-onclickoutside';
import PropTypes from 'prop-types';
import { AuxWrap } from 'hoc';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

import s from './style.module.scss';
import Complete from './Complete/index';

class Selection extends PureComponent {
	state = {
		isOpen: false,
		labelName: '',
	};

	getCurrentValues = () => {
		const { t } = this.props;

		const checkedArray = Object.values(this.props.checked);
		const values = checkedArray.map((obj) => obj[this.props.config.displayKey]);

		return (
			values.join(', ') ||
			t(this.props.config.defaultValue) || (
				<span className={s.placeholder}>
					{t(this.props.config.placeholder)}
				</span>
			) ||
			this.props.data[0][this.props.config.displayKey]
		);
	};

	getDefaultValue = () => {
		const { t } = this.props;
		if (this.props.config.defaultValue) {
			const isNotSet = !Object.values(this.props.checked).length;

			return (
				<AuxWrap>
					<li
						className={isNotSet ? s.active : ''}
						onClick={this.props.onDefaultClick}
					>
						{t(this.props.config.defaultValue)}
					</li>
					<li className={s.separator}></li>
				</AuxWrap>
			);
		}
	};
	handleClickOutside = () => {};
	getItemstList = () => {
		const { t } = this.props;
		return this.props.data.map((el) => {
			if (el.label) {
				this.setState({ labelName: i18next.t('define_user_role') });
			} else if (el.provider_codes) {
				this.setState({ labelName: i18next.t('select_provider') });
			} else if (el.name) {
				this.setState({ labelName: i18next.t('country') });
			} else {
				this.setState({ labelName: i18next.t('select_model') });
			}

			return t(el[this.props.config.label.path]);
		});
	};

	handleClick = (value) => {
		// 👇️ take parameter passed from Child component
		if (value === '') {
			const disabledBtn = false;
			this.props.onItemClicked(disabledBtn);
			this.setState({ isOpen: false });
		}
		this.props.data &&
			this.props.data.length > 0 &&
			this.props.data.map((el) => {
				if (
					el.name === value ||
					el.label === value ||
					(el.description && el.description === value)
				) {
					this.props.onItemClicked(el, this.props.type);
					this.setState({ isOpen: false });
				}
				return '';
			});
	};
	render() {
		const classes = [s.wrapper, s[this.props.theme], this.props.className];

		if (this.state.isOpen) {
			classes.push(s.open);
		}

		return (
			<div className={classes.join(' ')}>
				<Complete
					data={this.getItemstList()}
					label={this.state.labelName}
					onSubmit={this.handleClick}
				/>
			</div>
		);
	}
}

Selection.defaultProps = {
	// config: {},
	data: [],
	checked: {},
	type: 'select',
	onItemClicked: () => {},
};

Selection.propTypes = {
	// config: PropTypes.object,
	data: PropTypes.array,
	checked: PropTypes.object,
	type: PropTypes.string,
	onItemClicked: PropTypes.func,
};

export default withTranslation()(onClickOutside(Selection));
