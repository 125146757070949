import React from 'react';
import './style.modules.scss';

function ToggleButton(props) {
	const { isOn } = props;

	return (
		<div
			className={`toggle-button ${isOn ? 'on' : 'off'}`}
			onClick={props.toggle}
		>
			<div className={`slider ${isOn ? 'on' : 'off'}`} />
		</div>
	);
}

export default ToggleButton;
