import React from 'react';
import {
	AlertIcon,
	StatusIcon,
	SearchIcon,
	HealthCheckIcon,
	BatteryIcon,
	PowerIcon,
} from 'components/Icons';
import moment from 'moment';
import i18next from 'i18next';
import s from './style.module.scss';
class DataList extends React.Component {
	render() {
		const { dataList, title, notificationButton } = this.props;

		const marginTopStyle =
			title === i18next.t('device_health') ? { marginTop: '16px' } : {};

		const dataListStyle = {
			border: '1px solid #E0E0E0',
			borderRadius: '8px',
			...marginTopStyle,
		};

		let titleIcon;

		if (title === i18next.t('device_specification')) {
			titleIcon = <SearchIcon className={s.labelSpacing} />;
		} else if (title === i18next.t('device_health')) {
			titleIcon = <HealthCheckIcon className={s.labelSpacing} />;
		} else {
			titleIcon = null;
		}

		let fieldsToRender;

		if (title === i18next.t('device_specification')) {
			let lastConnect;
			const utcDateTime = new Date(dataList[0]?.last_connected);
			const localDateTime = new Date(
				utcDateTime.getTime() - utcDateTime.getTimezoneOffset() * 60000,
			);
			if (dataList) {
				if (moment(localDateTime).isBefore(moment().subtract(3, 'd'))) {
					lastConnect = `${moment(localDateTime).format('DD MMM YYYY')}`;
				} else {
					lastConnect = moment(localDateTime).fromNow();
				}
			}

			fieldsToRender = (
				<>
					<div className={s.fieldWrapper}>
						<div className={s.fieldFlex}>
							<span className={s.labelField}>{i18next.t('device_model')}</span>
							<div className={s.labelValues}>
								{dataList ? dataList[0]?.device_model : 'N/A'}
							</div>
						</div>
					</div>

					<div className={s.fieldWrapper}>
						<div className={s.fieldFlex}>
							<span className={s.labelField}>{i18next.t('serial_number')}</span>
							<div className={s.labelValues}>
								{dataList && dataList[0]?.serial_number !== null
									? dataList[0].serial_number
									: 'N/A'}
							</div>
						</div>
					</div>

					<div className={s.fieldWrapper}>
						<div className={s.fieldFlex}>
							<span className={s.labelField}>{i18next.t('provider_name')}</span>
							<div className={s.labelValues}>
								{dataList && dataList[0]?.provider_name !== null
									? dataList[0].provider_name
									: 'N/A'}
							</div>
						</div>
					</div>

					<div className={s.fieldWrapper}>
						<div className={s.fieldFlex}>
							<span className={s.labelField}>
								{i18next.t('firmware_version')}
							</span>
							<div className={s.labelValues}>
								{dataList && dataList?.[0]?.firmware_version !== null
									? dataList[0].firmware_version
									: 'N/A'}
							</div>
							<span className={s.fieldIcon}>{notificationButton}</span>
							<span className={s.fieldIcon}>
								<AlertIcon />
							</span>
						</div>
					</div>

					<div className={s.fieldWrapper}>
						<div className={s.fieldFlex}>
							<span className={s.labelField}>
								{i18next.t('last_connected')}
							</span>
							<div className={s.labelValues}>{lastConnect}</div>
							<span className={s.fieldIcon}>
								<StatusIcon />
							</span>
						</div>
					</div>

					<div className={s.fieldWrapper}>
						<div className={s.fieldFlex}>
							<span className={s.labelField}>{i18next.t('last_location')}</span>
							<div className={s.labelValues}>
								{dataList && dataList?.[0]?.last_location !== null
									? dataList[0].last_location
									: 'N/A'}
							</div>
						</div>
					</div>
				</>
			);
		} else if (title === i18next.t('device_health')) {
			var powerValue = [];
			var batteryCapacityValue = [];
			var sieveLifeValue = [];
			var oxygenPurityValue = [];
			var lifeClockValue = [];
			if (typeof dataList === 'object' && dataList.length) {
				dataList.forEach((item) => {
					if (item.name === 'power') {
						powerValue = item;
					}
					if (item.name === 'battery_capacity') {
						batteryCapacityValue = item;
					}
					if (item.name === 'sieve_life') {
						sieveLifeValue = item;
					}
					if (item.name === 'oxygen_purity') {
						oxygenPurityValue = item;
					}
					if (item.name === 'life_clock') {
						lifeClockValue = item;
					}
				});
			}

			const minutes = lifeClockValue?.value || 'N/A';
			const hours = Math.floor(minutes / 60);
			const remainingMinutes = minutes % 60;
			const result = `${hours ? hours : '0'} ${i18next.t('hrs')} ${
				remainingMinutes ? remainingMinutes : '0'
			} ${i18next.t('min')}`;

			fieldsToRender = (
				<>
					<div className={s.fieldWrapper}>
						<div className={s.fieldFlex}>
							<span className={s.labelField}>{i18next.t('power')}</span>
							<div className={s.labelValues}>
								{powerValue?.value === '0' && powerValue?.value !== null
									? i18next.t('battery')
									: powerValue?.value === '1'
									  ? i18next.t('voltage')
									  : 'N/A'}
							</div>
							<span className={s.fieldIcon}>
								{powerValue?.value === '0' && powerValue?.value !== null ? (
									<BatteryIcon />
								) : powerValue?.value === '1' ? (
									<PowerIcon />
								) : (
									''
								)}
							</span>
						</div>
					</div>

					<div className={s.fieldWrapper}>
						<div className={s.fieldFlex}>
							<span className={s.labelField}>
								{i18next.t('battery_capacity')}
							</span>
							<div className={s.labelValues}>
								{/* {dataList
									? dataList[1].value !== null && dataList
										? dataList[1].is_failed
											? i18next.t("bad")
											: i18next.t("good")
										: "N/A"
									: "N/A"} */}

								{batteryCapacityValue?.is_failed === true
									? i18next.t('bad')
									: batteryCapacityValue?.is_actual === true
									  ? i18next.t('good')
									  : 'N/A'}
							</div>
							<span className={s.fieldIcon}>
								{/* {dataList ? (
									dataList[1].value !== null && dataList ? (
										dataList[1].is_failed ? (
											<AlertIcon />
										) : (
											<StatusIcon />
										)
									) : (
										""
									)
								) : (
									""
								)} */}
								{batteryCapacityValue?.is_failed === true ? (
									<AlertIcon />
								) : batteryCapacityValue?.is_actual === true ? (
									<StatusIcon />
								) : (
									''
								)}
							</span>
						</div>
					</div>

					<div className={s.fieldWrapper}>
						<div className={s.fieldFlex}>
							<span className={s.labelField}>{i18next.t('column_life')}</span>
							<div className={s.labelValues}>
								{/* {dataList
									? dataList[2].value !== null && dataList
										? dataList[2].is_failed
											? i18next.t("bad")
											: i18next.t("good")
										: "N/A"
									: "N/A"} */}
								{sieveLifeValue?.is_failed === true
									? i18next.t('bad')
									: sieveLifeValue?.is_actual === true
									  ? i18next.t('good')
									  : 'N/A'}
							</div>
							<span className={s.fieldIcon}>
								{/* {dataList ? (
									dataList[2].value !== null && dataList ? (
										dataList[2].is_failed ? (
											<AlertIcon />
										) : (
											<StatusIcon />
										)
									) : (
										""
									)
								) : (
									""
								)} */}
								{sieveLifeValue?.is_failed === true ? (
									<AlertIcon />
								) : sieveLifeValue?.is_actual === true ? (
									<StatusIcon />
								) : (
									''
								)}
							</span>
						</div>
					</div>

					<div className={s.fieldWrapper}>
						<div className={s.fieldFlex}>
							<span className={s.labelField}>{i18next.t('oxygen_purity')}</span>
							<div className={s.labelValues}>
								{/* {dataList
									? dataList[3].value !== null && dataList
										? dataList[3].is_failed
											? i18next.t("bad")
											: i18next.t("good")
										: "N/A"
									: "N/A"} */}
								{oxygenPurityValue?.is_failed === true
									? i18next.t('bad')
									: oxygenPurityValue?.is_actual === true
									  ? i18next.t('good')
									  : 'N/A'}
							</div>
							<span className={s.fieldIcon}>
								{/* {dataList ? (
									dataList[3].value !== null && dataList ? (
										dataList[3].is_failed ? (
											<AlertIcon />
										) : (
											<StatusIcon />
										)
									) : (
										""
									)
								) : (
									""
								)} */}
								{oxygenPurityValue?.is_failed === true ? (
									<AlertIcon />
								) : oxygenPurityValue?.is_actual === true ? (
									<StatusIcon />
								) : (
									''
								)}
							</span>
						</div>
					</div>

					<div className={s.fieldWrapper}>
						<div className={s.fieldFlex}>
							<span className={s.labelField}>{i18next.t('life_clock')}</span>
							<div className={s.labelValues}>{result}</div>
						</div>
					</div>
				</>
			);
		} else {
			fieldsToRender = null;
		}

		return (
			<div style={dataListStyle}>
				{title && (
					<div className={s.modalTitle}>
						{titleIcon} {title}
					</div>
				)}
				{fieldsToRender}
			</div>
		);
	}
}

export default DataList;
