import i18next from 'i18next';

const filtersConfig = {
	// replacementParts: {
	//     key: 'replacementParts',
	//     title: 'Parts requiring replacement',
	//     data: 'healthcheckData',
	//     config: {
	//         labelKey: 'label',
	//         countKey: 'count'
	//     }
	// },
	replacementParts: {
		key: 'replacementParts',
		title: 'parts_requiring_replacement',
		data: 'errorsData',
		config: {
			labelKey: 'description',
			dataFilter: (data = []) => {
				let filterData = data.filter(
					(el) => el.code_string === 'Replace Columns',
				);

				if (filterData[0]) {
					filterData = [{ ...filterData[0] }];
					filterData[0].description = i18next.t('columns');
				}

				return filterData;
			},
		},
	},
	// providers: {
	//     key: 'providers',
	//     title: 'Providers',
	//     data: 'providersData',
	//     config: {
	//         labelKey: 'name',
	//         countKey: 'concentrator_profiles_count'
	//     }
	// },
};

export default filtersConfig;
