import { createSelector } from 'reselect';
import moment from 'moment';

// TODO: refactor code duplication
const getLoadingAll = (state) => state.engineering.recentErrorStatus.loading;
const getCurrentlyConcentrators = (state) =>
	state.engineering.connected.current;
const getMonthlyConcentrators = (state) => state.engineering.connected.month;
const getTotalConcentrators = (state) => state.engineering.connected.totalRows;
const getRecentErrorData = (state) => state.engineering.recentError.data;
const getRecentErrorPeriod = (state) => state.engineering.recentError.period;
const getConnectedByTime = (state) => state.engineering.appConnected.rows;
const getSoftwareVersions = (state) => state.engineering.softwareVersion.data;
const getParams = (state) => state.engineering.params;
const getO2Purity = (state) => state.engineering.o2purity.data;
const getCurrentUser = (state) => state.user.data;
const getLifeClock = (state) => state.engineering.lifeClock.data;
const getFlowSettings = (state) => {
	return state.engineering.flowSettings.data.map((item) => {
		return item
			? {
					name: item.range,
					amount: item.amount,
			  }
			: false;
	});
};
const getColumnLifeData = (state) =>
	state.engineering.columnLife.data.sort(
		(a, b) => moment(b.month_updated_at) - moment(a.month_updated_at),
	);
const getModels = (state) => {
	const { data } = state.models;

	if (data) {
		data.forEach((el) => {
			el.displayText = el.full_description;
		});
	}

	return data
		? [...data].sort((a, b) => (a.id > b.id ? 1 : a.id < b.id ? -1 : 0))
		: [];
};
// TODO: refactor code duplication
const getProviders = (state) =>
	state.providers.data.map((el) => {
		el.provider_codes =
			el.provider_codes &&
			el.provider_codes.map((pin) => {
				pin.label = `${pin.code_part_1}-${pin.code_part_2}`;
				pin.code_part_3 &&
					(pin.label += `-${pin.code_part_3.slice(
						0,
						2,
					)}-${pin.code_part_3.slice(-3)} `);
				pin.description && (pin.label += pin.description);
				return pin;
			});

		return el;
	});
const getPinCodes = (state) => {
	state.pinCodes.data.forEach((el) => {
		if (el.fullLabel) return;
		el.fullLabel = el.label += el.description ? ` ${el.description}` : '';
	});
	return state.pinCodes.data;
};
const getSystemPower = (state) => state.engineering.systemPower.data;
const getFlowSettingsStatistics = (state) =>
	state.engineering.systemPower.flow_settings_statistics;

const getLocation = (state) => state.locations.data;
const getLocationsCount = (state) => state.locations.count;
const getHeadings = (state) => state.locations.headings;
const getBatteryStatus = (state) => state.engineering.batteryStatus.data;
const getRecentErrorStatus = (state) =>
	state.engineering.recentErrorStatus.data;

export const makeGetAllEngineeringState = () =>
	createSelector(
		getLoadingAll,
		getCurrentlyConcentrators,
		getMonthlyConcentrators,
		getTotalConcentrators,
		getModels,
		getRecentErrorData,
		getRecentErrorPeriod,
		getConnectedByTime,
		getColumnLifeData,
		getSoftwareVersions,
		getParams,
		getProviders,
		getPinCodes,
		getCurrentUser,
		getO2Purity,
		getLifeClock,
		getFlowSettings,
		getSystemPower,
		getFlowSettingsStatistics,
		getLocation,
		getLocationsCount,
		getHeadings,
		getBatteryStatus,
		getRecentErrorStatus,
		(
			loading,
			currentlyConnected,
			monthlyConnected,
			totalConnected,
			models,
			recentErrorData,
			recentErrorPeriod,
			appConnected,
			columnLifeData,
			softwareVersionsData,
			params,
			providersData,
			pinCodesData,
			currentUserData,
			o2purity,
			lifeClock,
			flowSettings,
			systemPower,
			flowSettingsStatistics,
			locationData,
			countData,
			headingsData,
			batteryStatus,
			recentErrorStatus,
		) => ({
			loading,
			currentlyConnected,
			monthlyConnected,
			totalConnected,
			models,
			recentErrorData,
			recentErrorPeriod,
			appConnected,
			columnLifeData,
			softwareVersionsData,
			params,
			providersData,
			pinCodesData,
			currentUserData,
			o2purity,
			lifeClock,
			flowSettings,
			systemPower,
			flowSettingsStatistics,
			locationData,
			countData,
			headingsData,
			batteryStatus,
			recentErrorStatus,
		}),
	);
