import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'components';
import { AuxWrap } from 'hoc';
import { EditIcon } from '../../../../../components/Icons';

import s from './style.module.scss';
import { withTranslation } from 'react-i18next';

class DropdownList extends PureComponent {
	state = {
		data: [],
	};

	componentDidMount() {
		this.setState({ data: this.props.data });
	}

	componentDidUpdate(prevProps) {
		const { data } = this.props;

		if (prevProps.data !== data) {
			this.setState({ data: data });
		}
	}

	itemClick = (obj, index) => {
		this.setState((prevState) => {
			const newData = [...prevState.data];
			let showAll = true;

			newData[index].isOpen = !newData[index].isOpen;

			for (let item of newData) {
				showAll = item.isOpen && showAll;
			}

			return { data: newData, showAll: showAll };
		});
	};

	clickAll = (value) => {
		this.setState((prevState) => {
			const newData = prevState.data.map((el) => {
				el.isOpen = value || false;

				return el;
			});

			return { data: newData, showAll: !prevState.showAll };
		});
	};

	renderList = () => {
		const { label } = this.props.config;

		return (
			<AuxWrap>
				<ul className={s.list}>
					{this.state.data.map((el, index) => {
						const classes = [s.listItem];
						let content;

						if (el.isOpen) {
							classes.push(s.open);
							content = (
								<ul className={s.innerList}>
									{el.data.map((el, index) => {
										const classes = [];
										let label;

										return (
											<li
												className={classes.join(' ')}
												key={index}
												onClick={() => this.props.clicked(el)}
											>
												{el.name}
												{label}
												<EditIcon
													onClick={(e) =>
														this.props.onProviderEditClicked(e, el)
													}
												/>
											</li>
										);
									})}
								</ul>
							);
						}

						return (
							<li key={index} className={classes.join(' ')}>
								<span
									className={s.label}
									onClick={() => this.itemClick(el, index)}
								>
									{label.view ? label.view(el) : el[label.path]}
								</span>
								{content}
							</li>
						);
					})}
				</ul>
			</AuxWrap>
		);
	};

	render() {
		return (
			<>
				<span
					className={s.btn}
					onClick={() => this.clickAll(!this.state.showAll)}
				>
					{this.props.t(
						this.state.showAll ? 'hide_all_providers' : 'show_all_providers',
					)}
				</span>
				<div className={s.dropdownList}>
					{!this.props.data.length ? <Spinner /> : this.renderList()}
				</div>
			</>
		);
	}
}

DropdownList.defaultProps = {
	data: [],
	config: {},
};

DropdownList.propTypes = {
	data: PropTypes.array,
	config: PropTypes.object,
};

export default withTranslation()(DropdownList);
