import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { coreInstance as axios, api } from 'settings';
import { logger } from 'helpers';
import { Button } from 'components';
import { Close } from 'components/Icons';
import DropZone from './DropZone';
import ChangeSummary from './ChangeSummary';
import FirmwareInfo from './FirmwareInfo';
import PublishingSettings from './PublishingSettings';
import SelectConcentrators from './SelectConcentrators';
import initialData from './initialState';
import {
	startOfDay,
	parseISO,
	differenceInMilliseconds,
	isSameDay,
} from 'date-fns';
import s from './style.module.scss';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

class ManagePopup extends Component {
	constructor(props) {
		super(props);

		this.state = {
			data: props.data || initialData(),
			serial: '',
			concentrators: [],
		};

		this.cache = {
			[this.state.serial]: this.state.concentrators,
		};

		this.openAs = this.state.data.status_id;
	}

	submitHandler = () => {
		const { data } = this.state;
		const mutateData = { ...data };
		const utcReleasedDate = startOfDay(new Date(mutateData.released_at));
		delete mutateData.concentrator_model;

		mutateData.released_at = utcReleasedDate;
		if (typeof mutateData.filename !== 'object') {
			delete mutateData.filename;
			delete mutateData.filepath;
		}

		if (mutateData.test_concentrators) {
			mutateData.test_concentrators = mutateData.test_concentrators.map((el) =>
				// eslint-disable-next-line no-prototype-builtins
				el.hasOwnProperty('concentrator_profile_id')
					? el.concentrator_profile_id
					: el.id,
			);
		}

		if (
			mutateData.status_id === 2 &&
			// moment().utc().startOf('day') - utcReleasedDate === 0
			differenceInMilliseconds(startOfDay(new Date()), utcReleasedDate) === 0
		) {
			mutateData.status_id = 1;
		}

		Object.keys(mutateData).map((el) => {
			// if (Array.isArray(mutateData[el])) {
			//     mutateData[el].join() || (mutateData[el] = null);
			//     return el;
			// }

			!mutateData[el] && delete mutateData[el];
			return el;
		});

		// const formData = objectToFormData(mutateData);

		this.props.onManage(mutateData);
	};

	fileDropHandler = (files = null) => {
		this.setState((prevState) => {
			const data = { ...prevState.data };

			data.filename = files && files[0];
			files || (data.filepath = null);

			return { data };
		});
	};

	fileClearHandler = (e) => {
		e.stopPropagation();

		this.fileDropHandler();
	};

	selectDateHandler = (date) => {
		date &&
			this.setState((prevState) => {
				const data = { ...prevState.data };

				data.released_at = date;

				return { data };
			});
	};

	summaryHandler = (e, index) => {
		e.persist();

		this.setState((prevState) => {
			const data = { ...prevState.data };

			if (index !== undefined) {
				data.description
					? (data.description[index] = e.target.value)
					: (data.description = [e.target.value]);
			} else {
				data.description
					? data.description.push('')
					: (data.description = ['']);
			}

			return { data };
		});
	};

	infoHandler = (e, key, selectedData) => {
		e && e.persist();

		this.setState((prevState) => {
			const data = { ...prevState.data };

			switch (key) {
				case 'concentrator_model':
					data[key] = selectedData;
					data.concentrator_model_id = selectedData.id;
					this.cache = {};
					break;
				default:
					data[key] = e.target.value;
					break;
			}
			if (key === 'version' && this.props.errors?.length > 0) {
				this.props.errorHandler({ errors: null });
			}
			return { data };
		});
	};

	settingsHandler = (status) => {
		const { onError } = this.props;

		this.setState(
			(prevState) => {
				const data = { ...prevState.data };

				data.status_id = status;

				return { data };
			},
			() => onError({ errors: null }),
		);
	};

	serialHandler = (e) => {
		e.persist();

		const { value } = e.target;

		if (value) {
			if (/^\d+$/.test(value) && value.length <= 8) {
				this.setState((prevState) => {
					const state = { serial: value };

					if (prevState.serial !== value) {
						state.concentrators = [];
					}

					return state;
				});
			}
		} else {
			this.setState({ serial: value });
		}
	};

	serialFetchHandler = ({ value, reason }) => {
		if (reason === 'suggestion-selected') {
			return;
		}

		if (this.cache[value]) {
			this.setState({
				concentrators: this.cache[value],
			});
		} else {
			clearTimeout(this.timer);

			this.timer = setTimeout(() => {
				const params = {
					search: {
						serial_number: value,
						concentrator_model_id: this.state.data.concentrator_model_id,
					},
					searchFields: {
						serial_number: 'ilike',
						concentrator_model_id: 'in',
					},
					limit: 5,
					searchJoin: 'and',
				};

				axios
					.get(api.concentrator_profiles.index, { params })
					.then((response) => {
						logger('[Action] Get concentrator');

						if (this.state.serial === '') {
							return;
						}

						this.cache[value] = response.data.data;
						if (response && response.data && response.data.data) {
							this.setState({ concentrators: response.data.data });
						}
					})
					.catch(() => {
						logger('[Action] Get concentrator fail');
					});
			}, 300);
		}
	};

	serialClearHandler = () => this.setState({ concentrators: [] });

	serialSelectHandler = (e, { suggestion }) => {
		this.setState((prevState) => {
			const data = { ...prevState.data };

			data.test_concentrators
				? data.test_concentrators.push(suggestion)
				: (data.test_concentrators = [suggestion]);

			return { data, serial: '', concentrators: [] };
		});
	};

	serialSubmitHandler = (e, index) => {
		const { concentrators } = this.state;

		if (index !== undefined) {
			this.setState((prevState) => {
				const data = { ...prevState.data };

				data.test_concentrators = data.test_concentrators.filter(
					(el, i) => i !== index,
				);

				return { data };
			});
		} else if (concentrators.length === 1) {
			this.setState(
				(prevState) => {
					const data = { ...prevState.data };

					data.test_concentrators.push(concentrators[0]);

					return { data, serial: '', concentrators: [] };
				},
				() => clearTimeout(this.timer),
			);
		}
	};

	checkIsDisable = () => {
		const {
			data: {
				concentrator_model_id,
				version,
				filename,
				released_at,
				description,
				status_id,
				test_concentrators,
			},
		} = this.state;
		return (
			!filename ||
			!concentrator_model_id ||
			!version ||
			(status_id !== 5 && !released_at) ||
			(status_id !== 5 && !(description && description.join(''))) ||
			(status_id === 4 && test_concentrators && test_concentrators.length === 0)
		);
	};

	render() {
		const { data, concentrators, serial } = this.state;
		const { models, errors, loading, t } = this.props;
		const {
			concentrator_model,
			version,
			filename,
			part_num,
			status_id,
			description,
			released_at,
			test_concentrators,
		} = data;

		let btnText = 'Submit',
			title = t('edit_firmware'),
			// eslint-disable-next-line no-prototype-builtins
			isDropDisabled = data.hasOwnProperty('id');

		switch (status_id) {
			case 2:
				// btnText = moment(released_at).isSame(new Date(), 'd')
				btnText = isSameDay(parseISO(released_at), new Date())
					? i18next.t('publish')
					: i18next.t('save');
				break;
			case 4:
				btnText = i18next.t('submit_for_testing');
				break;
			case 5:
				btnText = i18next.t('save');
				break;
			default:
				break;
		}

		// eslint-disable-next-line no-prototype-builtins
		if (!data.hasOwnProperty('id')) title = t('add_new_firmware');
		else isDropDisabled = this.openAs !== 5;

		return (
			<Fragment>
				<div className={s.firmwareContainer}>
					<div className={s.titleAlign}>
						<div className={s.iconTitle}>
							<svg
								width='36'
								height='36'
								viewBox='0 0 36 36'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M16.875 23.6826V11.0826L13.1769 14.7807L11.5962 13.1538L18 6.75L24.4037 13.1538L22.823 14.7807L19.1249 11.0826V23.6826H16.875ZM9.46155 29.2499C8.70385 29.2499 8.0625 28.9874 7.5375 28.4624C7.0125 27.9374 6.75 27.2961 6.75 26.5384V22.4711H8.99996V26.5384C8.99996 26.6538 9.04804 26.7596 9.14419 26.8557C9.24036 26.9519 9.34615 27 9.46155 27H26.5384C26.6538 27 26.7596 26.9519 26.8557 26.8557C26.9519 26.7596 27 26.6538 27 26.5384V22.4711H29.2499V26.5384C29.2499 27.2961 28.9874 27.9374 28.4624 28.4624C27.9374 28.9874 27.2961 29.2499 26.5384 29.2499H9.46155Z'
									fill='#26251F'
								/>
							</svg>

							<span className={s.titleTypography}>{title}</span>
						</div>
						<Close
							className={s.closeBtn}
							width={36}
							height={36}
							fill='#6D6D6D'
							onClick={this.props.close}
						/>
					</div>

					<DropZone
						file={filename}
						onClear={this.fileClearHandler}
						onDrop={this.fileDropHandler}
						disabled={isDropDisabled}
						/*onDropAccepted={() => console.log('onDropAccepted')}
			onDropRejected={() => console.log('onDropRejected')}*/
					/>
					<FirmwareInfo
						selectedModel={concentrator_model}
						releasedDate={released_at}
						partNum={part_num}
						version={version}
						models={models}
						onChange={this.infoHandler}
						onSelectDate={this.selectDateHandler}
						statusId={status_id}
					/>
					<ChangeSummary
						data={description}
						onChange={this.summaryHandler}
						statusId={status_id}
					/>
					<PublishingSettings
						date={released_at}
						status={status_id}
						onChange={this.settingsHandler}
					/>
					<SelectConcentrators
						selected={test_concentrators}
						value={serial}
						suggestions={concentrators?.filter((item) => {
							return !test_concentrators?.some(
								(el) => el.serial_number === item.serial_number,
							);
						})}
						status={status_id}
						disabled={!concentrator_model}
						onFetch={this.serialFetchHandler}
						onChange={this.serialHandler}
						onClear={this.serialClearHandler}
						onSelected={this.serialSelectHandler}
						onSubmit={this.serialSubmitHandler}
					/>

					{errors && (
						<ul className={s.errors}>
							{errors.map((el, index) => (
								<li key={index}>{el}</li>
							))}
						</ul>
					)}

					<div className={s.btnContainer}>
						<Button
							btnType='type-20'
							clicked={this.submitHandler}
							disabled={loading || this.checkIsDisable()}
						>
							{btnText}
						</Button>
					</div>
				</div>
			</Fragment>
		);
	}
}

ManagePopup.defaultProps = {
	data: {},
	models: [],
	errors: null,
	loading: false,
	onManage: () => {},
	onError: () => {},
};

ManagePopup.propTypes = {
	data: PropTypes.object,
	models: PropTypes.array,
	errors: PropTypes.array,
	loading: PropTypes.bool,
	onManage: PropTypes.func,
	onError: PropTypes.func,
};

export default withTranslation()(ManagePopup);
