import { coreInstance as axios, api } from 'settings';
import { logger } from 'helpers';
import * as actionTypes from '../actionTypes';

export const getHealthcheckStart = () => {
	return {
		type: actionTypes.GET_HEALTHCHECK_START,
	};
};

export const getHealthcheckSuccess = (data) => {
	return {
		type: actionTypes.GET_HEALTHCHECK_SUCCESS,
		data: data,
	};
};

export const getHealthcheck = (options) => {
	return (dispatch) => {
		dispatch(getHealthcheckStart());

		axios
			.get(api.healthcheck_values.summary, { params: options })
			.then((response) => {
				logger('[Action] Get healthcheck');

				dispatch(getHealthcheckSuccess(response.data.data));
			})
			.catch(() => {
				logger('[Action] Get healthcheck fail');
			});
	};
};
