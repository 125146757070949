import React from 'react';
import T from 'prop-types';

const HealthCheckIcon = (props) => (
	<svg {...props} className={props.className}>
		<path
			d='M2.4315 9.03881V4.61141C2.4315 4.17622 2.5907 3.79886 2.90912 3.47932C3.22755 3.15978 3.6036 3 4.03725 3H19.9627C20.3979 3 20.7753 3.15978 21.0948 3.47932C21.4144 3.79886 21.5742 4.17622 21.5742 4.61141V9.03881H19.9627V4.61141H4.03725V9.03881H2.4315ZM4.03725 17.4289C3.6036 17.4289 3.22755 17.2697 2.90912 16.9512C2.5907 16.6328 2.4315 16.2568 2.4315 15.8231V10.453H4.03725V15.8231H19.9627V10.453H21.5742V15.8231C21.5742 16.2568 21.4144 16.6328 21.0948 16.9512C20.7753 17.2697 20.3979 17.4289 19.9627 17.4289H4.03725ZM1.5 20.3248V18.843H22.5V20.3248H1.5ZM2.4315 10.453V9.03881H8.23117C8.35793 9.03881 8.4796 9.07023 8.59618 9.13309C8.71276 9.19594 8.80247 9.29021 8.86532 9.41591L10.1632 12.0613L13.2435 6.61683C13.3026 6.49113 13.392 6.40078 13.5117 6.34579C13.6315 6.29079 13.7561 6.2633 13.8855 6.2633C14.015 6.2633 14.1377 6.29032 14.2536 6.34437C14.3696 6.39844 14.459 6.48738 14.5219 6.6112L15.7134 9.03881H21.5742V10.453H15.3381C15.1875 10.453 15.0463 10.4159 14.9145 10.3418C14.7827 10.2676 14.6836 10.1602 14.6172 10.0195L13.8384 8.45522L10.7565 13.8356C10.698 13.9651 10.6102 14.0622 10.4932 14.1269C10.3762 14.1916 10.2533 14.224 10.1246 14.224C9.99216 14.224 9.867 14.1926 9.74916 14.1297C9.63131 14.0669 9.54097 13.9726 9.47811 13.8469L7.78114 10.453H2.4315Z'
			fill='#26251F'
		/>
	</svg>
);

HealthCheckIcon.defaultProps = {
	fill: 'none',
	width: 24,
	height: 24,
};

HealthCheckIcon.propTypes = {
	className: T.string,
};

export default HealthCheckIcon;
