import React from 'react';
import PropTypes from 'prop-types';

import s from './style.module.scss';

const Legend = (props) => {
	const { data, colors } = props;

	return (
		<ul className={`${s.legend} ${props.className}`}>
			{data.length
				? data.map((item, index) => (
						<li className={s.legendItem} key={index}>
							<span
								className={s.marker}
								style={{ backgroundColor: colors[item.name] }}
							/>
							{item.name}
						</li>
				  ))
				: null}
		</ul>
	);
};

Legend.defaultProps = {
	className: '',
	colors: {},
	data: [],
};

Legend.propTypes = {
	className: PropTypes.string,
	colors: PropTypes.object,
	data: PropTypes.array,
};

export default Legend;
