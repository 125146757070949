import React from 'react';
import moment from 'moment';
import { permissions } from 'helpers';
import { NotificationsTwo, DownloadThree } from 'components/Icons';

import s from '../style.module.scss';
import { withTranslation } from 'react-i18next';

const DataLogButton = (props) => {
	const { logs, link, isSending, sendUpdateNotification, t } = props;

	let dataLogBtn = (
		<div className={[s.logsBtn, s.disabledBtn].join(' ')}>
			<span>
				<DownloadThree /> {t('download_latest_data_log')}
			</span>
			<span className={s.date}>
				{logs && moment(logs.updated_at).format('DD MMM YYYY')}
			</span>
		</div>
	);

	if (logs) {
		dataLogBtn = (
			<a
				className={s.logsBtn}
				href={link}
				target='_blank'
				rel='noopener noreferrer'
			>
				<DownloadThree /> {t('download_latest_data_log')}
				<span className={s.date}>
					{logs && moment(logs.updated_at).format('DD MMM YYYY')}
				</span>
			</a>
		);
	}

	return (
		<div className={s.groupsBtn}>
			{dataLogBtn}

			{permissions.check('notifications.data_log') && (
				<button
					className={s.logsBtn}
					disabled={isSending}
					onClick={() => sendUpdateNotification('logs')}
				>
					<NotificationsTwo /> {t('send_notification_to_transfer_data_log')}
				</button>
			)}
		</div>
	);
};

export default withTranslation()(DataLogButton);
