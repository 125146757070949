import * as actionTypes from '../../actions/actionTypes';

const initialState = {
	data: {
		group: {
			id: null,
			last_month: null,
			all: null,
		},
		total: {
			last_month: null,
			all: null,
		},
	},
	loading: false,
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_BATTERY_STATUS_START:
			return { ...state, loading: true };
		case actionTypes.GET_BATTERY_STATUS_SUCCESS:
			return {
				loading: false,
				data: { ...state.data, total: action.payload.data },
			};
		case actionTypes.GET_BATTERY_STATUS_SUCCESS_BY_ID:
			return {
				loading: false,
				data: { ...state.data, group: action.payload.data },
			};

		default:
			return state;
	}
};

export default reducer;
