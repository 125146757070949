const serializer = (obj) => {
	const arr = [];
	let str = '';

	for (let key in obj) {
		let item = obj[key];

		if (item === null) continue;

		if (typeof item === 'object') {
			if (Array.isArray(item)) {
				item = item.map((el) => {
					if (typeof el === 'object') {
						if (Array.isArray(el)) {
							return el.join(';');
						} else {
							const deptArr = [];

							for (let name in el) {
								if (el[name] !== null) {
									deptArr.push(`${name}:${el[name]}`);
								}
							}

							return deptArr.join(';');
						}
					}

					return el;
				});

				str = `${key}=${item.join(';')}`;
				arr.push(str);
				continue;
			} else {
				const deptArr = [];

				for (let name in item) {
					if (item[name] !== null) {
						deptArr.push(`${name}:${item[name]}`);
					}
				}

				arr.push(`${key}=${deptArr.join(';')}`);
				continue;
			}
		}

		str = `${key}=${item}`;
		arr.push(str);
	}

	return arr.join('&');
};

export default serializer;
