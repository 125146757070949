import commons from './commons';

import auth from './containers/auth';
import concentrators from './containers/concentrators';
import dashboard from './containers/dashboard';
import firmware from './containers/firmware';
import pushnotifications from './containers/pushnotifications';
import users from './containers/users';
import usersproviders from './containers/usersproviders';
import engineering from './containers/engineering';
import profile from './containers/profile';
import reports from './containers/reports';

import popups from './components/popups';
import table from './components/table';
import tabledatefilter from './components/tabledatefilter';

export default {
	...commons,
	...auth,
	...concentrators,
	...dashboard,
	...firmware,
	...pushnotifications,
	...users,
	...usersproviders,
	...engineering,
	...profile,
	...reports,
	...popups,
	...table,
	...tabledatefilter,
};
