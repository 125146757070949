import React from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';

import s from './style.module.scss';

const getTimesList = (step = 15) => {
	let min = 0;
	const timesList = [];

	while (min <= 1425) {
		const h = (min / 60) | 0;
		const m = min % 60 | 0;

		timesList.push({
			value: min,
			view: `${h > 9 ? '' + h : '0' + h}:${m > 9 ? '' + m : '0' + m}`,
		});

		min = min + step;
	}

	return timesList;
};

class TimePicker extends React.Component {
	constructor(props) {
		super(props);

		this.state = {
			isOpen: false,
			value: 0,
		};

		this.timesList = getTimesList();
	}

	setOpen = (state) => {
		this.setState({
			isOpen: state,
		});
	};

	handleClickOutside = () => {
		this.setOpen(false);
	};

	handleSelect = (value) => {
		this.props.onChange(value);
		this.setState({
			isOpen: false,
			value,
		});
	};

	getTimeValue = (min) => {
		const h = (min / 60) | 0;
		const m = min % 60 | 0;

		return {
			value: min,
			view: `${h > 9 ? '' + h : '0' + h}:${m > 9 ? '' + m : '0' + m}`,
		};
	};

	render() {
		const { isOpen, value } = this.state;

		const { defaultValue } = this.props;

		return (
			<div className={`${s.timePicker} ${this.props.className}`}>
				<div className={s.trigger} onClick={() => this.setOpen(!isOpen)}>
					<span className={s.triggerContent}>
						{this.getTimeValue(defaultValue || value).view}
					</span>
				</div>
				{isOpen ? (
					<div className={s.timeListContainer}>
						<div
							className={s.triggerView}
							onClick={() => this.setOpen(!isOpen)}
						>
							<span className={s.triggerContent}>
								{this.getTimeValue(defaultValue || value).view}
							</span>
						</div>
						<div className={s.listContainer}>
							<ul className={s.timeList}>
								{this.timesList.map((time) => (
									<li
										className={s.time}
										value={time.value}
										key={time.value}
										onClick={() => this.handleSelect(time.value)}
									>
										{time.view}
									</li>
								))}
							</ul>
						</div>
					</div>
				) : null}
				<input
					type='hidden'
					value={this.getTimeValue(defaultValue || value).value}
				/>
			</div>
		);
	}
}

TimePicker.propTypes = {
	className: PropTypes.string,
	onChange: PropTypes.func,
	defaultValue: PropTypes.number,
};

TimePicker.defaultProps = {
	className: '',
	selectedValue: '',
	onChange: () => {},
};

export default onClickOutside(TimePicker);
