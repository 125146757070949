import s from './style.module.scss';
import i18next from 'i18next';

const initialStateCreator = (initials = {}) => {
	try {
		const { role, provider, step, selectedRole, selectedProvider } = initials;
		const state = {
			controls: {
				newProvider: {
					name: {
						elementType: 'input',
						elementConfig: {
							type: 'text',
							placeholder: 'name',
						},
						className: s.input,
						value: '',
						validation: {
							required: true,
							isEmail: true,
						},
						valid: false,
						errorMsg: i18next.t('auth_invalid_email_name'),
						touched: false,
					},
					pin: {
						elementType: 'mask',
						elementConfig: {
							type: 'text',
							placeholder: '__',
							guide: true,
						},
						className: s.pinInput,
						value: '',
						validation: {
							required: true,
							isEmail: true,
						},
						valid: false,
						errorMsg: i18next.t('auth_invalid_email_name'),
						touched: false,
					},
				},
			},
			providerView: null,
			formValidMessage: null,
			newProviderData: null,
			codePartTwo: null,
			step: step || null,
			buttonEnabled: true,
			userbuttonEnabled: true,
			selectedProvider: selectedProvider || null,
			selectedRole: selectedRole || null,
		};

		if (role) {
			state.controls.role = role;
		}

		if (provider) {
			state.controls.provider = provider;
		}

		return state;
	} catch (e) {
		console.error(e);
	}
};

export default initialStateCreator;
