import moment from 'moment';
import { createSelector } from 'reselect';

const getRecentError = (state) => state.dashboard.recentError.data;
const getRecentErrorPeriod = (state) => state.dashboard.recentError.period;
const getParams = (state) => state.dashboard.params;
const getLocation = (state) => state.locations.data;
const getLocationsCount = (state) => state.locations.count;
const getHeadings = (state) => state.locations.headings;
const getModels = (state) => state.models.data;
const getConcentratorsByHour = (state) => state.dashboard.connected.byHour;
const getCurrentlyConcentrators = (state) => state.dashboard.connected.current;
const getMonthlyConcentrators = (state) => state.dashboard.connected.month;
const getTotalConcentrators = (state) => state.dashboard.connected.totalRows;
const getUserDeviceData = (state) => state.dashboard.userDevice.data;
const getUserDeviceNames = (state) => state.dashboard.userDevice.names;
const getSoftwareVersions = (state) => state.dashboard.softwareVersion.data;
const getCurrentUser = (state) => state.user.data;
const getConnectedByTime = (state) => state.dashboard.appConnected.rows;
const getPinCodes = (state) => {
	state.pinCodes?.data &&
		state.pinCodes.data.forEach((el) => {
			if (el.fullLabel) return;
			el.fullLabel = el.label += el.description ? ` ${el.description}` : '';
		});
	return state.pinCodes?.data && state.pinCodes.data;
};
const getColumnLifeData = (state) =>
	state.dashboard?.columnLife?.data &&
	state.dashboard.columnLife.data.sort(
		(a, b) => moment(b.month_updated_at) - moment(a.month_updated_at),
	);
const getProviders = (state) =>
	state.providers?.data &&
	state.providers.data.map((el) => {
		el.provider_codes =
			el.provider_codes &&
			el.provider_codes.map((pin) => {
				pin.label = `${pin.code_part_1}-${pin.code_part_2}`;
				pin.code_part_3 &&
					(pin.label += `-${pin.code_part_3.slice(
						0,
						2,
					)}-${pin.code_part_3.slice(-3)} `);
				pin.description && (pin.label += pin.description);
				return pin;
			});
		return el;
	});
const getScriptLoaded = (state) => state.auth.scriptLoaded;

export const makeGetAllDashboardState = () =>
	createSelector(
		getParams,
		getLocation,
		getLocationsCount,
		getHeadings,
		getPinCodes,
		getModels,
		getProviders,
		getRecentError,
		getRecentErrorPeriod,
		getConcentratorsByHour,
		getCurrentlyConcentrators,
		getMonthlyConcentrators,
		getTotalConcentrators,
		getUserDeviceData,
		getUserDeviceNames,
		getCurrentUser,
		getSoftwareVersions,
		getColumnLifeData,
		getConnectedByTime,
		getScriptLoaded,
		(
			params,
			locationData,
			countData,
			headingsData,
			pinCodesData,
			modelsData,
			providersData,
			recentErrorData,
			recentErrorPeriod,
			connectedByHour,
			currentlyConnected,
			monthlyConnected,
			totalConnected,
			userDeviceData,
			userDeviceNames,
			currentUserData,
			softwareVersionsData,
			columnLifeData,
			appConnected,
			scriptLoaded,
		) => ({
			params,
			locationData,
			countData,
			headingsData,
			pinCodesData,
			modelsData,
			providersData,
			recentErrorData,
			recentErrorPeriod,
			connectedByHour,
			currentlyConnected,
			monthlyConnected,
			totalConnected,
			userDeviceData,
			userDeviceNames,
			currentUserData,
			softwareVersionsData,
			columnLifeData,
			appConnected,
			scriptLoaded,
		}),
	);
