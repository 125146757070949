import React from 'react';
import T from 'prop-types';

const DownloadTwo = (props) => (
	<svg {...props} className={props.className}>
		<path d='M19 21v-2h2v4H3v-4h2v2h14zm-8.035-6.791v-12.2h2v12.2l5.6-5.6 1.4 1.4-8 8-8-8 1.4-1.4 5.6 5.6z' />
	</svg>
);

DownloadTwo.defaultProps = {
	fill: '#6AB221',
	width: 24,
	height: 24,
	viewBox: '0 0 24 24',
};

DownloadTwo.propTypes = {
	className: T.string,
};

export default DownloadTwo;
