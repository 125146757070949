import { createSelector } from 'reselect';

const getLoading = (state) => state.loading;
const getLoadingAll = (state) => state.firmware.all.loading;
const getRequestParams = (state) => state.firmware.all.requestParams;
const getFirmwaresHistory = (state) => {
	const { data } = state.firmware.all;

	return (
		data &&
		data
			.filter((el) => el.status_id <= 3)
			.sort((a, b) => parseInt(a.version, 10) - parseInt(b.version, 10))
			.reverse()
	);
};
const getFirmwaresTesting = (state) => {
	const { data } = state.firmware.all;

	return (
		data &&
		data
			.filter((el) => el.status_id === 4)
			.sort((a, b) => parseInt(a.version, 10) - parseInt(b.version, 10))
			.reverse()
	);
};
const getFirmwaresDrafts = (state) => {
	const { data } = state.firmware.all;

	return (
		data &&
		data
			.filter((el) => el.status_id === 5 && el.filepath)
			.sort((a, b) => parseInt(a.version, 10) - parseInt(b.version, 10))
			.reverse()
	);
};
const getRecentAddedFirmware = (state) => {
	const { data } = state.firmware.all;
	return (
		data &&
		data
			.sort(
				(a, b) =>
					new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime(),
			)
			.slice(0, 10)
	);
};
const getFirmwaresUnknown = (state) => {
	const { data } = state.firmware.all;

	return (
		data &&
		data
			.filter((el) => el.status_id === 5 && !el.filepath)
			.sort((a, b) => parseInt(a.version, 10) - parseInt(b.version, 10))
			.reverse()
	);
};
const getCurrentFirmware = (state) => {
	const { data } = state.firmware.current;

	if (data) {
		state.models.data.forEach((el) => {
			if (el.id === data.concentrator_model_id) {
				data.concentrator_model = el;
			}
		});
	}

	return data;
};
const getModels = (state) => {
	const { data } = state.models;

	if (data) {
		data.forEach((el) => {
			el.displayText = el.full_description;
		});
	}

	return data || [];
};

export const makeGetAllFirmwaresState = () =>
	createSelector(
		getLoading,
		getLoadingAll,
		getModels,
		getFirmwaresHistory,
		getFirmwaresTesting,
		getFirmwaresDrafts,
		getRecentAddedFirmware,
		getCurrentFirmware,
		getRequestParams,
		getFirmwaresUnknown,
		(
			globalLoading,
			loading,
			models,
			history,
			testing,
			drafts,
			recentfirmware,
			current,
			params,
			unknown,
		) => ({
			globalLoading,
			loading,
			models,
			history,
			testing,
			drafts,
			recentfirmware,
			current,
			params,
			unknown,
		}),
	);
