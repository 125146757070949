import React from 'react';
import { useState, useEffect, useRef } from 'react';
import sOld from '../style.module.scss';
import sLatest from '../newStyle.module.scss';

const AutoComplete = (props) => {
	const [inputValue, setInputValue] = useState(props.value || '');
	const [showSuggestions, setShowSuggestions] = useState(false);
	const suggestions = props.data
		? props.data.filter((data) =>
				data.toLowerCase().includes(inputValue.toLowerCase()),
		  )
		: '';
	const autocompleteRef = useRef();

	useEffect(() => {
		const handleClick = (event) => {
			if (
				autocompleteRef.current &&
				!autocompleteRef.current.contains(event.target)
			) {
				setShowSuggestions(false);
			}
		};
		document.addEventListener('click', handleClick);
		return () => {
			document.removeEventListener('click', handleClick);
		};
	}, []);

	useEffect(() => {
		setInputValue(props.value || '');
	}, [props.value]);

	const handleChange = (event) => {
		if (event.target.value === '') {
			props.onSubmit(event.target.value);
			setShowSuggestions(true);
		}
		setInputValue(event.target.value);
	};
	const clear = () => {
		setShowSuggestions(true);
	};

	const handleSuggestionClick = (suggetion) => {
		props.onSubmit(suggetion);
		setInputValue(suggetion);
		setShowSuggestions(false);
	};
	const s = props.newDesign ? sLatest : sOld;
	return (
		<div
			className={props.isDisabled ? s.selectListDisabled : s.selectList}
			ref={autocompleteRef}
		>
			<input
				value={inputValue}
				onChange={handleChange}
				onClick={clear}
				className={props.isDisabled ? s.selectDisabled : s.select}
				placeholder={props.label}
				onFocus={() => setShowSuggestions(true)}
				disabled={props.isDisabled}
			/>
			{props.newDesign && (
				<>
					{' '}
					<span className={s.borderRight}></span>
					<span
						className={(s.svgContainer, props.isDisabled ? s.disabled : '')}
					>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							onClick={() => setShowSuggestions(true)}
						>
							<path d='M7 10L12 15L17 10H7Z' fill='#999999' />
						</svg>
					</span>
				</>
			)}

			{showSuggestions && (
				<ul className={s.list}>
					{suggestions &&
						suggestions.map((suggestion) => (
							<li
								onClick={() => handleSuggestionClick(suggestion)}
								key={suggestion}
							>
								{suggestion}
							</li>
						))}
				</ul>
			)}
		</div>
	);
};
export default AutoComplete;
