import React from 'react';
import T from 'prop-types';

const LocationTwo = (props) => (
	<svg {...props} className={props.className}>
		<path d='M12 23c-3.06 0-9-6.555-9-12a9 9 0 1 1 18 0c0 5.445-5.94 12-9 12zm0-2c.469 0 2.331-1.277 3.822-2.922C17.802 15.894 19 13.408 19 11a7 7 0 0 0-14 0c0 2.408 1.199 4.894 3.178 7.078C9.67 19.723 11.531 21 12 21zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6zm0-1a2 2 0 1 0 0-4 2 2 0 0 0 0 4z' />
	</svg>
);

LocationTwo.defaultProps = {
	fill: '#999',
	width: 24,
	height: 24,
	viewBox: '0 0 24 24',
};

LocationTwo.propTypes = {
	className: T.string,
};

export default LocationTwo;
