import { createSelector } from 'reselect';

const getProfiles = (state) => state.reports.profiles.data;
const getHasMoreProfiles = (state) => state.reports.profiles.hasMoreData;
const getTableLoading = (state) => state.reports.profiles.loading;
const getRequestParams = (state) => state.reports.profiles.requestParams;
const getActiveFilters = (state) => state.reports.profiles.filters;
const getFirmwares = (state) => state.reports.firmwares.data;
const getModels = (state) => state.models.data;
const getProviders = (state) => state.providers.data;
const getCurrentUser = (state) => state.user.data;
const getSearch = (state) => state.concentrators.profiles.searchData;

const getErrors = (state) => {
	const obj = {};
	const data = [];
	let i = 1;

	state.errors.data.forEach((el) => {
		if (obj[el.code_string]) {
			return obj[el.code_string].push(el);
		}

		obj[el.code_string] = [el];
	});

	for (let key in obj) {
		const codes = [];
		const concentrator_model_ids = [];
		obj[key].forEach((el) => {
			codes.push(el.code);
		});
		obj[key].forEach((el) => {
			concentrator_model_ids.push(el.concentrator_model_id);
		});

		data.push({
			id: i,
			code: codes,
			code_string: obj[key][0].code_string,
			description: obj[key][0].description,
			concentrator_model_id: concentrator_model_ids,
			error_id: obj[key][0].id,
		});

		i++;
	}

	return data;
};
const getStates = (state) => {
	const other = {
		state_name: 'Other',
		id: [],
	};

	const data = state.states.data.filter((el) => {
		if (
			el.country_id === 238 &&
			el.state !== 'AA' &&
			el.state !== 'AE' &&
			el.state !== 'AP'
		) {
			return true;
		} else {
			other.id.push(el.id);
			return false;
		}
	});

	data.push(other);

	return data;
};
const getPinCodes = (state) => {
	state.pinCodes.data.forEach((el) => {
		if (el.fullLabel) return;
		el.fullLabel = el.label += el.description ? ` ${el.description}` : '';
	});
	return state.pinCodes.data;
};

export const makeGetAllReportsState = () =>
	createSelector(
		getProfiles,
		getHasMoreProfiles,
		getTableLoading,
		getRequestParams,
		getActiveFilters,
		getCurrentUser,
		getErrors,
		getSearch,
		getFirmwares,
		getStates,
		getModels,
		getProviders,
		getPinCodes,
		(
			profilesData,
			hasMoreProfilesData,
			tableLoading,
			requestParams,
			activeFilters,
			currentUserData,
			errorsData,
			searchData,
			firmwaresData,
			statesData,
			modelsData,
			providersData,
			pinCodesData,
		) => ({
			profilesData,
			hasMoreProfilesData,
			tableLoading,
			requestParams,
			activeFilters,
			currentUserData,
			errorsData,
			searchData,
			firmwaresData,
			statesData,
			modelsData,
			providersData,
			pinCodesData,
		}),
	);
