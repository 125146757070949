import React from 'react';
import T from 'prop-types';

const Users = (props) => (
	<svg {...props} className={props.className}>
		<path d='M18.5 9.3c.9-.6 1.5-1.6 1.5-2.8C20 4.6 18.4 3 16.5 3c-1.8 0-3.2 1.4-3.5 3.1C12.1 4.8 10.7 4 9 4 6.2 4 4 6.2 4 9c0 1.5.6 2.7 1.6 3.7C2.3 14 0 17.2 0 21c0 .6.4 1 1 1s1-.4 1-1c0-3.9 3.1-7 7-7 3.5 0 6.4 2.6 6.9 6H6c-.6 0-1 .4-1 1s.4 1 1 1h11c.6 0 1-.4 1-1 0-3.8-2.3-7-5.6-8.3 1-.9 1.6-2.2 1.6-3.7v-.1c.6.7 1.5 1.1 2.5 1.1 2.9 0 5.2 2.2 5.5 5h-3.5c-.3 0-.5.2-.5.5s.2.5.5.5h4c.3 0 .5-.2.5-.5 0-2.9-1.9-5.3-4.5-6.2zM6 9c0-1.7 1.3-3 3-3s3 1.3 3 3-1.3 3-3 3-3-1.3-3-3zm8-2.5C14 5.1 15.1 4 16.5 4S19 5.1 19 6.5 17.9 9 16.5 9 14 7.9 14 6.5z' />
	</svg>
);

Users.defaultProps = {
	fill: '#999',
	width: 24,
	height: 24,
	viewBox: '0 0 24 24',
};

Users.propTypes = {
	className: T.string,
};

export default Users;
