import React from 'react';
import PropTypes from 'prop-types';
// import { Input } from "components";

import s from '../style.module.scss';
import i18next from 'i18next';

const CheckboxList = (props) => {
	const { data, labelKey, onChange, config, className } = props;

	return (
		<ul className={`${s.checkboxList} ${className}`}>
			{data &&
				data.map((el, index) => (
					<li key={index}>
						<input
							type='checkbox'
							checked={
								config.value[el.id] ||
								config.value[el.provider_id] ||
								config.checkAll
							}
							onClick={(event) => onChange(event, el)}
							onChange={() => {}} // to avoid warning
						/>
						<span>{i18next.t(el[labelKey])}</span>
						{/* <Input
							label={i18next.t(el[labelKey])}
							theme={config.theme}
							newCheckbox={true}
							className={config.className}
							elementType={config.elementType}
							elementConfig={config.elementConfig}
							checked={
								config.value[el.id] ||
								config.value[el.provider_id] ||
								config.checkAll
							}
							changed={(event) => onChange(event, el)}
						/> */}
					</li>
				))}
		</ul>
	);
};

CheckboxList.defaultProps = {
	data: [],
	labelKey: 'label',
	config: {},
	onChange: () => {},
	className: '',
};

CheckboxList.propTypes = {
	data: PropTypes.array,
	labelKey: PropTypes.string,
	config: PropTypes.object,
	onChange: PropTypes.func,
	className: PropTypes.string,
};

export default CheckboxList;
