import React, { PureComponent } from 'react';
import { Button, Input, Select } from 'components';
import { coreInstance as axios, api } from 'settings';
import { checkValidity, logger } from 'helpers';
import { Close, Back } from 'components/Icons';
import { AuxWrap } from 'hoc';
import initialStateCreator from './initialStateCreator';

import s from './style.module.scss';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { handleLabel } from 'helpers';

class AddUser extends PureComponent {
	constructor(props) {
		super(props);
		this.state = initialStateCreator(props);
	}

	isEmailExist = (email, callback) => {
		const options = {
			search: {
				email: email,
			},
			searchFields: {
				email: '=',
			},
		};

		axios
			.get(api.users.index, { params: options })
			.then((response) => {
				logger('[AddUser] Check is email exist');

				if (response.data.data.length) {
					this.setState({
						formValidMessage: {
							email: i18next.t('the_email_has_already_been_taken'),
						},
					});
				} else {
					callback && callback();
				}
			})
			.catch(() => {
				logger('[AddUser] Check is email exist fail');
			});
	};

	inputChangedHandler = (event, name, group) => {
		const target = event.target;
		const value = name === 'pin' ? target.value.toUpperCase() : target.value;

		this.setState((prevState) => {
			const state = { ...prevState.controls };
			const control = group ? state[group][name] : state[name];

			control.value = value;
			control.valid = checkValidity(value, control.validation);

			return { controls: state, formValidMessage: null };
		});
	};

	inputFocusOutHandler = (event, name, group) => {
		this.setState((prevState) => {
			const state = { ...prevState.controls };
			const control = group ? state[group][name] : state[name];

			control.touched = true;

			return { controls: state };
		});
	};

	onItemClick = (data, type) => {
		if (data) {
			this.setState({ buttonEnabled: false });
			if (data.label) {
				this.setState({ userbuttonEnabled: false });
			}
			this.setState((prevState) => {
				const controls = { ...prevState.controls };

				controls[type].checked = { [data.id]: data };

				return { controls: controls };
			});
		} else {
			this.setState({ buttonEnabled: true });
			this.setState({ userbuttonEnabled: true });
		}
	};

	firstStepView = () => {
		const {
			controls: { email },
			formValidMessage,
			buttonEnabled,
		} = this.state;
		const { t } = this.props;
		const nextHandler = () => {
			if (email.valid) {
				this.setState({ step: 2, formValidMessage: null });
			}
		};

		this.validMessage = null;

		if (formValidMessage) {
			this.validMessage = (
				<ul className={s.errorText}>
					{Object.values(formValidMessage).map((error, index) => {
						return <li key={index}>{error}</li>;
					})}
				</ul>
			);
		}

		return (
			<AuxWrap>
				<div>
					<div className={s.titleAlign}>
						<div className={s.iconTitle}>
							<svg
								width='36'
								height='36'
								viewBox='0 0 36 36'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M18 17.538C16.5562 17.538 15.3203 17.0239 14.2922 15.9958C13.2641 14.9677 12.75 13.7318 12.75 12.2881C12.75 10.8443 13.2641 9.60837 14.2922 8.58027C15.3203 7.55215 16.5562 7.03809 18 7.03809C19.4437 7.03809 20.6796 7.55215 21.7077 8.58027C22.7359 9.60837 23.2499 10.8443 23.2499 12.2881C23.2499 13.7318 22.7359 14.9677 21.7077 15.9958C20.6796 17.0239 19.4437 17.538 18 17.538ZM6.75 28.961V25.6265C6.75 24.8919 6.94952 24.2116 7.34857 23.5856C7.7476 22.9597 8.28076 22.4784 8.94806 22.1419C10.4308 21.415 11.9264 20.8698 13.4351 20.5063C14.9437 20.1429 16.4654 19.9612 18 19.9612C19.5346 19.9612 21.0562 20.1429 22.5648 20.5063C24.0735 20.8698 25.5692 21.415 27.0519 22.1419C27.7192 22.4784 28.2523 22.9597 28.6514 23.5856C29.0504 24.2116 29.2499 24.8919 29.2499 25.6265V28.961H6.75ZM8.99996 26.7111H27V25.6265C27 25.3226 26.912 25.0414 26.736 24.7827C26.56 24.5241 26.3211 24.313 26.0192 24.1495C24.7269 23.513 23.4092 23.0308 22.066 22.7029C20.7229 22.375 19.3675 22.2111 18 22.2111C16.6324 22.2111 15.2771 22.375 13.9339 22.7029C12.5908 23.0308 11.273 23.513 9.98074 24.1495C9.67881 24.313 9.43987 24.5241 9.26392 24.7827C9.08795 25.0414 8.99996 25.3226 8.99996 25.6265V26.7111ZM18 15.2881C18.825 15.2881 19.5312 14.9943 20.1187 14.4068C20.7062 13.8193 21 13.113 21 12.2881C21 11.463 20.7062 10.7568 20.1187 10.1693C19.5312 9.5818 18.825 9.28805 18 9.28805C17.175 9.28805 16.4687 9.5818 15.8812 10.1693C15.2937 10.7568 15 11.463 15 12.2881C15 13.113 15.2937 13.8193 15.8812 14.4068C16.4687 14.9943 17.175 15.2881 18 15.2881Z'
									fill='#26251F'
								/>
							</svg>

							<span className={s.titleTypography}>{t('addUserTitle')}</span>
						</div>
						<Close
							className={s.closeBtn}
							width={36}
							height={36}
							fill='#6D6D6D'
							onClick={this.props.close}
						/>
					</div>
					<span className={s.stepAlign}>
						{i18next.t('step_of', { amount: 1, total: 2 })}
					</span>
					<div className={s.contentContainer}>
						<div className={s.addUserContainer}>
							<div className={s.selectHead}>
								<span className={s.subTitleTypography}>
									{t('user_email')}
									<span className={s.mandatory}>{' *'}</span>
								</span>
							</div>
							<Input
								className={email.className}
								elementType={email.elementType}
								elementConfig={email.elementConfig}
								value={email.value}
								invalid={!email.valid}
								invalidMsg={email.errorMsg}
								touched={email.touched}
								changed={(event) => this.inputChangedHandler(event, 'email')}
								onBlur={(event) => this.inputFocusOutHandler(event, 'email')}
								newDesign={true}
							/>
						</div>

						{this.validMessage}

						<div className={s.button}>
							<Button
								btnType='type-21'
								disabled={email.value === '' || buttonEnabled || !email.valid}
								clicked={() => this.isEmailExist(email.value, nextHandler)}
							>
								{i18next.t('next_new')}
								<svg
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
									style={{ marginLeft: '8px' }}
									onClick={() => this.props.close()}
								>
									<path
										d='M16.6269 12.75H4.5V11.25H16.6269L10.9308 5.55383L12 4.5L19.5 12L12 19.5L10.9308 18.4461L16.6269 12.75Z'
										fill='white'
									/>
								</svg>
							</Button>
						</div>
					</div>
				</div>
			</AuxWrap>
		);
	};

	secondStepView = () => {
		const {
			controls,
			providerView,
			newProviderData,
			formValidMessage,
			userbuttonEnabled,
		} = this.state;
		const { addUser, rolesData, close, t } = this.props;
		// const roles = rolesData.filter((el) => {
		// 	if (newProviderData) {
		// 		return el.is_inogen === newProviderData.is_inogen;
		// 	}

		// 	return (
		// 		el.is_inogen === Object.values(controls.provider.checked)[0].is_inogen
		// 	);
		// });
		const UserRoles = rolesData && rolesData.length > 0 ? rolesData : [];

		let backBtn = (
			<Button
				btnType='back'
				clicked={() =>
					this.setState((prevState) =>
						initialStateCreator(this.props, prevState),
					)
				}
			>
				<Back width='36' fill='#6D6D6D' height='36' />
			</Button>
		);

		const createHandler = () => {
			const args = {
				data: {
					email: controls.email.value,
					role_ids: [Object.values(controls.role.checked)[0].id],
					provider_id: Object.values(controls.provider.checked)[0].id,
				},
				success: close,
				error: (err) =>
					this.setState({ formValidMessage: err.response.data.errors }),
			};

			if (providerView === 'new') {
				args.data.provider_id = newProviderData.id;
			}

			addUser(args);
		};

		this.validMessage = null;

		if (formValidMessage) {
			this.validMessage = (
				<ul className={s.errorText}>
					{Object.values(formValidMessage).map((error, index) => {
						return <li key={index}>{error}</li>;
					})}
				</ul>
			);
		}

		return (
			<AuxWrap>
				<div className={s.titleAlign}>
					<div className={s.iconTitle}>
						{backBtn}
						<span className={s.titleTypography}>{t('selectUserRole')}</span>
					</div>
					<Close
						className={s.closeBtn}
						width={36}
						height={36}
						fill='#6D6D6D'
						onClick={this.props.close}
					/>
				</div>
				<span className={s.stepAlign}>
					{t('step_of', { amount: 2, total: 2 })}
				</span>
				<div className={s.contentContainer}>
					<div className={s.addUserContainer}>
						<div className={s.selectField}>
							<div className={s.selectHead}>
								<span className={s.subTitleTypography}>
									{t('user_role')} <span className={s.mandatory}>{'*'}</span>
								</span>
							</div>
							<div></div>
							<Select
								type={'role'}
								className={s.select}
								config={{
									displayKey: 'label',
									label: { path: 'label' },
									placeholder: t('selectUserRole'),
								}}
								data={handleLabel(UserRoles)}
								checked={this.state.controls.role.checked}
								isOpen={this.state.controls.role.isOpen}
								onItemClick={this.onItemClick}
								newDesign={true}
							/>
						</div>
					</div>
				</div>

				{this.validMessage}

				<div className={s.button}>
					<Button
						btnType='type-21'
						disabled={userbuttonEnabled}
						clicked={createHandler}
					>
						{t('add_user')}
					</Button>
				</div>
			</AuxWrap>
		);
	};

	renderSteps = () => {
		let step = this.firstStepView();

		switch (this.state.step) {
			case 2:
				step = this.secondStepView();
				break;
			default:
				break;
		}

		return step;
	};

	render() {
		return this.renderSteps();
	}
}

export default withTranslation()(AddUser);
