import * as actionTypes from './actionTypes';

export const clearConcentrators = () => {
	return {
		type: actionTypes.CLEAR_CONCENTRATORS,
	};
};

export const clearProviders = () => {
	return {
		type: actionTypes.CLEAR_PROVIDERS,
	};
};

export const clearReports = () => {
	return {
		type: actionTypes.CLEAR_REPORTS,
	};
};

export const clearPushNotifications = () => {
	return {
		type: actionTypes.CLEAR_PUSH_NOTIFICATIONS,
	};
};

export const clearFirmware = () => {
	return {
		type: actionTypes.CLEAR_FIRMWARES,
	};
};
