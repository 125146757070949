import React from 'react';
import T from 'prop-types';

const Lock = (props) => (
	<svg {...props} className={props.className}>
		<path d='M2 3v-.995A2 2 0 0 1 3.997 0h1.006C6.106 0 7 .894 7 2.005V3h.494C8.326 3 9 3.671 9 4.502v3.996C9 9.328 8.324 10 7.494 10H1.506A1.503 1.503 0 0 1 0 8.498V4.502C0 3.672.676 3 1.506 3H2zm2.018 4.376A.502.502 0 0 0 4 7.508v.484a.5.5 0 0 0 .5.508.51.51 0 0 0 .5-.508v-.484a.53.53 0 0 0-.017-.132 1 1 0 1 0-.965 0zM3 2.005v.99h3v-.99A.998.998 0 0 0 5.003 1H3.997A1 1 0 0 0 3 2.005z' />
	</svg>
);

Lock.defaultProps = {
	fill: '#D3D3D3',
	width: 9,
	height: 10,
	viewBox: '0 0 9 10',
};

Lock.propTypes = {
	className: T.string,
};

export default Lock;
