import React from 'react';
import T from 'prop-types';

const Search = (props) => (
	<svg {...props} className={props.className}>
		<path d='M17.32 16.906l3.387 3.387a1 1 0 0 1-1.414 1.414l-3.387-3.387a8 8 0 1 1 1.414-1.414zM11 18a6 6 0 1 0 0-12 6 6 0 0 0 0 12zM7.391 8.758a.5.5 0 0 1 .707.707 3.5 3.5 0 0 0 0 4.95.5.5 0 0 1-.707.707 4.5 4.5 0 0 1 0-6.364z' />
	</svg>
);

Search.defaultProps = {
	fill: '#999',
	width: 24,
	height: 24,
	viewBox: '0 0 24 24',
};

Search.propTypes = {
	className: T.string,
};

export default Search;
