import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
	data: [],
	loading: false,
};

const getFirmwaresNotifiableStart = (state) => {
	return updateObject(state, { loading: true });
};

const getFirmwaresNotifiableSuccess = (state, action) => {
	return updateObject(state, { data: action.data, loading: false });
};

const firmwaresNotifiableData = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_FIRMWARES_NOTIFIABLE_START:
			return getFirmwaresNotifiableStart(state, action);
		case actionTypes.GET_FIRMWARES_NOTIFIABLE_SUCCESS:
			return getFirmwaresNotifiableSuccess(state, action);
		default:
			return state;
	}
};

export default firmwaresNotifiableData;
