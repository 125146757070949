import axios from 'axios';
import { logger } from 'helpers';
import { serializer, toast } from 'helpers';
import i18next from 'i18next';

export const coreInstance = axios.create({
	baseURL:
		`${process.env.REACT_APP_CORE_API_URL}${process.env.REACT_APP_API_VERSION}` ||
		'/api/v1/',
	paramsSerializer: (params) => {
		return serializer(params);
	},
});

export const patientInstance = axios.create({
	baseURL:
		`${process.env.REACT_APP_PATIENT_API_URL}${process.env.REACT_APP_API_VERSION}` ||
		'/api/v1/',
	paramsSerializer: (params) => {
		return serializer(params);
	},
});

coreInstance.interceptors.response.use(
	(response) => {
		logger('[Axios - Core] Set axios response interceptors');

		return response;
	},
	(error) => {
		logger('[Axios - Core] Set axios response interceptors fail');

		const { status } = error.response;

		if (status === 401 || status === 403) {
			const isLoggedIn =
				localStorage.getItem('token') || sessionStorage.getItem('token');

			localStorage.removeItem('token');
			sessionStorage.removeItem('token');

			if (isLoggedIn) window.location = '/';
		}

		if (status >= 500) toast.error(i18next.t('oops_something_went_wrong'));

		return Promise.reject(error);
	},
);

patientInstance.interceptors.response.use(
	(response) => {
		logger('[Axios - Patient] Set axios response interceptors');

		return response;
	},
	(error) => {
		logger('[Axios - Patient] Set axios response interceptors fail');

		const { status } = error.response;

		if (status === 401 || status === 403) {
			const isLoggedIn =
				localStorage.getItem('token') || sessionStorage.getItem('token');

			localStorage.removeItem('token');
			sessionStorage.removeItem('token');

			if (isLoggedIn) window.location = '/';
		}

		if (status >= 500) toast.error(i18next.t('oops_something_went_wrong'));

		return Promise.reject(error);
	},
);

export default coreInstance;
