import PropTypes from 'prop-types';
import React, { useRef, useEffect } from 'react';
import { init, createMarkers } from './methods';

import s from './style.module.scss';

const internals = {};

const ConcentratorsMap = function (props) {
	const { data, headings, onClick } = props;

	// Hooks
	const mapNode = useRef(null);

	// Effects
	useEffect(() => {
		internals.map = init({ node: mapNode.current });
	}, []);
	useEffect(() => {
		(async () => {
			await internals.map;
			createMarkers({ data, headings, onClick });
		})();
	}, [data, headings, onClick]);

	return (
		<div className={s.map}>
			<div ref={mapNode} />
		</div>
	);
};

ConcentratorsMap.defaultProps = {
	data: [],
	headings: [],
	onClick: () => {},
};

ConcentratorsMap.propTypes = {
	data: PropTypes.array,
	headings: PropTypes.array,
	onClick: PropTypes.func,
};

export default ConcentratorsMap;
