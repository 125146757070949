import React, { useRef } from 'react';
import { uniqueClass } from 'config';
import s from './style.module.scss';

const CustomButton = (props) => {
	const btnRef = useRef(null);

	const onBtnClick = () => {
		if (btnRef.current) {
			btnRef.current.setAttribute('disabled', 'disabled');
		}
	};

	return (
		<button
			ref={btnRef}
			disabled={props.disabled}
			data-testid='btn-test-id'
			className={[
				s.btn,
				s[uniqueClass],
				s[props.btnType],
				s[props.btnTheme],
				props.className,
			].join(' ')}
			onClick={(e) => {
				onBtnClick(e);
				if (props.clicked) {
					props.clicked(e);
				}
			}}
		>
			{props.children}
		</button>
	);
};

export default CustomButton;
