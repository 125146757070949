import { createSelector } from 'reselect';

const isAuthenticated = (state) => state.auth.token !== null;
const getLoading = (state) => state.loading;
const getUserName = (state) => state.user.data.name;
const getProvider = (state) => state.user.data.provider || {};
const getUserRoles = (state) => state.user.data.roles;
const getUserPermissions = (state) => state.user.data.role_permissions;
const getTerms = (state) => state.terms.data;
const getUserConsent = (state) => state.user.data.user_consent_data;
const getPortalConsents = (state) => state.consents.portal_consent;
const getUser = (state) => state.user.data;

export const makeGetAllAppState = () =>
	createSelector(
		getLoading,
		isAuthenticated,
		getUserName,
		getProvider,
		getUserRoles,
		getUserPermissions,
		getTerms,
		getUserConsent,
		getPortalConsents,
		getUser,
		(
			loading,
			isAuthenticated,
			userName,
			provider,
			userRoles,
			userPermissions,
			terms,
			userConsent,
			portalConsents,
			currentUser,
		) => ({
			loading,
			isAuthenticated,
			userName,
			provider,
			userRoles,
			userPermissions,
			terms,
			userConsent,
			portalConsents,
			currentUser,
		}),
	);
