import ReactGA from 'react-ga';

const withTracker = (args = {}) => {
	ReactGA.set({
		...args,
	});
	ReactGA.pageview(args.page);
};

export default withTracker;
