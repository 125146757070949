export const errorsParams = {
	orderBy: 'description',
	sortedBy: 'asc',
};
export const modelsParams = {
	orderBy: 'id',
};
export const unresolvedErrorsParams = {
	orderBy: 'happened_at',
	sortedBy: 'desc',
	limit: '20',
	with: 'patients',
	offset: 0,
};
