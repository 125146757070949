import React from 'react';
import { GridView } from '../../../../components';
import * as actions from 'store/actions';
import { connect } from 'react-redux';

const PatientGridView = (props) => {
	const { data, pageSize, updateSelectedRows, selectedRows } = props;
	return (
		<div>
			<GridView
				dataToShow={data}
				loading={props.loading}
				pageSize={pageSize}
				updateSelectedRows={updateSelectedRows}
				selectedRows={selectedRows}
				setPatientId={props.setPatientId}
			/>
		</div>
	);
};
const mapStateToProps = () => {
	return {};
};
const mapDispatchToProps = (dispatch) => {
	return {
		setPatientId: (data) => dispatch(actions.setPatientId(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientGridView);
