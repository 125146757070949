import React from 'react';
import T from 'prop-types';

const Discount = (props) => {
	if (props.newLogo === true) {
		return (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='23'
				height='20'
				viewBox='0 0 23 20'
				fill='none'
				style={{ marginRight: '8px' }}
			>
				<path
					d='M18.1796 10.8051C17.9553 10.8051 17.7677 10.73 17.6167 10.58C17.4657 10.4299 17.3903 10.2434 17.3903 10.0204C17.3903 9.79739 17.4657 9.61286 17.6167 9.46682C17.7677 9.32075 17.9553 9.24772 18.1796 9.24772H21.3773C21.6016 9.24772 21.7893 9.32275 21.9402 9.47282C22.0912 9.6229 22.1667 9.80942 22.1667 10.0324C22.1667 10.2554 22.0912 10.4399 21.9402 10.586C21.7893 10.732 21.6016 10.8051 21.3773 10.8051H18.1796ZM16.9017 19.8353L14.3222 17.9426C14.1527 17.8036 14.0493 17.6318 14.0118 17.4273C13.9743 17.2228 14.0261 17.0285 14.167 16.8446C14.3079 16.6774 14.4821 16.5753 14.6894 16.5384C14.8968 16.5014 15.0937 16.5525 15.2801 16.6915L17.8463 18.5841C18.0247 18.7231 18.1326 18.8949 18.1701 19.0994C18.2075 19.304 18.1558 19.4939 18.0149 19.669C17.874 19.8449 17.6976 19.9514 17.4858 19.9883C17.2739 20.0253 17.0793 19.9743 16.9017 19.8353ZM15.3042 3.28741C15.1178 3.42644 14.9209 3.47746 14.7135 3.4405C14.5062 3.40355 14.332 3.29709 14.1911 3.12112C14.0502 2.95394 13.9984 2.76608 14.0359 2.55756C14.0733 2.34904 14.1768 2.17527 14.3463 2.03624L16.8482 0.164718C17.0258 0.0257179 17.2204 -0.0253089 17.4322 0.0116373C17.6441 0.0485835 17.8204 0.155052 17.9614 0.331044C18.1023 0.498205 18.154 0.686047 18.1165 0.89457C18.0791 1.10309 17.9712 1.27687 17.7928 1.41589L15.3042 3.28741Z'
					fill='#999999'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M4.07685 17.6161V13.0419H2.21415C1.74676 13.0419 1.34422 12.8755 1.00653 12.5425C0.668843 12.2096 0.5 11.8127 0.5 11.3519V8.69305C0.5 8.23224 0.668843 7.83538 1.00653 7.50245C1.34422 7.16952 1.74676 7.00306 2.21415 7.00306H7.24231L11.2877 4.62808C11.5696 4.45984 11.8534 4.45734 12.1391 4.62058C12.4248 4.78383 12.5676 5.02893 12.5676 5.3559V14.6891C12.5676 15.016 12.4248 15.2611 12.1391 15.4244C11.8534 15.5876 11.5696 15.5851 11.2877 15.4169L7.24231 13.0419H5.4253V17.6161H4.07685ZM13.7132 13.6774V6.3676C14.25 6.5 15.0551 7.30799 15.34 7.94041C15.6249 8.57285 15.7674 9.26688 15.7674 10.0225C15.7674 10.7781 15.6249 11.4721 15.34 12.1046C15.0551 12.737 14.25 13.5 13.7132 13.6774ZM11.2192 13.8238V6.22113L7.60344 8.3325H2.21415C2.12273 8.3325 2.03893 8.37005 1.96272 8.44516C1.88654 8.52029 1.84845 8.60291 1.84845 8.69304V11.3519C1.84845 11.4421 1.88654 11.5247 1.96272 11.5998C2.03893 11.6749 2.12273 11.7125 2.21415 11.7125H7.60344L11.2192 13.8238Z'
					fill='#999999'
				/>
			</svg>
		);
	} else {
		return (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='23'
				height='20'
				viewBox='0 0 23 20'
				fill='none'
				style={{ marginRight: '8px' }}
			>
				<path
					d='M18.1796 10.8051C17.9553 10.8051 17.7677 10.73 17.6167 10.58C17.4657 10.4299 17.3903 10.2434 17.3903 10.0204C17.3903 9.79739 17.4657 9.61286 17.6167 9.46682C17.7677 9.32075 17.9553 9.24772 18.1796 9.24772H21.3773C21.6016 9.24772 21.7893 9.32275 21.9402 9.47282C22.0912 9.6229 22.1667 9.80942 22.1667 10.0324C22.1667 10.2554 22.0912 10.4399 21.9402 10.586C21.7893 10.732 21.6016 10.8051 21.3773 10.8051H18.1796ZM16.9017 19.8353L14.3222 17.9426C14.1527 17.8036 14.0493 17.6318 14.0118 17.4273C13.9743 17.2228 14.0261 17.0285 14.167 16.8446C14.3079 16.6774 14.4821 16.5753 14.6894 16.5384C14.8968 16.5014 15.0937 16.5525 15.2801 16.6915L17.8463 18.5841C18.0247 18.7231 18.1326 18.8949 18.1701 19.0994C18.2075 19.304 18.1558 19.4939 18.0149 19.669C17.874 19.8449 17.6976 19.9514 17.4858 19.9883C17.2739 20.0253 17.0793 19.9743 16.9017 19.8353ZM15.3042 3.28741C15.1178 3.42644 14.9209 3.47746 14.7135 3.4405C14.5062 3.40355 14.332 3.29709 14.1911 3.12112C14.0502 2.95394 13.9984 2.76608 14.0359 2.55756C14.0733 2.34904 14.1768 2.17527 14.3463 2.03624L16.8482 0.164718C17.0258 0.0257179 17.2204 -0.0253089 17.4322 0.0116373C17.6441 0.0485835 17.8204 0.155052 17.9614 0.331044C18.1023 0.498205 18.154 0.686047 18.1165 0.89457C18.0791 1.10309 17.9712 1.27687 17.7928 1.41589L15.3042 3.28741Z'
					fill='#999999'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M4.07685 17.6161V13.0419H2.21415C1.74676 13.0419 1.34422 12.8755 1.00653 12.5425C0.668843 12.2096 0.5 11.8127 0.5 11.3519V8.69305C0.5 8.23224 0.668843 7.83538 1.00653 7.50245C1.34422 7.16952 1.74676 7.00306 2.21415 7.00306H7.24231L11.2877 4.62808C11.5696 4.45984 11.8534 4.45734 12.1391 4.62058C12.4248 4.78383 12.5676 5.02893 12.5676 5.3559V14.6891C12.5676 15.016 12.4248 15.2611 12.1391 15.4244C11.8534 15.5876 11.5696 15.5851 11.2877 15.4169L7.24231 13.0419H5.4253V17.6161H4.07685ZM13.7132 13.6774V6.3676C14.25 6.5 15.0551 7.30799 15.34 7.94041C15.6249 8.57285 15.7674 9.26688 15.7674 10.0225C15.7674 10.7781 15.6249 11.4721 15.34 12.1046C15.0551 12.737 14.25 13.5 13.7132 13.6774ZM11.2192 13.8238V6.22113L7.60344 8.3325H2.21415C2.12273 8.3325 2.03893 8.37005 1.96272 8.44516C1.88654 8.52029 1.84845 8.60291 1.84845 8.69304V11.3519C1.84845 11.4421 1.88654 11.5247 1.96272 11.5998C2.03893 11.6749 2.12273 11.7125 2.21415 11.7125H7.60344L11.2192 13.8238Z'
					fill='#999999'
				/>
			</svg>
		);
	}
};

Discount.defaultProps = {
	fill: '#E04829',
	width: 22,
	height: 22,
	viewBox: '0 0 22 22',
	style: { marginRight: '8px' },
};

Discount.propTypes = {
	className: T.string,
};

export default Discount;
