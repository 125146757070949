import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'components';
import { AuxWrap } from 'hoc';

import s from './style.module.scss';
import i18next from 'i18next';

class DropdownList extends PureComponent {
	state = {
		open: {},
	};

	itemClick = (id, obj) => {
		this.setState((prevState) => {
			const state = { ...prevState.open };

			// eslint-disable-next-line no-prototype-builtins
			if (state.hasOwnProperty(id)) {
				delete state[id];
			} else {
				state[id] = obj;
			}

			return { open: state };
		});
	};

	clickAll = (value) => {
		this.setState((prevState) => {
			const newData = prevState.data.map((el) => {
				el.isOpen = value || false;

				return el;
			});

			return { data: newData, showAll: !prevState.showAll };
		});
	};

	renderList = () => {
		const { data, newDesign } = this.props;
		const { open } = this.state;

		return (
			<AuxWrap>
				<ul className={newDesign ? s.newList : s.list}>
					{data.map((el, index) => {
						const classes = [newDesign ? s.newList : s.listItem];
						let content;

						// eslint-disable-next-line no-prototype-builtins
						if (open.hasOwnProperty(el.id)) {
							classes.push(s.open);
							content = <div className={s.borderStyling}>{el.content}</div>;
						}

						return (
							<li key={index} className={classes.join(' ')}>
								<span
									className={s.label}
									onClick={() => this.itemClick(el.id, el)}
								>
									{i18next.t(el.label)}
								</span>
								<div className={s.content}>{content}</div>
							</li>
						);
					})}
				</ul>
			</AuxWrap>
		);
	};

	render() {
		const { className } = this.props;
		const classes = [s.dropdownList, className];

		return (
			<div className={classes.join(' ')}>
				{!this.props.data.length ? <Spinner /> : this.renderList()}
			</div>
		);
	}
}

DropdownList.defaultProps = {
	data: [],
	config: {},
};

DropdownList.propTypes = {
	data: PropTypes.array,
	config: PropTypes.object,
};

export default DropdownList;
