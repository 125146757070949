import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
	data: null,
};

const getFirmwares = (state, action) => {
	const obj = {};
	const data = [];
	let i = 1;

	action.data.forEach((el) => {
		if (obj[el.version]) {
			return obj[el.version].push(el);
		}

		obj[el.version] = [el];
	});

	for (let key in obj) {
		const codes = [];

		obj[key].forEach((el) => {
			codes.push(el.id);
		});

		data.push({
			id: i,
			version: obj[key][0].version,
			codes: codes,
			description: obj[key][0].description,
		});

		i++;
	}

	return updateObject(state, { data: data });
};

const clearFirmwares = () => {
	return initialState;
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_FIRMWARES_SUCCESS:
			return getFirmwares(state, action);

		case actionTypes.CLEAR_REPORTS:
			return clearFirmwares(state, action);

		default:
			return state;
	}
};

export default reducer;
