import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
	data: null,
};

const getPushNotificationsFirmwares = (state, action) => {
	return updateObject(state, { data: action.data });
};

const firmwares = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_PUSH_NOTIFICATIONS_FIRMWARES_SUCCESS:
			return getPushNotificationsFirmwares(state, action);

		default:
			return state;
	}
};

export default firmwares;
