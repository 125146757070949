import { coreInstance as axios, api } from 'settings';
import { logger } from 'helpers';
import * as actionTypes from '../../actionTypes';
import { engineeringParams } from '../../../../containers/EngineeringDashboard/Configs/constants';

const getLifeClockStart = () => {
	return {
		type: actionTypes.GET_LIFE_CLOCK_START,
	};
};

const getLifeClockSuccess = (data) => {
	return {
		type: actionTypes.GET_LIFE_CLOCK_SUCCESS,
		data: data,
	};
};

export const getLifeClock = (args = {}) => {
	const params = {
		...args.params,
		search: {
			...(args.params && args.params.search),
			param_id: engineeringParams.LIFE_CLOCK,
		},
		searchFields: {
			...(args.params && args.params.searchFields),
			param_id: 'in',
		},
		orderBy: 'range',
	};

	return (dispatch) => {
		dispatch(getLifeClockStart());

		axios
			.get(api.engineering_params_statistics.index, { params })
			.then((response) => {
				logger('[Action] Get life clock');

				dispatch(getLifeClockSuccess(response.data.data));
			})
			.catch(() => {
				logger('[Action] Get life clock fail');
			});
	};
};
