import { combineReducers } from 'redux';
import profiles from './profiles';
import firmwares from './firmwares';

const reducer = combineReducers({
	profiles,
	firmwares,
});

export default reducer;
