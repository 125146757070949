import React from 'react';
import Dropzone from 'react-dropzone';
import { Delete } from 'components/Icons';

import s from '../style.module.scss';
import i18next from 'i18next';

const DropZone = ({
	file,
	disabled,
	onDrop,
	onClear,
	onDropAccepted,
	onDropRejected,
}) => {
	return (
		<Dropzone
			className={s.dropZone}
			multiple={false}
			onDrop={onDrop}
			onDropAccepted={onDropAccepted}
			onDropRejected={onDropRejected}
			disabled={disabled}
		>
			{({ isDragAccept, isDragReject }) => {
				if (isDragAccept) {
					return (
						<div className={[s.inner, s.accept].join(' ')}>
							This file is authorized
						</div>
					);
				}
				if (isDragReject) {
					return (
						<div className={[s.inner, s.reject].join(' ')}>
							This file is not authorized
						</div>
					);
				}
				if (file instanceof Object || typeof file === 'string') {
					let filename = file;

					if (typeof file === 'object') {
						filename = file.name;
					}

					return (
						<div className={s.inner}>
							<div>
								<div className={s.icon}>
									<svg
										width='33'
										height='32'
										viewBox='0 0 33 32'
										fill='none'
										xmlns='http://www.w3.org/2000/svg'
									>
										<path
											d='M15.5 18.6005V23.5134C15.5 23.7972 15.5957 24.0348 15.7872 24.2262C15.9786 24.4177 16.2162 24.5134 16.5 24.5134C16.7837 24.5134 17.0213 24.4177 17.2128 24.2262C17.4042 24.0348 17.4999 23.7972 17.4999 23.5134V18.6005L19.2641 20.3647C19.3632 20.4638 19.4748 20.5382 19.5987 20.5878C19.7226 20.6373 19.8466 20.6587 19.9705 20.6519C20.0944 20.645 20.2171 20.6168 20.3384 20.5673C20.4598 20.5177 20.57 20.4433 20.6692 20.3442C20.8623 20.1373 20.9623 19.9032 20.9692 19.6416C20.976 19.3801 20.876 19.1459 20.6692 18.9391L17.3435 15.6135C17.2188 15.4887 17.0871 15.4006 16.9487 15.3494C16.8102 15.2981 16.6607 15.2724 16.5 15.2724C16.3393 15.2724 16.1897 15.2981 16.0513 15.3494C15.9128 15.4006 15.7812 15.4887 15.6564 15.6135L12.3308 18.9391C12.1325 19.1374 12.0346 19.3694 12.0372 19.6352C12.0397 19.901 12.1444 20.1373 12.3513 20.3442C12.5581 20.5373 12.7923 20.6373 13.0538 20.6442C13.3153 20.651 13.5495 20.551 13.7564 20.3442L15.5 18.6005ZM8.91027 28.6673C8.23676 28.6673 7.66667 28.4339 7.2 27.9673C6.73333 27.5006 6.5 26.9305 6.5 26.257V5.74425C6.5 5.07074 6.73333 4.50065 7.2 4.03398C7.66667 3.56732 8.23676 3.33398 8.91027 3.33398H18.5026C18.8239 3.33398 19.1329 3.39639 19.4295 3.52119C19.726 3.64596 19.9837 3.81775 20.2026 4.03655L25.7974 9.63135C26.0162 9.85017 26.188 10.1079 26.3127 10.4045C26.4375 10.701 26.4999 11.01 26.4999 11.3314V26.257C26.4999 26.9305 26.2666 27.5006 25.7999 27.9673C25.3333 28.4339 24.7632 28.6673 24.0897 28.6673H8.91027ZM18.5 10.1288V5.33395H8.91027C8.80769 5.33395 8.71366 5.37669 8.62817 5.46215C8.5427 5.54764 8.49997 5.64167 8.49997 5.74425V26.257C8.49997 26.3596 8.5427 26.4536 8.62817 26.5391C8.71366 26.6246 8.80769 26.6673 8.91027 26.6673H24.0897C24.1922 26.6673 24.2863 26.6246 24.3718 26.5391C24.4572 26.4536 24.5 26.3596 24.5 26.257V11.3339H19.7051C19.3616 11.3339 19.0748 11.219 18.8449 10.989C18.615 10.7591 18.5 10.4724 18.5 10.1288Z'
											fill='#6C7DDF'
										/>
									</svg>
									{disabled || (
										<Delete className={s.delete} onClick={onClear} />
									)}
								</div>
								{filename}
							</div>
						</div>
					);
				}
				return (
					<div className={s.inner}>
						<svg
							width='33'
							height='32'
							viewBox='0 0 33 32'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
						>
							<path
								d='M15.5 18.6005V23.5134C15.5 23.7972 15.5957 24.0348 15.7872 24.2262C15.9786 24.4177 16.2162 24.5134 16.5 24.5134C16.7837 24.5134 17.0213 24.4177 17.2128 24.2262C17.4042 24.0348 17.4999 23.7972 17.4999 23.5134V18.6005L19.2641 20.3647C19.3632 20.4638 19.4748 20.5382 19.5987 20.5878C19.7226 20.6373 19.8466 20.6587 19.9705 20.6519C20.0944 20.645 20.2171 20.6168 20.3384 20.5673C20.4598 20.5177 20.57 20.4433 20.6692 20.3442C20.8623 20.1373 20.9623 19.9032 20.9692 19.6416C20.976 19.3801 20.876 19.1459 20.6692 18.9391L17.3435 15.6135C17.2188 15.4887 17.0871 15.4006 16.9487 15.3494C16.8102 15.2981 16.6607 15.2724 16.5 15.2724C16.3393 15.2724 16.1897 15.2981 16.0513 15.3494C15.9128 15.4006 15.7812 15.4887 15.6564 15.6135L12.3308 18.9391C12.1325 19.1374 12.0346 19.3694 12.0372 19.6352C12.0397 19.901 12.1444 20.1373 12.3513 20.3442C12.5581 20.5373 12.7923 20.6373 13.0538 20.6442C13.3153 20.651 13.5495 20.551 13.7564 20.3442L15.5 18.6005ZM8.91027 28.6673C8.23676 28.6673 7.66667 28.4339 7.2 27.9673C6.73333 27.5006 6.5 26.9305 6.5 26.257V5.74425C6.5 5.07074 6.73333 4.50065 7.2 4.03398C7.66667 3.56732 8.23676 3.33398 8.91027 3.33398H18.5026C18.8239 3.33398 19.1329 3.39639 19.4295 3.52119C19.726 3.64596 19.9837 3.81775 20.2026 4.03655L25.7974 9.63135C26.0162 9.85017 26.188 10.1079 26.3127 10.4045C26.4375 10.701 26.4999 11.01 26.4999 11.3314V26.257C26.4999 26.9305 26.2666 27.5006 25.7999 27.9673C25.3333 28.4339 24.7632 28.6673 24.0897 28.6673H8.91027ZM18.5 10.1288V5.33395H8.91027C8.80769 5.33395 8.71366 5.37669 8.62817 5.46215C8.5427 5.54764 8.49997 5.64167 8.49997 5.74425V26.257C8.49997 26.3596 8.5427 26.4536 8.62817 26.5391C8.71366 26.6246 8.80769 26.6673 8.91027 26.6673H24.0897C24.1922 26.6673 24.2863 26.6246 24.3718 26.5391C24.4572 26.4536 24.5 26.3596 24.5 26.257V11.3339H19.7051C19.3616 11.3339 19.0748 11.219 18.8449 10.989C18.615 10.7591 18.5 10.4724 18.5 10.1288Z'
								fill='#6C7DDF'
							/>
						</svg>
						<p className={s.browseTitle}>
							{i18next.t('drag_or_drop')}
							<span> {i18next.t('browse')}</span>
						</p>
					</div>
				);
			}}
		</Dropzone>
	);
};

export default DropZone;
