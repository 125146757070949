import { coreInstance as axios, api } from 'settings';
import { logger } from 'helpers';
import * as actionTypes from '../../actionTypes';

const getBatteryStatusStart = () => {
	return {
		type: actionTypes.GET_BATTERY_STATUS_START,
	};
};

const getBatteryStatusSuccess = (payload) => {
	return {
		type: actionTypes.GET_BATTERY_STATUS_SUCCESS,
		payload,
	};
};

const getBatteryStatusSuccessById = (payload) => {
	return {
		type: actionTypes.GET_BATTERY_STATUS_SUCCESS_BY_ID,
		payload,
	};
};

export const getBatteryStatusById = (args = {}) => {
	const params = {
		...args.params,
	};
	const id = params.search.concentrator_model_id[0];

	return (dispatch) => {
		dispatch(getBatteryStatusStart());

		axios
			.get(`${api.battery_status.index}/${id}`, { params })
			.then((response) => {
				logger('[Action] Get battery status by ID');
				dispatch(
					getBatteryStatusSuccessById({ data: { ...response.data.data, id } }),
				);
			})
			.catch(() => {
				logger('[Action] Get battery status by ID fail');
			});
	};
};

export const getBatteryStatus = (args = {}) => {
	const params = {
		...args.params,
	};

	return (dispatch) => {
		dispatch(getBatteryStatusStart());

		axios
			.get(api.battery_status.index, { params })
			.then((response) => {
				logger('[Action] Get battery status');
				dispatch(getBatteryStatusSuccess(response.data));
			})
			.catch(() => {
				logger('[Action] Get battery status fail');
			});
	};
};
