import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
	data: null,
	hasMoreData: true,
	filters: {},
	searchData: [],
	loading: false,
	requestParams: {
		with: [
			'concentratorErrorRecallLast',
			'concentratorErrorRecallLast.concentratorError',
			'concentratorModel',
			'concentratorDataLogsLast',
		],
		sortedBy: 'desc',
		orderBy: ['connected_at'],
		limit: 40,
	},
};

const setSearchParam = (state, action) => {
	return {
		...state,
		requestParams: {
			...state.requestParams,
			...action.params,
			search: {
				...state.requestParams.search,
				...action.params.search,
			},
			searchFields: {
				...state.requestParams.searchFields,
				...action.params.searchFields,
			},
		},
	};
};

const getProfilesStart = (state) => {
	return updateObject(state, { loading: true });
};

const getProfilesSuccess = (state, action) => {
	if (action.isMerge) {
		const data = [...state.data, ...action.data];

		return updateObject(state, {
			data: data,
			hasMoreData: action.data.length >= state.requestParams.limit,
		});
	}

	return updateObject(state, {
		data: action.data,
		loading: false,
		hasMoreData: action.data.length >= state.requestParams.limit,
	});
};

const getSearchData = (state, action) => {
	return updateObject(state, { searchData: action.data });
};

const clearSearchData = (state) => {
	return updateObject(state, { searchData: state.searchData });
};

const setSearchDataToStore = (state) => {
	return updateObject(state, { data: state.searchData });
};

const setFilter = (state, action) => {
	const { key, item, isMulti, provider } = action;
	const filters = { ...state.filters };

	if (key === 'pinCodes') {
		const { code_part_1, code_part_2, code_part_3, description } = item;

		item.label = `${code_part_1}-${code_part_2}`;
		code_part_3 &&
			(item.label += `-${code_part_3.slice(0, 2)}-${code_part_3.slice(-3)} `);
		description && (item.label += description);

		if (filters.providers) {
			delete filters.providers[item.provider_id];
		}
	}

	if (key === 'providers') {
		if (filters.pinCodes) {
			for (const pin of item.provider_codes) {
				delete filters.pinCodes[pin.id];
			}
		}
	}

	if (filters[key]) {
		filters[key][item.id]
			? delete filters[key][item.id]
			: isMulti
			  ? (filters[key][item.id] = item)
			  : (filters[key] = { [item.id]: item });
	} else {
		filters[key] = { [item.id]: item };
	}

	const isAllChecked =
		provider && provider.provider_codes.every((el) => filters[key][el.id]);

	if (isAllChecked) {
		filters.providers
			? (filters.providers[provider.id] = provider)
			: (filters.providers = { [provider.id]: provider });

		provider.provider_codes.forEach((el) => {
			filters[key][el.id] && delete filters[key][el.id];
		});
	}

	return {
		...state,
		filters,
	};
};

const removeFilter = (state, action) => {
	const { key, id } = action;
	const filters = { ...state.filters };

	delete filters[key][id];

	return {
		...state,
		filters,
	};
};

const clearProfiles = () => {
	return initialState;
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_PROFILES_START:
			return getProfilesStart(state, action);
		case actionTypes.GET_PROFILES_SUCCESS:
			return getProfilesSuccess(state, action);

		case actionTypes.GET_SEARCH_DATA_SUCCESS:
			return getSearchData(state, action);
		case actionTypes.CLEAR_SEARCH_DATA:
			return clearSearchData(state, action);
		case actionTypes.SET_SEARCH_DATA_TO_STORE:
			return setSearchDataToStore(state, action);

		case actionTypes.SET_FILTER:
			return setFilter(state, action);
		case actionTypes.REMOVE_FILTER:
			return removeFilter(state, action);

		case actionTypes.SET_SEARCH_PARAM:
			return setSearchParam(state, action);

		case actionTypes.CLEAR_CONCENTRATORS:
			return clearProfiles(state, action);

		default:
			return state;
	}
};

export default reducer;
