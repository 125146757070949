import { coreInstance as axios, api, messages } from 'settings';
import { logger, toast } from 'helpers';
import * as actionTypes from '../actionTypes';

export const setPushNotificationsRequestParam = (params) => {
	return {
		type: actionTypes.SET_PUSH_NOTIFICATIONS_REQUEST_PARAM,
		params: params,
	};
};

const getPushNotificationsHistoryStart = () => {
	return {
		type: actionTypes.GET_PUSH_NOTIFICATIONS_HISTORY_START,
	};
};

const getPushNotificationsHistorySuccess = (data, isMerge, rows) => {
	return {
		type: actionTypes.GET_PUSH_NOTIFICATIONS_HISTORY_SUCCESS,
		data: data,
		rows: rows,
		isMerge: isMerge,
	};
};

export const getPushNotificationsHistory = (options, isMerge, loading) => {
	return (dispatch) => {
		isMerge || loading || dispatch(getPushNotificationsHistoryStart());

		axios
			.get(api.push_notification_history.index, { params: options })
			.then((response) => {
				logger('[Action] Get push notification history');

				dispatch(
					getPushNotificationsHistorySuccess(
						response.data.data,
						isMerge,
						response.data.rows,
					),
				);
			})
			.catch(() => {
				logger('[Action] Get push notification history fail');
			});
	};
};

export const addPushNotificationSuccess = (data) => {
	toast.success(messages.notificationSent);

	return {
		type: actionTypes.ADD_PUSH_NOTIFICATIONS_HISTORY,
		data: data,
	};
};

export const addPushNotification = (args) => {
	const { data, success, error } = args;

	return (dispatch) => {
		axios
			.post(api.push_notification_history.index, data)
			.then((response) => {
				logger('[Action] Add push notification');

				dispatch(addPushNotificationSuccess(response.data.data));

				success && success();
			})
			.catch((err) => {
				logger('[Action] Add push notification fail');

				error && error(err.response.data);
			});
	};
};
