import { coreInstance as axios, api } from 'settings';
import { logger } from 'helpers';
import * as actionTypes from '../../actions/actionTypes';

const getUserDeviceStart = () => {
	return {
		type: actionTypes.GET_USER_DEVICE_START,
	};
};

const getUserDeviceSuccess = (data, names) => {
	return {
		type: actionTypes.GET_USER_DEVICE_SUCCESS,
		data: data,
		names: names,
	};
};

export const getUserDevice = (args = {}) => {
	const params = {
		...args.params,
	};

	return (dispatch) => {
		dispatch(getUserDeviceStart());

		axios
			.get(api.user_device_statistics.index, { params })
			.then((response) => {
				logger('[Action] Get user device statistics');

				dispatch(getUserDeviceSuccess(response.data.data, response.data.names));
			})
			.catch(() => {
				logger('[Action] Get user device statistics fail');
			});
	};
};
