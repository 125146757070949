import React, { Fragment } from 'react';
import { DownloadTwo } from 'components/Icons';
import moment from 'moment';

import s from '../style.module.scss';
import i18next from 'i18next';

const tableConfig = {
	dataHead: [
		{ displayText: 'S/N' },
		{
			filter: {
				param: 'concentrator_model_id',
				valuesField: 'id',
				codeField: 'error_id',
				displayField: 'description',
				propsKey: 'modelsFilterProps',
				defaultValue: 'all_models',
				label: {
					path: 'description',
				},
			},
		},
		{
			filter: {
				type: 'date',
				param: 'connected_at',
				data: [
					{
						id: 1,
						label: 'all',
					},
					{
						id: 2,
						label: 'single_date',
						type: 'single',
					},
					{
						id: 3,
						label: 'period_of_time',
						type: 'period',
					},
				],
				defaultValue: 'connection_date',
				propsKey: 'connectDateFilterProps',
				label: {
					path: 'description',
				},
			},
		},
		{
			filter: {
				type: 'errors',
				param: 'concentratorErrorRecallLast.code',
				valuesField: 'code',
				codeField: 'error_id',
				displayField: 'description',
				propsKey: 'errorsFilterProps',
				defaultValue: 'all_errors',
				label: {
					path: 'description',
				},
			},
		},
		{ displayText: 'data_log' },
	],
	dataBody: {
		serial_number: {
			path: 'serial_number',
		},
		concentrator_model: {
			path: ['concentrator_model', 'description'],
		},
		connected_at: {
			path: 'connected_at',
			view: ({ value }) => value && moment(value).format('MM.DD.YYYY, HH:mm'),
		},
		concentrator_error_recall_last: {
			path: [
				'concentrator_error_recall_last',
				'concentrator_error',
				'description',
			],
			alterPath: [
				'concentrator_error_recall_last',
				'concentrator_error',
				'code',
			],
			view: ({ value }) => i18next.t(value),
		},
		concentrator_data_logs_last: {
			path: 'concentrator_data_logs_last',
			textAlign: 'center',
			view: ({ value, props }) => {
				const clickHandler = (e) => {
					e.stopPropagation();
					props.onDataLogsDownload(value && value.temp_file_link);
				};

				return (
					value && (
						<Fragment>
							<DownloadTwo fill='#30adfa' height='20' onClick={clickHandler} />
							<span className={s.date}>
								{moment(value.created_at).format('DD MMM YYYY')}
							</span>
						</Fragment>
					)
				);
			},
		},
	},
};

export default tableConfig;
