import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import { Select, Input } from 'components';
import { AuxWrap } from 'hoc';
import { Lock, Info } from 'components/Icons';

import s from './style.module.scss';
import { withTranslation } from 'react-i18next';

const ProviderSelect = (props) => {
	const {
		data,
		t,
		state,
		type,
		inputChanged,
		inputBlur,
		selectClick,
		selectItemClick,
		changeView,
		defaultValue,
	} = props;

	const config = {
		displayKey: 'name',
		label: {
			path: 'name',
		},
		defaultValue: defaultValue,
	};

	let provider = (
		<div className={s.selectField}>
			<div className={s.selectHead}>
				<span>{t('provider')}</span>
			</div>
			<Select
				type={'provider'}
				config={config}
				data={data}
				checked={state.controls && state.controls.provider.checked}
				isOpen={state.controls && state.controls.provider.isOpen}
				clicked={selectClick}
				onItemClick={selectItemClick}
				defaultValue={props.defaultValue}
				newDesign={true}
			/>
		</div>
	);

	if (state.providerView === 'new') {
		const { newProvider } = state.controls;
		let nameSelectHead;
		let pinSelectHead = (
			<div className={s.selectHead}>
				<span>{t('pin_code')}</span>
			</div>
		);

		if (type !== 'create') {
			nameSelectHead = (
				<div className={s.selectHead}>
					<span>{t('add_new_provider')}</span>
					<span className={s.blue} onClick={() => changeView(null)}>
						{t('select_existing_provider')}
					</span>
				</div>
			);

			pinSelectHead = (
				<div className={s.selectHead}>
					<span>{t('provider_pin_code')}</span>
				</div>
			);
		}

		provider = (
			<AuxWrap>
				<div className={s.selectField}>
					{nameSelectHead}

					<Input
						className={newProvider.name.className}
						elementType={newProvider.name.elementType}
						elementConfig={newProvider.name.elementConfig}
						value={newProvider.name.value}
						invalid={!newProvider.name.valid}
						invalidMsg={t(newProvider.name.errorMsg)}
						touched={newProvider.name.touched}
						changed={(event) => inputChanged(event, 'name', 'newProvider')}
						onBlur={(event) => inputBlur(event, 'name', 'newProvider')}
					/>
				</div>
				<div className={s.selectField}>
					{pinSelectHead}

					<ul className={s.pinField}>
						<li>
							<Input
								className={newProvider.pin.className}
								elementType={newProvider.pin.elementType}
								elementConfig={newProvider.pin.elementConfig}
								value={newProvider.pin.value}
								invalid={!newProvider.pin.valid}
								invalidMsg={t(newProvider.pin.errorMsg)}
								touched={newProvider.pin.touched}
								changed={(event) => inputChanged(event, 'pin', 'newProvider')}
								onBlur={(event) => inputBlur(event, 'pin', 'newProvider')}
							/>
						</li>
						<li>
							<div className={s.inputBlock}>{state.codePartTwo}</div>
						</li>
						<li>
							<div className={s.inputBlock}>
								<Lock width={18} height={20} />
							</div>
						</li>
						<li>
							<div className={s.inputBlock}>
								<Lock width={18} height={20} />
								<Info data-tip='hello world' className={s.infoIcon} />
							</div>
						</li>
					</ul>
					<ReactTooltip
						className={s.tooltip}
						place='right'
						type='light'
						effect='solid'
					>
						<div className={s.tipTitle}>{t('pin_code_consists_of')}</div>
						<ul className={s.tipList}>
							<li>{t('pin_code_aa')}</li>
							<li>{t('pin_code_bbb')}</li>
							<li>{t('pin_code_cc')}</li>
							<li>{t('pin_code_ddd')}</li>
						</ul>
					</ReactTooltip>
				</div>
			</AuxWrap>
		);
	}

	return provider;
};

ProviderSelect.defaultProps = {
	state: {},
	changeView: () => {},
	inputBlur: () => {},
};

ProviderSelect.propTypes = {
	state: PropTypes.object,
	changeView: PropTypes.func,
	inputBlur: PropTypes.func,
};

export default withTranslation()(ProviderSelect);
