import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import {
	ResponsiveContainer,
	LineChart,
	Line,
	XAxis,
	YAxis,
	Tooltip,
} from 'recharts';
import ReactTooltip from 'react-tooltip';
import { Info } from 'components/Icons';
import moment from 'moment';

import ps from '../style.module.scss';
import s from './style.module.scss';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

const TooltipContent = (props) => {
	const { active, payload } = props;

	if (active && payload) {
		const time = payload[0].payload.connected_hour_at;
		return (
			<div className={s.tooltip}>
				<div className={s.value}>{payload ? payload[0].payload.count : ''}</div>
				<div>
					{moment(time).subtract(1, 'hours').format('H:mm')}-
					{moment(time).format('H:mm')}
				</div>
			</div>
		);
	}

	return null;
};

const Connected = (props) => {
	const { data, period, t } = props;

	return (
		<div className={ps.oneThird}>
			<div className={cs(ps.panel, s.panel)}>
				<h2 className={ps.title}>
					{t('connected_concentrators')}
					<Info
						fill='#a5bad4'
						data-tip=''
						data-for='ConnectedConcentrators '
						className={ps.infoIconTrigger}
					/>
					<span>{i18next.t(period)}</span>
				</h2>

				<ReactTooltip
					id='ConnectedConcentrators '
					className={ps.tooltip}
					place='bottom'
					type='light'
					effect='solid'
				>
					<Info fill='#a5bad4' className={ps.infoIconTooltip} />
					<p>{i18next.t('total_number_of_concentrators_sent_24_hours')}</p>
				</ReactTooltip>

				<ResponsiveContainer height={230}>
					<LineChart data={data} margin={{ top: 10, right: 30 }}>
						<XAxis reversed type='category' interval={5} dataKey='time' />

						<YAxis
							allowDecimals={false}
							type='number'
							dataKey='count'
							width={40}
						/>

						<Line
							type='monotone'
							dataKey='count'
							stroke='#6ab221'
							strokeWidth={2}
							dot={false}
						/>

						<Tooltip content={<TooltipContent />} />
					</LineChart>
				</ResponsiveContainer>
			</div>
		</div>
	);
};

Connected.defaultProps = {
	recently: null,
	total: null,
	period: '',
};

Connected.propTypes = {
	recently: PropTypes.number,
	total: PropTypes.number,
	period: PropTypes.string,
};

export default memo(withTranslation()(Connected));
