import { coreInstance as axios, api } from 'settings';

const generatePin = (success, error) => {
	axios
		.get(api.providers.generate_unique_code)
		.then((response) => {
			success && success(response);
		})
		.catch((err) => {
			error && error(err);
		});
};

export default generatePin;
