export const date = {
	type: 'date',
	param: 'connected_at',
	data: [
		{
			id: 1,
			label: 'all',
		},
		{
			id: 2,
			label: 'single_date',
			type: 'single',
		},
		{
			id: 3,
			label: 'period_of_time',
			type: 'period',
		},
	],
	defaultValue: 'connection_date',
	label: {
		path: 'description',
	},
};

export const errors = {
	type: 'errors',
	param: 'concentratorErrorRecallLast.code',
	valuesField: 'code',
	codeField: 'error_id',
	displayField: 'description',
	propsData: 'errorData',
	defaultValue: 'all_errors',
	label: {
		path: 'description',
	},
};

export const models = {
	param: 'concentrator_model_id',
	valuesField: 'id',
	displayField: 'description',
	propsKey: 'modelsFilterProps',
	defaultValue: 'all_models',
	label: {
		path: 'description',
	},
};
