import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
	rows: null,
	loading: false,
};

const getConnectedStart = (state) => {
	return updateObject(state, { loading: true });
};

const getConnectedSuccess = (state, action) => {
	return updateObject(state, {
		rows: action.rows,
		loading: false,
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.ENGINEERING_GET_APP_CONNECTED_START:
			return getConnectedStart(state, action);
		case actionTypes.ENGINEERING_GET_APP_CONNECTED_SUCCESS:
			return getConnectedSuccess(state, action);

		default:
			return state;
	}
};

export default reducer;
