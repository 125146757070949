import React from 'react';

import s from '../style.module.scss';
import i18next from 'i18next';

const Concentrators = ({
	status_id,
	test_concentrators: data = [],
	version,
}) => {
	if (!data.length || status_id !== 4) return null;

	return (
		<div className={s.panel}>
			<div className={s.panelHead}>
				<div className={s.panelTitle}>
					<span>{i18next.t('concentrators_for_testing')}</span>
				</div>
				<div className={s.panelVersioning}>{`${'V'} ${version}`}</div>
			</div>
			<div className={s.concentratorFeature}>
				<div className={s.list}>
					{data.map((el, i) => (
						<span key={i} className={s.label}>
							{el.serial_number}
						</span>
					))}
				</div>
			</div>
		</div>
	);
};

export default Concentrators;
