import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'components';
import { AuxWrap } from 'hoc';

import { withTranslation } from 'react-i18next';
import s from './style.module.scss';

const Filters = (props) => {
	const { config, data, t } = props;
	const { labelKey, secondKey, thirdKey, countKey } = config;
	let filterData = data || [];

	if (config.dataFilter) {
		filterData = config.dataFilter(filterData);
	}

	let list = (
		<ul className={s.sbList}>
			{filterData.map((item, index) => {
				let isActive;

				if (props.activeId) {
					isActive = props.activeId[item.id] && s.sbActive;
				}

				return (
					<li
						key={index}
						className={isActive}
						onClick={() => props.clickHandler(item, !!isActive)}
					>
						{item[labelKey]}
						{item[secondKey] && ` ${item[secondKey]}`}
						{item[thirdKey] && ` ${item[thirdKey]}`}
						{item[countKey] && ` (${item[countKey]})`}
					</li>
				);
			})}
		</ul>
	);

	if (props.loading) {
		list = <Spinner small />;
	}

	return (
		<AuxWrap>
			<h3 className={s.sbListTitle}>{t(props.title)}</h3>
			{list}
		</AuxWrap>
	);
};

Filters.defaultProps = {
	data: [],
	config: {},
};

Filters.propTypes = {
	data: PropTypes.array,
	config: PropTypes.object,
};

export default withTranslation()(Filters);
