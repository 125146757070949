import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
	data: [],
	loading: false,
};

const getCitiesStart = (state) => {
	return updateObject(state, { loading: true });
};

const getCitiesSuccess = (state, action) => {
	return updateObject(state, { data: action.data, loading: false });
};

const clearCities = () => {
	return initialState;
};

const cities = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_CITIES_START:
			return getCitiesStart(state, action);
		case actionTypes.GET_CITIES_SUCCESS:
			return getCitiesSuccess(state, action);

		case actionTypes.CLEAR_CITIES:
			return clearCities(state, action);

		default:
			return state;
	}
};

export default cities;
