import { coreInstance as axios, api } from 'settings';
import { logger } from 'helpers';
import * as actionTypes from '../../actionTypes';

const getSystemPowerStart = () => {
	return {
		type: actionTypes.GET_SYSTEM_POWER_START,
	};
};

const getSystemPowerSuccess = (payload) => {
	return {
		type: actionTypes.GET_SYSTEM_POWER_SUCCESS,
		payload,
	};
};

export const getSystemPower = (args = {}) => {
	const params = {
		...args.params,
		orderBy: 'system_power',
	};

	return (dispatch) => {
		dispatch(getSystemPowerStart());

		axios
			.get(api.system_power.index, { params })
			.then((response) => {
				logger('[Action] Get system power');
				dispatch(getSystemPowerSuccess(response.data));
			})
			.catch(() => {
				logger('[Action] Get system power fail');
			});
	};
};
