import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
	data: [],
	headings: [],
	count: 0,
	loading: false,
};

const getLocationsStart = (state) => {
	return updateObject(state, { loading: true });
};

const getLocationsSuccess = (state, action) => {
	return updateObject(state, {
		data: action.data,
		headings: action.headings,
		loading: false,
	});
};

const getLocationsCount = (state, action) => {
	return updateObject(state, {
		count: action.data,
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_LOCATIONS_START:
			return getLocationsStart(state, action);
		case actionTypes.GET_LOCATIONS_SUCCESS:
			return getLocationsSuccess(state, action);
		case actionTypes.GET_LOCATIONS_COUNT:
			return getLocationsCount(state, action);

		default:
			return state;
	}
};

export default reducer;
