/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, Fragment } from 'react';
import PatientPersonalDetails from './PatientPersonalDetails/index';
import PatientDeviceDetails from './PatientDeviceDetails/index';
import PatientErrorDetails from './PatientErrorDetails/index';
import s from './style.module.scss';
import Button from '../../components/UI/Button';
import { connect } from 'react-redux';
import * as actions from '../../store/actions';
import History from './History/index';
import i18next from 'i18next';
import {
	HistoryIcon,
	NotificationsThree,
	AdvanceEngineeringIcon,
	CloseButton,
	PatientRefresh,
} from '../../components/Icons';
import { ModalPopUp, Spinner, DetailsPanel } from '../../components';
import AdvanceEngineeringData from './AdvanceEngineeringData';
import { parseISO, format } from 'date-fns';

let ADVANCE_ENGINEERING_FIELDS = [
	{
		key: 'serial_number',
		label: i18next.t('serial_number_advande'),
		type: 'text',
		translateKey: 'serial_number_advande',
	},
	{
		key: 'device_model',
		label: i18next.t('device_model'),
		type: 'text',
		translateKey: 'device_model',
	},
	{
		key: 'software_version',
		label: i18next.t('software_version'),
		type: 'text',
		translateKey: 'software_version_advance',
	},
	{
		key: 'date_and_time',
		label: i18next.t('dateandtime'),
		type: 'date_and_time',
		translateKey: 'dateandtime',
	},
];
let HISTORY_FIELDS = [
	{
		key: 'serial_number',
		label: i18next.t('serial_number_advande'),
		type: 'text',
		translateKey: 'serial_number_advande',
	},
	{
		key: 'device_model',
		label: i18next.t('device_model'),
		type: 'text',
		translateKey: 'device_model',
	},
	{
		key: 'software_version',
		label: i18next.t('software_version'),
		type: 'text',
		translateKey: 'software_version_advance',
	},
];

function PatientDetails({
	getErrors,
	engineeringParameters,
	engineeringValues,
	SelectedPatientDetails,
	patientData,
	getData,
	getParameters,
	getengineeringValue,
	getConcentratorProfiles,
	getConcentratorLocationsByProfileId,
	fetchConcentratorData,
	fetchHistoryData,
	sendUpdateNotification,
	historyHandler,
	allowUpdate,
	allowSendNotification,
	PatientSelectedId,
	PatientSelectedErrorId,
	lastLocation,
}) {
	const [showHistory, setShowHistory] = useState(false);
	const [showAdvanceEngineering, setShowAdvanceEngineering] = useState(false);
	const [showNotification, setShowNotification] = useState(false);

	const closeModal = () => {
		setShowHistory(false);
		setShowAdvanceEngineering(false);
		historyHandler(
			'software',
			0,
			patientData?.data?.id,
			patientData?.data?.concentrator_model_id,
		);
	};

	useEffect(() => {
		getErrors({
			params: {
				orderBy: 'description',
				sortedBy: 'asc',
			},
		});
	}, [getParameters, PatientSelectedId, getErrors]);

	useEffect(() => {
		if (SelectedPatientDetails?.concentrator_profile_id) {
			getParameters();
			getengineeringValue(SelectedPatientDetails?.concentrator_profile_id);
			getConcentratorProfiles(
				SelectedPatientDetails?.concentrator_profile_id,
				SelectedPatientDetails?.id,
			);
			fetchConcentratorData(SelectedPatientDetails?.concentrator_profile_id);
			fetchHistoryData(SelectedPatientDetails?.concentrator_profile_id);
			getConcentratorLocationsByProfileId(
				SelectedPatientDetails?.concentrator_profile_id,
			);
		}
	}, [
		SelectedPatientDetails?.concentrator_profile_id,
		SelectedPatientDetails?.id,
	]);

	useEffect(() => {
		setShowNotification(false);
	}, [allowSendNotification]);

	const updateHandler = () => {
		historyHandler(
			getData.activeTabName,
			getData.activeTabIndex,
			patientData?.data?.id,
			patientData?.data?.concentrator_model_id,
		);
	};
	const handleNotificationClick = () => {
		sendUpdateNotification(patientData?.data?.serial_number || '', 'logs');
		setShowNotification(true);
	};

	const handleRefreshClick = () => {
		if (SelectedPatientDetails?.concentrator_profile_id) {
			getParameters();
			getengineeringValue(
				SelectedPatientDetails.concentrator_profile_id
					? SelectedPatientDetails.concentrator_profile_id
					: '',
			);
		}
	};

	const handleShowHistory = () => {
		setShowHistory(true);
		historyHandler(
			'ErrorRecall',
			3,
			patientData?.data?.id,
			patientData?.data?.concentrator_model_id,
		);
	};

	const tabChange = (tabName, tabIndex) => {
		try {
			historyHandler(
				tabName,
				tabIndex,
				patientData?.data?.id,
				patientData?.data?.concentrator_model_id,
			);
		} catch (e) {
			console.log(e);
		}
	};
	const dateInUTCStr =
		engineeringValues?.data?.data?.length > 0
			? engineeringValues?.data?.data?.[0]?.updated_at
			: 'N/A';
	const dateInUTC = dateInUTCStr !== 'N/A' ? parseISO(dateInUTCStr) : 'N/A';

	const detailsPanelData = {
		serial_number: patientData?.data?.serial_number || '',
		device_model: patientData?.data?.device_model || '',
		software_version:
			engineeringValues?.data?.data?.[0]?.concentrator_firmware?.version || '',
		date_and_time:
			dateInUTC === 'N/A' ? '' : `${format(dateInUTC, 'MM/dd/yyyy, HH:mm')}`,
	};

	const content = (
		<Fragment>
			<div className={s.titleAlign}>
				<div className={s.modalTitle}>{i18next.t('history')}</div>
				<div className={s.modalIcon}>
					<CloseButton className={s.closeBtn} onClick={closeModal} />
					<PatientRefresh className={s.refreshBtn} onClick={updateHandler} />
				</div>
			</div>
			<div className={s.panelDetails}>
				<DetailsPanel mapper={HISTORY_FIELDS} data={detailsPanelData} />
			</div>
			<History
				activeTab={getData.activeTabIndex}
				activeTabName={getData.activeTabName}
				data={getData.historyData || []}
				loading={getData.loadingHistory}
				tabClickHandler={tabChange}
			/>
		</Fragment>
	);

	const AdvanceData = (
		<Fragment>
			<div className={s.titleAlign}>
				<div className={s.modalTitle}>
					{i18next.t('advanced_enegineering_data')}
				</div>
				<div className={s.modalIcon}>
					<CloseButton className={s.closeBtn} onClick={closeModal} />
					<PatientRefresh
						className={s.refreshBtn}
						onClick={handleRefreshClick}
					/>
				</div>
			</div>
			<div className={s.panelDetails}>
				<DetailsPanel
					mapper={ADVANCE_ENGINEERING_FIELDS}
					data={detailsPanelData}
				/>
			</div>
			<AdvanceEngineeringData
				engineeringVals={
					engineeringValues?.data?.length !== 0 ? engineeringValues.data : ''
				}
				data={
					engineeringParameters?.data?.length !== 0
						? engineeringParameters.data
						: ''
				}
				serialNo={patientData?.data?.serial_number || ''}
				profileID={SelectedPatientDetails?.concentrator_profile_id || ''}
				DeviceDetails={patientData?.data?.device_model || ''}
				Description={
					getData.dataConcentratorProfiles?.concentrator_error_recall_last
						?.description || ''
				}
				Time={
					getData.dataConcentratorProfiles?.concentrator_error_recall_last
						?.elapsed_time || ''
				}
			/>
		</Fragment>
	);

	return (
		<>
			<div className={s.patientWrapper}>
				<div className={s.patientContainer}>
					<div className={s.patientPersonalDetailsWrapper}>
						<PatientPersonalDetails
							serial_number={patientData?.data?.serial_number || ''}
							patientId={PatientSelectedId}
						/>
					</div>
					<div className={s.patientDeviceDetailsWrapper}>
						<div className={s.leftContainer}>
							<PatientDeviceDetails
								patientId={PatientSelectedId}
								healthCheckData={
									getData.dataConcentratorProfiles
										?.all_healthcheck_parameters || ''
								}
								groupsData={patientData?.data || ''}
								lastLocation={lastLocation?.last_location || null}
								isAllowUpdate={allowUpdate}
								isAllowSendNotification={allowSendNotification}
							/>
						</div>
						<div className={s.rightContainer}>
							<PatientErrorDetails
								patientId={PatientSelectedId}
								selectedErrorId={PatientSelectedErrorId}
								onShowHistory={handleShowHistory}
							/>
						</div>
					</div>
					<div className={s.patientDeviceDetailsBtnWrapper}>
						<Button
							btnType={'type-18'}
							className={s.notification_button}
							clicked={() => {
								setShowHistory(true);
							}}
							disabled={
								SelectedPatientDetails?.concentrator_profile_id ? false : true
							}
						>
							<HistoryIcon />
							<span>{i18next.t('history')}</span>
						</Button>
						<Button
							className={s.notification_button}
							btnType={'type-18'}
							disabled={
								showNotification ||
								(SelectedPatientDetails?.concentrator_profile_id &&
								patientData?.data?.serial_number
									? false
									: true)
							}
							clicked={handleNotificationClick}
						>
							<NotificationsThree />
							<span>{i18next.t('send_notification_to_transfer_data_log')}</span>
						</Button>
						<Button
							className={s.notification_button}
							btnType={'type-18'}
							clicked={() => {
								setShowAdvanceEngineering(true);
							}}
							disabled={
								SelectedPatientDetails?.concentrator_profile_id ? false : true
							}
						>
							<AdvanceEngineeringIcon />
							<span>{i18next.t('advanced_enegineering_data')}</span>
						</Button>
					</div>
				</div>
			</div>
			<ModalPopUp className={s.modal} show={showHistory} restrictClosing={true}>
				{content}
			</ModalPopUp>
			<ModalPopUp
				className={s.modal}
				show={showAdvanceEngineering}
				restrictClosing={true}
			>
				{engineeringParameters.loading === true ||
				engineeringValues.loading === true ? (
					<Spinner />
				) : (
					AdvanceData
				)}
			</ModalPopUp>
		</>
	);
}

const mapStateToProps = (state) => {
	return {
		SelectedPatientDetails: state.patientData.SelectedPatientDetails,
		loading: state.patientData.loading,
		engineeringParameters: state.engineeringParameters,
		engineeringValues: state.engineeringValue,
		patientData: state.patientData.selectedPatientData,
		allowUpdate: state.patientData.allowUpdate,
		allowSendNotification: state.patientData.allowSendNotification,
		getData: state.patientData,
		PatientSelectedId: state.patientData.patientId,
		PatientSelectedErrorId: state.patientData.errorId,
		lastLocation: state.patientData.selectedPatientLastLocation,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getErrors: (args) => dispatch(actions.getErrors(args)),
		getParameters: (args) => dispatch(actions.getParameters(args)),
		getengineeringValue: (args) => dispatch(actions.getengineeringValue(args)),
		getConcentratorProfiles: (args, id) =>
			dispatch(actions.getConcentratorProfiles(args, id)),
		fetchConcentratorData: (args) =>
			dispatch(actions.fetchConcentratorData(args)),
		sendUpdateNotification: (args, data) =>
			dispatch(actions.sendUpdateNotification(args, data)),
		fetchHistoryData: (args) => dispatch(actions.fetchHistoryData(args)),
		historyHandler: (args, data, concentratorProfileId, concentratorModelId) =>
			dispatch(
				actions.historyHandler(
					args,
					data,
					concentratorProfileId,
					concentratorModelId,
				),
			),
		getConcentratorLocationsByProfileId: (args) =>
			dispatch(actions.getConcentratorLocationsByProfileId(args)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(PatientDetails);
