import * as actionTypes from '../../../store/actions/actionTypes';
import { actionsParams } from './constants';
import { ENGINEERING_GET_COLUMN_LIFE_START } from '../../../store/actions/actionTypes';
import { ENGINEERING_GET_COLUMN_LIFE_SUCCESS } from '../../../store/actions/actionTypes';

const actionsCreator = (action) => {
	switch (action) {
		case actionsParams.MOST_RECENT_ERROR:
			return {
				start: actionTypes.ENGINEERING_GET_RECENT_ERROR_START,
				success: actionTypes.ENGINEERING_GET_RECENT_ERROR_SUCCESS,
			};

		case actionsParams.APP_CONNECTED:
			return {
				start: actionTypes.ENGINEERING_GET_APP_CONNECTED_START,
				success: actionTypes.ENGINEERING_GET_APP_CONNECTED_SUCCESS,
			};

		case actionsParams.COLUMN_LIFE:
			return {
				start: ENGINEERING_GET_COLUMN_LIFE_START,
				success: ENGINEERING_GET_COLUMN_LIFE_SUCCESS,
			};

		case actionsParams.CURRENTLY_CONNECTED:
			return {
				start: actionTypes.ENGINEERING_GET_CURRENTLY_CONNECTED_START,
				success: actionTypes.ENGINEERING_GET_CURRENTLY_CONNECTED_SUCCESS,
			};

		case actionsParams.SOFTWARE_VERSIONS:
			return {
				start: actionTypes.ENGINEERING_GET_SOFTWARE_VERSIONS_START,
				success: actionTypes.ENGINEERING_GET_SOFTWARE_VERSIONS_SUCCESS,
			};

		case actionsParams.DASH_PARAMS:
			return actionTypes.ENGINEERING_SET_DASH_PARAM;

		case actionsParams.RESET_PARAMS:
			return actionTypes.ENGINEERING_RESET_DASH_PARAM;

		default:
			return {};
	}
};

export default actionsCreator;
