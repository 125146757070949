import React from 'react';
import T from 'prop-types';

const BlockedUserAction = (props) => (
	<svg {...props} className={props.className}>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M8.29655 20.752C9.45268 21.2506 10.6877 21.5 12.0016 21.5C13.3156 21.5 14.5504 21.2505 15.706 20.7516C16.8616 20.2527 17.8669 19.5757 18.7217 18.7205C19.5765 17.8652 20.2533 16.8596 20.752 15.7034C21.2506 14.5473 21.5 13.3122 21.5 11.9983C21.5 10.6844 21.2505 9.44959 20.7516 8.29398C20.2527 7.13834 19.5757 6.1331 18.7205 5.27825C17.8652 4.42342 16.8596 3.74667 15.7034 3.248C14.5473 2.74933 13.3122 2.5 11.9983 2.5C10.6844 2.5 9.44959 2.74944 8.29398 3.24833C7.13834 3.74721 6.1331 4.42427 5.27825 5.2795C4.42342 6.13472 3.74667 7.1404 3.248 8.29655C2.74933 9.45268 2.5 10.6877 2.5 12.0017C2.5 13.3156 2.74944 14.5504 3.24833 15.706C3.74721 16.8616 4.42427 17.8669 5.2795 18.7217C6.13472 19.5765 7.1404 20.2533 8.29655 20.752ZM17.675 17.675C16.125 19.225 14.2333 20 12 20C11.0618 20 10.1583 19.8413 9.28958 19.524C8.42086 19.2067 7.62688 18.7474 6.90765 18.1461L8.99134 16.0624L7.93751 15.0086L5.85383 17.0923C5.25254 16.3731 4.79325 15.5791 4.47595 14.7104C4.15863 13.8417 3.99998 12.9382 3.99998 12C3.99998 9.76664 4.77498 7.87498 6.32498 6.32498C7.87498 4.77498 9.76664 3.99998 12 3.99998C12.9404 3.99998 13.8461 4.15703 14.7169 4.47113C15.5877 4.78524 16.3795 5.24614 17.0923 5.85383L15.0086 7.93755L16.0624 8.99137L18.1461 6.90765C18.741 7.62688 19.1987 8.42086 19.5192 9.28958C19.8397 10.1583 20 11.0618 20 12C20 14.2333 19.225 16.125 17.675 17.675Z'
			fill='#6C7DDF'
		/>
	</svg>
);

BlockedUserAction.defaultProps = {
	fill: '#6C7DDF',
	width: 24,
	height: 24,
	viewBox: '0 0 24 24',
	xmlns: 'http://www.w3.org/2000/svg',
};

BlockedUserAction.propTypes = {
	className: T.string,
};

export default BlockedUserAction;
