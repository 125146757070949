import React, { memo } from 'react';
import PropTypes from 'prop-types';
import cs from 'classnames';
import ReactTooltip from 'react-tooltip';
import { Info } from 'components/Icons';

import ps from '../style.module.scss';
import s from './style.module.scss';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

const CurrentlyConnected = (props) => {
	const { currentlyConnected, monthlyConnected, total, t } = props;

	return (
		<div className={ps.oneThird}>
			<div className={cs(ps.panel, s.panel)}>
				<h2 className={ps.title}>
					{t('connected_concentrators')}
					<Info
						fill='#a5bad4'
						data-tip=''
						data-for='CurrentlyConnected'
						className={ps.infoIconTrigger}
					/>
				</h2>
				<ReactTooltip
					id='CurrentlyConnected'
					className={ps.tooltip}
					place='bottom'
					type='light'
					effect='solid'
				>
					<Info fill='#a5bad4' className={ps.infoIconTooltip} />
					<p>{t('total_number_of_concentrators')}</p>
				</ReactTooltip>
				<div className={s.inner}>
					<span className={s.period}>
						{i18next.t(currentlyConnected.period)}
					</span>
					<h3 className={s.recently}>{currentlyConnected.rows}</h3>
				</div>
				<div className={s.inner}>
					<span className={s.period}>{i18next.t(monthlyConnected.period)}</span>
					<h3 className={s.recently}>{monthlyConnected.data}</h3>
				</div>
				<span className={s.total}>
					{t('total_connections')}: {total}
				</span>
			</div>
		</div>
	);
};

CurrentlyConnected.defaultProps = {
	currentlyConnected: {
		rows: null,
		period: '',
	},
	monthlyConnected: {
		data: null,
		period: '',
	},
	total: null,
};

CurrentlyConnected.propTypes = {
	currentlyConnected: PropTypes.shape({
		rows: PropTypes.number,
		period: PropTypes.string,
	}),
	monthlyConnected: PropTypes.shape({
		data: PropTypes.number,
		period: PropTypes.string,
	}),
	total: PropTypes.number,
};

export default memo(withTranslation()(CurrentlyConnected));
