import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { SearchInput, Spinner, Table, Button, DetailsPanel } from 'components';
import { routers } from 'settings';
import { logger } from 'helpers';
import searchUsers from '../Configs/searchUsers';
import manageProvider from '../Configs/manageProvider';
import blockedUsers from '../Configs/blockedUsers';
import { EditIcon } from 'components/Icons';

import s from '../style.module.scss';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

let PATIENT_DETAILS_FIELDS = [
	{
		key: 'email_address',
		label: 'Provider Email-Id',
		type: 'text',
		translateKey: 'provider_email_id',
	},
	{
		key: 'phone_number',
		label: 'Phone Number',
		type: 'text',
		translateKey: 'provider_phone',
	},
	{
		key: 'customer_care_number',
		label: 'Customer Care Number',
		type: 'text',
		translateKey: 'customer_care_number',
	},
	{
		key: 'address_1',
		label: 'Address',
		type: 'text',
		translateKey: 'address',
	},
	{
		key: 'postal_code',
		label: 'PIN Code',
		type: 'text',
		translateKey: 'pinCode',
	},
];
class Provider extends Component {
	componentDidMount() {
		logger('[ManageUsers Provider] componentDidMount');

		const { data, match, currentUser, onInit, clear } = this.props;

		clear();

		if (!this.isLoaded) {
			this.isLoaded = data;
		}

		if (match.params.id === '1') {
			this.isInogen = true;
		}

		onInit(currentUser.provider_id);
	}

	render() {
		const {
			data,
			searchValue,
			usersPendingData,
			currentUser,
			history,
			changeUsersPendingStatus,
			changeManageUsersStatus,
			openModal,
			onSearchTyping,
			onSearchClear,
			onSearchSubmit,
			searchData,
			searchLoading,
			changeUsersStatus,
			t,
		} = this.props;

		let providerView = <Spinner />;

		if (data) {
			const usersByRoles = data.users_by_roles;
			const blockedUsersData =
				usersPendingData && usersPendingData.filter((el) => !el.status);
			// let description;
			// , additions;

			let result = (
				<Fragment>
					{usersByRoles && !Array.isArray(usersByRoles) ? (
						Object.keys(data.users_by_roles).map((el, index) => {
							if (usersByRoles[el].length) {
								return (
									<Fragment key={index}>
										<div className={s.tableContent}>
											<h2 className={s.listTitle}>
												{i18next.t(el)} ({usersByRoles[el].length})
											</h2>
											<Table
												className={[s.largeTable, s.providerTable].join(' ')}
												config={manageProvider}
												data={usersByRoles[el]}
												currentUser={currentUser}
												changeUsersStatus={changeManageUsersStatus}
												onRowClicked={(row, index) =>
													openModal({
														type: 'UserDetails',
														subType: 'byProvider',
														data: row,
														index: index,
														restrictClosing: true,
													})
												}
											/>
										</div>
									</Fragment>
								);
							} else {
								return null;
							}
						})
					) : (
						<p className={s.noUsersText}>
							{t('there_are_no_users_for_this_provider')}
						</p>
					)}

					{this.isInogen && (
						<Fragment>
							<h2 className={s.listTitle}>{i18next.t('blocked')}</h2>
							<Table
								className={[s.largeTable, s.providerTable].join(' ')}
								config={blockedUsers}
								data={blockedUsersData}
								currentUser={currentUser}
								changeUsersPendingStatus={changeUsersPendingStatus}
								onRowClicked={(row) =>
									openModal({ type: 'PendingRequest', data: row })
								}
							/>
						</Fragment>
					)}
				</Fragment>
			);

			// if (data ? data.description : "") {
			// 	description = <p className={s.descText}>{data.description}</p>;
			// }

			if (searchLoading || searchData) {
				const onUserClicked = (row, index) => {
					const args = {
						type: 'UserDetails',
						subType: 'search',
						data: row,
						index: index,
						restrictClosing: true,
					};

					if (!row.roles.length) {
						args.type = 'PendingRequest';
					}

					openModal(args);
				};

				result = (
					<Fragment>
						<div className={s.tableContent}>
							<h2 className={s.listTitle}>{i18next.t('users')}</h2>
							<Table
								className={[s.fullTable, s.searchUsersTable].join(' ')}
								config={searchUsers}
								data={searchData}
								loading={searchLoading}
								currentUser={currentUser}
								changeUsersStatus={changeUsersStatus}
								onRowClicked={onUserClicked}
							/>
						</div>
					</Fragment>
				);
			}

			providerView = (
				<Fragment>
					<div className={s.providerTitle}>
						<div className={s.displayFlex}>
							<span className={[s.pageTitle, s.providerTitle].join(' ')}>
								{data.name || i18next.t('provider_name')}
							</span>
						</div>
						<div className={s.displayFlex1}>
							<Button
								className={s.editButton}
								btnType='type-22'
								clicked={() =>
									history.push(
										routers.usersProviderEdit(currentUser.provider_id),
									)
								}
							>
								<EditIcon />
								{i18next.t('edit')}
							</Button>
							<Button
								className={s.addButton}
								btnType='type-20'
								clicked={() =>
									openModal({
										type: 'AddUser',
										restrictClosing: true,
									})
								}
							>
								<svg
									width='24'
									height='24'
									viewBox='0 0 24 24'
									fill='none'
									xmlns='http://www.w3.org/2000/svg'
								>
									<path
										d='M12 11.6923C11.0375 11.6923 10.2135 11.3496 9.52813 10.6642C8.84271 9.97879 8.5 9.15484 8.5 8.19236C8.5 7.22986 8.84271 6.40591 9.52813 5.72051C10.2135 5.03509 11.0375 4.69238 12 4.69238C12.9625 4.69238 13.7864 5.03509 14.4718 5.72051C15.1572 6.40591 15.5 7.22986 15.5 8.19236C15.5 9.15484 15.1572 9.97879 14.4718 10.6642C13.7864 11.3496 12.9625 11.6923 12 11.6923ZM4.5 19.3077V17.0846C4.5 16.5949 4.63302 16.1414 4.89905 15.7241C5.16507 15.3068 5.52051 14.986 5.96537 14.7616C6.95384 14.277 7.95096 13.9135 8.95672 13.6712C9.96249 13.4289 10.9769 13.3078 12 13.3078C13.023 13.3078 14.0375 13.4289 15.0432 13.6712C16.049 13.9135 17.0461 14.277 18.0346 14.7616C18.4794 14.986 18.8349 15.3068 19.1009 15.7241C19.3669 16.1414 19.5 16.5949 19.5 17.0846V19.3077H4.5ZM5.99997 17.8077H18V17.0846C18 16.8821 17.9413 16.6946 17.824 16.5221C17.7067 16.3497 17.5474 16.209 17.3461 16.1C16.4846 15.6757 15.6061 15.3542 14.7107 15.1356C13.8152 14.917 12.9117 14.8077 12 14.8077C11.0883 14.8077 10.1847 14.917 9.28927 15.1356C8.39384 15.3542 7.51536 15.6757 6.65382 16.1C6.45254 16.209 6.29325 16.3497 6.17595 16.5221C6.05863 16.6946 5.99997 16.8821 5.99997 17.0846V17.8077ZM12 10.1924C12.55 10.1924 13.0208 9.99653 13.4125 9.60486C13.8041 9.21319 14 8.74236 14 8.19236C14 7.64236 13.8041 7.17153 13.4125 6.77986C13.0208 6.38819 12.55 6.19236 12 6.19236C11.45 6.19236 10.9791 6.38819 10.5875 6.77986C10.1958 7.17153 9.99997 7.64236 9.99997 8.19236C9.99997 8.74236 10.1958 9.21319 10.5875 9.60486C10.9791 9.99653 11.45 10.1924 12 10.1924Z'
										fill='white'
									/>
								</svg>
								{i18next.t('add_user')}
							</Button>
						</div>
					</div>

					{/* {additions} */}

					{/* {description} */}
					<div className={s.providerDetails}>
						<DetailsPanel mapper={PATIENT_DETAILS_FIELDS} data={data} />
					</div>
					<SearchInput
						// className={s.searchForm}
						theme='violet'
						value={searchValue}
						placeholder={i18next.t('search_user')}
						disabled={!searchValue}
						onChange={onSearchTyping}
						onClear={onSearchClear}
						onSearchSubmit={onSearchSubmit}
					/>

					{result}
				</Fragment>
			);
		}

		return providerView;
	}
}

Provider.defaultProps = {
	data: {},
};

Provider.propTypes = {
	data: PropTypes.object,
};

export default withTranslation()(Provider);
