import { coreInstance as axios, api } from 'settings';
import { logger } from 'helpers';
import * as actionTypes from './actionTypes';

const getRolesStart = () => {
	return {
		type: actionTypes.GET_ROLES_START,
	};
};

const getRolesSuccess = (data) => {
	return {
		type: actionTypes.GET_ROLES_SUCCESS,
		data: data,
	};
};

export const getRoles = (options) => {
	return (dispatch) => {
		dispatch(getRolesStart());

		axios
			.get(api.roles.index, options)
			.then((response) => {
				logger('[Action] Get roles');

				dispatch(getRolesSuccess(response.data.data));
			})
			.catch(() => {
				logger('[Action] Get roles fail');
			});
	};
};
