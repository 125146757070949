import i18next from 'i18next';

export const modelsParams = {
	orderBy: 'id',
};

export const filtersParams = [
	{
		label: i18next.t('recent'),
		value: 'Recent Top 10',
		isActive: true,
	},
	{
		label: i18next.t('in_testing'),
		value: 'Test',
		isActive: false,
	},
	{
		label: i18next.t('unknown'),
		value: 'Unknown',
		isActive: false,
	},
	{
		label: i18next.t('drafts'),
		value: 'Draft',
		isActive: false,
	},
];
