import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
	patientId: localStorage.getItem('patientId'),
	errorId: localStorage.getItem('errorId'),
	selectedPatientId: null,
	SelectedPatientDetails: {},
	SelectedPatientDeviceDetails: {},
	selectedPatientDeviceErrors: [],
	selectedErrorTroubleshootingGuide: {},
	loadingErrorTroubleshootingGuide: false,
	loadingSelectedPatientDeviceErrors: false,
	resolvingError: false,
	loading: false,
	selectedPatientData: null,
	selectedPatientDataLoading: false,
	loadingConcentratorProfiles: false,
	dataConcentratorProfiles: null,
	errorConcentratorProfiles: null,
	notificationLoading: false,
	error: null,
	activeTabIndex: 0,
	activeTabName: 'software',
	tableData: {},
	loadingHistory: false,
	historyData: [],
	errorHistory: null,
	allowUpdate: false,
	allowSendNotification: false,
	selectedPatientLastLocation: null,
};

export const getPatientDemographicInfoStart = (state) => {
	return updateObject(state, { loading: true });
};

export const getPatientDemographicInfoSuccess = (state, action) => {
	return updateObject(state, {
		SelectedPatientDetails: action.data,
		loading: false,
	});
};
const setPatientId = (state, action) => {
	localStorage.setItem('patientId', action?.data?.patientId);
	localStorage.setItem('errorId', action?.data?.errorId);
	return updateObject(state, {
		patientId: action?.data?.patientId,
		errorId: action?.data?.errorId,
		loading: false,
	});
};

export const getPatientDeviceErrorsStart = (state) => {
	return updateObject(state, { loadingSelectedPatientDeviceErrors: true });
};

export const getPatientDeviceErrorsSuccess = (state, action) => {
	return updateObject(state, {
		selectedPatientDeviceErrors: action.data,
		loadingSelectedPatientDeviceErrors: false,
	});
};

export const errorResolvingStart = (state) => {
	return updateObject(state, { resolvingError: true });
};

export const errorResolvingSuccess = (state) => {
	return updateObject(state, {
		resolvingError: false,
	});
};

const getConcentratorProfilesStart = (state) => {
	return updateObject(state, { selectedPatientDataLoading: true });
};

const getConcentratorProfilesSuccess = (state, action) => {
	return updateObject(state, {
		selectedPatientData: action.data,
		selectedPatientDataLoading: false,
	});
};

const getConcentratorLocationsByProfileIdSuccess = (state, action) => {
	return updateObject(state, {
		selectedPatientLastLocation: action.data,
	});
};

const checkUpdateSuccess = (state, action) => {
	return updateObject(state, {
		allowUpdate: action.data,
	});
};

const sendUpdateNotificationSuccess = (state) => {
	return updateObject(state, {
		allowSendNotification: !state.allowSendNotification,
	});
};

const sendUpdateNotificationError = (state) => {
	return updateObject(state, {
		allowSendNotification: !state.allowSendNotification,
	});
};

export const getTroubleShootingGuideStart = (state) => {
	return updateObject(state, { loadingErrorTroubleshootingGuide: true });
};

export const getTroubleShootingGuideSuccess = (state, action) => {
	return updateObject(state, {
		selectedErrorTroubleshootingGuide: action.data,
		loadingErrorTroubleshootingGuide: false,
	});
};

export const resetPatientData = (state) => {
	localStorage.removeItem('patientId');
	localStorage.removeItem('errorId');
	return updateObject(state, initialState);
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_PATIENTDEMOGRAPHIC_INFO_START:
			return getPatientDemographicInfoStart(state, action);
		case actionTypes.GET_PATIENTDEMOGRAPHIC_INFO_SUCCESS:
			return getPatientDemographicInfoSuccess(state, action);

		case actionTypes.GET_PATIENT_DEVICE_ERRORS_INFO_START:
			return getPatientDeviceErrorsStart(state, action);
		case actionTypes.GET_PATIENT_DEVICE_ERRORS_INFO_SUCCESS:
			return getPatientDeviceErrorsSuccess(state, action);

		case actionTypes.ERROR_RESOLVING_START:
			return errorResolvingStart(state, action);
		case actionTypes.ERROR_RESOLVING_SUCCESS:
			return errorResolvingSuccess(state, action);

		case actionTypes.GET_CONCENTRATORPROFILES_START:
			return getConcentratorProfilesStart(state, action);
		case actionTypes.GET_CONCENTRATORPROFILES_SUCCESS:
			return getConcentratorProfilesSuccess(state, action);

		case actionTypes.GET_CONCENTRATORLOCATIONS_BY_PROFILEID_SUCCESS:
			return getConcentratorLocationsByProfileIdSuccess(state, action);

		case actionTypes.CHECK_UPDATE_SUCCESS:
			return checkUpdateSuccess(state, action);

		case actionTypes.SEND_UPDATE_NOTIFICATION_SUCCESS:
			return sendUpdateNotificationSuccess(state, action);
		case actionTypes.SEND_UPDATE_NOTIFICATION_ERROR:
			return sendUpdateNotificationError(state, action);

		case actionTypes.FETCH_CONCENTRATOR_DATA:
			return {
				...state,
				loadingConcentratorProfiles: true,
				errorConcentratorProfiles: null,
			};

		case actionTypes.SET_CONCENTRATOR_DATA:
			return {
				...state,
				loadingConcentratorProfiles: false,
				dataConcentratorProfiles: action.payload.data,
				errorConcentratorProfiles: null,
			};
		case actionTypes.ERROR_CONCENTRATOR_DATA:
			return {
				...state,
				loadingConcentratorProfiles: false,
				errorConcentratorProfiles: action.payload,
			};

		case actionTypes.SET_ACTIVE_TAB_INDEX:
			return {
				...state,
				activeTabIndex: action.payload,
			};
		case actionTypes.SET_ACTIVE_TAB_NAME:
			return {
				...state,
				activeTabName: action.payload,
			};
		case actionTypes.SET_TABLE_DATA:
			return {
				...state,
				tableData: action.payload,
			};
		case actionTypes.SET_LOADING:
			return {
				...state,
				loadingHistory: action.payload,
			};
		case actionTypes.FETCH_HISTORY_DATA_SUCCESS:
			return {
				...state,
				historyData: action.payload,
				loadingHistory: false,
				errorHistory: null,
			};
		case actionTypes.FETCH_HISTORY_DATA_FAILURE:
			return {
				...state,
				historyData: [],
				loadingHistory: false,
				errorHistory: action.payload,
			};
		case actionTypes.SEND_NOTIFICATION_START:
			return {
				...state,
				isLoading: true,
				error: null,
			};
		case actionTypes.SEND_NOTIFICATION_SUCCESS:
			return {
				...state,
				isLoading: false,
				error: null,
			};
		case actionTypes.SEND_NOTIFICATION_FAIL:
			return {
				...state,
				isLoading: false,
				error: action.error,
			};

		case actionTypes.GET_TROUBLE_SHOOTING_GUIDE_START:
			return getTroubleShootingGuideStart(state, action);
		case actionTypes.GET_TROUBLE_SHOOTING_GUIDE_SUCCESS:
			return getTroubleShootingGuideSuccess(state, action);

		case actionTypes.RESET_PATIENT_DATA:
			return resetPatientData(state, action);
		case actionTypes.SET_VALUE:
			return setPatientId(state, action);

		default:
			return state;
	}
};

export default reducer;
