import React, { PureComponent } from 'react';
import { Select, Button } from 'components';
import ProviderSelect from '../ProviderSelect';
import BlockUser from '../BlockUser';
import { AuxWrap } from 'hoc';
import { generatePin } from 'helpers';
import { Back } from 'components/Icons';

import initialStateCreator from './initialStateCreator';

import s from './style.module.scss';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

class PendingRequest extends PureComponent {
	constructor(props) {
		super(props);
		this.state = initialStateCreator(props);
	}

	generateUniqueCode = () => {
		const success = (response) => {
			this.setState({ codePartTwo: response.data.data.code_part_2 });
		};

		generatePin(success);
	};

	createProvider = () => {
		const { controls, codePartTwo } = this.state;
		const data = {
			name: controls.newProvider.name.value,
			code_part_1: controls.newProvider.pin.value.replace(/[_]/g, ''),
			code_part_2: codePartTwo,
		};

		const success = (data) =>
			this.setState({ step: 2, newProviderData: data, formValidMessage: null });
		const error = (data) => this.setState({ formValidMessage: data });

		this.props.addProvider(data, success, error);
	};

	onItemClick = (data, type) => {
		if (data) {
			if (data.label) {
				this.setState({ userbuttonEnabled: false });
			}
			this.setState((prevState) => {
				const controls = { ...prevState.controls };

				controls[type].checked = { [data.id]: data };

				return { controls: controls };
			});
		} else {
			this.setState({ userbuttonEnabled: true });
		}
	};

	inputChangedHandler = (event, name) => {
		const target = event.target;
		const value = name === 'pin' ? target.value.toUpperCase() : target.value;

		this.setState((prevState) => {
			const state = { ...prevState.controls };
			const control = state.newProvider[name];

			control.value = value;

			return { controls: state, formValidMessage: null };
		});
	};

	firstStepView = () => {
		const { formValidMessage, providerView } = this.state;
		const { modalData, providersData, changeStatus, close } = this.props;
		const { id, status } = modalData;

		const changeUserStatus = (status) => {
			changeStatus({ id, status, callback: close });
		};
		const nextHandler = () => {
			if (providerView === 'new') {
				this.createProvider();
			} else {
				this.setState({ step: 2, formValidMessage: null });
			}
		};
		const changeView = (value) => {
			if (value === 'new') {
				this.generateUniqueCode();
			}

			this.setState((prevState) => {
				const newState = { ...prevState };

				newState.controls.newProvider.name.value = '';
				newState.controls.newProvider.pin.value = '';
				newState.providerView = value;
				newState.formValidMessage = null;

				return newState;
			});
		};

		this.validMessage = null;

		if (formValidMessage) {
			this.validMessage = (
				<ul className={s.errorText}>
					{Object.values(formValidMessage).map((error, index) => {
						return <li key={index}>{error}</li>;
					})}
				</ul>
			);
		}

		return (
			<AuxWrap>
				<ProviderSelect
					data={providersData}
					state={this.state}
					inputChanged={(event, type) => this.inputChangedHandler(event, type)}
					selectItemClick={this.onItemClick}
					changeView={changeView}
				/>

				{this.validMessage}

				<div className={s.buttons}>
					<span
						className={[s.label, !status && s.unblock].join(' ')}
						onClick={() => changeUserStatus(status === 0 ? 1 : 0)}
					>
						{status === 0 ? 'Unblock User' : 'Block User'}
					</span>

					<Button btnType='type-6' clicked={nextHandler}>
						{i18next.t('next')}
					</Button>
				</div>
			</AuxWrap>
		);
	};

	secondStepView = () => {
		const { controls, providerView, newProviderData, userbuttonEnabled } =
			this.state;
		const {
			modalData,
			rolesData,
			type,
			close,
			approveUsersPending,
			changeStatus,
			searchSubmit,
			t,
		} = this.props;
		const { id, email, status } = modalData;
		const roles = rolesData.filter((el) => el.is_inogen);
		const UserRoles =
			roles &&
			roles.length > 0 &&
			roles.filter((data) => {
				return data.name && data.name !== t('firmware_admin');
			});
		const changeUserStatus = (status) => {
			if (!status) {
				return this.setState({ blockUserPopup: true });
			}

			changeStatus({ id, status, callback: close });
		};
		const approveHandler = () => {
			const args = {
				data: {
					role_label: Object.values(controls.role.checked)[0].label,
					user_id: id,
					user_email: email,
					user_status: status,
				},
				postData: {
					role_ids: [Object.values(controls.role.checked)[0].id],
				},
				callback: close,
			};

			if (type === 'search') {
				args.callback = () => {
					close();
					searchSubmit(false);
				};
			}

			if (providerView === 'new') {
				args.postData.provider_id = newProviderData.id;
			}

			approveUsersPending(args);
		};

		const config = {
			placeholder: i18next.t('define_user_role'),
			displayKey: 'label',
			label: {
				path: 'label',
			},
		};

		return (
			<AuxWrap>
				<div className={s.selectField}>
					<div className={s.selectHead}>
						<span>{t('user_role')}</span>
					</div>
					<Select
						type={'role'}
						className={s.select}
						config={config}
						data={UserRoles}
						checked={this.state.controls.role.checked}
						isOpen={this.state.controls.role.isOpen}
						onItemClick={this.onItemClick}
					/>
				</div>
				<div className={s.buttons}>
					<span
						className={[s.label, !status && s.unblock].join(' ')}
						onClick={() => changeUserStatus(status === 0 ? 1 : 0)}
					>
						{status === 0 ? 'Unblock User' : 'Block User'}
					</span>

					<Button
						btnType='type-6'
						disabled={userbuttonEnabled}
						clicked={approveHandler}
					>
						APPROVE
					</Button>
				</div>
			</AuxWrap>
		);
	};

	renderSteps = () => {
		let step = this.firstStepView();

		switch (this.state.step) {
			case 2:
				step = this.secondStepView();
				break;
			default:
				break;
		}

		return step;
	};

	render() {
		const {
			modalData: { id, status, email },
			close,
			changeStatus,
			t,
		} = this.props;
		const { step, blockUserPopup } = this.state;

		const changeUserStatus = (status) => {
			changeStatus({ id, status, callback: close });
		};

		try {
			let backBtn;

			if (blockUserPopup) {
				return (
					<BlockUser
						email={email}
						approveHandler={() => changeUserStatus(status === 0 ? 1 : 0)}
						cancelHandler={() => this.setState({ blockUserPopup: false })}
					/>
				);
			}

			if (step === 2) {
				backBtn = (
					<Button
						btnType='back'
						className={s.backBtn}
						clicked={() =>
							this.setState((prevState) =>
								initialStateCreator(this.props, prevState),
							)
						}
					>
						<Back /> <span>{t('back')}</span>
					</Button>
				);
			}

			return (
				<AuxWrap>
					<h2 className={s.title}>
						{backBtn}
						{email}
					</h2>
					{/*<div className={s.steps}>Step {step} of 2</div>*/}
					<div className={s.container}>{this.renderSteps()}</div>
				</AuxWrap>
			);
		} catch (e) {
			console.error(e);

			return null;
		}
	}
}

export default withTranslation()(PendingRequest);
