import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import s from './style.module.scss';
import { withTranslation } from 'react-i18next';
import Button from '../UI/Button';
import { connect } from 'react-redux';
import * as actions from 'store/actions';

class Consent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			formValidMessage: null,
			terms: null,
		};
	}

	agreeConsent = () => {
		const { clear, modal, addUserConsent } = this.props;
		const { formValidMessage } = this.state;

		const onRefresh = () => {
			clear();
			modal.close();
		};
		const consent_type = 'terms_of_use';
		this.validMessage = null;

		if (formValidMessage) {
			this.validMessage = (
				<ul className={s.errorText}>
					{Object.values(formValidMessage).map((error, index) => {
						return <li key={index}>{error}</li>;
					})}
				</ul>
			);
		}

		const args = {
			data: {
				consent_type: consent_type,
				is_consent: true,
				version: this.props?.terms?.data?.terms_of_use?.version || 2,
			},
			success: () => onRefresh(),
			error: (err) =>
				this.setState({ formValidMessage: err.response.data.errors }),
		};

		addUserConsent(args);
	};

	denyConsent = () => {
		const { logout } = this.props;
		logout();
	};

	render() {
		const { t, terms } = this.props;

		return (
			<Fragment>
				<h2 className={s.title}>{t('terms_of_use')}</h2>
				<div
					className={s.terms}
					dangerouslySetInnerHTML={{ __html: terms?.data?.terms_of_use?.value }}
				/>
				{this.validMessage}
				<div className={s.button_div}>
					<Button className={s.backBtn} clicked={this.agreeConsent}>
						<span>{t('Accept')}</span>
					</Button>
					<Button className={s.backBtn} clicked={this.denyConsent}>
						<span>{t('Deny')}</span>
					</Button>
				</div>
			</Fragment>
		);
	}
}

Consent.defaultProps = {
	clear: () => {},
	addUserConsentSuccess: () => {},
	logout: () => {},
};

Consent.propTypes = {
	clear: PropTypes.func,
	addUserConsent: PropTypes.func,
	logout: PropTypes.func,
};

const mapStateToProps = (state) => {
	const { terms } = state;

	return { terms: terms };
};

const mapDispatchToProps = (dispatch) => {
	return {
		logout: () => dispatch(actions.logout()),
		addUserConsent: (args) => dispatch(actions.addUserConsent(args)),
		getCurrentUser: () => dispatch(actions.getCurrentUser()),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withTranslation()(Consent));
