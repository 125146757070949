import React from 'react';
import s from './style.module.scss';
import { loginLogo } from 'img';
import { uniqueClass } from 'config';
import i18next from 'i18next';
import { Button } from 'components';
import { Back } from 'components/Icons';
import { routers } from 'settings';
import { useHistory } from 'react-router-dom';
const Errors = () => {
	const navigate = useHistory();

	const navigateToLogin = () => {
		navigate.push(routers.index);
	};

	return (
		<div className={s.login}>
			<div className={s.loginInner}>
				<img
					src={loginLogo}
					alt='Inogen Connect Portal'
					className={[s.loginLogo, s[uniqueClass]].join(' ')}
				/>
				<div className={s.contactAdmin}>
					<Button
						btnType='back'
						className={s.backBtn}
						clicked={navigateToLogin}
					>
						<Back />
						<span>{i18next.t('auth_logout')}</span>
					</Button>
					<p className={s.errorText}>
						{i18next.t('auth_contact_administrator')}
					</p>
				</div>
			</div>
		</div>
	);
};

export default Errors;
