import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import moment from 'moment';
import { Button, SearchInput, Table } from 'components';
import Filters from './Filters';
import ItemModal from './ItemModal';
import MapView from './MapView';
import { logger, permissions, withTracker } from 'helpers';
import * as actions from 'store/actions';
import tableConfig from './Configs/tableConfig';
import filtersConfig from './Configs/filtersConfig';
import { makeGetAllConcentratorsState } from 'store/selectors';
import { List, LocationTwo } from 'components/Icons';
import {
	errorsParams,
	getFiltersParam,
	getHealthcheckParams,
	getProvidersOptions,
	getSearchInputParams,
	getSuggestionsParams,
	modelsParams,
} from './Configs/paramsCreator';

import s from './style.module.scss';
import { withTranslation } from 'react-i18next';

class Concentrators extends Component {
	constructor(props) {
		super(props);

		let viewMode =
			props.location.search.indexOf('search') > 0 ? 'map' : 'table';

		withTracker({
			page: props.location.pathname,
			category: 'Concentrators',
			action: 'Page view',
		});

		props.getModels({ params: modelsParams });
		props.getErrors({ params: errorsParams });
		props.getProfiles(props.requestParams);
		props.getProviders(getProvidersOptions());
		props.getHealthcheck(getHealthcheckParams());

		if (permissions.check('concentrators.location.list')) {
			props.getLocationCount(props.requestParams);
			this.getLocation(props.requestParams);
		}

		if (
			!permissions.check('concentrators.list') &&
			permissions.check('concentrators.location.list')
		) {
			viewMode = 'map';
		}

		this.state = {
			searchValue: '',
			modal: {
				isOpen: false,
			},
			viewMode: viewMode,
			filters: {},
		};
		this.searchRef = React.createRef();
		this.listModels = [];
		this.InogenProviders = true;
		this.InogenFlag = false;
		this.NonInogenflag = false;
	}

	componentWillUnmount() {
		logger('[Concentrators] componentWillUnmount');

		this.props.clearConcentrators();
	}

	componentDidUpdate(prevProps) {
		logger('[Concentrators] componentDidUpdate');

		const { requestParams, getProfiles, getLocationCount, activeFilters } =
			this.props;
		const isParamsChange = requestParams !== prevProps.requestParams;
		const isFiltersChange = activeFilters !== prevProps.activeFilters;

		if (isParamsChange) {
			getProfiles(requestParams);
			this.tableOffset = prevProps.requestParams.limit;

			if (permissions.check('concentrators.location.list')) {
				getLocationCount(requestParams);
				this.getLocation(requestParams);
			}
		}

		if (isFiltersChange) {
			const { replacementParts = {}, providers = {} } = activeFilters;
			const errorIds = Object.keys(replacementParts).length
				? Object.values(replacementParts)[0].code
				: null;
			const errorValueId = Object.keys(replacementParts).length
				? Object.values(replacementParts)[0].error_id
				: null;
			const providerIds = Object.keys(providers).length
				? Object.keys(providers)
				: null;
			this.props.setRequestParam(
				getFiltersParam(errorIds, providerIds, errorValueId),
			);
		}

		if (
			this.searchRef.current &&
			this.props.location.search.indexOf('search') > 0
		) {
			this.searchRef.current.focus();
		}
	}

	onSearchTyping = (event, { newValue }) => {
		this.setState({ searchValue: newValue });

		if (!newValue) {
			this.onSearchSubmit(null);
		}
	};

	onSuggestions = () => {
		const { viewMode, searchValue } = this.state;
		const options = {
			prevParams: this.props.requestParams,
			value: searchValue,
			key: 'serial_number',
		};
		const args = {
			viewMode: viewMode,
			params: getSuggestionsParams(options),
		};

		this.props.getSearchData(args);
	};

	onSearchSubmit = (value) => {
		const params = getSearchInputParams(value);

		ReactGA.event({
			category: 'Concentrators',
			action: 'Submit',
			label: 'Search field',
		});

		this.props.setRequestParam(params);
	};

	onSearchClear = () => {
		this.setState({ searchValue: '' });
		this.onSearchSubmit(null);
	};

	onFilterAdd = (key, item, isMulti, isRemove, provider) => {
		if (!isRemove) {
			this.listModels.push(item);
		} else {
			this.listModels = this.listModels.filter((data) => {
				return data.name !== item.name;
			});
		}
		ReactGA.event({
			category: 'Concentrators',
			action: 'Click',
			label: `Select ${item.description || item.name} filter`,
		});
		this.props.setFilter({ key, item, isMulti, provider });
	};

	onFilterDelete = (key, item) => {
		this.listModels = this.listModels.filter((data) => {
			return data.id !== item;
		});
		this.props.removeFilter(key, item);
	};

	onRowClicked = (row) => {
		if (permissions.check('concentrators.details')) {
			const modal = { isOpen: true };
			const { t } = this.props;
			ReactGA.event({
				category: 'Concentrators',
				action: 'Click',
				label: t('amount_of_clicks'),
			});

			ReactGA.event({
				category: 'Concentrators',
				action: 'Click',
				label: 'Table row',
			});
			if (row.itemId && Array.isArray(row.itemId)) {
				if (row.itemId.length > 1) {
					modal.groups = row.itemId;
				} else {
					modal.rowId = row.itemId[0];
				}
			}

			if (row.model && Array.isArray(row.model)) {
				if (row.model.length > 1) {
					modal.groupsModels = row.model;
				} else {
					modal.concentrator_model_id = row.model[0];
				}
			} else {
				modal.rowId = row.id;
				modal.concentrator_model_id = row.concentrator_model_id;
			}

			this.setState({ modal });
		}
	};

	getLocation = (params, success) => {
		const { getLocation } = this.props;
		const { search, searchFields, searchJoin, codeFilter } = params;

		const locationArgs = {
			params: {
				search: search ? search : null,
				searchFields: searchFields ? searchFields : null,
				searchJoin: searchJoin ? searchJoin : null,
				codeFilter: codeFilter ? codeFilter : null,
			},
			success: success,
		};

		getLocation(locationArgs);
	};

	modalClose = () => {
		this.setState({ modal: { isOpen: false } });
	};

	viewHandler = (type) => {
		ReactGA.event({
			category: 'Concentrators',
			action: 'Change view',
			label: `View ${type}`,
		});

		this.setState({ viewMode: type });
	};

	loadMoreHandler = () => {
		const params = {
			...this.props.requestParams,
			offset: this.tableOffset,
		};

		this.tableOffset += this.props.requestParams.limit;
		this.props.getProfiles(params, true);
	};

	tableOffset = this.props.requestParams.limit;
	oldData = this.props.profilesData;

	render() {
		logger('[Concentrators] Render');
		const {
			viewMode,
			filters,
			filters: { connectDate },
		} = this.state;
		let isInogenUserFlag = false;
		const {
			setRequestParam,
			getDataLogs,
			loadingOn,
			loadingOff,
			currentUserData,
			t,
		} = this.props;
		currentUserData &&
			currentUserData.roles &&
			currentUserData.roles.length > 0 &&
			currentUserData.roles.map((userData) => {
				if (userData.is_inogen) {
					isInogenUserFlag = true;
				}
				return userData;
			});

		this.InogenFlag = false;
		this.NonInogenflag = false;
		this.InogenProviders = true;
		this.listModels.map((data) => {
			if (
				data &&
				data.name &&
				(data.name === t('inogen') || data.name === t('inogen_engineering'))
			) {
				this.InogenFlag = true;
			} else {
				this.NonInogenflag = true;
			}
			return data;
		});
		this.InogenFlag && this.NonInogenflag
			? (this.InogenProviders = false)
			: this.InogenFlag
			  ? (this.InogenProviders = true)
			  : this.NonInogenflag
			    ? (this.InogenProviders = false)
			    : (this.InogenProviders = true);
		let modelData = [];
		if (!this.InogenProviders && !isInogenUserFlag) {
			modelData =
				this.props['modelsData'] &&
				this.props['modelsData'].length > 0 &&
				this.props['modelsData'].filter((data) => {
					return data.id && data.id !== 12 && data.id !== 13;
				});
		}
		const filterProps = (filterName) => ({
			checked: filters[filterName] || {},
			data:
				filterName === t('models') && modelData.length > 0
					? modelData
					: this.props[`${filterName}Data`],
			onItemClick: (data, config) => {
				this.setState(
					(prevState) => {
						const prevFilters = { ...prevState.filters };
						const filter = prevFilters[filterName] || {};

						if (filter[data.id]) {
							delete filter[data.id];
						} else {
							if (filterName === 'errors') {
								filter[data.id] = {
									[config.displayField]: data[config.displayField],
									[config.valuesField]: data[config.valuesField],
									[config.codeField]: data[config.codeField],
								};
							} else {
								filter[data.id] = {
									[config.displayField]: data[config.displayField],
									[config.valuesField]: data[config.valuesField],
								};
							}
						}

						prevFilters[filterName] = filter;

						return { filters: prevFilters };
					},
					() => {
						const { valuesField, param, codeField } = config;
						let values = [],
							error_id = [];
						const filter = this.state.filters[filterName] || {};
						if (filterName === 'errors') {
							values = Object.values(filter).map((item) => item[valuesField]);
							error_id = Object.values(filter).map((item) => item[codeField]);
							setRequestParam({
								search: {
									[param]: values.length ? values : null,
								},
								searchFields: {
									[param]: values.length ? 'in' : null,
								},
								codeFilter: {
									[codeField]: error_id.length ? error_id : null,
								},
							});
						} else {
							values = Object.values(filter).map((item) => item[valuesField]);

							setRequestParam({
								search: {
									[param]: values.length ? values : null,
								},
								searchFields: {
									[param]: values.length ? 'in' : null,
								},
							});
						}
					},
				);
			},
			defaultChanged: (config) => {
				let codeFilterFlag = false;
				if (
					this.state.filters &&
					this.state.filters.errors &&
					Object.values(this.state.filters.errors).length > 0
				) {
					codeFilterFlag = true;
				}
				if (config.defaultValue === 'all_errors') {
					codeFilterFlag = false;
				}
				codeFilterFlag
					? setRequestParam({
							search: {
								[config.param]: null,
							},
							searchFields: {
								[config.param]: null,
							},
					  })
					: setRequestParam({
							search: {
								[config.param]: null,
							},
							searchFields: {
								[config.param]: null,
							},
							codeFilter: {
								[config.codeField]: null,
							},
					  });
			},
			clear: () =>
				this.setState((prevState) => ({
					filters: {
						...prevState.filters,
						[filterName]: {},
					},
				})),
		});

		const dateFilterProps = (dateState = {}, filterName) => ({
			checked: dateState.checked,
			singleDate: dateState.singleDate,
			toDate: dateState.toDate,
			fromDate: dateState.fromDate,
			singleChange: (date, config) => {
				this.setState(
					(prevState) => {
						const filtersState = { ...prevState.filters };
						const filter = filtersState[filterName] || {};

						filter.singleDate = date;
						filter.fromDate = null;
						filter.toDate = null;

						filtersState[filterName] = filter;

						return { filters: filtersState };
					},
					() => {
						this.props.setRequestParam({
							search: {
								[config.param]: [
									dateState.singleDate
										.startOf('day')
										.format('YYYY-MM-DDTHHmmss'),
									dateState.singleDate.endOf('day').format('YYYY-MM-DDTHHmmss'),
								],
							},
							searchFields: { [config.param]: 'between' },
						});
					},
				);
			},
			fromChange: (date, config) => {
				this.setState(
					(prevState) => {
						const filtersState = { ...prevState.filters };
						const filter = filtersState[filterName] || {};

						if (moment(date).isAfter(filter.toDate)) {
							filter.fromDate = date;
							filter.toDate = null;
							filter.singleDate = null;
						} else {
							filter.fromDate = date;
							filter.singleDate = null;
						}

						filtersState[filterName] = filter;

						return { filters: filtersState };
					},
					() => {
						if (dateState.toDate) {
							this.props.setRequestParam({
								search: {
									[config.param]: [
										date.startOf('day').format('YYYY-MM-DDTHHmmss'),
										dateState.toDate.endOf('day').format('YYYY-MM-DDTHHmmss'),
									],
								},
								searchFields: { [config.param]: 'between' },
							});
						}
					},
				);
			},
			toChange: (date, config) => {
				this.setState(
					(prevState) => {
						const filtersState = { ...prevState.filters };
						const filter = filtersState[filterName] || {};

						if (moment(date).isBefore(filter.fromDate)) {
							filter.fromDate = null;
							filter.toDate = date;
							filter.singleDate = null;
						} else {
							filter.toDate = date;
							filter.singleDate = null;
						}

						filtersState[filterName] = filter;

						return { filters: filtersState };
					},
					() => {
						if (dateState.fromDate) {
							this.props.setRequestParam({
								search: {
									[config.param]: [
										dateState.fromDate
											.startOf('day')
											.format('YYYY-MM-DDTHHmmss'),
										date.endOf('day').format('YYYY-MM-DDTHHmmss'),
									],
								},
								searchFields: { [config.param]: 'between' },
							});
						}
					},
				);
			},
			clearSelect: (config) =>
				this.props.setRequestParam({
					search: { [config.param]: null },
					searchFields: { [config.param]: null },
				}),
			itemClicked: (el) => {
				this.setState((prevState) => {
					const filtersState = { ...prevState.filters };
					const filter = filtersState[filterName] || {};

					filter.checked = el.id;
					filter.singleDate = null;
					filter.fromDate = null;
					filter.toDate = null;

					filtersState[filterName] = filter;

					return { filters: filtersState };
				});
			},
			clear: () =>
				this.setState((prevState) => ({
					filters: {
						...prevState.filters,
						date: {
							checked: 1,
							singleDate: null,
							toDate: null,
							fromDate: null,
						},
					},
				})),
		});

		let switcher;
		let view = (
			<Table
				className={s.table}
				config={tableConfig}
				data={this.props.profilesData}
				filters={this.props.activeFilters}
				loading={this.props.tableLoading}
				onFilterDelete={this.onFilterDelete}
				onSetParams={this.props.setRequestParam}
				onRowClicked={this.onRowClicked}
				onDataLogsDownload={getDataLogs}
				loadMoreHandler={this.loadMoreHandler}
				hasMoreData={this.props.hasMoreProfilesData}
				errorsFilterProps={filterProps('errors')}
				modelsFilterProps={filterProps('models')}
				connectDateFilterProps={dateFilterProps(connectDate, 'connectDate')}
				infinite
			/>
		);
		let sideBarFilters = Object.values(filtersConfig).map((list, index) => (
			<Filters
				key={index}
				title={list.title}
				data={this.props[list.data]}
				config={list.config}
				activeId={this.props.activeFilters[list.key]}
				clickHandler={(item, isRemove) =>
					this.onFilterAdd(list.key, item, false, isRemove)
				}
				loading={this.props.providersLoading}
			/>
		));

		if (viewMode === 'map') {
			view = (
				<MapView
					data={this.props.locationsData}
					count={this.props.locationsCount}
					headings={this.props.locationsHeadings}
					filters={this.props.activeFilters}
					errorData={this.props.errorData}
					modelsData={this.props.modelsData}
					markerClicked={this.onRowClicked}
					onSetParams={this.props.setRequestParam}
					onFilterDelete={this.onFilterDelete}
					errorProps={filterProps('errors')}
					modelsProps={filterProps('models')}
					dateProps={dateFilterProps(connectDate, 'connectDate')}
				/>
			);
		}

		if (
			permissions.check('concentrators.list', 'concentrators.location.list')
		) {
			switcher = (
				<div className={s.switch}>
					<Button
						btnType='type-13'
						className={[s.switchBtn, viewMode === 'table' && s.active].join(
							' ',
						)}
						clicked={() => this.viewHandler('table')}
					>
						<List />
					</Button>
					<i className={s.line}></i>
					<Button
						btnType='type-13'
						className={[s.switchBtn, viewMode === 'map' && s.active].join(' ')}
						clicked={() => this.viewHandler('map')}
					>
						<LocationTwo />
					</Button>
				</div>
			);
		}

		return (
			<div className={s.page}>
				<div className={s.content}>
					<h1 className={s.pageTitle}>{t('concentrators')}</h1>
					<div className={s.searchPanel}>
						<SearchInput
							inputRef={this.searchRef}
							value={this.state.searchValue}
							placeholder={t('search_by_serial_number')}
							disabled={this.props.tableLoading}
							suggestions={this.props.searchData}
							onChange={this.onSearchTyping}
							onSuggestions={this.onSuggestions}
							onSuggestionsClear={this.props.clearSearchData}
							onSearchSubmit={() => this.onSearchSubmit(this.state.searchValue)}
							onClear={this.onSearchClear}
						/>

						{switcher}
					</div>

					{view}
				</div>

				<div className={s.rightSideBar}>
					<h2 className={s.sbTitle}>Filter</h2>

					{sideBarFilters}
				</div>

				<ItemModal
					itemId={this.state.modal.rowId}
					isOpen={this.state.modal.isOpen}
					modelId={this.state.modal.concentrator_model_id}
					groups={this.state.modal.groups}
					modelGroups={this.state.modal.groupsModels}
					onRowClicked={this.onRowClicked}
					closeModal={this.modalClose}
					loadingOn={loadingOn}
					loadingOff={loadingOff}
				/>
			</div>
		);
	}
}

const mapStateToProps = () => {
	const getAllConcentratorsState = makeGetAllConcentratorsState();

	return (props, state) => getAllConcentratorsState(props, state);
};

const mapDispatchToProps = (dispatch) => {
	return {
		getProfiles: (options, isMerge) =>
			dispatch(actions.getProfiles(options, isMerge)),
		getProfileById: (id, option) =>
			dispatch(actions.getProfileById(id, option)),
		getProviders: (options) => dispatch(actions.getProviders(options)),
		clearProviders: () => dispatch(actions.clearProviders()),
		getCurrentUser: () => dispatch(actions.getCurrentUser()),
		getErrors: (args) => dispatch(actions.getErrors(args)),
		getModels: (args) => dispatch(actions.getModels(args)),
		getHealthcheck: (options) => dispatch(actions.getHealthcheck(options)),
		getSearchData: (options) => dispatch(actions.getSearchData(options)),
		clearSearchData: () => dispatch(actions.clearSearchData()),
		setFilter: (args) => dispatch(actions.setFilter(args)),
		removeFilter: (key, item) => dispatch(actions.removeFilter(key, item)),
		clearConcentrators: () => dispatch(actions.clearConcentrators()),
		setRequestParam: (params) => dispatch(actions.setSearchParam(params)),
		getLocationCount: (args) => dispatch(actions.getLocationCount(args)),
		getLocation: (args) => dispatch(actions.getLocation(args)),
		getDataLogs: (args) => dispatch(actions.getDataLogs(args)),
		// loadingOn: () => dispatch(actions.loadingOn()),
		// loadingOff: () => dispatch(actions.loadingOff()),
	};
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withTranslation()(Concentrators));
