import { createSelector } from 'reselect';

const getProfiles = (state) => state.concentrators.profiles.data;
const getHasMoreProfiles = (state) => state.concentrators.profiles.hasMoreData;
const getTableLoading = (state) => state.concentrators.profiles.loading;
const getSearch = (state) => state.concentrators.profiles.searchData;
const getActiveFilters = (state) => state.concentrators.profiles.filters;
const getProvidersLoading = (state) => state.providers.loading;
const getHealthcheck = (state) => state.concentrators.healthcheck.data;
const getHealthcheckLoading = (state) =>
	state.concentrators.healthcheck.loading;
const getModels = (state) => state.models.data;
const getRequestParams = (state) => state.concentrators.profiles.requestParams;
const getLocations = (state) => state.concentrators.locations.data;
const getLocationsHeadings = (state) => state.concentrators.locations.headings;
const getLocationsCount = (state) => state.concentrators.locations.count;
const getPinCodes = (state) => state.pinCodes.data;
const getPinCodesLoading = (state) => state.pinCodes.loading;
const getCurrentUser = (state) => state.user.data;
const getErrors = (state) => {
	const obj = {};
	const data = [];
	let i = 1;

	state.errors.data.forEach((el) => {
		if (obj[el.code_string]) {
			return obj[el.code_string].push(el);
		}

		obj[el.code_string] = [el];
	});

	for (let key in obj) {
		const codes = [];
		const concentrator_model_ids = [];

		obj[key].forEach((el) => {
			codes.push(el.code);
		});
		obj[key].forEach((el) => {
			concentrator_model_ids.push(el.concentrator_model_id);
		});

		data.push({
			id: i,
			code: codes,
			code_string: obj[key][0].code_string,
			description: obj[key][0].description,
			concentrator_model_id: concentrator_model_ids,
			error_id: obj[key][0].id,
		});

		i++;
	}

	return data;
};
const getProviders = (state) =>
	state.providers.data.map((el) => {
		el.provider_codes =
			el.provider_codes &&
			el.provider_codes.map((pin) => {
				pin.label = `${pin.code_part_1}-${pin.code_part_2}`;
				pin.code_part_3 &&
					(pin.label += `-${pin.code_part_3.slice(
						0,
						2,
					)}-${pin.code_part_3.slice(-3)} `);
				pin.description && (pin.label += pin.description);
				return pin;
			});

		return el;
	});

export const makeGetAllConcentratorsState = () =>
	createSelector(
		getProfiles,
		getHasMoreProfiles,
		getTableLoading,
		getSearch,
		getActiveFilters,
		getProviders,
		getProvidersLoading,
		getHealthcheck,
		getHealthcheckLoading,
		getModels,
		getCurrentUser,
		getErrors,
		getRequestParams,
		getLocations,
		getLocationsHeadings,
		getLocationsCount,
		getPinCodes,
		getPinCodesLoading,
		(
			profilesData,
			hasMoreProfilesData,
			tableLoading,
			searchData,
			activeFilters,
			providersData,
			providersLoading,
			healthcheckData,
			healthcheckLoading,
			modelsData,
			currentUserData,
			errorsData,
			requestParams,
			locationsData,
			locationsHeadings,
			locationsCount,
			pinCodes,
			pinCodesLoading,
		) => ({
			profilesData,
			hasMoreProfilesData,
			tableLoading,
			searchData,
			activeFilters,
			providersData,
			providersLoading,
			healthcheckData,
			healthcheckLoading,
			modelsData,
			currentUserData,
			errorsData,
			requestParams,
			locationsData,
			locationsHeadings,
			locationsCount,
			pinCodes,
			pinCodesLoading,
		}),
	);
