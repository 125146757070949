import ReactGA from 'react-ga';
import { coreInstance as axios, api, messages } from 'settings';
import { loadingOn, loadingOff } from 'store/actions';
import { logger, toast } from 'helpers';
import * as actionTypes from '../actionTypes';
import i18next from 'i18next';

const getManageProviderStart = () => {
	return {
		type: actionTypes.GET_MANAGE_PROVIDER_START,
	};
};

const getManageProviderSuccess = (data) => {
	return {
		type: actionTypes.GET_MANAGE_PROVIDER_SUCCESS,
		data: data,
	};
};

export const clearManageProvider = () => {
	return {
		type: actionTypes.CLEAR_MANAGE_PROVIDER,
	};
};

export const getManageProvider = (args = {}) => {
	const { id, options } = args;

	return (dispatch) => {
		dispatch(getManageProviderStart());

		axios
			.get(`${api.providers.index}/${id}`, { params: options })
			.then((response) => {
				logger('[Action] Get manage provider');

				dispatch(getManageProviderSuccess(response.data.data));
			})
			.catch(() => {
				logger('[Action] Get manage provider fail');
			});
	};
};

const changeManageUsersStatusStart = (index, label) => {
	return {
		type: actionTypes.CHANGE_MANAGE_USERS_STATUS_START,
		index: index,
		label: label,
	};
};

const changeManageUsersStatusSuccess = (id, label) => {
	return {
		type: actionTypes.CHANGE_MANAGE_USERS_STATUS_SUCCESS,
		id: id,
		label: label,
	};
};

export const changeManageUsersStatus = ({
	id,
	index,
	label,
	status,
	callback,
}) => {
	return (dispatch) => {
		dispatch(changeManageUsersStatusStart(index, label));
		dispatch(loadingOn());

		axios
			.post(api.users.index + `/${id}`, { status: status })
			.then(() => {
				logger('[Action] Change manage users status');
				dispatch(changeManageUsersStatusSuccess(id, label));
				dispatch(loadingOff());
				toast.success(messages.userUpdated);
				callback && callback();
			})
			.catch((e) => {
				if (e?.response?.status === 400) {
					toast.error(e?.response?.data?.message);
				}
				dispatch(loadingOff());
				logger('[Action] Change manage users status fail');
			});
	};
};

const addUserStart = () => {
	return {
		type: actionTypes.ADD_USER_START,
	};
};

const addUserSuccess = (data) => {
	ReactGA.event({
		category: 'Providers and Users',
		action: 'Create',
		label: i18next.t('create_user'),
	});

	toast.success(messages.userCreated);

	return {
		type: actionTypes.ADD_USER_SUCCESS,
		data: data,
	};
};

export const addUser = ({ data, success, error }) => {
	return (dispatch) => {
		dispatch(addUserStart());

		axios
			.post(api.users.index, data)
			.then((response) => {
				logger('[Action] Add user');

				dispatch(addUserSuccess(response.data.data));

				success && success();
			})
			.catch((err) => {
				logger('[Action] Add user fail');

				error && error(err);
			});
	};
};

const changeUserProviderStart = () => {
	return {
		type: actionTypes.CHANGE_USER_PROVIDER_START,
	};
};

const changeUserProviderSuccess = (type, id, role) => {
	return {
		type: actionTypes.CHANGE_USER_PROVIDER_SUCCESS,
		subType: type,
		id: id,
		role: role,
	};
};

export const changeUserProvider = ({
	type,
	id,
	role,
	data,
	success,
	error,
}) => {
	return (dispatch) => {
		dispatch(changeUserProviderStart());

		axios
			.post(api.users.index + `/${id}`, data)
			.then((response) => {
				logger('[Action] Change user provider');

				dispatch(changeUserProviderSuccess(type, id, role));
				toast.success(messages.userUpdated);
				success && success(response.data.data);
			})
			.catch((err) => {
				logger('[Action] Change user provider fail');
				if (err?.response?.status === 400) {
					toast.error(err?.response?.data?.message);
				}
				error && error(err);
			});
	};
};

const changeUserRoleStart = () => {
	return {
		type: actionTypes.CHANGE_USER_ROLE_START,
	};
};

const changeUserRoleSuccess = (id, oldRole, newRole) => {
	return {
		type: actionTypes.CHANGE_USER_ROLE_SUCCESS,
		id: id,
		oldRole: oldRole,
		newRole: newRole,
	};
};

export const changeUserRole = ({
	id,
	data,
	oldRole,
	newRole,
	success,
	error,
}) => {
	return (dispatch) => {
		dispatch(changeUserRoleStart());

		axios
			.post(api.users.index + `/${id}`, data)
			.then(() => {
				logger('[Action] Change user role');

				dispatch(changeUserRoleSuccess(id, oldRole, newRole));
				toast.success(messages.userUpdated);
				success && success();
			})
			.catch((err) => {
				logger('[Action] Change user role fail');
				if (err?.response?.status === 400) {
					toast.error(err?.response?.data?.message);
				}
				error && error(err);
			});
	};
};
