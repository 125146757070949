import React from 'react';
import { Modal, List, ListItem } from 'components';

import s from './style.module.scss';
import { withTranslation } from 'react-i18next';

const SystemPowerDialog = (props) => {
	const { t } = props;
	const settings = Object.keys(props.data)
		.filter((key) => key !== 'all')
		.sort();
	return (
		<Modal
			show={props.isOpen}
			modalClosed={props.closeDialog}
			className={s.dialog}
		>
			<div className={s.head}>
				<h2>{t('system_power')}</h2>
				<p>{props.model && props.model.description}</p>
			</div>
			<div className={s.container}>
				{settings.map((settingKey) => {
					const setting = props.data[settingKey];
					return (
						<div className={s.group} key={settingKey}>
							<span className={s.groupTitle}>
								{t('flow_setting')} {settingKey}
							</span>
							<List className={s.list}>
								<ListItem className={s.listItem}>
									<span className={s.itemTitle}>{t('mean')}</span>
									<span>{parseFloat(setting.mean).toFixed(2)}</span>
								</ListItem>
								<ListItem className={s.listItem}>
									<span className={s.itemTitle}>{t('median')}</span>
									<span>{parseFloat(setting.median).toFixed(2)}</span>
								</ListItem>
								<ListItem className={s.listItem}>
									<span className={s.itemTitle}>{t('standard_deviation')}</span>
									<span>
										{parseFloat(setting.standardDeviation).toFixed(2)}
									</span>
								</ListItem>
							</List>
						</div>
					);
				})}
			</div>
		</Modal>
	);
};

export default withTranslation()(SystemPowerDialog);
