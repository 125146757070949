import { patientInstance as axios, api } from 'settings';
import { logger } from 'helpers';
import * as actionTypes from '../actionTypes';

export const setPateintSearchParam = (params) => {
	return {
		type: actionTypes.SET_PATIENT_SEARCH_PARAM,
		params: params,
	};
};

const getPatientProfileStart = () => {
	return {
		type: actionTypes.GET_PATIENT_SEARCH_DETAILS_START,
	};
};

const getPatientProfilesSuccess = (data) => {
	return {
		type: actionTypes.GET_PATIENT_SEARCH_DETAILS_SUCCESS,
		data: data,
	};
};
export const clearPatientSearchData = () => {
	return {
		type: actionTypes.CLEAR_PATIENT_SEARCH_DATA,
	};
};
export const getPatientProfiles = (options) => {
	return (dispatch) => {
		dispatch(getPatientProfileStart());
		axios
			.get(api.patient_details.search_patients, {
				params: options,
			})
			.then((response) => {
				logger('[Action] Get profiles');
				dispatch(getPatientProfilesSuccess(response.data.data));
			})
			.catch((err) => {
				const result = {
					success: true,
					data: { message: 'No Results' },
					message: err.message,
				};
				dispatch(getPatientProfilesSuccess(result.data));
				logger('[Action] Get profiles fail');
			});
	};
};
