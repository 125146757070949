import React, { Component, Fragment } from 'react';
import ReactGA from 'react-ga';
import { connect } from 'react-redux';
import { Route, withRouter } from 'react-router-dom';
import { Modal } from 'components';
import Provider from './Provider';
import ProviderEdit from './ProviderEdit';
import { logger, withTracker } from 'helpers';
import * as actions from 'store/actions';
import { routers } from 'settings';
import { AddUser, DeleteProvider, UserDetails } from './Popups';
import { getUsersOptions, getProvider } from './Configs/paramsCreator';
// import { TrashBin } from "components/Icons";

import s from './style.module.scss';
import { withTranslation } from 'react-i18next';

class Users extends Component {
	constructor(props) {
		super(props);

		withTracker({
			page: props.location.pathname,
			category: 'Providers and Users',
			action: 'Page view',
		});

		this.state = {
			searchValue: '',
			modal: {
				isOpen: false,
				type: null,
			},
		};
	}

	componentDidMount() {
		logger('[Users] componentDidMount');

		this.props.getProviders();
		this.props.getRoles();
	}

	onSearchTyping = (event, { newValue }) => {
		this.setState({ searchValue: newValue });

		if (!newValue) {
			this.props.clearSearchUsers();
		}
	};

	toSearchView = (value) => {
		if (value) {
			this.onSearchSubmit(value);
		}
	};

	onSearchSubmit = (value) => {
		const { provider_id } = this.props.currentUser;

		if (value) {
			this.props.getSearchUsers(getUsersOptions(value, provider_id));
		}
	};

	onSearchClear = () => {
		this.setState({ searchValue: '' });
		this.props.clearSearchUsers();
	};

	providerView = (props) => {
		const {
			manageProviderData,
			usersPendingData,
			manageProviderLoading,
			getManageProvider,
			history,
			currentUser,
			changeManageUsersStatus,
			changeUsersPendingStatus,
			clearManageProvider,
			searchUsersData,
			searchUsersLoading,
			changeSearchUsersStatus,
			modal,
		} = this.props;
		const { searchValue } = this.state;

		const onInitHandler = (id) => {
			const args = { id: id, options: getProvider() };

			getManageProvider(args);
		};

		return (
			<Fragment>
				<Provider
					data={manageProviderData}
					searchData={searchUsersData}
					searchLoading={searchUsersLoading}
					searchValue={searchValue}
					usersPendingData={usersPendingData}
					loading={manageProviderLoading}
					match={props.match}
					history={history}
					currentUser={currentUser}
					onInit={onInitHandler}
					onSearchTyping={this.onSearchTyping}
					onSearchSubmit={() => this.toSearchView(searchValue)}
					onSearchClear={this.onSearchClear}
					changeManageUsersStatus={changeManageUsersStatus}
					changeUsersPendingStatus={changeUsersPendingStatus}
					changeUsersStatus={changeSearchUsersStatus}
					openModal={this.modalOpen}
					globalModal={modal}
					clear={clearManageProvider}
				/>
			</Fragment>
		);
	};

	providerEditView = (props) => {
		const {
			history,
			manageProviderData,
			updateProvider,
			clearManageProvider,
			getManageProvider,
		} = this.props;
		const onInitHandler = (id) => getManageProvider({ id });

		return (
			<Fragment>
				{/* <Button
					className={s.addUserBtn}
					btnType={"type-8"}
					clicked={() =>
						this.modalOpen({
							type: "DeleteProvider",
							data: manageProviderData,
							restrictClosing: true,
						})
					}
				>
					<TrashBin /> <span>{t("delete")}</span>
				</Button> */}
				<ProviderEdit
					data={manageProviderData}
					match={props.match}
					history={history}
					onInit={onInitHandler}
					clear={clearManageProvider}
					openModal={this.modalOpen}
					updateProvider={updateProvider}
				/>
			</Fragment>
		);
	};

	modalView = () => {
		let modal;

		switch (this.state.modal.type) {
			case 'AddUser':
				modal = (
					<AddUser
						providersData={this.props.providersData}
						rolesData={this.props.rolesData}
						addUser={this.props.addUser}
						close={this.modalClose}
					/>
				);
				break;
			case 'DeleteProvider':
				modal = (
					<DeleteProvider
						modalData={this.state.modal.data}
						history={this.props.history}
						deleteProvider={this.props.deleteProvider}
						close={this.modalClose}
					/>
				);
				break;
			case 'UserDetails':
				modal = (
					<UserDetails
						type={this.state.modal.subType}
						modalData={this.state.modal.data}
						providerUsersData={this.props.manageProviderData}
						usersData={this.props.searchUsersData}
						providersData={this.props.providersData}
						rolesData={this.props.rolesData}
						rowIndex={this.state.modal.index}
						changeUserStatus={this.props.changeManageUsersStatus}
						changeBlockUserStatus={this.props.changeUsersPendingStatus}
						changeSearchUsersStatus={this.props.changeSearchUsersStatus}
						changeUserProvider={this.props.changeUserProvider}
						changeUserRole={this.props.changeUserRole}
						close={this.modalClose}
					/>
				);
				break;
			default:
				modal = 'Modal type is undefined';
				break;
		}

		return modal;
	};

	modalClose = () => {
		this.setState({
			modal: {
				index: null,
				isOpen: false,
				type: null,
			},
		});
	};

	modalOpen = ({ index, type, subType, data, restrictClosing }) => {
		ReactGA.event({
			category: 'Providers and Users',
			action: 'Click',
			label: `Open Modal ${type}`,
		});

		this.setState({
			modal: {
				index: index,
				isOpen: true,
				type: type,
				subType: subType,
				data: data,
				restrictClosing,
			},
		});
	};

	render() {
		logger('[Users] Render');

		return (
			<div className={s.page}>
				<div className={s.content}>
					<div className={s.topContainer}>
						<Route path={routers.users} exact render={this.providerView} />
						<Route
							path={routers.usersProviderEdit()}
							render={this.providerEditView}
						/>

						<Modal
							className={s.modal}
							show={this.state.modal.isOpen}
							modalClosed={this.modalClose}
							restrictClosing={this.state.modal.restrictClosing}
						>
							{this.modalView()}
						</Modal>
					</div>
				</div>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	const {
		manageUsers: { usersPending, searchUsers, searchProviders, provider },
		providers,
		user,
		roles,
	} = state;

	return {
		currentUser: user.data,
		usersPendingData: usersPending.data,
		usersPendingLoading: usersPending.loading,
		providersData: providers.data,
		rolesData: roles.data,
		searchUsersData: searchUsers.data,
		searchUsersLoading: searchUsers.loading,
		searchProvidersData: searchProviders.data,
		searchProvidersLoading: searchProviders.loading,
		manageProviderData: provider.data,
		manageProviderLoading: provider.loading,
		blockedUsersData: provider.blockedData,
		blockedUsersLoading: provider.blockedLoading,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getProviders: (args) => dispatch(actions.getProviders(args)),
		deleteProvider: (id, success, error) =>
			dispatch(actions.deleteProvider(id, success, error)),
		updateProvider: (args) => dispatch(actions.updateProvider(args)),

		getSearchUsers: (args) => dispatch(actions.getSearchUsers(args)),
		clearSearchUsers: (args) => dispatch(actions.clearSearchUsers(args)),
		changeSearchUsersStatus: (args) =>
			dispatch(actions.changeSearchUsersStatus(args)),

		getManageProvider: (args) => dispatch(actions.getManageProvider(args)),
		changeManageUsersStatus: (args) =>
			dispatch(actions.changeManageUsersStatus(args)),
		clearManageProvider: () => dispatch(actions.clearManageProvider()),

		changeUserProvider: (args) => dispatch(actions.changeUserProvider(args)),
		changeUserRole: (args) => dispatch(actions.changeUserRole(args)),
		getRoles: (args) => dispatch(actions.getRoles(args)),
		addUser: (args) => dispatch(actions.addUser(args)),

		deletePinCodes: (args) => dispatch(actions.deletePinCodes(args)),
	};
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Users)),
);
