import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
	data: {},
	error: null,
	loading: false,
};

const getCurrentUserStart = (state) => {
	return updateObject(state, { error: null, loading: true });
};

const getCurrentUserSuccess = (state, action) => {
	// action.data.role_permissions = [];

	return updateObject(state, {
		data: action.data,
		error: null,
		loading: false,
	});
};

const getCurrentUserFail = (state, action) => {
	return updateObject(state, {
		error: action.error,
		loading: false,
	});
};

const updateUserProfile = (state, action) => {
	return updateObject(state, {
		data: updateObject(state.data, action.data),
		error: null,
		loading: false,
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_CURRENT_USER_START:
			return getCurrentUserStart(state, action);
		case actionTypes.GET_CURRENT_USER_SUCCESS:
			return getCurrentUserSuccess(state, action);
		case actionTypes.GET_CURRENT_USER_FAIL:
			return getCurrentUserFail(state, action);
		case actionTypes.UPDATE_USER_PROFILE:
			return updateUserProfile(state, action);
		default:
			return state;
	}
};

export default reducer;
