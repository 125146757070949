import { createSelector } from 'reselect';

const getPatientDemographicInfo = (state) => state.patientData;
const getModels = (state) => state.models.data;
const getErrors = (state) => state.errors.data;
const getSearch = (state) => state.concentrators.profiles.searchData;
const getProfiles = (state) => state.concentrators.profiles.data;
const getRequestParams = (state) =>
	state.patientSearchData.patientSearch.requestParams;
const getPatientProfiles = (state) =>
	state.patientSearchData.patientSearch.patientSearchListData;

export const makeGetPatientDataState = () =>
	createSelector(
		getPatientDemographicInfo,
		getModels,
		getErrors,
		getSearch,
		getProfiles,
		getRequestParams,
		getPatientProfiles,
		(
			patientData,
			modelsData,
			errorsData,
			searchData,
			profilesData,
			requestParams,
			patientSearchData,
		) => ({
			patientData,
			modelsData,
			errorsData,
			searchData,
			profilesData,
			requestParams,
			patientSearchData,
		}),
	);
