import { combineReducers } from 'redux';
import params from './params';
import recentError from './recentError';
import connected from './connected';
import userDevice from './userDevice';
import softwareVersion from './softwareVersion';
import columnLife from './columnLife';
import appConnected from './appConnected';

const reducer = combineReducers({
	params,
	recentError,
	connected,
	userDevice,
	softwareVersion,
	columnLife,
	appConnected,
});

export default reducer;
