import { coreInstance as axios, api } from 'settings';
import { logger } from 'helpers';
import * as actionTypes from '../../actions/actionTypes';

const getSoftwareVersionsStart = (action) => {
	return {
		type: actionTypes[action],
	};
};

const getSoftwareVersionsSuccess = (data, action) => {
	return {
		type: actionTypes[action],
		data: data,
	};
};

export const getSoftwareVersions = (args = {}) => {
	const params = {
		with: 'concentratorFirmware',
		...args.params,
	};

	const actions = args.actionTypes || {
		start: actionTypes.GET_SOFTWARE_VERSIONS_START,
		success: actionTypes.GET_SOFTWARE_VERSIONS_SUCCESS,
	};

	return (dispatch) => {
		dispatch(getSoftwareVersionsStart(actions.start));

		axios
			.get(api.concentrator_firmware_statistics.index, { params })
			.then((response) => {
				logger('[Action] Get software versions');

				const { data } = response.data;
				const total = data.reduce((acc, el) => (acc += el.amount), 0);
				const modifiedData = data
					.map((el) => {
						el.piece = +((el.amount / total) * 100);

						return el;
					})
					.sort((a, b) => b.piece - a.piece);

				dispatch(getSoftwareVersionsSuccess(modifiedData, actions.success));
			})
			.catch(() => {
				logger('[Action] Get software versions fail');
			});
	};
};
