import * as actionTypes from '../actions/actionTypes';

const initialState = false;

const loadingOn = () => {
	return true;
};

const loadingOff = () => {
	return false;
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.LOADING_ON:
		case actionTypes.AUTH_START:
			return loadingOn(state, action);
		case actionTypes.LOADING_OFF:
		case actionTypes.AUTH_SUCCESS:
		case actionTypes.AUTH_FAIL:
			return loadingOff(state, action);

		default:
			return state;
	}
};

export default reducer;
