import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
	data: null,
	filters: {},
	hasMoreData: true,
	loading: false,
	requestParams: {
		withNotEmpty: 'column_life',
		with: ['concentratorModel', 'healthcheckValues'],
		searchFields: {
			provider_id: 'in',
			'healthcheckValues.healthcheck_parameter_id': '=',
			'healthcheckValues.value': '>=',
		},
		search: {
			provider_id: process.env.APP_TYPE === 'oxygo' ? 4 : null,
			'healthcheckValues.healthcheck_parameter_id': 5,
			'healthcheckValues.value': 0,
		},
		orderBy: 'connected_at',
		sortedBy: 'desc',
		limit: 40,
	},
};

const setRequestParam = (state, action) => {
	const {
		withNotEmpty,
		with: withParam,
		search,
		searchFields,
		orderBy,
		sortedBy,
		codeFilter,
	} = action.params;
	const newParams = { ...state.requestParams };

	withNotEmpty !== undefined && (newParams.withNotEmpty = withNotEmpty);
	newParams.search = { ...newParams.search, ...search };
	newParams.searchFields = { ...newParams.searchFields, ...searchFields };
	withParam && (newParams.with = withParam);
	orderBy && (newParams.orderBy = orderBy);
	sortedBy && (newParams.sortedBy = sortedBy);
	newParams.codeFilter = { ...newParams.codeFilter, ...codeFilter };

	// if (!search.provider_id) {
	//     newParams.search.provider_id = initialState.requestParams.search.provider_id;
	//     newParams.searchFields.provider_id = initialState.requestParams.searchFields.provider_id;
	// }

	return { ...state, requestParams: newParams };
};

const getProfilesReportStart = (state) => {
	return updateObject(state, { loading: true });
};

const getProfilesReportSuccess = (state, action) => {
	const actionData = action.data.filter((el) => {
		// if (el.concentrator_error_recall_last) {
		//     return el.concentrator_error_recall_last.code !== 0;
		// }

		if (el.concentrator_location_detailed) {
			return (
				el.concentrator_location_detailed.country ||
				el.concentrator_location_detailed.locality ||
				el.concentrator_location_detailed.state_id ||
				el.concentrator_location_detailed.countryCode ||
				el.concentrator_location_detailed.adminLevelCode
			);
		}

		if (el.concentrator_location_detailed === null) {
			return false;
		}

		return true;
	});

	if (action.isMerge) {
		const data = [...state.data, ...actionData];

		return updateObject(state, {
			data: data,
			hasMoreData: action.data.length >= state.requestParams.limit,
		});
	}

	return updateObject(state, {
		data: actionData,
		loading: false,
		hasMoreData: action.data.length >= state.requestParams.limit,
	});
};

const setFilter = (state, action) => {
	return {
		...state,
		filters: updateObject(state.filters, {
			[action.filters.key]: [
				{
					id: action.item.id,
					name:
						action.item.name ||
						action.item.label ||
						action.item.description ||
						action.item.code,
				},
			],
		}),
	};
};

const removeFilter = (state, action) => {
	const newArr = state.filters[action.key].filter((value) => {
		return value.id !== action.item.id;
	});

	return {
		...state,
		filters: {
			...state.filters,
			[action.key]: newArr,
		},
	};
};

const clearProfiles = () => {
	return initialState;
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_PROFILES_REPORT_START:
			return getProfilesReportStart(state, action);
		case actionTypes.GET_PROFILES_REPORT_SUCCESS:
			return getProfilesReportSuccess(state, action);

		case actionTypes.SET_REPORT_FILTER:
			return setFilter(state, action);
		case actionTypes.REMOVE_REPORT_FILTER:
			return removeFilter(state, action);

		case actionTypes.SET_REQUEST_PARAM:
			return setRequestParam(state, action);

		case actionTypes.CLEAR_REPORTS:
			return clearProfiles(state, action);

		default:
			return state;
	}
};

export default reducer;
