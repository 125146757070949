import React from 'react';
import T from 'prop-types';

const Close = (props) => (
	<svg {...props} className={props.className}>
		<path d='M6.132 11.152L4.86 9.88l8.996-8.018a.416.416 0 0 1 .593 0l.69.69a.42.42 0 0 1 0 .593l-9.007 8.007zm-2.92.428l1.22 1.221-2.018.79.797-2.011zM15.678.633A2.143 2.143 0 0 0 14.153 0c-.578 0-1.12.225-1.528.634l-10.74 9.765a.865.865 0 0 0-.193.294L.062 14.81a.87.87 0 0 0 1.125 1.13l4.13-1.617a.871.871 0 0 0 .298-.195l10.754-9.753a2.16 2.16 0 0 0 0-3.053l-.69-.69z' />
	</svg>
);

Close.defaultProps = {
	fill: '#30ADFA',
	width: 17,
	height: 16,
	viewBox: '0 0 17 16',
};

Close.propTypes = {
	className: T.string,
};

export default Close;
