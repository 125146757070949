import React from 'react';
import {
	BarChart,
	ResponsiveContainer,
	XAxis,
	YAxis,
	Bar,
	CartesianGrid,
} from 'recharts';
import ReactTooltip from 'react-tooltip';
import { Info } from 'components/Icons';

import ps from '../style.module.scss';
import { withTranslation } from 'react-i18next';

const hideFirstTick = (tickValue) => {
	return tickValue || '';
};

const O2Purity = (props) => {
	const { t } = props;
	return (
		<div className={ps.twoThird}>
			<div className={ps.panel}>
				<h2 className={ps.title}>
					{t('o2_purity')}
					<Info
						fill='#a5bad4'
						data-tip=''
						data-for='O2Purity '
						className={ps.infoIconTrigger}
					/>
					<span>{t('last_30_days')}</span>
				</h2>
				<ReactTooltip
					id='O2Purity '
					className={ps.tooltip}
					place='bottom'
					type='light'
					effect='solid'
				>
					<Info fill='#a5bad4' className={ps.infoIconTooltip} />
					<p>{t('o2_purity_app_checks')}</p>
				</ReactTooltip>
				<ResponsiveContainer height={230}>
					<BarChart
						data={props.data}
						margin={{ top: 10, right: 20, bottom: 0, left: -30 }}
						maxBarSize={20}
					>
						<CartesianGrid
							vertical={false}
							strokeDasharray='2 6'
							stroke='#e0e0e0'
						/>
						<XAxis
							dataKey='range'
							tickLine={false}
							tickSize={3}
							stroke='#e0e0e0'
							tick={{ fill: '#333437', fontSize: 10 }}
						/>
						<YAxis
							tickLine={false}
							tickSize={4}
							tick={{ fill: '#999999', fontSize: 10 }}
							stroke='#e0e0e0'
							tickFormatter={hideFirstTick}
						/>
						<Bar dataKey='amount' fill='#30adfa' />
					</BarChart>
				</ResponsiveContainer>
			</div>
		</div>
	);
};

export default withTranslation()(O2Purity);
