export const colors = {
	0: '#30adfa',
	1: '#1d7adf',
	2: '#0857ad',
	3: '#0b3090',
	4: '#d9d9d9',
	5: '#30adfa',
	6: '#c56cdf',
	7: '#6c7ddf',
	8: '#efb815',
	9: '#0d9347',
	10: '#d9d9d9',
	11: '#0b3090',
	12: '#d9d9d9',
	13: '#333437',
	14: '#333437',
	15: '#e04829',
	16: '#ff8300',
	17: '#6ab221',
	18: '#1d7adf',
	19: '#0b3090',
	20: '#30adfa',
	21: '#c56cdf',
	22: '#6c7ddf',
	23: '#efb815',
	24: '#0d9347',
	25: '#d9d9d9',
	26: '#d9d9d9',
	27: '#d9d9d9',
	28: '#333437',
	29: '#333437',
	30: '#0d9347',
};

export const chartData = (data = [], models) =>
	data.map((el) => {
		let name = el.label || el.concentrator_firmware_id;
		let value = el.piece;

		if (el.concentrator_firmware && models) {
			name = `${models[el.concentrator_firmware.concentrator_model_id]} - ${
				el.concentrator_firmware.version
			}`;
		}

		return { name, value };
	});

export const TOP_CATEGORIES_AMOUNT = 4;
