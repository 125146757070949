import React, { PureComponent } from 'react';
import moment from 'moment';
import { Modal, Table } from 'components';
import { CloseButton } from 'components/Icons';
import { coreInstance as axios, api } from 'settings';
import { logger } from 'helpers';
import { AuxWrap } from 'hoc';
import tableConfig from './Configs/table';

import {
	Update,
	LocationOne,
	Discount,
	Service,
	Pair,
	Replace,
	Billing,
	Advertisements,
	Reminder,
	DataLog,
} from 'components/Icons';

import s from './style.module.scss';
import ps from '../style.module.scss';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';

class ItemModal extends PureComponent {
	state = {
		data: null,
		loading: true,
	};

	componentDidUpdate(prevProps) {
		const { itemId } = this.props;
		const isChange = prevProps !== this.props;

		if (isChange) {
			if (itemId) {
				this.getData(itemId);
			} else {
				this.setState({ data: null, loading: true });
			}
		}
	}

	getData = (id) => {
		const options = {
			with: ['user', 'pushType', 'pushNotificationSchedule'],
			append: ['filter_labels', 'analytics'],
		};

		axios
			.get(`${api.push_notification_history.index}/${id}`, { params: options })
			.then((response) => {
				logger('[ItemModal] Get push notifications by ID');

				this.setState({
					data: response.data.data,
					loading: false,
				});
			})
			.catch(() => {
				logger('[ItemModal] Get push notifications by ID fail');
			});
	};

	getAnalyticsTableData = () => {
		const { data } = this.state;
		const analytics = data ? data.analytics : {};
		const totalEvents = analytics.push_discarded + analytics.push_clicked;

		return [
			// {
			//     totalEvents: (
			//         <AuxWrap>
			//             <strong>{totalEvents}</strong>
			//             <small>% of Total 100.00% ({totalEvents})</small>
			//         </AuxWrap>
			//     )
			// },
			{
				category: i18next.t('push_sent'),
				totalEvents: (
					<AuxWrap>
						<b>{analytics.push_received}</b>
						{/*<small>({((analytics.push_received / totalEvents) * 100).toFixed(2)}%)</small>*/}
					</AuxWrap>
				),
			},
			{
				category: i18next.t('push_discarded'),
				totalEvents: (
					<AuxWrap>
						<b>{analytics.push_discarded}</b>
					</AuxWrap>
				),
				age: (
					<small>
						(
						{((analytics.push_discarded / (totalEvents || 1)) * 100).toFixed(2)}
						%)
					</small>
				),
			},
			{
				category: i18next.t('push_opened'),
				totalEvents: (
					<AuxWrap>
						<b>{analytics.push_clicked}</b>
					</AuxWrap>
				),
				age: (
					<small>
						({((analytics.push_clicked / (totalEvents || 1)) * 100).toFixed(2)}
						%)
					</small>
				),
			},
		];
	};

	getTimezoneName = (id) => {
		const key = Object.keys(this.props.timezonesData).find(
			(tz) => this.props.timezonesData[tz].id === id,
		);
		return this.props.timezonesData[key].name;
	};

	getTzOffset = (id) => {
		const key = Object.keys(this.props.timezonesData).find(
			(tz) => this.props.timezonesData[tz].id === id,
		);
		return this.props.timezonesData[key].utc_offset_hours;
	};

	getStatus = (statusId) => {
		if (statusId === 1) {
			return <span className={`${ps.scheduled} ${s.status}`}>Scheduled</span>;
		}

		if (statusId === 2) {
			return <span className={`${ps.sent} ${s.status}`}>Sent</span>;
		}
	};

	modalBody = () => {
		const { data } = this.state;
		const { t } = this.props;
		if (data) {
			const date = moment(data.created_at).format('DD MMM YYYY').toUpperCase();
			const name = data.user ? data.user.name : 'system';
			let sentList;
			let icon;

			switch (data.push_type.code) {
				case 'update':
					icon = (
						<>
							<Update newLogo={true} />
						</>
					);
					break;
				case 'location':
					icon = <LocationOne />;
					break;
				case 'discount':
				case 'promotion':
					icon = (
						<>
							<Discount newLogo={true} />
						</>
					);
					break;
				case 'service':
					icon = (
						<>
							<Service newLogo={true} />
						</>
					);
					break;
				case 'pair':
					icon = <Pair />;
					break;
				case 'replace':
					icon = <Replace />;
					break;
				case 'billing':
					icon = <Billing />;
					break;
				case 'advertisement':
					icon = (
						<>
							<Advertisements newLogo={true} />
						</>
					);
					break;
				case 'reminder':
					icon = <Reminder />;
					break;
				case 'data_transfer':
					icon = (
						<>
							<DataLog newLogo={true} theme={'dark'} />
						</>
					);
					break;

				default:
					// icon = null;
					icon = 'No icon';
					break;
			}

			if (data.filter_labels) {
				let labels = Object.values(data.filter_labels);

				sentList = (
					<div className={s.sentTo}>
						{i18next.t('sent_to')}
						<ul>
							{labels.map((el, index) => (
								<li key={index}>{el}</li>
							))}
						</ul>
					</div>
				);
			}

			return (
				<AuxWrap>
					<div className={s.alignTitle}>
						<h2 className={s.title}>
							{icon}

							{i18next.t(data.title)}
						</h2>
						<CloseButton
							className={s.closeBtn}
							onClick={this.props.closeModal}
						/>
					</div>
					<p className={s.note}>{i18next.t(data.body)}</p>
					{data.url_to_open ? (
						<div className={s.url}>
							<a href={data.url_to_open}>{data.url_to_open}</a>
						</div>
					) : null}
					<div
						className={s.createdInfo}
						dangerouslySetInnerHTML={{
							__html: t('created_by_at', { name: name, date: date }),
						}}
					></div>
					{data.push_notification_schedule.length ? (
						<div className={s.sendingTime}>
							<div className={s.byTimezones}>
								<span className={s.timeTitle}>Sending time:</span>
								<ul className={s.timzonesList}>
									{data.push_notification_schedule.map((date, index) => (
										<li className={s.timezoneItem} key={index}>
											<span>
												{moment
													.utc(date.scheduled_at)
													.utcOffset(this.getTzOffset(date.timezone_id))
													.format('DD MMM YYYY, HH:mm')
													.toUpperCase()}{' '}
												-
											</span>
											<span className={s.time}>
												{this.getTimezoneName(date.timezone_id)}
											</span>
											{this.getStatus(date.status)}
										</li>
									))}
								</ul>
							</div>
						</div>
					) : null}

					{sentList}
					<div>
						<Table
							theme='simple'
							className={s.analyticsTable}
							config={tableConfig}
							data={this.getAnalyticsTableData()}
							loading={this.props.tableLoading}
						/>
					</div>
				</AuxWrap>
			);
		}
	};

	render() {
		logger('[ItemModal] Render');

		return (
			<Modal
				className={s.modal}
				loading={this.state.loading}
				show={this.props.isOpen}
				// modalClosed={this.props.closeModal}
				restrictClosing={true}
			>
				{this.modalBody()}
			</Modal>
		);
	}
}

export default withTranslation()(ItemModal);
