import React from 'react';

import s from '../style.module.scss';
import { Button } from 'components';
import i18next from 'i18next';
import * as XLSX from 'xlsx';

const UsedConcentrators = ({
	version,
	status_id,
	serial_numbers: data = [],
}) => {
	if (!data.length || status_id === 4) return null;
	const downloadSNData = () => {
		if (data) {
			let SNData = [];
			data.map((sn) => {
				SNData.push({ 'S/N': sn.serial_number });
				return '';
			});
			var wb = XLSX.utils.book_new();
			var ws = XLSX.utils.json_to_sheet(SNData);
			XLSX.utils.book_append_sheet(wb, ws, 'serialNumber');
			XLSX.writeFile(wb, 'SerialNumberData.xlsx');
		}
	};

	let downloadText = (
		<span className={s.titleTypography}>{i18next.t('download')}</span>
	);

	return (
		<div>
			<div className={s.panel}>
				<div className={s.panelHead}>
					<div className={s.panelTitle}>
						<span>{i18next.t('used_concentrator')}</span>
					</div>
					<div className={s.panelVersioning}>{`${'V'} ${version}`}</div>
					<div className={s.panelButton}>
						<Button
							className={s.btn}
							btnType='type-purple-outline'
							clicked={downloadSNData}
						>
							{downloadText}
						</Button>
					</div>
				</div>
				<div className={s.concentratorFeature}>
					<div className={s.list}>
						{data.map((el, i) => (
							<span key={i} className={s.label}>
								{el.serial_number}
							</span>
						))}
					</div>
				</div>
			</div>
		</div>
	);
};

export default UsedConcentrators;
