export const getProvidersOptions = () => ({
	withCount: 'concentratorProfiles',
});
export const getSuggestionsParams = (options) => {
	const params = { ...options.prevParams };

	params.search = {
		...params.search,
		[options.key]: options.value,
	};

	params.searchFields = {
		...params.searchFields,
		[options.key]: 'like',
	};

	return params;
};
export const getSearchInputParams = (value) => ({
	search: {
		serial_number: value ? encodeURIComponent(value) : null,
	},
	searchFields: {
		serial_number: 'like',
	},
	searchJoin: 'and',
});

export const getFiltersParam = (providerIds = null, pinCodeIds = null) => ({
	search: {
		provider_id: providerIds
			? providerIds
			: process.env.APP_TYPE === 'oxygo'
			  ? 4
			  : providerIds,
		provider_code_id: pinCodeIds,
	},
	searchFields: {
		provider_id: 'in',
		provider_code_id: pinCodeIds && 'in',
	},
});

export const getTabParams = (name = 'column_life', paramsToSet = {}) => {
	const params = {
		withNotEmpty: null,
		search: {
			created_at: null,
			connected_at: null,
			'healthcheckValues.value': null,
			'healthcheckValues.is_failed': null,
			'healthcheckValues.healthcheck_parameter_id': null,
			'concentratorErrorRecallLast.code':
				paramsToSet.concentratorErrorRecallLastCode || null,
			concentrator_firmware_id: null,
			concentrator_model_id: paramsToSet.concentrator_model_id || null,
			'concentratorParameter.latest_firmware_created_at': null,
			'concentratorLocationDetailed.state_id': null,
			'concentratorErrorRecallLast.concentrator_error_recall_id': null,
		},
		searchFields: {
			created_at: null,
			connected_at: null,
			'healthcheckValues.value': null,
			'healthcheckValues.is_failed': null,
			'concentratorErrorRecallLast.code':
				paramsToSet.concentratorErrorRecallLastCode ? 'in' : null,
			concentrator_firmware_id: null,
			concentrator_model_id: paramsToSet.concentrator_model_id ? 'in' : null,
			'concentratorParameter.latest_firmware_created_at': null,
			'concentratorLocationDetailed.state_id': null,
			'concentratorErrorRecallLast.concentrator_error_recall_id': null,
		},
		codeFilter: {
			error_id: null,
		},
		orderBy: 'connected_at',
		sortedBy: 'desc',
	};

	switch (name) {
		case 'column_life':
			params.with = ['concentratorModel', 'healthcheckValues'];
			params.searchFields['healthcheckValues.value'] = '>=';
			params.search['healthcheckValues.healthcheck_parameter_id'] = 5;
			params.search['healthcheckValues.value'] = 0;
			params.withNotEmpty = 'column_life';
			break;
		case 'purity_o2':
			params.with = ['concentratorModel', 'healthcheckValues'];
			params.search['healthcheckValues.healthcheck_parameter_id'] = 7;
			break;
		case 'list_of_errors':
			params.with = [
				'concentratorModel',
				'concentratorErrorRecallLast',
				'concentratorErrorRecallLast.concentratorError',
			];
			params.searchFields[
				'concentratorErrorRecallLast.concentrator_error_recall_id'
			] = '>';
			params.searchFields['concentratorErrorRecallLast.code'] =
				paramsToSet.concentratorErrorRecallLastCode ? 'in' : '>';
			params.search[
				'concentratorErrorRecallLast.concentrator_error_recall_id'
			] = 0;
			params.search['concentratorErrorRecallLast.code'] =
				paramsToSet.concentratorErrorRecallLastCode || 0;
			params.codeFilter['error_id'] = paramsToSet.error_id || null;

			break;
		case 'software':
			params.with = [
				'concentratorParameter',
				'concentratorModel',
				'concentratorFirmware',
			];
			break;
		case 'location':
			params.with = ['concentratorModel', 'concentratorLocationDetailed'];
			break;
		default:
			return params;
	}

	return params;
};

export const errorsParams = {
	orderBy: 'description',
	sortedBy: 'asc',
};

export const modelsParams = {
	orderBy: 'id',
};
