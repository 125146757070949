import React from 'react';
import T from 'prop-types';

const Off = (props) => (
	<svg {...props} className={props.className}>
		<g fill='none' fillRule='evenodd'>
			<circle cx='8.5' cy='8.5' r='8' stroke={props.fill} />
			<path
				fill={props.fill}
				d='M8.033 10.38c0-.546.067-.982.2-1.306.132-.324.375-.643.729-.958.353-.314.589-.57.706-.767.117-.198.176-.406.176-.624 0-.66-.305-.99-.914-.99-.29 0-.52.088-.695.266-.173.178-.264.423-.272.735h-1.7c.008-.746.25-1.33.724-1.752.475-.422 1.122-.632 1.943-.632.828 0 1.47.2 1.927.6.457.4.686.966.686 1.696 0 .332-.074.646-.223.94-.148.296-.408.623-.779.982l-.475.451c-.296.286-.466.62-.51 1.002l-.023.358h-1.5zm-.17 1.8c0-.262.09-.478.267-.648a.947.947 0 0 1 .682-.255c.278 0 .505.085.683.255.178.17.267.386.267.648a.85.85 0 0 1-.261.638c-.174.168-.403.252-.688.252-.286 0-.515-.084-.689-.252a.85.85 0 0 1-.26-.638z'
			/>
		</g>
	</svg>
);

Off.defaultProps = {
	fill: '#30ADFA',
	width: 17,
	height: 17,
	viewBox: '0 0 17 17',
};

Off.propTypes = {
	fill: T.string,
	className: T.string,
};

export default Off;
