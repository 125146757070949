/** A generic grid component to show the data in tiles format
 *
 * @component
 * @example
 * import React from 'react';
 * import GridView from './GridView';
 *
 * const data = [
 *   { id: 1, name: 'Alice', age: 25, date: '2023-08-25T10:30:00Z' },
 * ];
 *
 * const pageSize=10; // default page size is 10 which shows 10 data per page
 *
 * call the component:
 * <GridView dataToShow={data} pageSize={pageSize}/>
 * @param {Object} props - Component props
 * @param {Array} props.data - The data to be displayed in the table
 * @param {number} [props.pageSize=10] - The number of rows to display per page
 * return {JSX.Element} - Rendered component
 * @category Patient
 * @subcategory PatientDashboard
 */

import React, { useState } from 'react';
import s from './style.module.scss';
import { RightChevron } from '../Icons';
import { useEffect } from 'react';
import { routers } from 'settings';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { Spinner } from '../../components';
import i18next from 'i18next';

const options = {
	year: 'numeric',
	month: 'short',
	day: 'numeric',
	hour: '2-digit',
	minute: '2-digit',
	second: '2-digit',
};

const GridView = (props) => {
	let { dataToShow, updateSelectedRows } = props;

	const pageSize = props?.pageSize || 2;
	const [pageIndex, setPageIndex] = useState(0);
	const { selectedRows } = props;
	const paginatedData = dataToShow?.slice(
		pageIndex * pageSize,
		(pageIndex + 1) * pageSize,
	);
	useEffect(() => {
		setPageIndex(0);
	}, [dataToShow]);

	const handleRowSelection = (e, rowId) => {
		e.stopPropagation();
		const index = selectedRows?.indexOf(rowId);
		const newSelectedRows = [...selectedRows];
		if (index === -1) {
			newSelectedRows.push(rowId);
		} else {
			newSelectedRows.splice(index, 1);
		}
		updateSelectedRows(newSelectedRows);
	};
	const handlePatientIds = (patient_id, error_id) => {
		const patientIds = { patientId: patient_id, errorId: error_id };
		props.setPatientId(patientIds);
	};

	return (
		<>
			{dataToShow?.length === 0 && !props?.loading && (
				<div className={s.noData}>{i18next.t('no_data_found')}</div>
			)}
			<div className={s.grid}>
				{paginatedData?.map((eachItem, index) => (
					<div
						className={s.grid_item}
						key={index}
						onClick={() => {
							handlePatientIds(eachItem.patient_id, eachItem.id);
							props.history.push(routers.patientDetail);
						}}
					>
						<div>
							<div className={s.firstRow}>
								<input
									className={s.checkBox}
									type='checkbox'
									checked={selectedRows?.includes(eachItem.id)}
									onClick={(e) => {
										handleRowSelection(e, eachItem.id);
									}}
									onChange={() => {}} // to remove warning
								/>
								<div className={s.error_typography}>
									{eachItem?.error_description !== null
										? i18next.t(eachItem.error_description)
										: ''}
								</div>
							</div>
							<div className={s.name_typography}>
								{eachItem?.first_name?.concat(' ', eachItem?.last_name)}
							</div>
							<div className={s.date_typography}>
								{(() => {
									const date = new Date(eachItem?.happened_at);
									const year = date.getFullYear();
									let month = date.toLocaleString(i18next.language, {
										month: 'short',
									});
									month = month.replace('.', '');
									const capitalizedMonth =
										month.charAt(0).toUpperCase() + month.slice(1);
									const day = date.getDate().toString().padStart(2, '0');
									const time = date.toLocaleTimeString('en-US', { options });
									return `${capitalizedMonth} ${day} ${year}, ${time}`;
								})()}
							</div>
						</div>
						<div className={s.chevron_top}>
							<RightChevron />
						</div>
					</div>
				))}
			</div>
			{props?.loading && <Spinner className={s.alignCenter} />}
		</>
	);
};

export default withRouter(withTranslation()(GridView));
