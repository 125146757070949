import React, { Fragment } from 'react';
import { List, ListItem, Spinner } from 'components';
import { ErrorRecall } from 'components/Icons';
import { timeConvert, permissions } from 'helpers';

import s from './style.module.scss';
import ps from '../style.module.scss';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';

const G4_ONLY_PARAMS = [
	i18next.t('calibration_deviation_fs4'),
	i18next.t('calibration_deviation_fs5'),
	i18next.t('calibration_deviation_fs6'),
];
const G4HF_ONLY_PARAMS = [
	i18next.t('calibration_deviation_fs5'),
	i18next.t('calibration_deviation_fs6'),
];

const convertTime = (mins = 0) => {
	let hours = parseInt(mins / 60);
	let minutes = mins % 60;
	return `${hours} hours ${minutes} minutes`;
};

const AdvancedDataDialog = (props) => {
	const { loading, engineeringParams, engineeringVals } = props.data;

	const { concentrator, t } = props;

	const data = engineeringParams
		? engineeringParams.map((param, index) => {
				const item = {
					label: param.label,
					index,
				};

				if (param.code_string === 'software_version') {
					item.value =
						engineeringVals[0] && engineeringVals[0].concentrator_firmware
							? engineeringVals[0].concentrator_firmware.version
							: '';
				} else {
					item.value = engineeringVals[0]
						? engineeringVals[0][param.code_string]
						: '';
				}

				return item;
		  })
		: [];

	const getValue = (item, concentrator) => {
		if (item.label === 'Life Clock') {
			return convertTime(item.value);
		}
		if (
			concentrator &&
			concentrator.concentrator_model &&
			concentrator.concentrator_model.description
		) {
			if (
				concentrator.concentrator_model.description ===
					i18next.t('inogen_one_g4') &&
				G4_ONLY_PARAMS.includes(item.label)
			) {
				return 'N/A';
			}
			if (
				concentrator.concentrator_model.description ===
					i18next.t('inogen_p4_via') &&
				G4HF_ONLY_PARAMS.includes(item.label)
			) {
				return 'N/A';
			}
		}
		return item.value;
	};

	const errorRecallLast = () => {
		if (
			concentrator.concentrator_error_recall_last &&
			concentrator.concentrator_error_recall_last.code
		) {
			const isEngineer = permissions.checkRole('engineer');
			let elapsed, description;

			if (concentrator.concentrator_error_recall_last.elapsed_time) {
				elapsed = (
					<span className={ps.elapsed}>
						{timeConvert(
							concentrator.concentrator_error_recall_last.elapsed_time,
						)}
					</span>
				);
			}

			if (
				concentrator.concentrator_error_recall_last.concentrator_error.code ===
				0
			) {
				description = i18next.t('no_errors');
			} else {
				description = isEngineer
					? concentrator.concentrator_error_recall_last.concentrator_error
							.description_engineer
					: concentrator.concentrator_error_recall_last.concentrator_error
							.description;
				// description = isEngineer ? 'concentrator_error_engineer_' + concentrator.concentrator_error_recall_last.concentrator_error.code : 'concentrator_error_' + concentrator.concentrator_error_recall_last.concentrator_error.code;
			}

			return (
				<li className={s.errorRecall}>
					<div>
						<span>{t('error_recall')}</span> <ErrorRecall className={s.icon} />
					</div>
					<div className={s.description}>
						<b>{i18next.t(description)}</b>
					</div>
					<div>
						<div className={ps.cellFlex}>{elapsed}</div>
					</div>
				</li>
			);
		}

		return (
			<li className={s.errorRecall}>
				<div>
					<span>{t('error_recall')}</span> <ErrorRecall className={s.icon} />
				</div>
				<div className={s.description} />
				<div>
					<div className={ps.cellFlex} />
				</div>
			</li>
		);
	};

	return (
		<Fragment>
			<div className={s.container}>
				{loading ? (
					<Spinner />
				) : (
					<List className={s.list}>
						{data.map((item) => (
							<ListItem key={item.index} className={s.listItem}>
								<span>{item.label}</span>
								<span className={s.value}>{getValue(item, concentrator)}</span>
							</ListItem>
						))}
					</List>
				)}
			</div>
			<div className={`${s.container} ${s.margins}`}>
				<List>{errorRecallLast()}</List>
			</div>
		</Fragment>
	);
};

export default withTranslation()(AdvancedDataDialog);
