import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Autosuggest from 'react-autosuggest';
import { Button } from 'components';
import { logger } from 'helpers';

import s from './style.module.scss';
import theme from './theme.module.scss';
import { withTranslation } from 'react-i18next';

const getSuggestionValue = (suggestion) => suggestion.serial_number;

const renderSuggestion = (suggestion) => (
	<div className={s.item}>{suggestion.serial_number}</div>
);

class SearchInput extends Component {
	onSubmit = (e) => {
		e.preventDefault();
		logger('[SearchInput] Seacrh submit');

		this.props.onSearchSubmit();
	};

	onSuggestionsFetch = () => {
		clearTimeout(this.timeOut);

		this.timeOut = setTimeout(() => {
			this.props.onSuggestions();
		}, 300);
	};

	renderInputComponent = (inputProps) => (
		<form
			onSubmit={this.onSubmit}
			className={[
				s.searchInput,
				s[this.props.theme],
				this.props.className,
			].join(' ')}
		>
			<div className={s.input}>
				<input {...inputProps} ref={this.props.inputRef} />
				{inputProps.value && (
					<span className={s.clear} onClick={this.props.onClear} />
				)}
			</div>
			<Button
				btnType={this.props.btnType ? this.props.btnType : 'search'}
				btnTheme={this.props.theme}
				className={s.searchBtn}
				disabled={this.props.disabled}
			>
				{this.props.t('search')}
			</Button>
		</form>
	);

	render() {
		const { t } = this.props;
		const inputProps = {
			value: t(this.props.value),
			placeholder: t(this.props.placeholder),
			onChange: this.props.onChange,
		};

		return (
			<Autosuggest
				theme={theme}
				suggestions={this.props.suggestions}
				onSuggestionsFetchRequested={this.onSuggestionsFetch}
				onSuggestionsClearRequested={this.props.onSuggestionsClear}
				getSuggestionValue={getSuggestionValue}
				renderSuggestion={renderSuggestion}
				inputProps={inputProps}
				renderInputComponent={this.renderInputComponent}
			/>
		);
	}
}

SearchInput.defaultProps = {
	value: '',
	suggestions: [],
	onSuggestions: () => {},
	onSearchSubmit: () => {},
	onSuggestionsClear: () => {},
};

SearchInput.propTypes = {
	value: PropTypes.string,
	suggestions: PropTypes.array,
	onChange: PropTypes.func,
	onSuggestions: PropTypes.func,
	onSuggestionsClear: PropTypes.func,
	onSearchSubmit: PropTypes.func,
};

export default withTranslation()(SearchInput);
