import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Input, Spinner, ValidateError } from 'components';
import { coreInstance as axios, api } from 'settings';

import s from '../style.module.scss';
import ps from '../style.module.scss';
import i18next from 'i18next';

const FirstStep = (props) => {
	const {
		controls: { title, type, description, url_to_open },
		typesData,
		inputChangedHandler,
		inputFocusOutHandler,
		nextHandler,
	} = props;
	var { isValid } = props;
	const [linkValidating, setLinkValidating] = useState(false);
	const [errorsMsg, setErrorsMsg] = useState(null);
	const typeChecked = Object.values(type.checked);
	const typeValue = typeChecked[0];
	if (typeValue && typeValue.code === 'update') {
		title.value = i18next.t('update_software');
		title.touched = false;
		isValid = true;
		description.elementConfig.disabled = true;
	} else {
		description.elementConfig.disabled = false;
	}
	const clickHandler = () => {
		if (url_to_open.value) {
			setLinkValidating(true);

			const params = {
				url_to_open: url_to_open.value,
			};

			return axios.get(api.validate_link.index, { params }).then(
				() => {
					setLinkValidating(false);
					nextHandler();
				},
				(error) => {
					setErrorsMsg(error.response.data);
					setLinkValidating(false);
				},
			);
		}

		return nextHandler();
	};

	const filteredTypes =
		typesData && typesData.length > 1
			? typesData.filter((e) => e.push_group_id !== 6)
			: typesData;

	const titleLabel = () => {
		const length = title.value.length;
		const maxLength = title.validation.maxLength;

		return (
			<Fragment>
				<span className={s.alignCountValidation}>
					{length}/
					<span
						className={
							length >= maxLength ? s.red : '' + length > 0 ? s.green : ''
						}
					>
						{maxLength - length}
					</span>
				</span>
			</Fragment>
		);
	};

	const descriptionLabel = () => {
		const length = description.value.length;
		const maxLength = description.validation.maxLength;

		return (
			<Fragment>
				<span className={s.alignCountValidation}>
					{length}/
					<span
						className={
							length >= maxLength ? s.red : '' + length > 0 ? s.green : ''
						}
					>
						{maxLength - length}
					</span>
				</span>
			</Fragment>
		);
	};
	return (
		<Fragment>
			<div className={s.contentContainer}>
				<div className={s.addUserContainer}>
					<div className={s.fieldContainer}>
						<div className={s.fieldHeight}>
							<div className={s.selectHead}>
								<span>
									{i18next.t('type')} <span className={s.mandatory}>{'*'}</span>
								</span>
							</div>
							<div className={[s.selectField, s.selectMargin].join(' ')}>
								<Input
									newDesign={true}
									newIcon={true}
									className={type.className}
									options={filteredTypes}
									checked={type.checked}
									disabled={type.disabled}
									elementType={type.elementType}
									elementConfig={type.elementConfig}
									value={type.value}
									invalid={!type.valid}
									invalidMsg={type.errorMsg}
									touched={type.touched}
									changed={(event) => inputChangedHandler(event, 'type')}
									onBlur={(event) => inputFocusOutHandler(event, 'type')}
								/>
							</div>
						</div>
						<div className={s.fieldHeight}>
							<div className={s.selectHead}>
								<span>
									{i18next.t('title')}{' '}
									<span className={s.mandatory}>{'*'}</span>
								</span>
							</div>
							<Input
								className={title.className}
								elementType={title.elementType}
								elementConfig={title.elementConfig}
								value={title.value}
								invalid={!title.valid}
								invalidMsg={title.errorMsg}
								shouldValidate={title.validation}
								newDesign={true}
								touched={title.touched}
								changed={(event) => inputChangedHandler(event, 'title')}
								onBlur={(event) => inputFocusOutHandler(event, 'title')}
							/>
							{titleLabel()}
						</div>
						<div>
							<div className={s.selectHead} style={{ marginBottom: '8px' }}>
								<span>{i18next.t('notify_description')}</span>
							</div>
							<Input
								newDesign={true}
								className={description.className}
								elementType={description.elementType}
								elementConfig={description.elementConfig}
								value={description.value}
								rows={4}
								invalid={!description.valid}
								invalidMsg={description.errorMsg}
								touched={description.touched}
								changed={(event) => inputChangedHandler(event, 'description')}
								onBlur={(event) => inputFocusOutHandler(event, 'description')}
							/>

							{descriptionLabel()}
						</div>
						<div className={s.fieldHeight}>
							<Input
								label={i18next.t('link')}
								elementType={url_to_open.elementType}
								elementConfig={url_to_open.elementConfig}
								value={url_to_open.value}
								newDesign={true}
								invalid={!url_to_open.valid}
								invalidMsg={url_to_open.errorMsg}
								shouldValidate={url_to_open.validation}
								touched={url_to_open.touched}
								changed={(event) => inputChangedHandler(event, 'url_to_open')}
								onBlur={(event) => inputFocusOutHandler(event, 'url_to_open')}
							/>
							{linkValidating ? (
								<div className={s.linkValidation}>
									<Spinner smaller />
									{i18next.t('verifying_the_link_address')}
								</div>
							) : null}
						</div>
					</div>
				</div>

				<div className={s.button}>
					<Button
						btnType='type-21'
						disabled={!isValid || linkValidating}
						clicked={clickHandler}
					>
						{i18next.t('next_new')}
						<svg
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
							style={{ marginLeft: '8px' }}
						>
							<path
								d='M16.6269 12.75H4.5V11.25H16.6269L10.9308 5.55383L12 4.5L19.5 12L12 19.5L10.9308 18.4461L16.6269 12.75Z'
								fill='white'
							/>
						</svg>
					</Button>
				</div>
				{errorsMsg && <ValidateError data={errorsMsg} className={ps.errors} />}
			</div>
		</Fragment>
	);
};

FirstStep.propTypes = {
	typesData: PropTypes.array,
	controls: PropTypes.object,
	isValid: PropTypes.bool,
	inputChangedHandler: PropTypes.func,
	inputFocusOutHandler: PropTypes.func,
	nextHandler: PropTypes.func,
};

export default FirstStep;
