import { combineReducers } from 'redux';
import history from './history';
import types from './types';
import firmwares from './firmwares';

const reducer = combineReducers({
	history,
	types,
	firmwares,
});

export default reducer;
