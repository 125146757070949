import React from 'react';
import { withTranslation } from 'react-i18next';
import { DetailsPanel } from '../../../../../../src/components';
import s from './../style.module.scss';

const FirmwareInfo = ({ data }) => {
	let FIRMWARE_DETAILS = [
		{
			key: 'concentrator_model_id',
			label: 'Product',
			type: 'text',
			translateKey: 'product',
		},
		{
			key: 'version',
			label: 'Version',
			type: 'text',
			translateKey: 'version',
		},
		{
			key: 'part_num',
			label: 'Part Number',
			type: 'text',
			translateKey: 'part_number',
		},
		{
			key: 'released_at',
			label: 'Release Date',
			type: 'text',
			translateKey: 'release_date',
		},
	];
	return (
		<div className={s.panelDetails}>
			<DetailsPanel mapper={FIRMWARE_DETAILS} data={data} />
		</div>
	);
};

export default withTranslation()(FirmwareInfo);
