import React from 'react';
import T from 'prop-types';

const Engineering = (props) => (
	<svg {...props} className={props.className}>
		<path
			fillRule='evenodd'
			d='M3.737 16.327l3.774-4.313a2.5 2.5 0 1 1 4.225-.394l2.866 2.253a2.494 2.494 0 0 1 2.709-.739l2.684-5.637a2.5 2.5 0 1 1 .903.43l-2.723 5.717a2.5 2.5 0 1 1-4.064 1.115l-2.994-2.352c-.436.37-1 .593-1.617.593-.45 0-.872-.119-1.237-.327l-3.774 4.313a2.5 2.5 0 1 1-.752-.659z'
		/>
	</svg>
);

Engineering.defaultProps = {
	fill: '#999',
	width: 24,
	height: 24,
	viewBox: '0 0 24 24',
};

Engineering.propTypes = {
	className: T.string,
};

export default Engineering;
