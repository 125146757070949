import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
	data: null,
	patientSearchListData: null,
	loading: true,
	requestParams: {
		sortedBy: 'desc',
		orderBy: 'created_at',
		limit: 40,
		offset: 1,
	},
};
const setPateintSearchParam = (state, action) => {
	return {
		...state,
		requestParams: {
			...state.requestParams,
			...action.params,
			search: {
				...state.requestParams.search,
				...action.params.search,
			},
			searchFields: {
				...state.requestParams.searchFields,
				...action.params.searchFields,
			},
		},
	};
};

const getPatientProfileStart = (state) => {
	return updateObject(state, { loading: true });
};

const getPatientProfilesSuccess = (state, action) => {
	return updateObject(state, {
		patientSearchListData: action.data,
		loading: false,
	});
};
const clearPatientSearchData = (state) => {
	return updateObject(state, { patientSearchListData: [] });
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_PATIENT_SEARCH_DETAILS_START:
			return getPatientProfileStart(state, action);
		case actionTypes.GET_PATIENT_SEARCH_DETAILS_SUCCESS:
			return getPatientProfilesSuccess(state, action);
		case actionTypes.SET_PATIENT_SEARCH_PARAM:
			return setPateintSearchParam(state, action);
		case actionTypes.CLEAR_PATIENT_SEARCH_DATA:
			return clearPatientSearchData(state, action);

		default:
			return state;
	}
};

export default reducer;
