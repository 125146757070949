import React from 'react';
import s from './style.module.scss';
import imgPlaceholder from 'globalImg/image-placeholder.png';

const Avatar = (props) => {
	return (
		<div
			className={`${s.avatar} ${props.className}`}
			style={{
				backgroundImage: `url(${props.img || imgPlaceholder})`,
				width: props.width || '10rem',
				height: props.height || '10rem',
			}}
		/>
	);
};

export default Avatar;
