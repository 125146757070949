import moment from 'moment';
import { coreInstance as axios, api } from 'settings';
import { logger } from 'helpers';
import * as actionTypes from './actionTypes';

const createParams = (params = {}, min = 10) => ({
	...params,
	search: {
		...params.search,
		connected_at: [
			moment().utc().subtract(min, 'minutes').format('YYYY-MM-DDTHHmmss'),
		],
	},
	searchFields: {
		...params.searchFields,
		connected_at: '>=',
	},
});

export const getLocationsCount = (args = {}) => {
	const { lastMin } = args;
	const params = { count: 1, ...args.params };

	return (dispatch) => {
		return axios
			.get(api.concentrator_locations.all, {
				params: lastMin ? createParams(params, lastMin) : params,
			})
			.then(({ data }) => {
				logger('[Action] Get locations count');

				dispatch({
					type: actionTypes.GET_LOCATIONS_COUNT,
					data: data.rows,
				});
			})
			.catch(() => {
				logger('[Action] Get locations count fail');
			});
	};
};

const getLocationsStart = () => {
	return {
		type: actionTypes.GET_LOCATIONS_START,
	};
};

const getLocationsSuccess = (data, headings) => {
	return {
		type: actionTypes.GET_LOCATIONS_SUCCESS,
		data: data,
		headings: headings,
	};
};

export const getLocations = (args = {}) => {
	const { params, lastMin } = args;

	return (dispatch) => {
		dispatch(getLocationsStart());

		return axios
			.get(api.concentrator_locations.all, {
				params: lastMin ? createParams(params, lastMin) : params,
			})
			.then((response) => {
				logger('[Action] Get location');

				const { data, headings } = response.data;

				dispatch(getLocationsSuccess(data, headings));
			})
			.catch(() => {
				logger('[Action] Get location fail');
			});
	};
};
