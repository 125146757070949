import React from 'react';
import T from 'prop-types';

const BatteryIcon = (props) => (
	<svg {...props} className={props.className}>
		<path
			d='M11.5538 19.2822L15.7046 10.4703H12.5388V4.6252L8.38793 13.4253H11.5538V19.2822ZM12 22C10.583 22 9.26551 21.7456 8.04755 21.2367C6.82957 20.7278 5.77159 20.0244 4.87361 19.1264C3.97562 18.2284 3.27219 17.1704 2.76331 15.9524C2.25444 14.7345 2 13.417 2 12C2 10.5994 2.25397 9.29101 2.76191 8.07476C3.26986 6.8585 3.9733 5.80052 4.87221 4.90082C5.77112 4.00112 6.82957 3.29273 8.04755 2.77565C9.26551 2.25855 10.583 2 12 2C13.4005 2 14.7091 2.2583 15.9255 2.77489C17.142 3.29146 18.2002 3.99956 19.1 4.8992C19.9999 5.79883 20.7082 6.85673 21.2249 8.07289C21.7416 9.28906 22 10.5991 22 12.0029C22 13.418 21.7414 14.7345 21.2243 15.9524C20.7072 17.1704 19.9989 18.2289 19.0992 19.1278C18.1995 20.0267 17.1415 20.7301 15.9252 21.2381C14.709 21.746 13.4005 22 12 22Z'
			fill='#5B60D7'
		/>
	</svg>
);

BatteryIcon.defaultProps = {
	fill: 'none',
	width: 24,
	height: 24,
};

BatteryIcon.propTypes = {
	className: T.string,
};

export default BatteryIcon;
