import React from 'react';
import T from 'prop-types';

const StatusIcon = (props) => (
	<svg {...props} className={props.className}>
		<path
			d='M8.53242 14.9095L15.5659 7.87598L14.3155 6.64421L8.53242 12.4272L5.63046 9.52529L4.40458 10.7571L8.53242 14.9095ZM9.99786 20.3365C8.62761 20.3365 7.33449 20.0749 6.11847 19.5516C4.90246 19.0282 3.84038 18.3125 2.93222 17.4043C2.02407 16.4962 1.30832 15.4344 0.784983 14.2189C0.261661 13.0034 0 11.7106 0 10.3403C0 8.95365 0.261661 7.65232 0.784983 6.43631C1.30832 5.2203 2.02376 4.16221 2.93129 3.26205C3.83884 2.3619 4.90049 1.64929 6.11626 1.12419C7.33201 0.599096 8.62517 0.336548 9.99574 0.336548C11.3827 0.336548 12.6845 0.598948 13.9009 1.12375C15.1174 1.64853 16.1756 2.36074 17.0754 3.26037C17.9753 4.16001 18.6877 5.21791 19.2126 6.43407C19.7375 7.65024 20 8.95205 20 10.3395C20 11.7103 19.7375 13.0037 19.2124 14.2196C18.6873 15.4355 17.9746 16.4974 17.0745 17.405C16.1743 18.3127 15.1165 19.0282 13.9011 19.5516C12.6856 20.0749 11.3845 20.3365 9.99786 20.3365Z'
			fill='#00A023'
		/>
	</svg>
);

StatusIcon.defaultProps = {
	fill: '#6AB221',
	width: 24,
	height: 24,
	viewBox: '0 0 24 24',
};

StatusIcon.propTypes = {
	className: T.string,
};

export default StatusIcon;
