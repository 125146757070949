import { combineReducers } from 'redux';
import all from './all';
import current from './current';

const reducer = combineReducers({
	all,
	current,
});

export default reducer;
