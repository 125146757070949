import * as actionTypes from './actionTypes';

export const loadingOn = () => {
	return {
		type: actionTypes.LOADING_ON,
	};
};

export const loadingOff = () => {
	return {
		type: actionTypes.LOADING_OFF,
	};
};
