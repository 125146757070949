import { coreInstance as axios, api } from 'settings';
import { logger } from 'helpers';
import * as actionTypes from './actionTypes';
import i18next from 'i18next';

const getTermsStart = () => {
	return {
		type: actionTypes.GET_TERMS_START,
	};
};

const getTermsSuccess = (data) => {
	return {
		type: actionTypes.GET_TERMS_SUCCESS,
		data: data,
	};
};

export const getTerms = (args = {}) => {
	const { params = {} } = args;

	const currentLang = i18next.language;

	// params.search = { key: 'terms_of_use_'+currentLang };
	// params.searchFields = { key: '=' };
	// conuntry field from local storage
	const conuntry = localStorage.getItem('country') || 'US';
	const languages = {
		en: 'english',
		fr: 'french',
	};
	params.category = 'terms_of_use';
	params.language = languages[currentLang];
	params.country = conuntry;

	return (dispatch) => {
		dispatch(getTermsStart());

		// axios.get(api.settings.index, { params })
		axios
			.get(api.get_terms_of_use.index, { params })
			.then((response) => {
				logger('[Action] Get terms');

				dispatch(getTermsSuccess(response.data.data));
			})
			.catch(() => {
				logger('[Action] Get terms fail');
			});
	};
};
