import React from 'react';
import { AuxWrap } from 'hoc';
import moment from 'moment';

const tableConfig = [
	{
		dataHead: [
			{ displayText: 'date' },
			{ displayText: 'software_version' },
			{ displayText: 'changes' },
		],
		dataBody: {
			created_at: {
				path: 'created_at',
				view: ({ value }) => value && moment(value).format('MM.DD.YYYY'),
			},
			version: {
				path: ['concentrator_firmware', 'version'],
			},
			description: {
				path: ['concentrator_firmware', 'description'],
			},
		},
	},
	{
		dataHead: [
			{ displayText: 'pairing_date' },
			{ displayText: 'unpairing_date' },
			{ displayText: 'device' },
		],
		dataBody: {
			paired_at: {
				path: 'paired_at',
				view: ({ value }) => {
					if (value) {
						return (
							<AuxWrap>
								<strong>{moment(value).format('MM.DD.YYYY')}</strong>
								{moment(value).format('HH:mm')}
							</AuxWrap>
						);
					}
				},
			},
			unpaired_at: {
				path: 'unpaired_at',
				view: ({ value }) => {
					if (value) {
						return (
							<AuxWrap>
								<strong>{moment(value).format('MM.DD.YYYY')}</strong>
								{moment(value).format('HH:mm')}
							</AuxWrap>
						);
					} else {
						return <strong>- -</strong>;
					}
				},
			},
			device_name: {
				path: ['device_name', 'name'],
			},
		},
	},
	{
		dataHead: [
			{ displayText: 'connection_date' },
			{ displayText: 'disconnection_date' },
		],
		dataBody: {
			connected_at: {
				path: 'connected_at',
				view: ({ value }) => {
					if (value) {
						return (
							<AuxWrap>
								<strong>{moment(value).format('MM.DD.YYYY')}</strong>
								{moment(value).format('HH:mm')}
							</AuxWrap>
						);
					} else {
						return <strong>- -</strong>;
					}
				},
			},
			disconnected_at: {
				path: 'disconnected_at',
				view: ({ value }) => {
					if (value) {
						return (
							<AuxWrap>
								<strong>{moment(value).format('MM.DD.YYYY')}</strong>
								{moment(value).format('HH:mm')}
							</AuxWrap>
						);
					} else {
						return <strong>- -</strong>;
					}
				},
			},
		},
	},
	{
		dataHead: [
			{ displayText: 'Error Description' },
			{ displayText: 'Error Date & Time' },
		],
		dataBody: {
			description: {
				path: 'description',
			},
			happened_at: {
				path: 'happened_at',
				view: ({ value }) => {
					if (value) {
						return (
							<AuxWrap>
								<strong>{moment(value).format('MM.DD.YYYY')}</strong>
								{moment(value).format('HH:mm')}
							</AuxWrap>
						);
					} else {
						return <strong>- -</strong>;
					}
				},
			},
		},
	},
];

export default tableConfig;
