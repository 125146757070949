import React from 'react';
// import { Spinner } from 'components';
import { Block } from 'components/Icons';

const manageProvider = {
	dataBody: {
		email: {
			path: 'email',
		},
		status: {
			path: 'status',
			view: ({ row }) => {
				if (row.status) {
					return null;
				}

				return <Block />;
			},
			// view: ({ props, row: { id, status, loading, email }, rowIndex }) => {
			//     let icon = <Spinner smaller />;

			//     const handleClick = (e) => {
			//         e.stopPropagation();

			//         const args = {
			//             id: id,
			//             status: status ? 0 : 1
			//         };

			//         props.changeUsersPendingStatus(args);
			//     };

			//     if (email === props.currentUser.email) return;

			//     if (!loading) {
			//         switch (status) {
			//             case 0:
			//                 icon = <WarningTwo onClick={handleClick} />;
			//                 break;
			//             default:
			//                 icon = <WarningOne onClick={handleClick} />;
			//                 break;
			//         }
			//     }

			//     return icon;
			// }
		},
	},
	rowView: (row, classesArr, style) => {
		if (row && !row.status) {
			classesArr.push(style['blockRow']);
		}
	},
};

export default manageProvider;
