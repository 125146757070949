import * as actionTypes from '../actionTypes';

export const setDashParam = (params, action = actionTypes.SET_DASH_PARAM) => {
	return {
		type: actionTypes[action],
		params: params,
	};
};

export const resetDashParam = (action) => {
	return {
		type: actionTypes[action],
	};
};
