import React, { PureComponent } from 'react';
import onClickOutside from 'react-onclickoutside';
import PropTypes from 'prop-types';
import { AuxWrap } from 'hoc';
import { withTranslation } from 'react-i18next';

import sOld from './style.module.scss';
import sLatest from './newStyle.module.scss';

class Select extends PureComponent {
	state = {
		isOpen: false,
	};

	getCurrentValues = () => {
		const { checked, path, placeholder, t } = this.props;
		const s = this.props.newDesign ? sLatest : sOld;
		const checkedArray = Object.values(checked);
		const values = checkedArray.map((obj) => obj[path]);
		let value = values;

		if (!checkedArray.length) {
			value =
				this.props.showIcon && !this.state.isOpen ? (
					<div className={s.iconStyle}>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							width='24'
							height='24'
							viewBox='0 0 24 24'
							fill='none'
						>
							<path
								d='M4.1 21.6246C3.61667 21.4579 3.24583 21.2329 2.9875 20.9496C2.72917 20.6663 2.525 20.2829 2.375 19.7996L19.875 2.34961C20.2917 2.46628 20.6625 2.69128 20.9875 3.02461C21.3125 3.35794 21.525 3.74128 21.625 4.17461L4.1 21.6246ZM2.375 15.2496V11.9746L11.975 2.34961H15.25L2.375 15.2496ZM2.375 7.14961V4.72461C2.375 4.07461 2.60417 3.51628 3.0625 3.04961C3.52083 2.58294 4.075 2.34961 4.725 2.34961H7.15L2.375 7.14961ZM16.85 21.6246L21.65 16.8496V19.2746C21.65 19.9246 21.4167 20.4788 20.95 20.9371C20.4833 21.3954 19.925 21.6246 19.275 21.6246H16.85ZM8.75 21.6246L21.65 8.74961V12.0246L12.025 21.6246H8.75Z'
								fill='#999999'
							/>
						</svg>
						<span
							className={s.placeholder}
							style={{ margin: '8px', color: '#999' }}
						>
							{t(placeholder)}
						</span>
					</div>
				) : (
					<span
						className={s.placeholder}
						style={{ margin: '8px', color: '#999' }}
					>
						{t(placeholder)}
					</span>
				);
		}

		return value;
	};

	getDefaultValue = () => {
		const { checked, defaultValue, onDefaultClick, t } = this.props;
		const s = this.props.newDesign ? sLatest : sOld;
		if (defaultValue) {
			const isNotSet = !Object.values(checked).length;

			return (
				<AuxWrap>
					<li
						className={[s.default, isNotSet ? s.active : ''].join(' ')}
						onClick={onDefaultClick}
					>
						{t(defaultValue)}
					</li>
					<li className={s.separator}></li>
				</AuxWrap>
			);
		}
	};

	getItemstList = () => {
		const { options, checked, path, onChange, t } = this.props;
		const s = this.props.newDesign ? sLatest : sOld;
		if (options && options.length === 0) return null;

		return (
			options &&
			options.map((el) => {
				const isActive = checked[el.id] && ['active', s.active].join(' ');
				return (
					<li
						key={el.id || el}
						className={isActive}
						onClick={() => {
							onChange(el);
							this.setState({ isOpen: false });
						}}
					>
						{path ? el[path] : t(el)}
					</li>
				);
			})
		);
	};

	handleClickOutside = () => {
		this.setState({ isOpen: false });
	};

	render() {
		const {
			theme,
			className,
			classes: { wrapper, open, selectPane, selectList, list },
			disabled,
			onBlur,
		} = this.props;
		const s = this.props.newDesign ? sLatest : sOld;
		const classes = [s.wrapper, s[theme], className, wrapper];

		if (this.state.isOpen) {
			classes.push(s.open, open);
		}

		return (
			<div
				tabIndex='0'
				className={classes.join(' ')}
				data-disabled={disabled}
				onBlur={onBlur}
			>
				<div
					className={[s.selectPane, selectPane].join(' ')}
					data-disabled={disabled}
					onClick={() =>
						this.setState((prevState) => ({ isOpen: !prevState.isOpen }))
					}
				>
					<div>{this.getCurrentValues()}</div>
					{this.props.newDesign && (
						<div>
							{' '}
							<span className={s.borderRight}></span>
							{!this.state.isOpen ? (
								<span className={s.svgStyle}>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
										onClick={() =>
											this.setState(() => ({
												isOpen: false,
											}))
										}
									>
										<path d='M7 10L12 15L17 10H7Z' fill='#999999' />
									</svg>
								</span>
							) : (
								<span className={s.svgStyle}>
									<svg
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 24 24'
										fill='none'
										onClick={() =>
											this.setState(() => ({
												isOpen: true,
											}))
										}
									>
										<path d='M17 14L12 9L7 14L17 14Z' fill='#999999' />
									</svg>
								</span>
							)}
						</div>
					)}
				</div>

				{this.state.isOpen && !disabled && (
					<div className={[s.selectList, selectList].join(' ')}>
						<ul className={[s.list, list].join(' ')}>
							{this.getDefaultValue()}
							{this.getItemstList()}
						</ul>
					</div>
				)}
			</div>
		);
	}
}

Select.defaultProps = {
	classes: {
		wrapper: '',
		open: '',
		selectPane: '',
		selectList: '',
		list: '',
	},
	options: [],
	checked: {},
	type: 'select',
};

Select.propTypes = {
	classes: PropTypes.object,
	options: PropTypes.array,
	checked: PropTypes.object,
	type: PropTypes.string,
};

export default withTranslation()(onClickOutside(Select));
