import React from 'react';

import s from './style.module.scss';

const Spinner = ({ className, small, smaller, theme }) => {
	const classes = [s.spinner, className];

	if (small) classes.push(s.small);
	if (smaller) classes.push(s.smaller);
	if (theme) classes.push(s[theme]);

	return <div className={classes.join(' ')}></div>;
};

export default Spinner;
