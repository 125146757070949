import s from './style.module.scss';
import i18next from 'i18next';

const initialStateCreator = (props, state = {}) => {
	try {
		const { controls, newProviderData } = state;
		let providerDefault = { 1: { id: 1, name: 'Inogen' } };

		if (controls && controls.provider.checked) {
			providerDefault = controls.provider.checked;
		}

		if (newProviderData) {
			providerDefault = { [newProviderData.id]: newProviderData };
		}

		return {
			controls: {
				provider: {
					checked: providerDefault,
				},
				role: {},
				newProvider: {
					name: {
						elementType: 'input',
						elementConfig: {
							type: 'text',
							placeholder: 'name',
						},
						className: s.input,
						value: '',
						validation: {
							required: true,
							isEmail: true,
						},
						valid: false,
						errorMsg: i18next.t('auth_invalid_email_name'),
						touched: false,
					},
					pin: {
						elementType: 'mask',
						elementConfig: {
							type: 'text',
							placeholder: '__',
							guide: true,
						},
						className: s.pinInput,
						value: '',
						validation: {
							required: true,
							isEmail: true,
						},
						valid: false,
						errorMsg: i18next.t('auth_invalid_email_name'),
						touched: false,
					},
				},
			},
			newProviderData: null,
			providerView: null,
			codePartTwo: null,
			step: 2,
			userbuttonEnabled: true,
		};
	} catch (e) {
		console.error(e);
	}
};

export default initialStateCreator;
