import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import { withTranslation } from 'react-i18next';
import Select from './Select';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import sOld from './style.module.scss';
import sLatest from './newStyle.module.scss';

const Input = (props) => {
	const { t } = props;
	const s = props.newDesign ? sLatest : sOld;
	let input = null;
	let inputElement = null;
	let labelElement = null;
	let inputError = null;
	const inputClasses = [s.inputElement];
	const wrapperClasses = [
		props.newCheckbox ? s.inputCheckBox : s.input,
		s[props.theme],
		props.className,
	];

	if (props.invalid && props.touched) {
		inputClasses.push(s.inputError);
		inputError = <span className={s.error}>{props.invalidMsg}</span>;
	}

	if (props.checked) {
		inputClasses.push(s.checked);
	}

	switch (props.elementType) {
		case 'mask':
			inputElement = (
				<MaskedInput
					mask={props.mask || [/[a-z,A-Z,0-9]/, /[a-z,A-Z,0-9]/]}
					className={inputClasses.join(' ')}
					value={props.value}
					onChange={props.changed}
					onBlur={props.onBlur}
					{...props.elementConfig}
					placeholder={t(props.elementConfig.placeholder)}
				/>
			);
			break;
		case 'phone':
			inputElement = (
				<PhoneInput
					value={props.value}
					onChange={props.changed}
					onBlur={props.onBlur}
					name={props.name}
					{...props.elementConfig}
					placeholder={t(props.elementConfig.placeholder)}
					inputStyle={{ width: '280px' }}
				/>
			);
			break;
		case 'input':
			inputElement = (
				<input
					className={inputClasses.join(' ')}
					value={props.value}
					onChange={props.changed}
					onBlur={props.onBlur}
					name={props.name}
					maxLength={props.maxlength || null}
					{...props.elementConfig}
					placeholder={t(props.elementConfig.placeholder)}
					disabled={props.disabled}
				/>
			);
			break;
		case 'textarea':
			inputElement = (
				<textarea
					className={inputClasses.join(' ')}
					value={props.value}
					rows={props.rows}
					onChange={props.changed}
					onBlur={props.onBlur}
					maxLength={props.maxlength || null}
					{...props.elementConfig}
					placeholder={t(props.elementConfig.placeholder)}
				/>
			);
			break;
		case 'select':
			inputClasses.push(s.select);
			inputElement = (
				<Select
					options={props.options}
					checked={props.checked}
					className={inputClasses.join(' ')}
					view={props.view}
					newDesign={props.newDesign}
					disabled={props.disabled}
					onChange={props.changed}
					showIcon={props.newIcon}
					onBlur={props.onBlur}
					onDefaultClick={props.onDefaultClick}
					{...props.elementConfig}
				/>
			);
			break;
		default:
			inputElement = (
				<input
					className={inputClasses.join(' ')}
					value={props.value}
					onChange={props.changed}
					onBlur={props.onBlur}
					placeholder={t(props.elementConfig.placeholder)}
					{...props.elementConfig}
				/>
			);
	}

	if (props.label)
		labelElement = <label className={s.label}>{props.label}</label>;
	if (props.icon)
		inputElement = (
			<div className={s.iconWrap}>
				{inputElement}
				{props.icon}
			</div>
		);

	input = (
		<span className={wrapperClasses.join(' ')}>
			{labelElement}
			{inputElement}
			{inputError}
		</span>
	);

	if (
		props.elementConfig.type === 'checkbox' ||
		props.elementConfig.type === 'radio'
	) {
		input = (
			<label className={wrapperClasses.join(' ')}>
				{inputElement}
				<i></i>
				<span>{props.label}</span>
			</label>
		);
	}

	return input;
};

Input.defaultProps = {
	elementConfig: {
		type: 'text',
	},
	mask: null,
	theme: 'default',
	onDefaultClick: () => {},
	onItemClick: () => {},
};

Input.propTypes = {
	elementConfig: PropTypes.object,
	mask: PropTypes.array,
	theme: PropTypes.string,
};

export default withTranslation()(Input);
