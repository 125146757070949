import { coreInstance as axios, api } from 'settings';
import { logger } from 'helpers';
import * as actionTypes from '../../actions/actionTypes';

const getRecentErrorStart = (actionType) => {
	return {
		type: actionTypes[actionType],
	};
};

const getRecentErrorSuccess = (data, period, actionType) => {
	return {
		type: actionTypes[actionType],
		data: data,
		period: period,
	};
};

export const getRecentError = (args = {}) => {
	const params = {
		with: 'concentratorError',
		orderBy: 'amount',
		sortedBy: 'desc',
		...args.params,
	};

	const actions = args.actionTypes || {
		start: actionTypes.GET_RECENT_ERROR_START,
		success: actionTypes.GET_RECENT_ERROR_SUCCESS,
	};

	return (dispatch) => {
		dispatch(getRecentErrorStart(actions.start));

		axios
			.get(api.concentrator_error_statistics.index, { params })
			.then((response) => {
				logger('[Action] Get recent error');

				dispatch(
					getRecentErrorSuccess(
						response.data.data,
						response.data.period,
						actions.success,
					),
				);
			})
			.catch(() => {
				logger('[Action] Get recent error fail');
			});
	};
};
