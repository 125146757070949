import { combineReducers } from 'redux';
import usersPending from './usersPending';
import searchUsers from './searchUsers';
import searchProviders from './searchProviders';
import provider from './provider';

const reducer = combineReducers({
	usersPending,
	searchUsers,
	searchProviders,
	provider,
});

export default reducer;
