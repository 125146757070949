import React from 'react';
import T from 'prop-types';

const Centrify = (props) => (
	<svg {...props} className={props.className}>
		<path
			fill='#AE0000'
			fillRule='nonzero'
			d='M34.233 44.903a2.344 2.344 0 0 1-2.915-1.598 2.367 2.367 0 0 1 1.586-2.937c4.944-1.47 8.404-6.063 8.404-11.337 0-6.528-5.253-11.82-11.732-11.82-2.027 0-3.974.517-5.703 1.488a2.337 2.337 0 0 1-3.192-.913 2.373 2.373 0 0 1 .907-3.216 16.263 16.263 0 0 1 7.988-2.086C38.646 12.484 46 19.892 46 29.03c0 7.383-4.842 13.812-11.767 15.872zM.287 23.49a2.344 2.344 0 0 1 2.81-1.775 2.367 2.367 0 0 1 1.8 2.81c-1.111 5.037 1.229 10.29 5.842 12.847 5.709 3.165 12.883 1.136 16.024-4.53a11.598 11.598 0 0 0 1.464-5.71 2.337 2.337 0 0 1 2.346-2.349 2.373 2.373 0 0 1 2.374 2.352 16.263 16.263 0 0 1-2.049 7.999c-4.397 7.933-14.441 10.773-22.434 6.342C2.006 37.897-1.27 30.546.287 23.491zM35.594 4.359a2.344 2.344 0 0 1 .131 3.321 2.367 2.367 0 0 1-3.334.153c-3.806-3.48-9.525-4.08-14.046-1.364-5.595 3.362-7.426 10.59-4.089 16.143a11.598 11.598 0 0 0 4.213 4.123 2.337 2.337 0 0 1 .86 3.206 2.373 2.373 0 0 1-3.222.88 16.263 16.263 0 0 1-5.903-5.774c-4.672-7.775-2.109-17.893 5.724-22.6 6.33-3.803 14.334-2.963 19.666 1.912z'
		/>
	</svg>
);

Centrify.defaultProps = {
	width: 23,
	height: 23,
	viewBox: '0 0 46 46',
};

Centrify.propTypes = {
	className: T.string,
};

export default Centrify;
