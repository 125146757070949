import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import cs from 'classnames';
import ReactGA from 'react-ga';
import Cookies from 'universal-cookie';
import { Back, Centrify, View } from 'components/Icons';
import { Button, Input, Modal, ValidateError } from 'components';
import { api, axios, routers } from 'settings';
import { checkValidity } from 'helpers';
import { buttons, uniqueClass } from 'config';
import passwordValidator from 'password-validator';
import * as actions from 'store/actions';

import { loginLogo } from 'img';

import { constants } from '../../helpers';
import s from './style.module.scss';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import LanguagePopover from '../../../src/components/LanguagePopover/index.js';

const cookies = new Cookies();
const schemaMin8 = new passwordValidator();
const schemaUppercase = new passwordValidator();
const schemaLowercase = new passwordValidator();
const schemaDigits = new passwordValidator();
schemaMin8.is().min(8);
schemaUppercase.has().uppercase();
schemaLowercase.has().lowercase();
schemaDigits.has().digits(1);

class Auth extends Component {
	constructor(props) {
		super(props);

		let type = 'credentials';

		if (props.location.pathname === '/password/reset') {
			type = 'reset';
		}

		this.state = {
			viewType: type,
			isModalOpen: false,
			passwordGuidelines: {
				min: false,
				uppercase: false,
				lowercase: false,
				digits: false,
			},
			loginType: null,
			controls: {
				login: {
					email: {
						elementType: 'input',
						elementConfig: {
							type: 'email',
							placeholder: i18next.t('auth_email_address'),
						},
						theme: 'login',
						className: s.input,
						value: '',
						validation: {
							required: true,
							isEmail: true,
						},
						valid: false,
						errorMsg: i18next.t(i18next.t('auth_invalid_email_name')),
						touched: false,
						order: 1,
						isDisabled: false,
					},
					password: {
						elementType: 'input',
						elementConfig: {
							type: 'password',
							placeholder: i18next.t('auth_password'),
						},
						theme: 'login',
						className: s.input,
						value: '',
						validation: {
							required: true,
							minLength: 6,
						},
						valid: false,
						errorMsg: i18next.t('auth_password_invalid'),
						touched: false,
						icon: <View onClick={this.showHidePassword} />,
						order: 2,
						isVisible: false,
					},
					remember: {
						elementType: 'input',
						elementConfig: {
							type: 'checkbox',
						},
						theme: 'checkbox',
						className: s.checkbox,
						value: false,
						valid: true,
						label: i18next.t('auth_remember_me'),
						order: 3,
						isVisible: false,
					},
				},
				reset: {
					email: {
						elementType: 'input',
						elementConfig: {
							type: 'email',
							placeholder: 'providername@gmail.com',
						},
						theme: 'login',
						className: s.input,
						value: '',
						validation: {
							required: true,
							isEmail: true,
						},
						valid: false,
						errorMsg: i18next.t(i18next.t('auth_invalid_email_name')),
						touched: false,
					},
				},
				newPassword: {
					password: {
						name: 'password',
						elementType: 'input',
						elementConfig: {
							type: 'password',
							placeholder: i18next.t('auth_password'),
						},
						theme: 'login',
						className: s.input,
						value: '',
						validation: {
							required: true,
							minLength: 6,
						},
						valid: false,
						errorMsg: '',
						touched: false,
					},
					passwordRepeat: {
						name: 'passwordRepeat',
						elementType: 'input',
						elementConfig: {
							type: 'password',
							placeholder: i18next.t('auth_repeat_password'),
						},
						theme: 'login',
						className: s.input,
						value: '',
						validation: {
							required: true,
							minLength: 6,
						},
						valid: false,
						errorMsg: '',
						touched: false,
					},
				},
			},
		};
	}

	inputChangedHandler = (event, controlName, type) => {
		if (!type) return;

		const target = event.target;
		const name = event.target.name;
		const value = target.type === 'checkbox' ? target.checked : target.value;

		this.setState((prevState) => {
			const state = { ...prevState.controls };
			const controls = state[type][controlName];
			let formIsValid = true;
			let passwordGuidelines = { ...prevState.passwordGuidelines };
			controls.value = value;
			controls.valid = checkValidity(value, controls.validation);

			if (name === 'password') {
				passwordGuidelines.min = schemaMin8.validate(value);
				passwordGuidelines.uppercase = schemaUppercase.validate(value);
				passwordGuidelines.lowercase = schemaLowercase.validate(value);
				passwordGuidelines.digits = schemaDigits.validate(value);
			}

			for (let control in state[type]) {
				formIsValid = state[type][control].valid && formIsValid;
			}

			return {
				passwordGuidelines,
				controls: state,
				isEmailFieldValid: state.login.email.valid,
				[type + 'FormIsValid']: formIsValid,
				errors: null,
			};
		});
	};

	inputFocusOutHandler = (event, controlName, type) => {
		if (!type) return;

		this.setState((prevState) => {
			const state = { ...prevState.controls };
			const controls = state[type][controlName];

			controls.touched = true;

			return { controls: state };
		});
	};

	loginSubmitHandler = (event) => {
		event.preventDefault();
		if (
			this.state.loginType !== null &&
			this.state.loginType === 'non-inogen'
		) {
			const login = this.state.controls.login;
			const options = {
				email: login.email.value,
				password: login.password.value,
				remember: login.remember.value,
				error: (data) => this.setState({ errors: data }),
			};

			this.props.onAuth(options);
			this.props.history.replace(routers.index);
		}
	};

	resetSubmitHandler = (event) => {
		event.preventDefault();

		const { email } = this.state.controls.reset;
		const args = {
			email: email.value,
			success: () => this.setState({ isModalOpen: false, errors: null }),
			error: (data) => this.setState({ errors: data }),
		};

		this.props.resetPassword(args);
	};

	newPasswordHandler = async (event) => {
		event.preventDefault();

		const { onAuth, location } = this.props;
		const {
			password: { value: firstValue },
			passwordRepeat: { value: secondValue },
		} = this.state.controls.newPassword;

		if (firstValue === secondValue) {
			//const urlParams = new URLSearchParams(location.search);
			const urlParams = JSON.parse(
				'{"' +
					decodeURI(location.search.substring(1))
						.replace(/"/g, '\\"')
						.replace(/&/g, '","')
						.replace(/=/g, '":"') +
					'"}',
			);
			const args = {
				token: urlParams['token'],
				password: firstValue,
				email: unescape(urlParams['email']),
				success: onAuth,
				error: (data) => this.setState({ errors: data }),
			};

			await this.props.setNewPassword(args);
			this.props.history.replace(routers.index);
		} else {
			this.setState({
				errors: { message: i18next.t('auth_passwords_do_not_match') },
			});
		}
	};

	showHidePassword = () => {
		const passConfig = this.state.controls.login.password.elementConfig;
		const type = passConfig.type === 'password' ? 'text' : 'password';

		this.setState((prevState) => {
			const iconProps = { type: 'show' };

			prevState.controls.login.password.icon.props.type === 'show' &&
				(iconProps.type = 'hide');

			return {
				...prevState,
				controls: {
					...prevState.controls,
					login: {
						...prevState.controls.login,
						password: {
							...prevState.controls.login.password,
							elementConfig: {
								...prevState.controls.login.password.elementConfig,
								type: type,
							},
							icon: <View {...iconProps} onClick={this.showHidePassword} />,
						},
					},
				},
			};
		});
	};

	onChangeView = (type) => () => {
		if (type === 'credentials') {
			this.setState((prevState) => {
				const state = { ...prevState.controls };
				state.login.email.isDisabled = false;
				state.login.password.isVisible = false;
				state.login.remember.isVisible = false;
				return {
					controls: state,
					loginType: null,
					viewType: type,
				};
			});
		}
		if (type === 'credentials-non-inogen') {
			this.setState((prevState) => {
				const state = { ...prevState.controls };
				state.login.email.isDisabled = true;
				state.login.password.isVisible = true;
				state.login.remember.isVisible = true;
				return {
					controls: state,
					loginType: 'non-inogen',
					viewType: 'credentials',
				};
			});
		} else {
			this.setState({ viewType: type });
		}
	};

	onMicrosoftLogin = () => {
		ReactGA.event({
			category: 'Authorization',
			action: 'Click',
			label: 'Inogen Log In',
		});
		window.open(axios.defaults.baseURL + api.authentication.login, '_blank'); // SAML open in new tab
	};

	logout = () => {
		this.props.onLogout();
	};

	handleRedirection = () => {
		const { email } = this.state.controls.login;
		const emailValue = email.value;
		const domain = emailValue
			.substring(emailValue.lastIndexOf('@') + 1)
			.toLowerCase();
		if (constants.SSO_DOMAINS.includes(domain)) {
			this.onMicrosoftLogin();
		} else {
			this.setState((prevState) => {
				const state = { ...prevState.controls };
				state.login.email.isDisabled = true;
				state.login.password.isVisible = true;
				state.login.remember.isVisible = true;
				return {
					controls: state,
					loginType: 'non-inogen',
				};
			});
		}
	};

	render() {
		const {
			viewType,
			controls,
			errors,
			isModalOpen,
			isEmailFieldValid,
			loginFormIsValid,
			resetFormIsValid,
			newPasswordFormIsValid,
		} = this.state;

		const {
			user: { email, role_permissions: permissions },
			t,
		} = this.props;

		const loginElementsArray = [];
		const resetElementsArray = [];
		const newPassElementsArray = [];
		const error = cookies.get('INOGEN_ERRORS');
		let authError, centrifyPart;

		const modalCloseHandler = () =>
			this.setState((prevState) => {
				const controls = { ...prevState.controls };

				controls.reset.email.value = '';
				controls.reset.email.touched = false;

				return { controls, isModalOpen: false, errors: null };
			});

		if (error) {
			authError = <div className={s.authError}>{error}</div>;
			cookies.remove('INOGEN_ERRORS');
		}

		if (buttons && buttons.centrify) {
			centrifyPart = (
				<div>
					<Button btnType='type-2' clicked={this.onMicrosoftLogin}>
						<Centrify /> {i18next.t('inogen_log_in')}
					</Button>

					<span className={s.or}>{i18next.t('or')}</span>
				</div>
			);
		}

		let loginView = (
			<div className={s.microsoftLogin}>
				{centrifyPart}

				<Button btnType='type-3' clicked={this.onChangeView('credentials')}>
					{i18next.t('user_log_in')}
				</Button>

				{authError}
			</div>
		);

		for (let key in controls.login) {
			loginElementsArray.push({
				id: key,
				config: controls.login[key],
			});
		}

		loginElementsArray.sort((a, b) =>
			a.config.order > b.config.order
				? 1
				: b.config.order > a.config.order
				  ? -1
				  : 0,
		);

		for (let key in controls.reset) {
			resetElementsArray.push({
				id: key,
				config: controls.reset[key],
			});
		}

		for (let key in controls.newPassword) {
			newPassElementsArray.push({
				id: key,
				config: controls.newPassword[key],
			});
		}

		if (viewType === 'credentials') {
			const loginClasses = [s.credentialsLogin];

			if (errors) loginClasses.push(s.loginError);

			loginView = (
				<div className={loginClasses.join(' ')}>
					{this.state.loginType === 'non-inogen' && (
						<Button
							btnType='back'
							className={s.backBtn}
							clicked={this.onChangeView('credentials')}
						>
							<Back /> <span>{t('back')}</span>
						</Button>
					)}

					<form className={s.alignInput} onSubmit={this.loginSubmitHandler}>
						{loginElementsArray.map((el) => {
							return el?.config?.isVisible === false ? null : (
								<Input
									label={el.config.label}
									theme={el.config.theme}
									className={el.config.className}
									disabled={el.config.isDisabled}
									key={el.id}
									elementType={el.config.elementType}
									elementConfig={el.config.elementConfig}
									checked={el.config.value}
									value={el.config.value}
									invalid={!el.config.valid}
									invalidMsg={el.config.errorMsg}
									shouldValidate={el.config.validation}
									touched={el.config.touched}
									changed={(event) =>
										this.inputChangedHandler(event, el.id, 'login')
									}
									onBlur={(event) =>
										this.inputFocusOutHandler(event, el.id, 'login')
									}
									icon={el.config.icon}
								/>
							);
						})}

						{!this.state.loginType && (
							<Button
								className={s.loginBtn}
								btnType='type-1'
								disabled={!isEmailFieldValid}
								clicked={this.handleRedirection}
							>
								{i18next.t('auth_login')}
							</Button>
						)}

						<ValidateError data={errors} className={s.error} />
						{this.state.loginType === 'non-inogen' && (
							<div>
								<Button
									className={s.loginBtn}
									btnType='type-1'
									disabled={!loginFormIsValid}
								>
									{i18next.t('auth_login')}
								</Button>

								<div className={s.btnLinks}>
									<span
										className={s.btnLink}
										onClick={() =>
											this.setState({ isModalOpen: true, errors: null })
										}
									>
										{i18next.t('forgot_password')}
									</span>
									<span
										className={s.btnLink}
										onClick={this.onChangeView('contactAdmin')}
									>
										{i18next.t('auth_i_dont_have_an_account')}
									</span>
								</div>
							</div>
						)}
					</form>
				</div>
			);
		}

		if (viewType === 'reset') {
			const resetClasses = [s.credentialsLogin];
			const guidelinesPwdClasses = [s.guidelinesPwd];

			loginView = (
				<div className={resetClasses.join(' ')}>
					<form onSubmit={this.newPasswordHandler}>
						<h2 className={s.title}>{i18next.t('auth_set_new_password')}</h2>
						{newPassElementsArray.map((el) => (
							<Input
								label={el.config.label}
								name={el.config.name}
								theme={el.config.theme}
								className={el.config.className}
								key={el.id}
								elementType={el.config.elementType}
								elementConfig={el.config.elementConfig}
								checked={el.config.value}
								value={el.config.value}
								invalid={!el.config.valid}
								invalidMsg={el.config.errorMsg}
								shouldValidate={el.config.validation}
								touched={el.config.touched}
								changed={(event) =>
									this.inputChangedHandler(event, el.id, 'newPassword')
								}
								onBlur={(event) =>
									this.inputFocusOutHandler(event, el.id, 'newPassword')
								}
								icon={el.config.icon}
							/>
						))}

						<ValidateError data={errors} className={s.error} />

						<Button
							className={s.loginBtn}
							btnType='type-1'
							disabled={
								!newPasswordFormIsValid ||
								!(
									this.state.passwordGuidelines.min === true &&
									this.state.passwordGuidelines.lowercase === true &&
									this.state.passwordGuidelines.uppercase === true &&
									this.state.passwordGuidelines.digits === true
								)
							}
						>
							{t('save')}
						</Button>
					</form>
					<div className={guidelinesPwdClasses.join(' ')}>
						<h4
							dangerouslySetInnerHTML={{
								__html: t('passwordGuidelines_title'),
							}}
						/>
						<ul>
							<li
								className={cs({
									[s.correct]: this.state.passwordGuidelines.lowercase,
								})}
								dangerouslySetInnerHTML={{
									__html: t('passwordGuidelines_lowercase'),
								}}
							/>
							<li
								className={cs({
									[s.correct]: this.state.passwordGuidelines.uppercase,
								})}
								dangerouslySetInnerHTML={{
									__html: t('passwordGuidelines_uppercase'),
								}}
							/>
							<li
								className={cs({
									[s.correct]: this.state.passwordGuidelines.digits,
								})}
								dangerouslySetInnerHTML={{
									__html: t('passwordGuidelines_digits'),
								}}
							/>
							<li
								className={cs({
									[s.correct]: this.state.passwordGuidelines.min,
								})}
								dangerouslySetInnerHTML={{
									__html: t('passwordGuidelines_min'),
								}}
							/>
						</ul>
					</div>
				</div>
			);
		}

		if (viewType === 'contactAdmin') {
			loginView = (
				<div className={s.contactAdmin}>
					<Button
						btnType='back'
						className={s.backBtn}
						clicked={this.onChangeView('credentials-non-inogen')}
					>
						<Back /> <span>{t('back')}</span>
					</Button>
					<p>{i18next.t('auth_contact_administrator')}</p>
				</div>
			);
		}

		if (permissions && !permissions.length) {
			loginView = (
				<div className={s.noPermissions}>
					<Button btnType='back' className={s.backBtn} clicked={this.logout}>
						<Back /> <span>{t('auth_logout')}</span>
					</Button>
					<h3 className={s.title}>{i18next.t('auth_welcome')}!</h3>
					<p>{email}</p>
					<p>{i18next.t('auth_please_wait_permission')}</p>
				</div>
			);
		}

		return (
			<div className={s.login}>
				<div className={s.languageSelector}>
					<LanguagePopover placement='bottom' rootClose={true} />
				</div>
				<div className={s.loginInner}>
					<img
						src={loginLogo}
						alt='Inogen Connect Portal'
						className={[s.loginLogo, s[uniqueClass]].join(' ')}
					/>
					{loginView}
				</div>

				<Modal
					className={s.modal}
					show={isModalOpen}
					modalClosed={modalCloseHandler}
				>
					<form
						onSubmit={this.resetSubmitHandler}
						className={[s.resetPassword, s.alignInput].join(' ')}
					>
						<h2 className={s.title}>{i18next.t('forgot_password')}</h2>
						<p>{t('auth_link_to_reset_will_be_sent')}</p>

						{resetElementsArray.map((el) => (
							<Input
								theme={el.config.theme}
								className={el.config.className}
								key={el.id}
								elementType={el.config.elementType}
								elementConfig={el.config.elementConfig}
								value={el.config.value}
								invalid={!el.config.valid}
								invalidMsg={el.config.errorMsg}
								shouldValidate={el.config.validation}
								touched={el.config.touched}
								changed={(event) =>
									this.inputChangedHandler(event, el.id, 'reset')
								}
								onBlur={(event) =>
									this.inputFocusOutHandler(event, el.id, 'reset')
								}
							/>
						))}

						<ValidateError data={errors} className={s.error} />

						<Button
							className={s.loginBtn}
							btnType='type-1'
							disabled={!resetFormIsValid}
						>
							{t('Send')}
						</Button>
					</form>
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = (state) => {
	return {
		authError: state.auth.error,
		user: state.user.data,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		onAuth: (options) => dispatch(actions.auth(options)),
		onLogout: () => dispatch(actions.logout()),
		resetPassword: (args) => dispatch(actions.resetPassword(args)),
		setNewPassword: (args) => dispatch(actions.setNewPassword(args)),
	};
};

export default withRouter(
	connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Auth)),
);
