import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
	resolveDashboardErrors: {},
	loading: false,
};

export const postResolveDashboardErrosStart = (state) => {
	return updateObject(state, { loading: true });
};

export const postResolveDashboardErrosSuccess = (state, action) => {
	return updateObject(state, {
		resolveDashboardErrors: action.data,
		loading: false,
	});
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.POST_UNRESOLVED_DASHBOARD_ERRORS_START:
			return postResolveDashboardErrosStart(state, action);
		case actionTypes.POST_UNRESOLVED_DASHBOARD_ERRORS:
			return postResolveDashboardErrosSuccess(state, action);
		case actionTypes.POST_UNRESOLVED_DASHBOARD_ERRORS_SUCCESS:
			return postResolveDashboardErrosSuccess(state, action);

		default:
			return state;
	}
};

export default reducer;
