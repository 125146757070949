import React from 'react';
import T from 'prop-types';

const Update = (props) => {
	if (props.newLogo === true) {
		return (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='24'
				height='24'
				viewBox='0 0 24 24'
				fill='none'
				style={{ marginRight: '8px' }}
			>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M9.38495 4.30507C8.764 4.30507 8.26062 4.80845 8.26062 5.4294V6.62125C8.26062 7.2422 8.764 7.74558 9.38495 7.74558H11.5305C12.1514 7.74558 12.6548 7.2422 12.6548 6.62125V5.4294C12.6548 4.80845 12.1514 4.30507 11.5305 4.30507H9.38495ZM9.18618 5.4294C9.18618 5.31962 9.27517 5.23063 9.38495 5.23063H11.5305C11.6403 5.23063 11.7293 5.31962 11.7293 5.4294V6.62125C11.7293 6.73103 11.6403 6.82002 11.5305 6.82002H9.38495C9.27517 6.82002 9.18618 6.73103 9.18618 6.62125V5.4294Z'
					fill='#999999'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M8.72433 0.15892C8.73089 0.073185 8.66309 0 8.57711 0H6.68283C6.56676 0 6.47266 0.0940933 6.47266 0.210163V15.2694C6.47266 15.3855 6.56676 15.4795 6.68283 15.4795H14.2326C14.3487 15.4795 14.4428 15.3855 14.4428 15.2694V0.210162C14.4428 0.0940928 14.3487 0 14.2326 0H12.3383C12.2524 0 12.1846 0.0731837 12.1911 0.158918C12.1931 0.185015 12.1941 0.211396 12.1941 0.238026V0.51899C12.1941 0.635059 12.1 0.729152 11.984 0.729152H8.93147C8.8154 0.729152 8.72131 0.635059 8.72131 0.51899V0.238026C8.72131 0.211395 8.72233 0.185015 8.72433 0.15892ZM7.46774 10.9175C7.42274 10.8793 7.39822 10.8227 7.39822 10.7637V1.12432C7.39822 1.01455 7.48721 0.925556 7.59699 0.925556C7.70676 0.925556 7.79575 1.01455 7.79575 1.12432V1.14493C7.79575 2.22011 8.66736 3.09171 9.74254 3.09171H11.1729C12.2481 3.09171 13.1197 2.22011 13.1197 1.14493V1.12432C13.1197 1.01455 13.2087 0.925556 13.3185 0.925556C13.4282 0.925556 13.5172 1.01455 13.5172 1.12432V10.7637C13.5172 10.8227 13.4927 10.8793 13.4477 10.9175C13.0073 11.2915 12.4371 11.517 11.8141 11.517H9.10138C8.47839 11.517 7.90817 11.2915 7.46774 10.9175ZM9.74254 2.16616C9.49493 2.16616 9.26785 2.07803 9.09102 1.93138C8.9665 1.8281 9.05964 1.65471 9.22141 1.65471H11.694C11.8558 1.65471 11.949 1.8281 11.8244 1.93138C11.6476 2.07803 11.4205 2.16616 11.1729 2.16616H9.74254ZM7.60838 14.554C7.49231 14.554 7.39822 14.4599 7.39822 14.3438V12.3549C7.39822 12.1937 7.57334 12.0909 7.72103 12.1554C8.14378 12.3401 8.61066 12.4425 9.10138 12.4425H11.8141C12.3048 12.4425 12.7717 12.3401 13.1944 12.1554C13.3421 12.0909 13.5172 12.1937 13.5172 12.3549V14.3438C13.5172 14.4599 13.4231 14.554 13.3071 14.554H7.60838Z'
					fill='#999999'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M4.43073 4.79609C4.54685 4.79613 4.64099 4.70198 4.64096 4.58586L4.63971 0.555204C4.63967 0.439186 4.54563 0.345143 4.42961 0.345107L0.398955 0.343861C0.282835 0.343825 0.188691 0.437968 0.188727 0.554089L0.188908 1.13472C0.188944 1.25074 0.282987 1.34478 0.399005 1.34481L2.42885 1.34544C2.61606 1.3455 2.70977 1.57184 2.57739 1.70421L2.06324 2.21837C0.682821 3.59879 -0.00622023 5.26998 4.23049e-05 7.22238C0.00630536 9.17485 0.707703 10.8514 2.09944 12.2432C2.43996 12.5837 2.81451 12.8845 3.22288 13.1455C3.63037 13.4059 4.05522 13.6213 4.49733 13.7914C4.60789 13.834 4.72672 13.7524 4.72669 13.6339L4.72647 12.9373C4.72645 12.8703 4.68678 12.8096 4.6254 12.7827C4.28145 12.6322 3.95354 12.4493 3.64158 12.2342C3.32856 12.0182 3.04897 11.7869 2.80236 11.5402C1.59434 10.3322 0.990307 8.89545 0.984407 7.22145C0.978506 5.54738 1.57087 4.11715 2.76645 2.92158L3.2806 2.40742C3.41298 2.27505 3.63932 2.36876 3.63937 2.55596L3.64 4.58581C3.64004 4.70183 3.73408 4.79587 3.8501 4.79591L4.43073 4.79609Z'
					fill='#999999'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M16.2493 11.0471C16.1332 11.0471 16.039 11.1413 16.039 11.2574L16.0403 15.288C16.0403 15.4041 16.1344 15.4981 16.2504 15.4981L20.281 15.4994C20.3972 15.4994 20.4913 15.4053 20.4913 15.2892L20.4911 14.7085C20.4911 14.5925 20.397 14.4985 20.281 14.4984L18.2511 14.4978C18.0639 14.4977 17.9702 14.2714 18.1026 14.139L18.6168 13.6249C19.9972 12.2445 20.6862 10.5733 20.68 8.62086C20.6737 6.66839 19.9723 4.99181 18.5806 3.60008C18.24 3.25955 17.8655 2.95876 17.4571 2.69778C17.0496 2.43736 16.6248 2.22197 16.1827 2.05181C16.0721 2.00926 15.9533 2.09088 15.9533 2.20934L15.9535 2.90598C15.9535 2.97299 15.9932 3.03364 16.0546 3.06051C16.3986 3.21107 16.7265 3.39389 17.0384 3.60909C17.3514 3.82502 17.631 4.05639 17.8776 4.303C19.0857 5.51103 19.6897 6.94779 19.6956 8.62179C19.7015 10.2959 19.1091 11.7261 17.9136 12.9217L17.3994 13.4358C17.267 13.5682 17.0407 13.4745 17.0406 13.2873L17.04 11.2574C17.04 11.1414 16.9459 11.0474 16.8299 11.0473L16.2493 11.0471Z'
					fill='#999999'
				/>
			</svg>
		);
	} else {
		return (
			<svg
				xmlns='http://www.w3.org/2000/svg'
				width='24'
				height='24'
				viewBox='0 0 24 24'
				fill='none'
				style={{ marginRight: '8px' }}
			>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M9.38495 4.30507C8.764 4.30507 8.26062 4.80845 8.26062 5.4294V6.62125C8.26062 7.2422 8.764 7.74558 9.38495 7.74558H11.5305C12.1514 7.74558 12.6548 7.2422 12.6548 6.62125V5.4294C12.6548 4.80845 12.1514 4.30507 11.5305 4.30507H9.38495ZM9.18618 5.4294C9.18618 5.31962 9.27517 5.23063 9.38495 5.23063H11.5305C11.6403 5.23063 11.7293 5.31962 11.7293 5.4294V6.62125C11.7293 6.73103 11.6403 6.82002 11.5305 6.82002H9.38495C9.27517 6.82002 9.18618 6.73103 9.18618 6.62125V5.4294Z'
					fill='#999999'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M8.72433 0.15892C8.73089 0.073185 8.66309 0 8.57711 0H6.68283C6.56676 0 6.47266 0.0940933 6.47266 0.210163V15.2694C6.47266 15.3855 6.56676 15.4795 6.68283 15.4795H14.2326C14.3487 15.4795 14.4428 15.3855 14.4428 15.2694V0.210162C14.4428 0.0940928 14.3487 0 14.2326 0H12.3383C12.2524 0 12.1846 0.0731837 12.1911 0.158918C12.1931 0.185015 12.1941 0.211396 12.1941 0.238026V0.51899C12.1941 0.635059 12.1 0.729152 11.984 0.729152H8.93147C8.8154 0.729152 8.72131 0.635059 8.72131 0.51899V0.238026C8.72131 0.211395 8.72233 0.185015 8.72433 0.15892ZM7.46774 10.9175C7.42274 10.8793 7.39822 10.8227 7.39822 10.7637V1.12432C7.39822 1.01455 7.48721 0.925556 7.59699 0.925556C7.70676 0.925556 7.79575 1.01455 7.79575 1.12432V1.14493C7.79575 2.22011 8.66736 3.09171 9.74254 3.09171H11.1729C12.2481 3.09171 13.1197 2.22011 13.1197 1.14493V1.12432C13.1197 1.01455 13.2087 0.925556 13.3185 0.925556C13.4282 0.925556 13.5172 1.01455 13.5172 1.12432V10.7637C13.5172 10.8227 13.4927 10.8793 13.4477 10.9175C13.0073 11.2915 12.4371 11.517 11.8141 11.517H9.10138C8.47839 11.517 7.90817 11.2915 7.46774 10.9175ZM9.74254 2.16616C9.49493 2.16616 9.26785 2.07803 9.09102 1.93138C8.9665 1.8281 9.05964 1.65471 9.22141 1.65471H11.694C11.8558 1.65471 11.949 1.8281 11.8244 1.93138C11.6476 2.07803 11.4205 2.16616 11.1729 2.16616H9.74254ZM7.60838 14.554C7.49231 14.554 7.39822 14.4599 7.39822 14.3438V12.3549C7.39822 12.1937 7.57334 12.0909 7.72103 12.1554C8.14378 12.3401 8.61066 12.4425 9.10138 12.4425H11.8141C12.3048 12.4425 12.7717 12.3401 13.1944 12.1554C13.3421 12.0909 13.5172 12.1937 13.5172 12.3549V14.3438C13.5172 14.4599 13.4231 14.554 13.3071 14.554H7.60838Z'
					fill='#999999'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M4.43073 4.79609C4.54685 4.79613 4.64099 4.70198 4.64096 4.58586L4.63971 0.555204C4.63967 0.439186 4.54563 0.345143 4.42961 0.345107L0.398955 0.343861C0.282835 0.343825 0.188691 0.437968 0.188727 0.554089L0.188908 1.13472C0.188944 1.25074 0.282987 1.34478 0.399005 1.34481L2.42885 1.34544C2.61606 1.3455 2.70977 1.57184 2.57739 1.70421L2.06324 2.21837C0.682821 3.59879 -0.00622023 5.26998 4.23049e-05 7.22238C0.00630536 9.17485 0.707703 10.8514 2.09944 12.2432C2.43996 12.5837 2.81451 12.8845 3.22288 13.1455C3.63037 13.4059 4.05522 13.6213 4.49733 13.7914C4.60789 13.834 4.72672 13.7524 4.72669 13.6339L4.72647 12.9373C4.72645 12.8703 4.68678 12.8096 4.6254 12.7827C4.28145 12.6322 3.95354 12.4493 3.64158 12.2342C3.32856 12.0182 3.04897 11.7869 2.80236 11.5402C1.59434 10.3322 0.990307 8.89545 0.984407 7.22145C0.978506 5.54738 1.57087 4.11715 2.76645 2.92158L3.2806 2.40742C3.41298 2.27505 3.63932 2.36876 3.63937 2.55596L3.64 4.58581C3.64004 4.70183 3.73408 4.79587 3.8501 4.79591L4.43073 4.79609Z'
					fill='#999999'
				/>
				<path
					fillRule='evenodd'
					clipRule='evenodd'
					d='M16.2493 11.0471C16.1332 11.0471 16.039 11.1413 16.039 11.2574L16.0403 15.288C16.0403 15.4041 16.1344 15.4981 16.2504 15.4981L20.281 15.4994C20.3972 15.4994 20.4913 15.4053 20.4913 15.2892L20.4911 14.7085C20.4911 14.5925 20.397 14.4985 20.281 14.4984L18.2511 14.4978C18.0639 14.4977 17.9702 14.2714 18.1026 14.139L18.6168 13.6249C19.9972 12.2445 20.6862 10.5733 20.68 8.62086C20.6737 6.66839 19.9723 4.99181 18.5806 3.60008C18.24 3.25955 17.8655 2.95876 17.4571 2.69778C17.0496 2.43736 16.6248 2.22197 16.1827 2.05181C16.0721 2.00926 15.9533 2.09088 15.9533 2.20934L15.9535 2.90598C15.9535 2.97299 15.9932 3.03364 16.0546 3.06051C16.3986 3.21107 16.7265 3.39389 17.0384 3.60909C17.3514 3.82502 17.631 4.05639 17.8776 4.303C19.0857 5.51103 19.6897 6.94779 19.6956 8.62179C19.7015 10.2959 19.1091 11.7261 17.9136 12.9217L17.3994 13.4358C17.267 13.5682 17.0407 13.4745 17.0406 13.2873L17.04 11.2574C17.04 11.1414 16.9459 11.0474 16.8299 11.0473L16.2493 11.0471Z'
					fill='#999999'
				/>
			</svg>
		);
	}
};

Update.defaultProps = {
	fill: '#6AB221',
	width: 24,
	height: 24,
	viewBox: '0 0 24 24',
	style: { marginRight: '8px' },
};

Update.propTypes = {
	className: T.string,
};

export default Update;
