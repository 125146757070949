import React from 'react';

import s from './style.module.scss';

const backdrop = (props) => {
	let element;
	const clickHandler = (e) => {
		if (e.target === element) props.clicked();
	};

	if (props.show) {
		return (
			<div
				ref={(el) => (element = el)}
				className={s.backdrop}
				onClick={clickHandler}
			>
				{props.children}
			</div>
		);
	} else {
		return null;
	}
};

export default backdrop;
