import React from 'react';
import T from 'prop-types';

const ErrorIcon = (props) => (
	<svg {...props} className={props.className}>
		{/* <g fill="none" fillRule="evenodd"> */}
		<path
			d='M2.29956 21C2.14956 21 2.01912 20.9656 1.90826 20.8969C1.79739 20.8281 1.71116 20.7375 1.64956 20.625C1.58011 20.515 1.54192 20.3958 1.53498 20.2675C1.52803 20.1392 1.56622 20.0083 1.64956 19.875L11.3496 3.125C11.4329 2.99167 11.5287 2.89583 11.6371 2.8375C11.7454 2.77917 11.8662 2.75 11.9996 2.75C12.1329 2.75 12.2537 2.77917 12.3621 2.8375C12.4704 2.89583 12.5662 2.99167 12.6496 3.125L22.3496 19.875C22.4329 20.0083 22.4711 20.1392 22.4641 20.2675C22.4572 20.3958 22.419 20.515 22.3496 20.625C22.288 20.7375 22.2017 20.8281 22.0909 20.8969C21.98 20.9656 21.8496 21 21.6996 21H2.29956ZM3.59956 19.5H20.3996L11.9996 5L3.59956 19.5ZM12.1039 18.075C12.3177 18.075 12.4954 18.0027 12.6371 17.8581C12.7787 17.7135 12.8496 17.5344 12.8496 17.3206C12.8496 17.1069 12.7773 16.9292 12.6327 16.7875C12.4881 16.6458 12.3089 16.575 12.0952 16.575C11.8814 16.575 11.7037 16.6473 11.5621 16.7919C11.4204 16.9365 11.3496 17.1156 11.3496 17.3294C11.3496 17.5431 11.4218 17.7208 11.5664 17.8625C11.711 18.0042 11.8902 18.075 12.1039 18.075ZM12.1039 15.3C12.3177 15.3 12.4954 15.2281 12.6371 15.0844C12.7787 14.9406 12.8496 14.7625 12.8496 14.55V10.45C12.8496 10.2375 12.7773 10.0594 12.6327 9.91563C12.4881 9.77187 12.3089 9.7 12.0952 9.7C11.8814 9.7 11.7037 9.77187 11.5621 9.91563C11.4204 10.0594 11.3496 10.2375 11.3496 10.45V14.55C11.3496 14.7625 11.4218 14.9406 11.5664 15.0844C11.711 15.2281 11.8902 15.3 12.1039 15.3Z'
			fill='#6D6D6D'
		/>
		{/* </g> */}
	</svg>
);

ErrorIcon.defaultProps = {
	fill: '#6C7DDF',
	width: 24,
	height: 24,
	viewBox: '0 0 24 24',
};

ErrorIcon.propTypes = {
	className: T.string,
};

export default ErrorIcon;
