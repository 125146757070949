import s from './style.module.scss';
import { permissions } from 'helpers';
import i18next from 'i18next';

let checked = false;

const setInitialChecked = (props) => {
	if (props.typesData && props.typesData.length === 1) {
		const type = props.typesData[0];
		checked = true;
		return {
			[type.id]: type,
		};
	}

	return {};
};

export default (props = {}) => {
	const isEngineer = permissions.checkRole('engineer');
	return {
		data: null,
		loading: false,
		controls: {
			title: {
				elementType: 'input',
				elementConfig: {
					type: 'text',
					placeholder: i18next.t('title'),
					disabled: isEngineer,
				},
				className: s.input,
				value: isEngineer ? i18next.t('transfer_data') : '',
				validation: {
					required: true,
					maxLength: 50,
				},
				valid: isEngineer,
				errorMsg: i18next.t('title_is_required'),
				touched: false,
			},
			type: {
				elementType: 'select',
				elementConfig: {
					path: 'label',
					placeholder: 'select_type',
					classes: { selectPane: s.typeSelectPane },
				},
				className: s.input,
				theme: 'select',
				checked: setInitialChecked(props),
				validation: {
					required: true,
				},
				valid: checked,
				errorMsg: i18next.t('title_is_required'),
				touched: false,
				disabled: isEngineer,
			},
			description: {
				elementType: 'textarea',
				elementConfig: {
					placeholder: i18next.t('notify_description'),
					disabled: isEngineer,
				},
				className: s.textareaNot,
				value: isEngineer ? i18next.t('please_perform_data_transfer') : '',
				validation: {
					maxLength: 100,
				},
				valid: true,
				errorMsg: i18next.t('title_is_required'),
				touched: false,
			},
			url_to_open: {
				className: s.linkNot,
				value: '',
				elementConfig: {
					type: 'text',
					placeholder: i18next.t('link_address'),
				},
				validation: {
					required: false,
					isUrl: true,
				},
				valid: true,
				errorMsg: i18next.t('link_unavailable'),
			},
			cities: {
				elementConfig: {
					placeholder: i18next.t('type'),
				},
				value: '',
				valid: true,
			},
			concentrators: {
				elementConfig: {
					placeholder: i18next.t('serial_number'),
				},
				value: '',
				valid: true,
			},
			timezonesValues: {
				data: {},
				valid: true,
			},
		},
		controlsGroups: {
			provider: {},
			dataValues: {},
			FirmwareData_LV: {},
			FirmwareData_OV: {},
			errors: {},
			models: {},
			firmware: {},
			state: {},
			cities: {},
			concentrators: {},
			pinCodes: {},
			pinCodesByProvider: {},
		},
		citiesSuggestions: [],
		concentratorsSuggestions: [],
		formIsValid: isEngineer,
		allDevices: false,
		isSending: false,
		matches: null,
		step: 1,
	};
};
