import React from 'react';
import T from 'prop-types';

const Refresh = (props) => (
	<svg {...props} className={props.className}>
		<path d='M19.468 12.847l-2.482-1.49a1 1 0 1 1 1.028-1.714l.717.43a7.003 7.003 0 0 0-12.36-2.234 1 1 0 1 1-1.608-1.19A9.004 9.004 0 0 1 20.74 9.846l.614-.615a1 1 0 0 1 1.415 1.415l-2.047 2.046a.993.993 0 0 1-.6.3.996.996 0 0 1-.636-.135l-.018-.01A1.003 1.003 0 0 1 19 12c0-.668-.094-1.315-.269-1.927l.737 2.774zM5.16 11.153l2.482 1.49a1 1 0 0 1-1.03 1.715l-.716-.43a7.003 7.003 0 0 0 12.36 2.233 1 1 0 1 1 1.608 1.19 9.004 9.004 0 0 1-15.977-3.197l-.614.615a1 1 0 0 1-1.414-1.414l2.046-2.047a.993.993 0 0 1 .6-.3.996.996 0 0 1 .637.135l.018.01a1.003 1.003 0 0 1 .467.847c0 .669.094 1.315.269 1.928l-.736-2.775z' />
	</svg>
);

Refresh.defaultProps = {
	fill: '',
	width: 24,
	height: 24,
	viewBox: '0 0 24 24',
};

Refresh.propTypes = {
	className: T.string,
};

export default Refresh;
