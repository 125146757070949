import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
	data: null,
	loading: false,
};

const getSearchProvidersStart = (state) => {
	return updateObject(state, { loading: true });
};

const getSearchProvidersSuccess = (state, action) => {
	return updateObject(state, {
		data: action.data,
		loading: false,
	});
};

const clearSearchProviders = () => {
	return initialState;
};

const searchProviders = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.GET_SEARCH_PROVIDERS_START:
			return getSearchProvidersStart(state, action);
		case actionTypes.GET_SEARCH_PROVIDERS_SUCCESS:
			return getSearchProvidersSuccess(state, action);

		case actionTypes.CLEAR_SEARCH_PROVIDERS:
			return clearSearchProviders(state, action);

		default:
			return state;
	}
};

export default searchProviders;
