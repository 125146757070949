import React from 'react';
import T from 'prop-types';

const Reminder = (props) => (
	<svg {...props} className={props.className}>
		<path d='M3.638 17.585c-1.043-.435-.634-1.35 0-1.812 2.486-1.812.802-4.711 2.005-8.335.599-1.803 1.838-2.88 3.717-3.23a2.044 2.044 0 0 1-.266-1.006C9.094 1.986 10.171 1 11.5 1c1.329 0 2.406.986 2.406 2.202 0 .362-.096.705-.266 1.006 1.88.35 3.118 1.427 3.717 3.23 1.203 3.624-.481 6.523 2.005 8.335.634.462 1.043 1.377 0 1.812H3.638zm5.029.976h6.181C14.381 19.986 13.173 21 11.758 21c-1.416 0-2.623-1.014-3.091-2.439z' />
	</svg>
);

Reminder.defaultProps = {
	fill: '#EFB815',
	width: 22,
	height: 22,
	viewBox: '0 0 22 22',
};

Reminder.propTypes = {
	className: T.string,
};

export default Reminder;
