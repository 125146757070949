import React, { Component } from 'react';
import PropTypes from 'prop-types';
import onClickOutside from 'react-onclickoutside';
import { AuxWrap } from 'hoc';
import { withTranslation } from 'react-i18next';

import s from './style.module.scss';

class TableFilter extends Component {
	state = {
		isOpen: false,
		checked: {},
	};

	componentDidUpdate(prevProps) {
		const { config, clear } = this.props;

		if (prevProps.config !== config) {
			clear();
		}
	}

	getCurrentValues = () => {
		const { config, checked, t } = this.props;
		const checkedArray = Object.values(checked);
		const values = checkedArray.map((obj) => t(obj[config.displayField]));

		return values.join(', ') || t(config.defaultValue);
	};

	getDefaultValue = () => {
		const { config, checked, t } = this.props;

		if (config.defaultValue) {
			const isNotSet = !Object.values(checked).length;

			return (
				<AuxWrap>
					<li
						className={isNotSet ? s.active : ''}
						onClick={this.onDefaultClick}
					>
						{t(config.defaultValue)}
					</li>
					<li className={s.separator}></li>
				</AuxWrap>
			);
		}
	};

	getItemstList = () => {
		const { config, data, checked, t } = this.props;
		return (
			data &&
			data.map((el) => {
				const isActive = checked[el.id] && s.active;

				if (
					config.propsKey === 'typesFilterProps' &&
					config.param === 'push_type_id'
				) {
					return (
						<li
							key={el.id}
							className={isActive}
							onClick={() => this.onItemClick(el)}
						>
							{t(el[config?.label.path] + el.id)}
						</li>
					);
				}
				return (
					<li
						key={el.id}
						className={isActive}
						onClick={() => this.onItemClick(el)}
					>
						{t(el[config.label.path])}
					</li>
				);
			})
		);
	};

	onSelectClick = () => {
		this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
	};

	onDefaultClick = () => {
		this.props.defaultChanged(this.props.config);
		this.props.clear();
	};

	onItemClick = (data) => {
		this.props.onItemClick(data, this.props.config);
	};

	handleClickOutside = () => {
		this.setState({ isOpen: false });
	};

	render() {
		const classes = [s.filterWrap, this.props.className];

		if (this.state.isOpen) {
			classes.push(s.open);
		}

		return (
			<div className={classes.join(' ')}>
				<div className={s.filterSelect} onClick={this.onSelectClick}>
					{this.getCurrentValues()}
				</div>

				{this.state.isOpen && (
					<div className={s.filterList}>
						<ul className={s.list}>
							{this.getDefaultValue()}
							{this.getItemstList()}
						</ul>
					</div>
				)}
			</div>
		);
	}
}

TableFilter.defaultProps = {
	config: {},
	data: [],
};

TableFilter.propTypes = {
	config: PropTypes.object,
	data: PropTypes.array,
};

export default withTranslation()(onClickOutside(TableFilter));
