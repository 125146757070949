import React from 'react';
import T from 'prop-types';

const PatientRefresh = (props) => (
	<svg {...props} className={props.className}>
		<path
			d='M13 26C9.3889 26 6.31945 24.7361 3.79165 22.2083C1.26388 19.6805 0 16.6111 0 13C0 9.3889 1.26388 6.31945 3.79165 3.79165C6.31945 1.26388 9.3889 0 13 0C15.1576 0 17.0986 0.440104 18.8229 1.32031C20.5472 2.20052 22.0368 3.42153 23.2917 4.98335V0H26V10.6708H15.3292V7.9625H22.1542C21.1792 6.3736 19.9085 5.10069 18.3422 4.14375C16.7759 3.18679 14.9951 2.70831 13 2.70831C10.1292 2.70831 7.69618 3.70588 5.70103 5.70103C3.70588 7.69618 2.70831 10.1292 2.70831 13C2.70831 15.8708 3.70588 18.3038 5.70103 20.299C7.69618 22.2941 10.1292 23.2917 13 23.2917C15.1937 23.2917 17.1934 22.6643 18.999 21.4094C20.8045 20.1545 22.0639 18.4979 22.7771 16.4396H25.5938C24.8174 19.2924 23.2646 21.599 20.9354 23.3594C18.6062 25.1198 15.9611 26 13 26Z'
			fill='#6D6D6D'
		/>
	</svg>
);

PatientRefresh.defaultProps = {
	fill: 'none',
	width: 26,
	height: 26,
	viewBox: '0 0 26 26',
};

PatientRefresh.propTypes = {
	className: T.string,
};

export default PatientRefresh;
