import React from 'react';
import T from 'prop-types';

const Download = (props) => (
	<svg {...props} className={props.className}>
		<path d='M12.065 15.998a.997.997 0 0 1-.796-.316 1.018 1.018 0 0 1-.026-.025L7.707 12.12a1 1 0 1 1 1.414-1.414L11 12.586V4a1 1 0 0 1 2 0v8.677l1.921-1.921a1 1 0 0 1 1.415 1.414L12.8 15.705a.997.997 0 0 1-.735.293zM18 19v-1a1 1 0 0 1 2 0v2a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-2a1 1 0 0 1 2 0v1h12z' />
	</svg>
);

Download.defaultProps = {
	fill: '#999',
	width: 24,
	height: 24,
	viewBox: '0 0 24 24',
};

Download.propTypes = {
	className: T.string,
};

export default Download;
