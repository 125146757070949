import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { permissions } from 'helpers';
import { Button } from 'components';
import FirmwareInfo from './FirmwareInfo';
import ChangeSummary from './ChangeSummary';
import Concentrators from './Concentrators';
import UsedConcentrators from './UsedConcentrators';

import s from './style.module.scss';
import i18next from 'i18next';
import {
	DeleteButton,
	EditIcon,
	CloseButton,
} from '../../../../../src/components/Icons';

const DetailsFoot = (props) => {
	const {
		data: { id, status_id, released_at },
		loading,
		onEdit,
		onDelete,
		onManage,
		onCopy,
	} = props;

	const manageHandler = () => onManage({ id, status_id: 1 });

	let deleteBtn,
		copyBtn,
		publishText = (
			<span className={s.titleTypography}>{i18next.t('publish_now')}</span>
		);
	let publishBtn = permissions.check('firmwares.update') && (
		<Button
			className={s.btn}
			btnType='type-21'
			disabled={loading || !released_at}
			clicked={manageHandler}
		>
			{publishText}
		</Button>
	);
	let updateBtn = permissions.check('firmwares.update') && (
		<Button className={s.btn} btnType='type-purple-outline' clicked={onEdit}>
			<EditIcon style={{ margin: '0', paddingRight: '8px' }} />
			{i18next.t('edit')}
		</Button>
	);
	switch (status_id) {
		case 1:
		case 3:
			publishBtn = null;
			updateBtn = null;
			copyBtn = permissions.check('firmwares.create') && (
				<Button
					className={s.btn}
					btnType='type-purple-outline'
					disabled={loading}
					clicked={() => onCopy({ id })}
				>
					{i18next.t('make_a_copy')}
				</Button>
			);
			break;
		case 5:
			deleteBtn = permissions.check('firmwares.delete') && (
				<Button
					className={s.btn}
					btnType='typeFirmware'
					disabled={loading}
					clicked={() => onDelete(id)}
				>
					<DeleteButton fill='#6C7DDF' style={{ marginRight: '10px' }} />
					{i18next.t('delete')}
				</Button>
			);
			break;
		default:
			break;
	}
	return (
		<div className={s.detailsFoot}>
			<div className={s.left}>
				{copyBtn}
				{updateBtn}
				{deleteBtn}
			</div>
			<div className={s.right}>{publishBtn}</div>
		</div>
	);
};

const DetailsPopup = (props) => {
	const { errors } = props;

	return (
		<Fragment>
			<div className={s.modalTitle}>
				<div className={s.titleAlign}>{props.title}</div>
				<div>
					{props.additionalPlaceholder}
					<CloseButton className={s.closeBtn} onClick={props.close} />
				</div>
			</div>

			<FirmwareInfo data={props.data} />
			<ChangeSummary {...props.data} />
			<Concentrators {...props.data} />
			<UsedConcentrators {...props.data} />

			{errors && (
				<ul className={s.errors}>
					{errors.map((el, index) => (
						<li key={index}>{el}</li>
					))}
				</ul>
			)}

			<DetailsFoot {...props} />
		</Fragment>
	);
};

DetailsPopup.defaultProps = {
	data: {},
	loading: false,
	onEdit: () => {},
	onDelete: () => {},
	onManage: () => {},
	onCopy: () => {},
};

DetailsPopup.propTypes = {
	data: PropTypes.object,
	loading: PropTypes.bool,
	onEdit: PropTypes.func,
	onDelete: PropTypes.func,
	onManage: PropTypes.func,
	onCopy: PropTypes.func,
};

export default DetailsPopup;
