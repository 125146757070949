import moment from 'moment';

export default {
	dataBody: {
		serial_number: {
			path: 'serial_number',
		},
		concentrator_model: {
			path: ['concentrator_model', 'description'],
		},
		connected_at: {
			path: 'connected_at',
			view: ({ value }) => value && moment(value).format('MM.DD.YYYY, HH:mm'),
		},
	},
};
