import * as actionTypes from '../../actions/actionTypes';
import { updateObject } from '../../utility';

const initialState = {
	data: [],
	loading: false,
};

const getSoftwareVersionsStart = (state) => {
	return updateObject(state, { loading: true });
};

const getSoftwareVersionsSuccess = (state, action) => {
	return updateObject(state, { data: action.data, loading: false });
};

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.ENGINEERING_GET_SOFTWARE_VERSIONS_START:
			return getSoftwareVersionsStart(state, action);
		case actionTypes.ENGINEERING_GET_SOFTWARE_VERSIONS_SUCCESS:
			return getSoftwareVersionsSuccess(state, action);

		default:
			return state;
	}
};

export default reducer;
