import { coreInstance as axios, api } from 'settings';
import { logger } from 'helpers';
import * as actionTypes from '../actionTypes';

export const setRequestParam = (params) => {
	return {
		type: actionTypes.SET_REQUEST_PARAM,
		params: params,
	};
};

const getProfilesReportStart = () => {
	return {
		type: actionTypes.GET_PROFILES_REPORT_START,
	};
};

const getProfilesReportSuccess = (data, isMerge) => {
	return {
		type: actionTypes.GET_PROFILES_REPORT_SUCCESS,
		data: data,
		isMerge: isMerge,
	};
};

export const getProfilesReport = (options, isMerge) => {
	return (dispatch) => {
		isMerge || dispatch(getProfilesReportStart());

		axios
			.get(api.concentrator_profiles.index, { params: options })
			.then((response) => {
				logger('[Action] Get profiles report');

				dispatch(getProfilesReportSuccess(response.data.data, isMerge));
			})
			.catch(() => {
				logger('[Action] Get profiles report fail');

				dispatch(getProfilesReportSuccess([], isMerge));
			});
	};
};

export const setReportFilter = ({ list, item }) => {
	return {
		type: actionTypes.SET_REPORT_FILTER,
		filters: list,
		item: item,
	};
};

export const removeReportFilter = (key, item) => {
	return {
		type: actionTypes.REMOVE_REPORT_FILTER,
		key: key,
		item: item,
	};
};
